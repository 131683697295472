import DomainResource from './DomainResource';
import Attachment from './types/Attachment';
import ContactPoint from './ContactPoint';
import Address from './Address';
import CodeableConcept from './types/CodeableConcept';
import Coding from './types/Coding';
import Identifier from './types/Identifier';
import OrganizationContact from './OrganizationContact';
import Reference from './types/Reference';

import { addressTraits } from './trait/addressTraits';
import { contactPointTraits } from './trait/contactPointTraits';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';

const contactTypeValues = {
    bill: 'BILL',
    admin: 'ADMIN',
    humanResource: 'HR',
    payor: 'PAYOR',
    patient: 'PATINF',
    press: 'PRESS',
};

export default class Organization extends DomainResource {
    static __className = 'Organization';

    __objectStructure = {
        // from Resource: id, meta, implicitRules, and language
        // from DomainResource: text, contained, extension, and modifierExtension
        identifier: [Identifier], // Identifies this organization across multiple systems
        active: Boolean, // Whether the organization's record is still in active use
        type: [CodeableConcept], // Kind of organization
        name: String, // Name used for the organization
        alias: [String], //  list of alternate names that the organization is known as, or was known as in the past
        telecom: [ContactPoint], // A contact detail for the organization, may never by type home
        address: [Address], // An address for the organization, may never by type home
        partOf: Reference, // The organization of which this organization forms a part
        contact: [OrganizationContact], // Date the answers were gathered
        endpoint: [Reference], // Technical endpoints providing access to services operated for the organization
        /**
         * First Class extensions
         */
        sessionTimeOut: Number,
        clientVocab: String,
        inputDateFormat: String,
        outputDateFormat: String,
        militaryTime: Boolean,
        softWipLimit: Number,
        timeZone: String,
        allProvider: Boolean,
        allIndividual: Boolean,
        allGroup: Boolean,
        comments: String,
        planName: String,
        sourceOfPayment: Coding,
        financialClass: [Reference],
        modeOfSubmission: String,
        payerQualifier: Coding,
        payerId: String,
        serviceTypes: CodeableConcept,
        claimType: Coding,
        photo: Attachment,
        keyComments: String,
        calendarTimeSlot: Number,
    };

    constructor(constructJson, className = 'Organization') {
        super(constructJson, className);

        addressTraits.call(this);
        contactPointTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    // Properties
    get nameDisplay() {
        return this.name;
    }

    get billingContact() {
        return this.getBillingContact() || '';
    }

    // We are only allowing 1 billing contact at this time
    set billingContact(value) {
        let billingContact = this.getBillingContact();
        if (billingContact) {
            this.contact.splice(this.contact.indexOf(billingContact), 1);
        }
        if (value === undefined) {
            return;
        }

        if (!(value instanceof OrganizationContact)) {
            value = new OrganizationContact(value);
        }

        // If the purpose property is empty assign it a billing code
        if (isEmpty(value.purpose.toJSON())) {
            value.purpose = { coding: [{ code: 'BILL' }] };
        }
        // Make sure code is BILL
        value.purpose.coding[0].code = 'BILL';

        this.contact = value;
    }

    get adminContact() {
        return this.getAdminContact() || '';
    }

    // We are only allowing 1 admin contact at this time
    set adminContact(value) {
        let adminContact = this.getAdminContact();
        if (adminContact) {
            this.contact.splice(this.contact.indexOf(adminContact), 1);
        }
        if (value === undefined) {
            return;
        }

        if (!(value instanceof OrganizationContact)) {
            value = new OrganizationContact(value);
        }

        // If the purpose property is empty assign it a billing code
        if (isEmpty(value.purpose.toJSON())) {
            value.purpose = { coding: [{ code: 'BILL' }] };
        }
        // Make sure code is ADMIN
        value.purpose.coding[0].code = 'ADMIN';

        this.contact = value;
    }

    // Methods
    getContactByType(contactCode, createIfNotExists = false) {
        if (!contactCode) return null;

        let contact = this.contact.find((contactItem) => contactItem.purpose.coding.find((contactPurpose) => contactPurpose.code === contactCode.toUpperCase()));

        if (contact) {
            return contact;
        }

        if (createIfNotExists) {
            let newContact = new OrganizationContact();

            newContact.purpose = new CodeableConcept({ coding: [{ code: contactCode }] });

            this.contact = newContact;
            return newContact;
        }

        return null;
    }

    getBillingContact(createIfNotExists = false) {
        return this.getContactByType(contactTypeValues.bill, createIfNotExists);
    }

    getAdminContact(createIfNotExists = false) {
        return this.getContactByType(contactTypeValues.admin, createIfNotExists);
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}

export { contactTypeValues, contactPointTraits };
