import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { addSeconds } from 'date-fns';
import { useAuthStore } from '@/stores/auth';
import { $http } from '@/common/api/http.service';
import debounce from 'lodash/debounce';

export const useSignedUrlStore = defineStore('signedUrl', () => {
    const signedUrls = ref([]);
    const failedSignedUrls = ref([]);
    const urlsLoading = ref([]);

    const getImageSignedUrl = async function (uploadedFile, duration = '') {
        if (!Array.isArray(uploadedFile)) uploadedFile = [uploadedFile];

        let loadFromServer = false;
        let storedUrls = [];

        // load Urls from url store, if possible
        for (const file of uploadedFile) {
            let signedUrl = getSignedUrl(file);

            storedUrls.push(signedUrl);

            if (!signedUrl) {
                loadFromServer = true;
                break;
            }
        }

        // if all url's are loaded from store.  return url's here
        if (!loadFromServer) {
            return { success: true, urls: storedUrls };
        }

        let filesToFetch = uploadedFile.filter((file) => !urlsLoading.value.includes(file));

        if (filesToFetch.length) {
            fetchImageSignedUrl(filesToFetch, duration);
        }

        return new Promise((resolve) => {
            watch(
                [() => signedUrls.value, () => failedSignedUrls.value],
                debounce(
                    () => {
                        if (uploadedFile.every((file) => signedUrls.value.find((sUrl) => sUrl.url === file))) {
                            let returnedUrls = uploadedFile.map((uf) => signedUrls.value.find((signedUrl) => signedUrl.url === uf).signedUrl);
                            resolve({ success: true, urls: returnedUrls });
                        } else if (uploadedFile.every((file) => failedSignedUrls.value.find((sUrl) => sUrl.url === file))) {
                            resolve({ success: false, urls: [] });
                        }
                    },
                    150,
                    { maxWait: 300 },
                ),
            );
        });
    };

    const fetchImageSignedUrl = async (uploadedFile, duration) => {
        urlsLoading.value.push(...uploadedFile);

        let data = {
            uploadedFile: uploadedFile,
        };

        if (duration) {
            data.duration = duration;
        }

        const authStore = useAuthStore();

        let getSignedUrlResponse =
            window.page === 'portal'
                ? await $http.post('/portal/account/' + authStore.account.id + '/images/getPortalSignedUrl', data)
                : await $http.post('/api/account/' + authStore.account.id + '/images/getSignedUrl', data);

        if (getSignedUrlResponse.data.success) {
            // Save signedUrls in url store
            uploadedFile.forEach((url, index) => {
                addSignedUrl(url, getSignedUrlResponse.data.urls[index], duration);
            });
        } else {
            uploadedFile.forEach((url) => {
                addFailedSignedUrl(url, duration);
            });
        }
    };

    const getFileSignedUrl = async function (uploadedFile, duration = '') {
        if (!Array.isArray(uploadedFile)) uploadedFile = [uploadedFile];

        let loadFromServer = false;
        let storedUrls = [];

        // load Urls from url store, if possible
        for (const file of uploadedFile) {
            let signedUrl = getSignedUrl(file);
            storedUrls.push(signedUrl);

            if (!signedUrl) {
                loadFromServer = true;
                break;
            }
        }

        // if all url's are loaded from store.  return url's here
        if (!loadFromServer) {
            return { success: true, urls: storedUrls };
        }

        let filesToFetch = uploadedFile.filter((file) => !urlsLoading.value.includes(file));

        if (filesToFetch.length) {
            fetchFileSignedUrl(filesToFetch, duration);
        }

        return new Promise((resolve) => {
            watch(
                [() => signedUrls.value, () => failedSignedUrls.value],
                debounce(
                    () => {
                        if (uploadedFile.every((file) => signedUrls.value.find((sUrl) => sUrl.url === file))) {
                            let returnedUrls = uploadedFile.map((uf) => signedUrls.value.find((signedUrl) => signedUrl.url === uf).signedUrl);
                            resolve({ success: true, urls: returnedUrls });
                        } else if (uploadedFile.every((file) => failedSignedUrls.value.find((sUrl) => sUrl.url === file))) {
                            resolve({ success: false, urls: [] });
                        }
                    },
                    150,
                    { maxWait: 300 },
                ),
            );
        });
    };

    const fetchFileSignedUrl = async (uploadedFile, duration) => {
        urlsLoading.value.push(...uploadedFile);

        let data = {
            uploadedFile: uploadedFile,
        };

        if (duration) {
            data.duration = duration;
        }

        const authStore = useAuthStore();

        let getSignedUrlResponse =
            window.page === 'portal'
                ? await $http.post('/portal/account/' + authStore.account.id + '/files/getPortalSignedUrl', data)
                : await $http.post('/api/account/' + authStore.account.id + '/files/getSignedUrl', data);

        if (getSignedUrlResponse.data.success) {
            // Save signedUrls in url store
            uploadedFile.forEach((url, index) => {
                addSignedUrl(url, getSignedUrlResponse.data.urls[index], duration);
            });
        } else {
            uploadedFile.forEach((url) => {
                addFailedSignedUrl(url, duration);
            });
        }
    };
    const addSignedUrl = (url, signedUrl, duration = 1440) => {
        cleanExpiredUrls();
        removeSignedUrl(url);

        signedUrls.value.push({
            url: url,
            signedUrl: signedUrl,
            expires: addSeconds(new Date(), duration),
        });

        urlsLoading.value = urlsLoading.value.filter((urlLoading) => urlLoading !== url);
    };

    const addFailedSignedUrl = (url, duration = 1440) => {
        cleanExpiredUrls();
        removeSignedUrl(url);

        failedSignedUrls.value.push({
            url: url,
            expires: addSeconds(new Date(), duration),
        });

        urlsLoading.value = urlsLoading.value.filter((urlLoading) => urlLoading !== url);
    };
    const getSignedUrl = (url) => {
        return signedUrls.value.find((entry) => entry.url === url && entry.expires > new Date())?.signedUrl;
    };
    const removeSignedUrl = (urlToRemove) => {
        signedUrls.value = signedUrls.value.filter((url) => url.url !== urlToRemove);
        failedSignedUrls.value = failedSignedUrls.value.filter((url) => url.url !== urlToRemove);
    };
    const cleanExpiredUrls = () => {
        signedUrls.value = signedUrls.value.filter((url) => url.expires > new Date());
        failedSignedUrls.value = failedSignedUrls.value.filter((url) => url.expires > new Date());
    };

    return { getImageSignedUrl, getFileSignedUrl };
});
