import { defineStore } from 'pinia';
import isArray from 'lodash/isArray';

export const useNotificationStore = defineStore('notification', {
    state() {
        return {
            notifications: [],
        };
    },
    actions: {
        add(notifications) {
            if (!isArray(notifications)) notifications = [notifications];

            notifications.forEach((notification) => {
                if (this.notifications.some((item) => item.message === notification.message)) return;
                this.notifications.push(notification);
            });
        },
        shift() {
            this.notifications.shift();
        },
    },
});
