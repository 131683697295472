import BackboneElement from './BackboneElement';
import ValueX from './types/ValueX';
import merge from 'lodash/merge';

export default class BN_ListItem extends BackboneElement {
    static __className = 'BN_ListItem';

    __objectStructure = {
        code: String,
        name: String,
        value: ValueX,
        status: String,
    };

    constructor(constructJson, className = 'BN_ListItem') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    get valueX() {
        return this.value ? this.value.internalValue : this.value;
    }

    // valueX Setter ONLY supports Boolean, Decimal, Integer and String along with the FHIR types:
    // Age, Attachment,CodeableConcept, Coding, Money, Period, Quantity, Range and Reference.
    // Assignment of FHIR type MUST be a FHIR entity NOT JSON.
    // Access to other ValueX supported type required standard ValueX access.
    set valueX(value) {
        if (!this.value) {
            this.value = new ValueX();
        }
        this.value.internalValue = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
