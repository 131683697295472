import Element from './Element';
import Period from './types/Period';

import merge from 'lodash/merge';

export const HumanNameUseType = {
    usual: 'usual',
    official: 'official',
    temp: 'temp',
    nickname: 'nickname',
    anonymous: 'anonymous',
    old: 'old',
    maiden: 'maiden',
};

export default class HumanName extends Element {
    static __className = 'HumanName';

    __objectStructure = {
        use: String, // usual | official | temp | nickname | anonymous | old | maiden
        text: String, // Text representation of the full name
        family: String, // Family name (often called 'Surname')
        given: [String], // Given names (not always 'first'). Includes middle names
        prefix: [String], // Parts that come before the name
        suffix: [String], // Parts that come after the name
        period: Period, // Time period when name was/is in use
        /**
         * AidBox First Class extensions
         */
        givenPhoneticSpelling: String,
    };

    constructor(humanNameJson, className = 'HumanName') {
        super(humanNameJson, className);
        this.createAndPopulateStructure(this.__objectStructure, humanNameJson);

        this.originalObjJson = this.toJSON();
    }

    // *************** Custom Getters/Setters ***************

    get firstName() {
        return this.getFirstName();
    }

    set firstName(value) {
        this.__given = Object.assign([], this.__given, { 0: !value ? undefined : value });

        if (this.__given[0] === undefined) {
            this.__given = [];
        }
    }

    get middleName() {
        return this.getMiddleName();
    }

    set middleName(value) {
        this.__given = Object.assign([], this.__given, { 1: !value ? undefined : value });

        if (this.__given[1] === undefined) {
            this.__given.splice(1, 1);
        }
    }

    get lastName() {
        return this.__family;
    }

    set lastName(value) {
        this.__family = !value ? undefined : value;
    }

    // Methods

    isUseType(nameType) {
        if (!nameType) {
            return false;
        }

        return this.use === nameType;
    }

    getGivenByIndex(indexNumber = 0) {
        if (indexNumber > this.given.length) {
            return undefined;
        }

        return this.given[indexNumber];
    }

    getFirstName() {
        return this.getGivenByIndex();
    }

    getMiddleName() {
        return this.getGivenByIndex(1);
    }

    getGivenNames() {
        return this.given;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
