import DomainResource from './DomainResource';
import Coding from './types/Coding';
import ContactPoint from './ContactPoint';
import Address from './Address';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Position from './Position';
import HoursOfOperation from './HoursOfOperation';
import addressTraits from '@/fhirworks/trait/addressTraits';
import { contactPointSystemValues, contactPointTraits, contactPointUseValues } from './trait/contactPointTraits';
import merge from 'lodash/merge';

export default class Location extends DomainResource {
    static __className = 'Location';

    /**
     * Ryan Christensen <ryan@bestnotes.com>
     * UPDATE: converted to use objectStructure method 12/15/21
     * NOTE: managingOrganization used to be initialized with Reference:
     * this._managingOrganization = new Reference(get(locationJson, 'managingOrganization', undefined));
     **/

    __objectStructure = {
        status: String,
        operationalStatus: Coding,
        name: String,
        description: String,
        mode: String,
        address: Address,
        availabilityExceptions: String,
        position: Position,
        physicalType: CodeableConcept,
        identifier: [Identifier],
        alias: [String],
        type: [CodeableConcept],
        telecom: [ContactPoint],
        managingOrganization: Reference,
        hoursOfOperation: [HoursOfOperation],
        endpoint: [Reference],
        partOf: Reference,
        /**
         * First Class extensions
         */
        pos: CodeableConcept,
        defaultProgram: Reference,
        defaultEncounterType: Reference,
        timeZone: String,
    };

    constructor(locationJson, className = 'Location') {
        super(locationJson, className);

        addressTraits.call(this);
        contactPointTraits.call(this);

        this.defaultContactPointSystemValue = contactPointSystemValues.phone;
        this.defaultContactPointUseType = contactPointUseValues.work;

        this.createAndPopulateStructure(this.__objectStructure, locationJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
