import merge from 'lodash/merge';

import Address from './Address';
import Attachment from './types/Attachment';
import ContactPoint from './ContactPoint';
import DomainResource from './DomainResource';
import HumanName from './HumanName';
import PersonLink from './PersonLink';
import Identifier from './types/Identifier';
import { contactPointSystemValues, contactPointTraits, contactPointUseValues } from './trait/contactPointTraits';
import { addressTraits } from './trait/addressTraits';
import humanNameTraits from './trait/humanNameTraits';
import { differenceInYears } from 'date-fns';

export default class Person extends DomainResource {
    static __className = 'Person';

    __objectStructure = {
        identifier: [Identifier],
        name: [HumanName],
        telecom: [ContactPoint],
        gender: String, // male | female | other | unknown
        birthDate: String,
        address: [Address],
        photo: Attachment,
        // managingOrganization: Reference,
        active: Boolean,
        link: [PersonLink],
        /**
         * First Class extensions
         */
        timeZone: String,
    };

    __objectDefaults = {
        photo: new Attachment(),
    };

    constructor(constructJson, className = 'Person') {
        super(constructJson, className);

        addressTraits.call(this);
        contactPointTraits.call(this);
        humanNameTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.defaultContactPointSystemValue = contactPointSystemValues.phone;
        this.defaultContactPointUseType = contactPointUseValues.mobile;

        this.originalObjJson = this.toJSON();
    }

    // *************** Getters & Setters ***************

    get age() {
        return differenceInYears(new Date(), new Date(this.birthDate));
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
