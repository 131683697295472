const bnVuelidateRequired = {
    install(app) {
        app.config.globalProperties.$bnVuelidateRequired = (validations, dataProperty) => {
            if (!validations[dataProperty?.toString()]) {
                return false;
            }

            return !validations[dataProperty.toString()]['required'];
        };
    },
};

export default bnVuelidateRequired;
