import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { Reference } from '@/fhirworks';

const postProcess = function (actions) {
    actions.forEach((item) => {
        if (item.action === 'sync') {
            item['resource'].setObjectSaved();
        }
    });
};

async function postTransaction(entry, returnTransaction = false) {
    let transaction = { type: 'transaction', entry: entry };
    // filter out empty items
    transaction.entry = transaction.entry.filter((item) => item);
    // if this is a subflow then just return the transactions
    if (returnTransaction) return { transaction: transaction };

    if (!transaction.entry.length) return;

    // extract post process actions
    let postProcessActions = [];
    transaction.entry.forEach((entry) => {
        if (entry.postProcess) {
            postProcessActions.push(entry.postProcess);
            delete entry.postProcess;
        }
    });
    let resultCollection = [];
    await $httpFhirApi
        .post('/', transaction)
        .then((transactionResult) => {
            resultCollection = transactionResult.data;
            postProcess(postProcessActions);
        })
        .catch(() => {
            resultCollection = null;
        });
    return resultCollection;
}

function getReference(resource, toJson) {
    if (!resource?.id) return undefined;
    const reference = new Reference({ id: resource.id, resourceType: resource.resourceType });
    reference.display = resource.fullName || resource.name || resource.title || resource.originalComplaint || resource.display;
    return toJson ? reference.toJSON() : reference;
}

export function useWorkflow() {
    return { postTransaction, getReference };
}
