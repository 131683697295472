import BackboneElement from '../BackboneElement';
import merge from 'lodash/merge';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';
import AsNeededX from '@/fhirworks/types/AsNeededX';
import Timing from '@/fhirworks/types/Timing';

/**
 * @property {String} value
 * @property {String} comparator
 * @property {String} unit
 * @property {String} system
 * @property {String} code
 */
export default class Dosage extends BackboneElement {
    static __className = 'Dosage';

    __objectStructure = {
        sequence: Number,
        text: String,
        additionalInstruction: [CodeableConcept],
        patientInstruction: String,
        timing: Timing,
        asNeeded: AsNeededX,
        site: CodeableConcept,
        route: CodeableConcept,
        method: CodeableConcept,
    };

    constructor(constructJson, className = 'Dosage') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
