import merge from 'lodash/merge';
import DomainResource from './DomainResource';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Annotation from './types/Annotation';
import EffectiveX from './types/EffectiveX';
import ClinicalImpressionInvestigation from '@/fhirworks/ClinicalImpressionInvestigation';
import ClinicalImpressionFinding from '@/fhirworks/ClinicalImpressionFinding';
import get from 'lodash/get';
import ValueX from '@/fhirworks/types/ValueX';

export default class ClinicalImpression extends DomainResource {
    static __className = 'ClinicalImpression';

    __objectStructure = {
        identifier: [Identifier],
        status: String, // in-progress | completed | entered-in-error
        statusReason: CodeableConcept,
        code: CodeableConcept,
        description: String,
        subject: Reference, // Patient or group assessed
        encounter: Reference, // Encounter created as part of
        effective: EffectiveX,
        date: String,
        assessor: Reference, // Practitioner | PractitionerRole
        previous: Reference, // ClinicalImpression
        problem: [Reference], // Condition | AllergyIntolerance
        investigation: [ClinicalImpressionInvestigation],
        protocol: [String],
        summary: String,
        finding: [ClinicalImpressionFinding],
        prognosisCodeableConcept: [CodeableConcept],
        prognosisReference: [Reference], // RiskAssessment
        supportingInfo: [Reference], // Any
        note: [Annotation],

        /**
         * AidBox First Class extensions
         */
        formulationCategory: String, // standard | ASAM | LOCUS
    };

    constructor(constructJson, className = 'ClinicalImpression') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        // this.populateCustomPropertiesFromJson(constructJson, []);
        let effectiveXEffectiveDateTime = get(constructJson, 'effectiveDateTime', undefined);
        if (effectiveXEffectiveDateTime) {
            this.effectiveDateTime = effectiveXEffectiveDateTime;
        }

        let effectiveXEffectivePeriod = get(constructJson, 'effectivePeriod', undefined);
        if (effectiveXEffectivePeriod) {
            this.effectivePeriod = effectiveXEffectivePeriod;
        }

        this.originalObjJson = this.toJSON();
    }

    get effectiveDateTime() {
        if (!this.effective) {
            return undefined;
        }
        return this.effective.effectiveDateTime;
    }
    set effectiveDateTime(value) {
        if (!this.effective) {
            this.effective = new EffectiveX();
        }
        this.effective.effectiveDateTime = value;
    }

    get effectivePeriod() {
        if (!this.effective) {
            return undefined;
        }
        return this.effective.effectivePeriod;
    }
    set effectivePeriod(value) {
        if (!this.effective) {
            this.effective = new EffectiveX();
        }
        this.effective.effectivePeriod = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
