// pro solid icons (fas)
import {
    faArrowAltRight,
    faBookmark,
    faCaretDown,
    faCaretUp,
    faCircle,
    faColumns,
    faDollarSign,
    faSortDown,
    faFilter,
    faGlobeAmericas,
    faMapMarkerAlt,
    faPeoplePantsSimple,
    faPeopleRoof,
    faStar,
    faStarHalfAlt,
    faSort,
    faSortUp,
    faUnlock,
    faUsers,
    faPersonCirclePlus,
    faFlask,
} from '@fortawesome/pro-solid-svg-icons';

export default (library) => {
    library.add(
        faArrowAltRight,
        faBookmark,
        faCaretDown,
        faCaretUp,
        faCircle,
        faColumns,
        faDollarSign,
        faSortDown,
        faFilter,
        faGlobeAmericas,
        faMapMarkerAlt,
        faPeoplePantsSimple,
        faPeopleRoof,
        faStar,
        faStarHalfAlt,
        faSort,
        faSortUp,
        faUnlock,
        faUsers,
        faPersonCirclePlus,
        faFlask,
    );
};
