import merge from 'lodash/merge';
import Element from './Element';

/**
 * @property {String} renderXhtml
 * */
export default class QuestionnaireItem_text extends Element {
    static __className = 'QuestionnaireItem_text';

    __objectStructure = {
        /**
         * First Class extensions
         */
        renderingXhtml: String,
    };

    constructor(constructJson, className = 'QuestionnaireItem_text') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
