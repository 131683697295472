<script>
import { mergeProps } from 'vue';
import _ from 'lodash';
export default {
    name: 'BnButton',
    emits: ['click'],
    props: {
        label: String,
        icon: String,
        iconFamily: {
            type: String,
            default: 'far',
        },
        activator: Boolean,
        dense: Boolean,
        toolTip: String,
    },
    setup() {
        return { mergeProps };
    },
    computed: {
        buttonAttributes() {
            let attrs = _.clone(this.$attrs);

            if (!!this.icon && !this.hasAttr('outlined') && !this.hasAttr('text')) {
                attrs.icon = true;
                attrs.variant = 'plain';
            }
            attrs.color = this.$attrs.color ? this.$attrs.color : this.hasAttr('dark') ? 'white' : 'secondary';
            // density="compact" (xs remove label and outline)
            if (this.hasAttr('outlined')) {
                attrs.variant = 'outlined';
            } else if (this.hasAttr('text')) {
                attrs.variant = 'text';
            }
            if (this.dense && this.$vuetify.display.xs) {
                attrs.icon = true;
                attrs.size = 'large';
            }

            return attrs;
        },
    },
    methods: {
        click() {
            this.$emit('click');
        },
        hasAttr(attr) {
            return this.$attrs[attr] === '';
        },
    },
};
</script>

<template>
    <v-tooltip v-if="toolTip" bottom>
        <template #activator="{ props }">
            <v-btn v-bind="mergeProps(props, buttonAttributes)" @click.stop="click">
                <font-awesome-icon v-if="icon" :icon="[iconFamily, icon]" size="lg" />
                <span v-if="label && !$vuetify.display.xs" v-html="label" :class="{ 'ml-2': $vuetify.display.mdAndUp && icon, 'text-subtitle-2': hasAttr('fab') }"></span>
                <font-awesome-icon v-if="activator" :icon="['fas', 'caret-down']" size="lg" class="ml-3" />
            </v-btn>
        </template>
        <span v-html="toolTip"></span>
    </v-tooltip>
    <v-btn v-else v-bind="buttonAttributes" @click.stop="click">
        <font-awesome-icon v-if="icon" :icon="[iconFamily, icon]" size="lg" />
        <span v-if="label && !$vuetify.display.xs" v-html="label" :class="{ 'ml-2': icon, 'text-subtitle-2': hasAttr('fab') }"></span>
        <font-awesome-icon v-if="activator" :icon="['fas', 'caret-down']" size="lg" class="ml-3" />
    </v-btn>
</template>
