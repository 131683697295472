export default [
    {
        path: 'reports',
        name: 'reports',
        redirect: { name: 'services-report' },
        component: () => import('../views/ReportsPage.vue'),
        children: [
            {
                path: 'services-report',
                name: 'services-report',
                meta: { label: 'Services report' },
                component: () => import('../views/reportsLibrary/ServicesReportPage.vue'),
                props: true,
            },
        ],
    },
];
