import BackboneElement from './BackboneElement';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';
import Reference from '@/fhirworks/types/Reference';
import merge from 'lodash/merge';

export default class CarePlanActivityDetail extends BackboneElement {
    static __className = 'CarePlanActivityDetail';

    __objectStructure = {
        code: CodeableConcept, // Detail type of activity
        goal: [Reference], // Goals this activity relates to
        status: String, // *required* not-started | scheduled | in-progress | on-hold | completed | cancelled | stopped | unknown | entered-in-error
        performer: [Reference], // Who will be responsible?
        //first-class extensions
        createdDate: String,
    };

    constructor(constructJson, className = 'CarePlanActivityDetail') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
