import BaseElement from '../structure/BaseElement';
import merge from 'lodash/merge';
import CodeableConcept from '../types/CodeableConcept';
import Period from '../types/Period';
import Reference from '../types/Reference';

/**
 * @property {String} use // usual | official | temp | secondary | old (If known)
 * @property {CodeableConcept} type // Description of identifier
 * @property {String} system // The namespace for the identifier value
 * @property {String} value // The value that is unique
 * @property {Period} period // Time period when id is/was valid for use
 * @property {Reference} assigner // Organization that issued id (may be just text)
 */
export default class Identifier extends BaseElement {
    static __className = 'Identifier';

    __objectStructure = {
        use: String, // usual | official | temp | secondary | old (If known)
        type: CodeableConcept, // Description of identifier
        system: String, // The namespace for the identifier value
        value: String, // The value that is unique
        period: Period, // Time period when id is/was valid for use
        assigner: Reference, // Organization that issued id (may be just text)
    };

    __objectDefaults = {
        type: new CodeableConcept(),
    };

    constructor(constructJson, className = 'Identifier') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
