import merge from 'lodash/merge';
import DomainResource from './DomainResource';
import CoverageClass from './CoverageClass';
import CoverageCostToBeneficiary from './CoverageCostToBeneficiary';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Period from './types/Period';
import get from 'lodash/get';
import CoveragePayor from './CoveragePayor';

export default class Coverage extends DomainResource {
    static __className = 'Coverage';

    __objectStructure = {
        identifier: [Identifier],
        status: String, // active | cancelled | draft | entered-in-error
        type: CodeableConcept,
        policyHolder: Reference,
        subscriber: Reference,
        subscriberId: String,
        beneficiary: Reference,
        dependent: String,
        relationship: CodeableConcept,
        period: Period,
        payor: [CoveragePayor],
        class: [CoverageClass],
        order: Number,
        network: String,
        costToBeneficiary: [CoverageCostToBeneficiary],
        subrogation: Boolean,
        contract: [Reference],
        /**
         * First Class extensions
         */
        claimId: String,
        medicareSecondaryIndicator: CodeableConcept,
        insurancePriority: CodeableConcept,
        patientOrAuthorizedSignature: String,
        acceptAssignment: String,
    };

    __objectDefaults = {
        type: new CodeableConcept({ coding: [] }),
        relationship: new CodeableConcept({ coding: [] }),
    };

    constructor(constructJson, className = 'Coverage') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();

        // Must be done AFTER the storing of the originalObjJson property above
        let emptyCodeableConceptJson = { coding: [{ code: undefined, display: undefined }] };

        let constructorMedicareSecondaryIndicatorValue = get(constructJson, 'medicareSecondaryIndicator', undefined);
        if (!constructorMedicareSecondaryIndicatorValue && this.medicareSecondaryIndicator === undefined) {
            this.medicareSecondaryIndicator = new CodeableConcept(emptyCodeableConceptJson);
        }

        let constructorInsurancePriorityValue = get(constructJson, 'insurancePriority', undefined);
        if (!constructorInsurancePriorityValue && this.insurancePriority === undefined) {
            this.insurancePriority = new CodeableConcept(emptyCodeableConceptJson);
        }
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
