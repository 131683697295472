import DomainResource from './DomainResource';
import merge from 'lodash/merge';

export default class BN_AccessGroup extends DomainResource {
    static _className = 'BN_AccessGroup';

    _objectStructure = {
        name: String,
        active: Boolean,
        questionnaire: [String],
    };

    _objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'BN_AccessGroup') {
        super(constructJson, className);
        this.createAndPopulateStructure(this._objectStructure, constructJson, this._objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this._objectStructure));
    }
}
