import merge from 'lodash/merge';
import BackboneElement from '@/fhirworks/BackboneElement';
import ValueX from './types/ValueX';

export default class DocumentReferenceContentProfile extends BackboneElement {
    static __className = 'DocumentReferenceContentProfile';

    __objectStructure = {
        value: ValueX,
    };

    constructor(constructJson, className = 'DocumentReferenceContentProfile') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }
    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
