import axios from 'axios';
import axiosRetry from 'axios-retry';
import { $log } from '@/common/api/log.service';

const googleAPIBaseUrl = 'https://maps.googleapis.com/maps/api/';
const GEOCODING_BAD_ADDRESS_ENTITY = 'Address, City or State information required';

export const googleTimezoneAPI = axios.create({
    baseURL: googleAPIBaseUrl,
    headers: {
        get: { Accept: 'application/json' },
    },
});

axiosRetry(googleTimezoneAPI, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
});

export function getGoogleTimeZoneInfo(locationInfo) {
    let latLngTuple = locationInfo.lat + ',' + locationInfo.lng;
    return googleTimezoneAPI
        .get('timezone/json?location=' + latLngTuple + '&timestamp=' + Math.floor(new Date().getTime() / 1000) + '&key=' + import.meta.env.VITE_GOOGLE_TIMEZONE_API_KEY)
        .then((res) => {
            if (res.data.status !== 'OK') {
                throw new Error(res.data.status);
            }
            return Promise.resolve(res);
        })
        .catch((error) => {
            $log.error('googleTimeZoneAPI Error', error.message);
            return Promise.reject(error.message);
        });
}

export function getGoogleGeocodingInfo(addressEntity) {
    if (!(addressEntity.getAddressLine() || addressEntity.city || addressEntity.state)) {
        return Promise.reject(GEOCODING_BAD_ADDRESS_ENTITY);
    }
    let addressInfo = encodeURI(addressEntity.getAddressLine()) + ',' + encodeURI(addressEntity.city) + ',' + encodeURI(addressEntity.state);

    return googleTimezoneAPI
        .get('geocode/json?address=' + addressInfo + '&key=' + import.meta.env.VITE_GOOGLE_TIMEZONE_API_KEY)
        .then((res) => {
            if (res.data.status !== 'OK') {
                throw new Error(res.data.status);
            }
            return Promise.resolve(res);
        })
        .catch((error) => {
            $log.error('googleGeocodingAPI Error', error.message);
            return Promise.reject(error.message);
        });
}
