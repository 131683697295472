import merge from 'lodash/merge';
import Identifier from './types/Identifier';
import Attachment from './types/Attachment';
import Coding from './types/Coding';
import CodeableConcept from './types/CodeableConcept';
import { addressTraits } from './trait/addressTraits';
import humanNameTraits from './trait/humanNameTraits';
import HumanName from './HumanName';
import ContactPoint from './ContactPoint';
import Address from './Address';
import DomainResource from './DomainResource';
import PractitionerQualification from './PractitionerQualification';
import { contactPointSystemValues, contactPointTraits, contactPointUseValues } from './trait/contactPointTraits';
import humanExtensionValueTraits from './trait/humanExtensionValueTraits';
import { differenceInYears } from 'date-fns';
import Reference from '@/fhirworks/types/Reference';

export default class Practitioner extends DomainResource {
    static __className = 'Practitioner';

    __objectStructure = {
        identifier: [Identifier],
        active: Boolean,
        name: [HumanName],
        telecom: [ContactPoint],
        address: [Address],
        gender: String, // male | female | other | unknown
        birthDate: String,
        photo: [Attachment],
        qualification: [PractitionerQualification],
        communication: [CodeableConcept],
        /**
         * First Class extensions
         */
        isSupervisor: Boolean,
        primaryLocation: Reference,
        primaryLocationBrand: Reference,
        pronouns: Coding,
        religion: CodeableConcept,
        sendSms: String,
        sexualOrientation: Coding,
        timeZone: String,
        keyComments: String,
    };

    constructor(constructJson, className = 'Practitioner') {
        super(constructJson, className);

        addressTraits.call(this);
        contactPointTraits.call(this);
        humanNameTraits.call(this);
        humanExtensionValueTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.defaultContactPointSystemValue = contactPointSystemValues.phone;
        this.defaultContactPointUseType = contactPointUseValues.mobile;

        this.populateCustomPropertiesFromJson(constructJson, ['spokenLanguage', 'ssn']);

        this.originalObjJson = this.toJSON();
    }

    // *************** Getters & Setters ***************

    get age() {
        return differenceInYears(new Date(), new Date(this.birthDate));
    }

    // *************** US Core Extension Properties ***************
    // Supported by humanExtensionValueTraits
    // race, raceCategories, raceDetailed, ethnicity, ethnicityCategory, ethnicityDetailed,
    // spokenLanguage, pronouns, sexualOrientation, birthSex, genderIdentity, religion

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
