import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection, capSQLiteUpgradeOptions } from '@capacitor-community/sqlite';
import { BehaviorSubject } from 'rxjs';
import { getCurrentInstance } from 'vue';
import { IDbVersionService } from '../services/dbVersionService';
import { UserUpgradeStatements } from './upgrades/user.upgrade.statements';
import { Capacitor } from '@capacitor/core';

export interface IStorageService {
    getDatabaseName(): string;
    getDatabaseVersion(): number;
    initWebStore(): Promise<void>;
    addUpgradeStatement(options: capSQLiteUpgradeOptions): Promise<void>;
    openDatabase(dbName: string, loadToVersion: number, readOnly: boolean): Promise<SQLiteDBConnection>;
    isConnection(dbName: string, readOnly: boolean): Promise<boolean>;
    closeDatabase(dbName: string, readOnly: boolean): Promise<void>;
    saveToStore(dbName: string): Promise<void>;
    saveToLocalDisk(dbName: string): Promise<void>;
    initializeDatabase(): Promise<void>;
    checkSQLiteDbConnection(): Promise<SQLiteDBConnection>;
    getQuerySQLite(stmt: string, values: any[], closeConn?: boolean): Promise<any[]>;
    insertQuerySQLite(stmt: string, values: any[], closeConn?: boolean): Promise<any>;
}
class StorageService implements IStorageService {
    sqlitePlugin = CapacitorSQLite;
    sqliteConnection = new SQLiteConnection(CapacitorSQLite);
    dbNameVersionDict: Map<string, number> = new Map();
    versionUpgrades = UserUpgradeStatements;
    loadToVersion = UserUpgradeStatements[UserUpgradeStatements.length - 1].toVersion;
    db!: SQLiteDBConnection;
    database: string = 'fresh';
    dbVerServ!: IDbVersionService;
    isInitCompleted = new BehaviorSubject(false);
    appInstance = getCurrentInstance();
    platform!: string;
    dbVal!: Boolean;

    constructor(dbVersionService: IDbVersionService) {
        this.dbVerServ = dbVersionService;
        this.platform = Capacitor.getPlatform();
    }

    getDatabaseName(): string {
        return this.database;
    }
    getDatabaseVersion(): number {
        return this.loadToVersion;
    }

    // for web application storage
    async initWebStore(): Promise<void> {
        try {
            await this.sqliteConnection.initWebStore();
        } catch (error) {
            const msg = error.message ? error.message : error;
            throw new Error(`storageService.initWebStore: ${msg}`);
        }
        return;
    }
    async addUpgradeStatement(options: capSQLiteUpgradeOptions): Promise<void> {
        try {
            await this.sqlitePlugin.addUpgradeStatement(options);
        } catch (error) {
            const msg = error.message ? error.message : error;
            throw new Error(`storageService.addUpgradeStatement: ${msg}`);
        }
        return;
    }
    async openDatabase(dbName: string, loadToVersion: number, readOnly: boolean): Promise<SQLiteDBConnection> {
        this.dbNameVersionDict.set(dbName, loadToVersion);
        let encrypted = false;
        const mode = encrypted ? 'secret' : 'no-encryption';
        try {
            let db: SQLiteDBConnection;
            const retCC = (await this.sqliteConnection.checkConnectionsConsistency()).result;
            let isConn = (await this.sqliteConnection.isConnection(dbName, readOnly)).result;
            if (retCC && isConn) {
                db = await this.sqliteConnection.retrieveConnection(dbName, readOnly);
            } else {
                db = await this.sqliteConnection.createConnection(dbName, encrypted, mode, loadToVersion, readOnly);
            }
            const jeepSQlEL = document.querySelector('jeep-sqlite');

            await db.open();
            const res = await db.isDBOpen();
            return db;
        } catch (error) {
            const msg = error.message ? error.message : error;
            throw new Error(`storageService.openDatabase: ${msg}`);
        }
    }
    async isConnection(dbName: string, readOnly: boolean): Promise<boolean> {
        try {
            const isConn = (await this.sqliteConnection.isConnection(dbName, readOnly)).result;
            if (isConn != undefined) {
                return isConn;
            } else {
                throw new Error(`storageService.isConnection undefined`);
            }
        } catch (error) {
            const msg = error.message ? error.message : error;
            throw new Error(`storageService.isConnection: ${msg}`);
        }
    }
    async closeDatabase(dbName: string, readOnly: boolean): Promise<void> {
        try {
            const isConn = (await this.sqliteConnection.isConnection(dbName, readOnly)).result;
            if (isConn) {
                let testConnection = await this.sqliteConnection.closeConnection(dbName, readOnly);
            }
            return;
        } catch (error) {
            const msg = error.message ? error.message : error;
            throw new Error(`storageService.closeDatabase: ${msg}`);
        }
    }
    async saveToStore(dbName: string): Promise<void> {
        try {
            await this.sqliteConnection.saveToStore(dbName);
            return;
        } catch (error) {
            const msg = error.message ? error.message : error;
            throw new Error(`storageService.saveToStore: ${msg}`);
        }
    }
    async saveToLocalDisk(dbName: string): Promise<void> {
        try {
            await this.sqliteConnection.saveToLocalDisk(dbName);
            return;
        } catch (err) {
            const msg = err.message ? err.message : err;
            throw new Error(`storageService.saveToLocalDisk: ${msg}`);
        }
    }
    async initializeDatabase(): Promise<void> {
        // create upgrade statements
        try {
            await this.addUpgradeStatement({ database: this.database, upgrade: this.versionUpgrades });
            this.db = await this.openDatabase(this.database, this.loadToVersion, false);
            this.dbVerServ.setDbVersion(this.database, this.loadToVersion);
            if (this.platform === 'web') {
                await this.saveToStore(this.database);
            }
            this.isInitCompleted.next(true);
        } catch (error) {
            const msg = error.message ? error.message : error;
            throw new Error(`storageService.initializeDatabase: ${msg}`);
        }
    }
    async checkSQLiteDbConnection(): Promise<SQLiteDBConnection> {
        let isConn = (await this.sqliteConnection.isConnection(this.database, false)).result;
        if (!isConn) {
            this.db = await this.openDatabase(this.database, this.loadToVersion, false);
        }
        return this.db;
    }
    async getQuerySQLite(stmt: string, values: any[], closeConn: boolean = false): Promise<any> {
        try {
            let response = await this.db.query(stmt, values);
            return response.values;
        } catch (err) {
            const msg = err.message ? err.message : err;
            throw new Error(`getQueryInSQLiteService: ${msg}`);
        }
    }

    async insertQuerySQLite(stmt: string, values: any[], closeConn: boolean = false): Promise<any> {
        try {
            //const res = await this.db.execute(stmt);
            const res = await this.db.run(stmt, values);
            if (res.changes !== undefined && res.changes.lastId !== undefined && res.changes.lastId > 0) {
                return res.changes.lastId;
            } else {
                throw new Error(`insertQueryInSQLiteService: lastId not returned`);
            }
        } catch (err) {
            const msg = err.message ? err.message : err;
            throw new Error(`insertQueryInSQLiteService: ${msg}`);
        }
    }
}
export default StorageService;
