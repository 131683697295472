<script>
export default {
    name: 'BnChip',
    props: {
        modelValue: String,
        color: {
            type: String,
            default: 'grey-lighten-2',
        },
        icon: String,
        dark: Boolean,
        clickable: Boolean,
        selectable: Boolean,
        checked: Boolean,
        label: Boolean,
        native: Boolean,
    },
    computed: {
        computedClass: function () {
            let classes = ['bg-' + this.color];
            if (this.label) classes.push('bn-chip-label');
            if (this.dark) classes.push('text-white');
            if (this.clickable) classes.push('bn-chip-clickable');
            return classes.join(' ');
        },
        clickHandler() {
            return this.clickable ? 'click' : null;
        },
    },
    methods: {
        click() {
            this.$emit('click', this.modelValue);
        },
    },
};
</script>

<template>
    <v-chip v-if="native" v-bind="$attrs" :color="color" @[clickHandler].stop="click" label class="no-select px-2">
        <font-awesome-icon v-if="icon" :icon="['far', icon]" />
        <span v-if="modelValue" :class="{ 'ml-2': icon }" v-html="modelValue"></span>
        <span v-else :class="{ 'ml-2': icon && $slots.default }">
            <slot></slot>
        </span>
    </v-chip>
    <div v-else class="bn-chip" :class="computedClass" @[clickHandler].stop="click">
        <font-awesome-icon v-if="icon" :icon="['far', icon]" class="bn-chip-icon" />
        <div class="flex-grow-1">
            <span v-if="modelValue" :class="{ 'ml-2': icon && modelValue }" v-html="modelValue"></span>
            <span v-else :class="{ 'ml-2': icon && $slots.default }">
                <slot></slot>
            </span>
        </div>
        <div v-if="selectable" class="bn-chip-selectable">
            <font-awesome-icon v-if="checked" :icon="['far', 'check']" class="bn-chip-icon-right" />
        </div>
    </div>
</template>

<style lang="scss">
.bn-chip {
    display: flex;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 10px;
    margin: 4px 0;
    user-select: none;
    &-label {
        border-radius: 4px !important;
    }
    &-selectable {
        display: flex;
        flex-grow: 0;
        padding-left: 8px;
        width: 20px;
    }
    &-icon {
        flex-grow: 0;
        margin-top: 3px;
        &-right {
            align-self: center;
        }
    }
    &-clickable {
        cursor: pointer;
    }
}
</style>
