export const LOG_CRITICAL = 'CRITICAL';
export const LOG_ERROR = 'ERROR';
export const LOG_WARNING = 'WARNING';
export const LOG_NOTICE = 'NOTICE';
export const LOG_INFO = 'INFO';
export const LOG_DEBUG = 'DEBUG';

export default class LogEntry {
    level;
    summary;
    description;
    trace;
    occurred;
    logId;

    constructor(level = LOG_ERROR, summary = null, description = null, trace = null, occurred = null, logId = null) {
        this.level = level;
        this.summary = summary;
        this.description = description;
        this.trace = trace;
        this.occurred = occurred ? occurred : new Date();
        this.logId = logId;
    }
}
