import PatientRace from '../PatientRace';
import Coding from '../types/Coding';
import PatientEthnicity from '../PatientEthnicity';

const patientUsCoreTraits = function () {
    //**********************************
    // Race
    // https://build.fhir.org/ig/HL7/US-Core/StructureDefinition-us-core-race.html
    //**********************************

    Object.defineProperty(this, 'race', {
        get() {
            return this.__race;
        },
        set(value) {
            if (value && !(value instanceof PatientRace)) {
                value = new PatientRace(value);
            }
            this.__race = value;
        },
    });

    Object.defineProperty(this, 'raceCategories', {
        get() {
            return this.__race?.ombCategory;
        },
        set(value) {
            if (!this.__race || !(this.__race instanceof PatientRace)) {
                this.__race = new PatientRace();
            }

            if (Array.isArray(value)) {
                this.__race.ombCategory?.splice(0);
                value.forEach((item) => {
                    if (!(item instanceof Coding)) {
                        item = new Coding(item);
                    }
                    this.__race.ombCategory = item;
                });
                return;
            }

            if (value instanceof Coding) {
                this.__race.ombCategory = value;
                return;
            }

            if (value === undefined) {
                this.__race.ombCategory?.splice(0);
                return;
            }

            this.__race.ombCategory = new Coding(value);
        },
    });

    Object.defineProperty(this, 'raceDetailed', {
        get() {
            return this.__race?.detailed;
        },
        set(value) {
            if (!this.__race || !(this.__race instanceof PatientRace)) {
                this.__race = new PatientRace();
            }

            if (Array.isArray(value)) {
                this.__race.detailed?.splice(0);
                value.forEach((item) => {
                    if (!(item instanceof Coding)) {
                        item = new Coding(item);
                    }
                    this.__race.detailed = item;
                });
                return;
            }

            if (value instanceof Coding) {
                this.__race.detailed = value;
                return;
            }

            if (value === undefined) {
                this.__race.detailed?.splice(0);
                return;
            }

            this.__race.detailed = new Coding(value);
        },
    });

    Object.defineProperty(this, 'raceDescription', {
        get() {
            return this.__race?.text;
        },
    });

    // *************** Related Race Functions ***************

    this.removeRace = function (removeItem) {
        if (!(removeItem instanceof Coding)) {
            return false;
        }

        let itemRemoved = false;
        let itemIndex = this.__race?.ombCategory.indexOf(removeItem);
        if (itemIndex >= 0) {
            this.__race.ombCategory.splice(itemIndex, 1);
            itemRemoved = true;
        }
        itemIndex = this.__race?.detailed.indexOf(removeItem);
        if (itemIndex >= 0) {
            this.__race.detailed.splice(itemIndex, 1);
            itemRemoved = true;
        }

        if (itemRemoved) {
            return true;
        }

        return false;
    };

    // *************** Related Race Functions ***************

    //**********************************
    // Ethnicity
    // http://build.fhir.org/ig/HL7/US-Core/StructureDefinition-us-core-ethnicity.html
    //**********************************
    Object.defineProperty(this, 'ethnicity', {
        get() {
            return this.__ethnicity;
        },
        set(value) {
            if (value && !(value instanceof PatientEthnicity)) {
                value = new PatientEthnicity(value);
            }
            this.__ethnicity = value;
        },
    });

    Object.defineProperty(this, 'ethnicityCategory', {
        get() {
            return this.__ethnicity?.ombCategory;
        },
        set(value) {
            if (!this.__ethnicity || !(this.__ethnicity instanceof PatientEthnicity)) {
                this.__ethnicity = new PatientEthnicity();
            }

            if (value instanceof Coding) {
                this.__ethnicity.ombCategory = value;
                return;
            }

            if (value === undefined || value === null) {
                this.__ethnicity.ombCategory = undefined;
                return;
            }

            this.__ethnicity.ombCategory = new Coding(value);
        },
    });

    Object.defineProperty(this, 'ethnicityDetailed', {
        get() {
            return this.__ethnicity?.detailed;
        },
        set(value) {
            if (!this.__ethnicity || !(this.__ethnicity instanceof PatientEthnicity)) {
                this.__ethnicity = new PatientEthnicity();
            }

            if (Array.isArray(value)) {
                this.__ethnicity.detailed?.splice(0);
                value.forEach((item) => {
                    if (!(item instanceof Coding)) {
                        item = new Coding(item);
                    }
                    this.__ethnicity.detailed = item;
                });
                return;
            }

            if (value instanceof Coding) {
                this.__ethnicity.detailed = value;
                return;
            }

            if (value === undefined) {
                this.__ethnicity.detailed?.splice(0);
                return;
            }

            this.__ethnicity.detailed = new Coding(value);
        },
    });

    Object.defineProperty(this, 'ethnicityDescription', {
        get() {
            return this.__ethnicity?.text;
        },
    });

    // *************** Related Ethnicity Functions ***************

    this.removeEthnicity = function (removeItem) {
        if (!(removeItem instanceof Coding)) {
            return false;
        }

        let itemRemoved = false;
        if (this.__ethnicity?.ombCategory === removeItem) {
            this.__ethnicity.ombCategory = undefined;
            itemRemoved = true;
        }
        let itemIndex = this.__ethnicity?.detailed.indexOf(removeItem);
        if (itemIndex >= 0) {
            this.__ethnicity.detailed.splice(itemIndex, 1);
            itemRemoved = true;
        }

        if (itemRemoved) {
            return true;
        }

        return false;
    };

    // *************** Related Ethnicity Functions ***************

    return this;
};

export default patientUsCoreTraits;
