import BackboneElement from './BackboneElement';
import OperationOutcome from './OperationOutcome';

import merge from 'lodash/merge';

export default class BundleEntryResponse extends BackboneElement {
    static __className = 'BundleEntryResponse';

    __objectStructure = {
        status: String,
        location: String,
        etag: String,
        lastModified: String,
        outcome: OperationOutcome,
    };

    constructor(constructJson, className = 'BundleEntryResponse') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
