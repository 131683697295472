import DomainResource from './DomainResource';
import Identifier from './types/Identifier';
import merge from 'lodash/merge';
import get from 'lodash/get';
import CodeableConcept from './types/CodeableConcept';
import Reference from './types/Reference';
import Annotation from './types/Annotation';
import PerformedX from './types/PerformedX';
import ProcedureModifier from '@/fhirworks/ProcedureModifier';

export default class Procedure extends DomainResource {
    static __className = 'Procedure';

    __objectStructure = {
        identifier: [Identifier], // External Identifiers for this procedure
        instantiatesCanonical: [String], // Instantiates FHIR protocol or definition
        instantiatesUri: [String], // Instantiates external protocol or definition
        basedOn: [Reference], // A request for this procedure
        partOf: [Reference], // Part of referenced event
        status: String, // preparation | in-progress | not-done | on-hold | stopped | completed | entered-in-error | unknown
        statusReason: CodeableConcept, // Reason for current status
        category: CodeableConcept, // Classification of the procedure
        code: CodeableConcept, // Identification of the procedure
        subject: Reference, // Who the procedure was performed on
        encounter: Reference, // Encounter created as part of
        performed: PerformedX, // When the procedure was performed
        recorder: Reference, // Who recorded the procedure
        asserter: Reference, // Person who asserts this procedure
        // performer: [], // The people who performed the procedure
        location: Reference, // Where the procedure happened
        reasonCode: [CodeableConcept], // Coded reason procedure performed
        reasonReference: [Reference], // The justification that the procedure was performed
        bodySite: [CodeableConcept], // 	Target body sites SNOMED CT Body Structures
        outcome: CodeableConcept, // 	The result of procedure
        report: [Reference], // Any report resulting from the procedure
        complication: [CodeableConcept], // Complication following the procedure
        complicationDetail: [Reference], // A condition that is a result of the procedure
        followUp: [CodeableConcept], // Instructions for follow up
        note: [Annotation], // Additional information about the procedure
        // focalDevice: [], // Manipulated, implanted, or removed device
        usedReference: [Reference], // Items used during procedure
        usedCode: [CodeableConcept], // Coded items used during the procedure
        /**
         * First Class extensions
         */
        modifier: [ProcedureModifier],
        unit: Number,
        officeCode: String,
        codeDescription: String,
        perUnitDuration: String,
    };

    __objectDefaults = {};

    constructor(constructJson, className = 'Procedure') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.__modifiers = [];
        let initialValue = this.getBNExtensionValue('modifiers');
        initialValue?.forEach((item) => this.__modifiers.push(JSON.parse(item.value)));

        let fhirPerformedDateTime = get(constructJson, 'performedDateTime', undefined);
        if (fhirPerformedDateTime) {
            this.performedDateTime = fhirPerformedDateTime;
        }

        let fhirPerformedPeriod = get(constructJson, 'performedPeriod', undefined);
        if (fhirPerformedPeriod) {
            this.performedPeriod = fhirPerformedPeriod;
        }

        let fhirPerformedString = get(constructJson, 'performedString', undefined);
        if (fhirPerformedString) {
            this.performedString = fhirPerformedString;
        }

        let fhirPerformedAge = get(constructJson, 'performedAge', undefined);
        if (fhirPerformedAge) {
            this.performedAge = fhirPerformedAge;
        }

        let fhirPerformedRange = get(constructJson, 'performedRange', undefined);
        if (fhirPerformedRange) {
            this.performedRange = fhirPerformedRange;
        }

        this.originalObjJson = this.toJSON();
    }

    // *************** Getters & Setters ***************

    get performedDateTime() {
        if (!this.performed) {
            return undefined;
        }
        return this.performed.performedDateTime;
    }

    set performedDateTime(value) {
        if (!this.performed) {
            this.performed = new PerformedX();
        }
        this.performed.performedDateTime = value;
    }

    get performedPeriod() {
        if (!this.performed) {
            return undefined;
        }
        return this.performed.performedPeriod;
    }

    set performedPeriod(value) {
        if (!this.performed) {
            this.performed = new PerformedX();
        }
        this.performed.performedPeriod = value;
    }

    get performedString() {
        if (!this.performed) {
            return undefined;
        }
        return this.performed.performedString;
    }

    set performedString(value) {
        if (!this.performed) {
            this.performed = new PerformedX();
        }
        this.performed.performedString = value;
    }

    get performedAge() {
        if (!this.performed) {
            return undefined;
        }
        return this.performed.performedAge;
    }

    set performedAge(value) {
        if (!this.performed) {
            this.performed = new PerformedX();
        }
        this.performed.performedAge = value;
    }

    get performedRange() {
        if (!this.performed) {
            return undefined;
        }
        return this.performed.performedRange;
    }

    set performedRange(value) {
        if (!this.performed) {
            this.performed = new PerformedX();
        }
        this.performed.performedRange = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
