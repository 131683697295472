import DomainResource from './DomainResource';
import Identifier from './types/Identifier';
import merge from 'lodash/merge';
import QuestionnaireResponseItem from './QuestionnaireResponseItem';
import Reference from './types/Reference';
import QuestionnaireResponseReviewer from '@/fhirworks/QuestionnaireResponseReviewer';

/**
 * @property {Identifier} identifier // Unique id for this set of answers
 * @property {Reference[]} basedOn //[{ Reference(CarePlan|ServiceRequest) }], // Request fulfilled by this QuestionnaireResponse
 * @property {Reference[]} partOf // Part of this action
 * @property {String} questionnaire // Form being answered
 * @property {String} status // R!  in-progress | completed | amended | entered-in-error | stopped
 * @property {Reference} subject // The subject of the questions
 * @property {Reference} encounter // Encounter created as part of
 * @property {String} authored // Date the answers were gathered
 * @property {Reference} author // Person who received and recorded the answers
 * @property {Reference} source // The person who answered the questions
 * @property {QuestionnaireResponseItem} item
 */
export default class QuestionnaireResponse extends DomainResource {
    static __className = 'QuestionnaireResponse';

    __objectStructure = {
        // from Resource: id, meta, implicitRules, and language
        // from DomainResource: text, contained, extension, and modifierExtension
        identifier: Identifier, // Unique id for this set of answers
        basedOn: [Reference], //[{ Reference(CarePlan|ServiceRequest) }], // Request fulfilled by this QuestionnaireResponse
        partOf: [Reference], // Part of this action
        questionnaire: String, // Form being answered
        status: String, // R!  in-progress | completed | amended | entered-in-error | stopped
        subject: Reference, // The subject of the questions
        encounter: Reference, // Encounter created as part of
        authored: String, // Date the answers were gathered
        author: Reference, // Person who received and recorded the answers
        source: Reference, // The person who answered the questions
        item: [QuestionnaireResponseItem],
        /**
         * First Class extensions
         */
        accessGroup: [Reference],
        reviewer: [QuestionnaireResponseReviewer],
    };

    constructor(constructJson, className = 'QuestionnaireResponse') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
