<script>
export default {
    name: 'NotFoundPage',
    components: {},
};
</script>

<template>
    <div class="text-center">
        <h1>Page Not Found</h1>
    </div>
</template>
