import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import CodeableConcept from './types/CodeableConcept';
import GroupCharacteristic from '@/fhirworks/GroupCharacteristic';
import GroupMember from '@/fhirworks/GroupMember';

export default class Group extends DomainResource {
    static __className = 'Group';

    __objectStructure = {
        identifier: [Identifier], // Unique id
        active: Boolean, // Whether this group's record is in active use
        type: String, // person | animal | practitioner | device | medication | substance (GroupType)
        actual: Boolean, // Descriptive or actual
        code: CodeableConcept, // Kind of Group members
        name: String, // Label for Group
        quantity: Number, // Number of members
        managingEntity: Reference, // Entity that is the custodian of the Group's definition
        characteristic: [GroupCharacteristic], // Include / Exclude group members by Trait
        member: [GroupMember], // Who or what is in group
        // first-class extensions
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'Group') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
