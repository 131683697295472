import BackboneElement from './BackboneElement';
import DiagnosisX from './types/DiagnosisX';
import merge from 'lodash/merge';
import get from 'lodash/get';

export default class BN_SuperBillDiagnosis extends BackboneElement {
    static __className = 'BN_SuperBillDiagnosis';

    __objectStructure = {
        sequence: Number,
        diagnosis: DiagnosisX,
    };

    constructor(constructJson, className = 'BN_SuperBillDiagnosis') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        let fhirDiagnosisCodeableConcept = get(constructJson, 'diagnosisCodeableConcept', undefined);
        if (fhirDiagnosisCodeableConcept) {
            this.diagnosisCodeableConcept = fhirDiagnosisCodeableConcept;
        }

        let fhirDiagnosisReference = get(constructJson, 'diagnosisReference', undefined);
        if (fhirDiagnosisReference) {
            this.diagnosisReference = fhirDiagnosisReference;
        }

        this.originalObjJson = this.toJSON();
    }

    get diagnosisCodeableConcept() {
        if (!this.diagnosis) {
            return undefined;
        }
        return this.diagnosis.diagnosisCodeableConcept;
    }

    set diagnosisCodeableConcept(value) {
        if (!this.diagnosis) {
            this.diagnosis = new DiagnosisX();
        }
        this.diagnosis.diagnosisCodeableConcept = value;
    }

    get diagnosisReference() {
        if (!this.diagnosis) {
            return undefined;
        }
        return this.diagnosis.diagnosisReference;
    }
    set diagnosisReference(value) {
        if (!this.diagnosis) {
            this.diagnosis = new DiagnosisX();
        }
        this.diagnosis.diagnosisReference = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
