import DomainResource from './DomainResource';
import merge from 'lodash/merge';

export default class Role extends DomainResource {
    static __className = 'Role';

    __objectStructure = {
        name: String,
        active: Boolean,
        permissions: [String],
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'Role') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
