import Address from './Address';
import Attachment from './types/Attachment';
import ContactPoint from './ContactPoint';
import DomainResource from './DomainResource';
import HumanName from './HumanName';
import PatientLink from './PatientLink';
import PatientCommunication from './PatientCommunication';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import merge from 'lodash/merge';
import { contactPointSystemValues, contactPointTraits, contactPointUseValues } from './trait/contactPointTraits';
import { addressTraits } from './trait/addressTraits';
import { differenceInYears } from 'date-fns';
import humanNameTraits from './trait/humanNameTraits';
import humanExtensionValueTraits from './trait/humanExtensionValueTraits';
import patientUsCoreTraits from './trait/patientUsCoreTraits';
import { v4 as uuidv4 } from 'uuid';
import Coding from './types/Coding';
import CodeableConcept from './types/CodeableConcept';
import PatientEthnicity from './PatientEthnicity';
import PatientRace from './PatientRace';
import PatientContact from '@/fhirworks/PatientContact';

const mrnIdentifierType = {
    coding: [
        {
            system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
            code: 'MR',
            display: 'Medical record number',
        },
    ],
};

export default class Patient extends DomainResource {
    static __className = 'Patient';

    __objectStructure = {
        identifier: [Identifier],
        active: Boolean,
        name: [HumanName],
        telecom: [ContactPoint],
        gender: String, // male | female | other | unknown
        birthDate: String,
        // deceased[x]: valueType,
        address: [Address],
        // maritalStatus: CodeableConcept,
        // multipleBirth[x]: valueType,
        photo: [Attachment],
        contact: [PatientContact],
        communication: [PatientCommunication],
        // generalPractitioner: [Reference],
        managingOrganization: Reference,
        link: [PatientLink],
        /**
         * US-Core
         */
        // spokenLanguage, , sexualOrientation,, ,
        birthSex: String,
        ethnicity: PatientEthnicity,
        genderIdentity: CodeableConcept,
        race: PatientRace,
        maritalStatus: CodeableConcept,

        /**
         * First Class extensions
         */
        Guarantor: Reference,
        encounter: Reference,
        pronouns: Coding,
        religion: CodeableConcept,
        sendSms: String,
        sexualOrientation: Coding,
        timeZone: String,
        livesWith: String,
        employerSchool: String,
        keyComments: String,
        livingArrangement: CodeableConcept,
    };

    __objectDefaults = {
        gender: 'unknown',
    };

    constructor(constructJson, className = 'Patient') {
        super(constructJson, className);

        addressTraits.call(this);
        contactPointTraits.call(this);
        humanNameTraits.call(this);
        humanExtensionValueTraits.call(this);
        patientUsCoreTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.defaultContactPointSystemValue = contactPointSystemValues.phone;
        this.defaultContactPointUseType = contactPointUseValues.mobile;

        // Setup default value for existing entities that do not have a gender property
        // default will be used for new patient entities
        if (!this.gender) {
            this.gender = 'unknown';
        }

        this.populateCustomPropertiesFromJson(constructJson, ['spokenLanguage', 'ssn']);

        this.originalObjJson = this.toJSON();
    }

    // Question for Scott/Justin: can this functionality be added to the __objectDefaults for all new Patients?
    addMrn(baseUri) {
        // Add a MRN identifier entities if one does not already exist
        if (!baseUri || typeof baseUri !== 'string') return false;

        // Abort if there already is an MRN associated
        if (this.identifier.length && this.identifier.find((e) => JSON.stringify(e.type) === JSON.stringify(mrnIdentifierType))) {
            return false;
        }

        this.identifier = {
            system: 'https://' + baseUri + (baseUri.charAt(-1) === '/' ? '' : '/') + 'patients',
            value: uuidv4().slice(-8),
            type: mrnIdentifierType,
        };
        return true;
    }
    // *************** Getters & Setters ***************

    get age() {
        return differenceInYears(new Date(), new Date(this.birthDate));
    }

    get mrn() {
        return this.identifier.find((e) => JSON.stringify(e.type) === JSON.stringify(mrnIdentifierType))?.value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
