<script>
export default {
    name: 'InvalidTabPage',
};
</script>

<template>
    <div class="text-center">
        <h1>You have been logged out.</h1>
    </div>
</template>
