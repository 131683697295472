import { $http } from '@/common/api/http.service';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { Practitioner } from '@/fhirworks';
import createHuman from '@/common/createHuman';
import { useAuthStore } from '@/stores/auth';

export default {
    getMe: async (userId) => {
        let aidboxReturn = await $httpFhirApi.get(
            'User?systemId=' +
                userId +
                '&_include=practitioner&_revinclude=PractitionerRole:practitioner:Practitioner&_include=PractitionerRole:organization&_include=PractitionerRole:location&_elements=Practitioner.name,Practitioner.primaryLocation,Practitioner.primaryLocationBrand,Practitioner.photo,Location.name',
        );
        return aidboxReturn.data;
    },
    getMePortal: async (userId) => {
        // If there is an associated user in aidbox, add it to the data to return.
        let aidboxReturn = await $httpFhirApi.get('User?systemId=' + userId + '&_include=portalUser');
        return aidboxReturn.data;
    },

    createUser: async (resource, config) => {
        const authStore = useAuthStore();
        let ret = await $http.post('/api/account/' + authStore.account.id + '/account_users', {}, config);
        let fhirUser = { id: ret.data.id, active: false };

        let practitioner = new Practitioner();

        if (resource.type === 'user') {
            // this is a brand-new contact/user
            createHuman(resource.value, practitioner, true);
        } else if (resource.id) {
            // this is a new user from an existing resource
            let personJson = resource.toJSON();
            delete personJson.id;
            personJson.resourceType = 'Practitioner';
            practitioner = new Practitioner(personJson);
        } else {
            practitioner = resource;
        }

        // if no timezone specified for the newly created user then default to the company timezone
        if (!practitioner.timeZone) {
            practitioner.timeZone = authStore.timeZone;
        }

        let transaction = {
            resourceType: 'Bundle',
            type: 'transaction',
            entry: [
                {
                    fullUrl: 'urn:uuid:postPractitioner',
                    resource: practitioner.toJSON(),
                    request: {
                        method: 'POST',
                        url: '/Practitioner',
                    },
                },
                {
                    fullUrl: 'urn:uuid:postUser',
                    resource: {
                        id: fhirUser.id,
                        active: true,
                        practitioner: { uri: 'urn:uuid:postPractitioner' },
                    },
                    request: {
                        method: 'PUT',
                        url: '/User/' + fhirUser.id,
                    },
                },
            ],
        };

        if (resource && resource.resourceType === 'Practitioner' && resource.id) {
            transaction.entry.shift();
            transaction.entry[0].resource.practitioner = { id: resource.id, resourceType: 'Practitioner' };
        } else if (resource && resource.resourceType === 'Patient') {
            transaction.entry.push({
                fullUrl: 'urn:uuid:createLinkage',
                resource: {
                    active: true,
                    author: { id: authStore.practitioner.id, resourceType: 'Practitioner' },
                    item: [
                        {
                            resource: { uri: 'urn:uuid:postPractitioner' },
                            type: 'source',
                        },
                        {
                            resource: { id: resource.id, resourceType: 'Patient' },
                            type: 'alternate',
                        },
                    ],
                },
                request: {
                    method: 'POST',
                    url: '/Linkage',
                },
            });
        }

        let newRet = await $httpFhirApi.post('/', transaction, config);

        if (!newRet.data?.find((el) => el.resourceType === 'Practitioner')) {
            newRet.data.push(resource);
        }

        return newRet.data;
    },
    inviteUser: async (data, config) => {
        const authStore = useAuthStore();
        return await $http.post('/api/account/' + authStore.account.id + '/invite-user', data, config);
    },
    revokeInvite: async (data, config) => {
        const authStore = useAuthStore();
        return await $http.post('/api/account/' + authStore.account.id + '/revoke-invite', data, config);
    },
    updatePassword: async (data, config) => await $http.post('/api/system/password-update', data, config),
    updatePortalPassword: async (data, config) => await $http.post('/portal/system/password-update', data, config),
};
