import merge from 'lodash/merge';
import Element from '../Element';
import Reference from '../types/Reference';
import CodeableConcept from '../types/CodeableConcept';

export default class CodeableReference extends Element {
    static __className = 'CodeableReference';

    __objectStructure = {
        concept: CodeableConcept,
        reference: Reference,
    };

    constructor(constructJson, className = 'CodeableReference') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }
    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
