import merge from 'lodash/merge';
import Coding from './types/Coding';
import Reference from './types/Reference';
import Element from './Element';

export default class Signature extends Element {
    static __className = 'Signature';

    __objectStructure = {
        type: [Coding], // Indication of the reason the entity signed the objects
        when: String, // When the signature was created
        who: Reference, // Who signed
        onBehalfOf: Reference, // The party represented
        targetFormat: String, // *required, any valid mime type
        sigFormat: String, // *required, any valid mime type
        data: String, // The actual signature content (XML DigSig, JWS, picture, etc.)

        // first class extension
        ip: String, // The actual signature content (XML DigSig, JWS, picture, etc.)
    };

    constructor(constructJson, className = 'Signature') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
