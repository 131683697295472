import BackboneElement from '../BackboneElement';
import merge from 'lodash/merge';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';

/**
 * @property {String} value
 * @property {String} comparator
 * @property {String} unit
 * @property {String} system
 * @property {String} code
 */
export default class Timing extends BackboneElement {
    static __className = 'Timing';

    __objectStructure = {
        event: [String],
        code: CodeableConcept,
    };

    constructor(constructJson, className = 'Timing') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
