import { $http } from '@/common/api/http.service';
import { useAuthStore } from '@/stores/auth';

const resourceName = 'permission_categories';

export default {
    // Custom endpoints
    getPermissions: (data, config) => {
        const authStore = useAuthStore();
        config = { headers: { Accept: 'application/ld+json' } };

        return $http.get('api/account/' + authStore.account.id + '/' + resourceName, config);
    },
};
