import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import ValueSetExpansionParameter from '@/fhirworks/ValueSetExpansionParameter';
import ValueSetExpansionContains from '@/fhirworks/ValueSetExpansionContains';

export default class ValueSetExpansion extends BackboneElement {
    static __className = 'ValueSetExpansion';

    __objectStructure = {
        identifier: String, // Identifies the value set expansion (business identifier)
        timestamp: String, // !R Time ValueSet expansion happened
        total: Number, // Total number of codes in the expansion
        offset: Number, // Offset at which this resource starts
        parameter: [ValueSetExpansionParameter], // Parameter that controlled the expansion process
        contains: [ValueSetExpansionContains], // Codes in the value set + Rule: SHALL have a code or a display + Rule: Must have a code if not abstract + Rule: Must have a system if a code is present
    };

    constructor(constructJson, className = 'ValueSetExpansion') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
