import BackboneElement from './BackboneElement';
import Reference from './types/Reference';
import Identifier from './types/Identifier';
import merge from 'lodash/merge';

export default class ClaimInsurance extends BackboneElement {
    static __className = 'ClaimInsurance';

    __objectStructure = {
        sequence: Number,
        focal: Boolean,
        identifier: Identifier,
        coverage: Reference,
        businessArrangement: String,
        preAuthRef: [String],
        claimResponse: Reference,
    };

    constructor(constructJson, className = 'ClaimInsurance') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
