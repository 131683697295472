import merge from 'lodash/merge';
import Address from './Address';
import Attachment from './types/Attachment';
import CodeableConcept from './types/CodeableConcept';
import ContactPoint from './ContactPoint';
import DomainResource from './DomainResource';
import humanNameTraits from './trait/humanNameTraits';
import HumanName from './HumanName';
import Identifier from './types/Identifier';
import Period from './types/Period';
import Reference from './types/Reference';
import { contactPointTraits } from './trait/contactPointTraits';
import { addressTraits } from './trait/addressTraits';
import humanExtensionValueTraits from './trait/humanExtensionValueTraits';

export default class RelatedPerson extends DomainResource {
    static __className = 'RelatedPerson';

    __objectStructure = {
        identifier: [Identifier],
        active: Boolean,
        patient: Reference,
        relationship: [CodeableConcept],
        name: [HumanName],
        telecom: [ContactPoint],
        gender: String, // male | female | other | unknown
        birthDate: String,
        address: [Address],
        photo: [Attachment],
        period: Period,
        // communication: [Communication],
    };

    constructor(constructJson, className = 'RelatedPerson') {
        super(constructJson, className);

        addressTraits.call(this);
        contactPointTraits.call(this);
        humanNameTraits.call(this);
        humanExtensionValueTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
