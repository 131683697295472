import merge from 'lodash/merge';
import BackboneElement from '@/fhirworks/BackboneElement';
import Reference from '@/fhirworks/types/Reference';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';

export default class DocumentReferenceRelatesTo extends BackboneElement {
    static __className = 'DocumentReferenceRelatesTo';

    __objectStructure = {
        code: CodeableConcept,
        target: Reference,
    };

    constructor(constructJson, className = 'DocumentReferenceRelatesTo') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
