<script>
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import { required, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import BnFooter from '@/components/BnFooter';
import PatientContact from '@/fhirworks/PatientContact';

export default {
    name: 'BnContactPointEdit',
    components: { BnToolbarBtn, BnFooter },
    emits: ['save', 'cancel'],
    props: {
        mode: {
            type: String,
            default: 'edit',
        },
        disablePreferredCheck: {
            type: Boolean,
            default: false,
        },
        resource: {
            type: Object,
            required: true,
        },
        contactPoint: {
            type: Object,
            required: true,
        },
        phoneUseOptions: {
            type: Array,
            default: () => [
                { value: 'mobile', text: 'Mobile' },
                { value: 'home', text: 'Home' },
                { value: 'work', text: 'Work' },
            ],
        },
        emailUseOptions: {
            type: Array,
            default: () => [
                { value: 'home', text: 'Personal' },
                { value: 'work', text: 'Work' },
            ],
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            workingContactPoint: null,
            contactPointOriginalValues: {},
        };
    },
    created() {
        this.contactPointOriginalValues = this.contactPoint.toJSON();
        // Working with the actual object is done so that when values are changed
        // the processing of the telecom contact points of the type being edited
        // can be changed correctly.  If changes are canceled setPropertyValues
        // is called in the cancel method with contactPointOriginalValues
        this.workingContactPoint = this.contactPoint;
        this.workingContactPoint.temp.preferred = this.workingContactPoint.rank === 1;
        this.workingContactPoint.temp.notifyViaSms = this.resource.sendSms && this.resource.sendSms === this.workingContactPoint.value;
    },
    validations() {
        return { workingContactPoint: this.validationRules };
    },
    methods: {
        cancelWorkingContactPoint() {
            if (this.mode === 'edit') {
                // Because we are working with the actual object we must restore
                // the original value when form was called
                this.workingContactPoint.setPropertyValues(this.contactPointOriginalValues);
            }
            this.workingContactPoint = null;
            this.$emit('cancel');
        },
        async saveWorkingContactPoint() {
            this.v$.workingContactPoint.$touch();
            let valid = await this.v$.workingContactPoint.$validate();
            if (!valid) return;

            // Add mode so add to array of contact points so it can be processed below
            if (this.mode === 'add') {
                this.resource.telecom = this.workingContactPoint;
            }

            // Setting of preferred/rank for contact point
            this.workingContactPoint.rank = undefined;
            if (this.workingContactPoint.temp.preferred) {
                this.resource.getContactPointsBySystem(this.workingContactPoint.system).forEach((item) => {
                    item.rank = undefined;
                });
                this.workingContactPoint.rank = 1;
            }

            // Process acceptSms information
            if (this.workingContactPoint.system === 'phone') {
                // Because the notifyViaSms property is not actually defined on the entity
                // it is tricky to determine how to set the sendSms property when saving.
                let orgSendSms = this.resource.sendSms;
                // Initially set sendSms based on the notifyViaSms property, if set use value otherwise clear sendSms value
                this.resource.sendSms = this.workingContactPoint.use === 'mobile' && this.workingContactPoint.temp.notifyViaSms ? this.workingContactPoint.value : undefined;
                // We only need to check if there are other contact point values that match the sendSms
                // value if the notifyViaSms property is not set to true because we only can have one.
                let checkNotifyViaSms = !this.workingContactPoint.temp.notifyViaSms;
                this.resource.getContactPointsBySystem('phone').forEach((item) => {
                    if (checkNotifyViaSms) {
                        // Verify use is mobile and notifyViaSms is set to true, the 2nd compare is needed because
                        // the notifyViaSms property will NOT exist if the record has not be edited already
                        // (remember this is not a defined property value) so in this state we need
                        // to check the value property.
                        if ((item.use === 'mobile' && item.temp.notifyViaSms) || (orgSendSms === item.value && item.temp.notifyViaSms === undefined)) {
                            this.resource.sendSms = item.value;
                        }
                    }
                    item.temp.notifyViaSms = this.resource.sendSms === item.value;
                });
            }

            // Make sure all emails are stored lower case
            if (this.workingContactPoint.isEmail()) {
                this.workingContactPoint.value = this.workingContactPoint.value.toLowerCase();
            }

            this.$emit('save', this.workingContactPoint);
        },
    },
    computed: {
        PatientContact() {
            return PatientContact;
        },
        validationRules() {
            return this.workingContactPoint?.system === 'phone' || this.workingContactPoint?.system === 'fax'
                ? {
                      value: {
                          required,
                          invalidPhoneFax: function (value) {
                              let phoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
                              return phoneRegEx.test(value);
                          },
                      },
                  }
                : { value: { required, email } };
        },
    },
};
</script>

<template>
    <v-card flat class="d-flex flex-column">
        <div class="flex-grow-0">
            <ion-header>
                <ion-toolbar>
                    <v-toolbar density="compact">
                        <v-toolbar-title>{{ workingContactPoint.system === 'phone' ? 'Phone' : 'Email' }}</v-toolbar-title>

                        <bn-toolbar-btn
                            type="text"
                            :label="mode === 'edit' ? 'Cancel' : 'Discard'"
                            color="secondary"
                            :icon="mode === 'edit' ? 'times' : 'trash'"
                            @click="cancelWorkingContactPoint"
                        ></bn-toolbar-btn>
                        <bn-toolbar-btn
                            :disabled="!!v$.workingContactPoint.$errors.length"
                            :label="mode === 'edit' ? 'Update' : 'Add'"
                            color="primary"
                            :icon="mode === 'edit' ? 'check' : 'plus'"
                            data-cy="bnContactPointSaveButton"
                            @click="saveWorkingContactPoint"
                        ></bn-toolbar-btn>
                    </v-toolbar>
                </ion-toolbar>
            </ion-header>
            <v-divider></v-divider>
        </div>
        <v-card-text class="mt-4">
            <!-- Temple for Fax numbers -->
            <template v-if="workingContactPoint.system === 'fax'">
                <v-text-field
                    class="mr-2"
                    label="Fax number"
                    type="tel"
                    autofocus
                    v-facade="'(###) ###-####'"
                    v-model="workingContactPoint.value"
                    v-bind="$bnVuelidateErrorExtractor(v$.workingContactPoint, 'value', true)"
                    @blur="v$.workingContactPoint.value.$touch()"
                    data-cy="bnContactPointEditFaxField"
                ></v-text-field>
            </template>
            <!-- Organization Entity, email use (work), single line and no Use type -->
            <template v-else-if="resource.resourceType === 'Organization' && workingContactPoint.system === 'email'">
                <v-text-field
                    class="mr-2"
                    label="Email address"
                    type="email"
                    autofocus
                    v-model="workingContactPoint.value"
                    v-bind="$bnVuelidateErrorExtractor(v$.workingContactPoint, 'value', true)"
                    @blur="v$.workingContactPoint.value.$touch()"
                    data-cy="bnContactPointEditEmailField"
                ></v-text-field>
            </template>
            <!-- Standard template used for Patients/Practitioners -->
            <template v-else>
                <v-text-field
                    v-if="workingContactPoint.system === 'phone'"
                    class="mr-2"
                    label="Phone number"
                    type="tel"
                    autofocus
                    v-facade="'(###) ###-####'"
                    v-model="workingContactPoint.value"
                    v-bind="$bnVuelidateErrorExtractor(v$.workingContactPoint, 'value', true)"
                    @blur="v$.workingContactPoint.value.$touch()"
                    data-cy="bnContactPointEditPhoneField"
                ></v-text-field>
                <v-text-field
                    v-if="workingContactPoint.system === 'email'"
                    class="mr-2"
                    label="Email address"
                    type="email"
                    autofocus
                    v-model="workingContactPoint.value"
                    v-bind="$bnVuelidateErrorExtractor(v$.workingContactPoint, 'value', true)"
                    @blur="v$.workingContactPoint.value.$touch()"
                    data-cy="bnContactPointEditEmailField"
                ></v-text-field>

                <v-radio-group v-if="workingContactPoint.system === 'phone'" v-model="workingContactPoint.use" class="mt-0" label="Use">
                    <v-radio v-for="(phoneOption, index) in phoneUseOptions" :key="index" :label="phoneOption.text" :value="phoneOption.value"></v-radio>
                </v-radio-group>
                <v-radio-group v-if="workingContactPoint.system === 'email'" v-model="workingContactPoint.use" class="mt-0" label="Use">
                    <v-radio v-for="(emailOption, index) in emailUseOptions" :key="index" :label="emailOption.text" :value="emailOption.value"></v-radio>
                </v-radio-group>
            </template>

            <div class="bn-section-header">Options</div>
            <v-checkbox
                v-if="workingContactPoint.system !== 'fax' && resource.resourceType !== 'Organization'"
                v-model="workingContactPoint.doNotContact"
                :disabled="workingContactPoint.temp.preferred || workingContactPoint.temp.notifyViaSms"
                hide-details
                class="mt-0"
                label="Do not contact"
                data-cy="bnContactPointDoNotContactCheckbox"
            ></v-checkbox>
            <v-checkbox
                v-if="workingContactPoint.system === 'phone'"
                v-model="workingContactPoint.temp.preferred"
                :disabled="workingContactPoint.doNotContact || disablePreferredCheck"
                hide-details
                class="mt-0"
                label="Preferred phone"
                data-cy="bnContactPointPreferredPhoneCheckbox"
            ></v-checkbox>
            <v-checkbox
                v-if="workingContactPoint.system === 'email'"
                v-model="workingContactPoint.temp.preferred"
                :disabled="workingContactPoint.doNotContact || disablePreferredCheck"
                hide-details
                class="mt-0"
                label="Preferred email"
                data-cy="bnContactPointPreferredEmailCheckbox"
            ></v-checkbox>
            <v-checkbox
                v-if="
                    (['Patient', 'Practitioner'].includes(resource.resourceType) && workingContactPoint.system === 'phone' && workingContactPoint.use === 'mobile') ||
                    (resource instanceof PatientContact && workingContactPoint.system === 'phone' && workingContactPoint.use === 'mobile')
                "
                :disabled="workingContactPoint.doNotContact"
                v-model="workingContactPoint.temp.notifyViaSms"
                hide-details
                class="mt-0"
                label="Notify via SMS"
                data-cy="bnContactPointNotifyViaSmsCheckbox"
            ></v-checkbox>
        </v-card-text>
        <!-- ios ample touch footer bar -->
        <bn-footer class="dialog-footer"></bn-footer>
    </v-card>
</template>
