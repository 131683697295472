import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';

const scrollSpy = {
    install(app) {
        let scrollSpyItemListener;
        let scrollItems = [];
        let scrollLinks = [];

        let scrollEventListener = (vnode) =>
            throttle(() => {
                let offset = vnode.context.$root.headerHeight + vnode.context.$root.subHeaderHeight + 10;

                let filteredItems = scrollItems.filter((item) => {
                    let itemOffset = scrollLinks.find((link) => link.selector === item.selector).binding.offset || 0;
                    return item.el.getBoundingClientRect().bottom > offset + itemOffset + 1; // 1 is added cause it doesn't work without it :(
                });

                if (isEmpty(filteredItems)) {
                    return;
                }

                let activeItem = filteredItems.shift();
                let activeLink = scrollLinks.find((link) => link.selector === activeItem.selector);
                let inactiveLinks = scrollLinks.filter((link) => link.selector !== activeItem.selector);

                activeLink.el.classList.add('active');
                inactiveLinks.forEach((link) => link.el.classList.remove('active'));
            }, 200);

        app.directive('scroll-spy-link', {
            bind: function (el, binding, vnode) {
                let node = typeof binding.value === 'string' ? binding.value : binding.value.el;
                scrollLinks.push({ el: el, selector: node, binding: binding.value });

                el.addEventListener('click', () => {
                    let clientRects = scrollItems.find((item) => item.selector === node).el.getBoundingClientRect();
                    let offset = vnode.context.$root.headerHeight + vnode.context.$root.subHeaderHeight + 10;
                    let itemOffset = binding.value.offset || 0;

                    window.document.querySelector('ion-content').scrollByPoint(null, clientRects.y - offset - itemOffset, 400);
                });
            },
            unbind: function (el) {
                scrollLinks = scrollLinks.filter((i) => i.el !== el);
            },
        });

        app.directive('scroll-spy-item', {
            bind: function (el, binding, vnode) {
                let node = typeof binding.value === 'string' ? binding.value : binding.value.el;
                scrollItems.push({ el: el, selector: node, binding: binding.value });

                scrollItems.sort((el1, el2) => {
                    let rects1 = el1.el.getBoundingClientRect();
                    let rects2 = el2.el.getBoundingClientRect();

                    return rects1.bottom < rects2.bottom ? -1 : 1;
                });

                if (!scrollSpyItemListener) {
                    window.document.querySelector('ion-content').addEventListener('ionScroll', scrollEventListener(vnode));
                    scrollSpyItemListener = true;
                }
            },
            unbind: function (el, directiveBinding, vnode) {
                scrollItems = scrollItems.filter((i) => i.el !== el);

                if (isEmpty(scrollItems)) {
                    window.document.querySelector('ion-content').removeEventListener('ionScroll', scrollEventListener(vnode));
                    scrollSpyItemListener = false;
                }
            },
        });
    },
};

export default scrollSpy;
