import patientApi from '@/common/api/patient.api';
import organizationApi from '@/common/api/organization.api';
import practitionerApi from '@/common/api/practitioner.api';
import { sortBy } from 'lodash';
import { useAuthStore } from '@/stores/auth';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { createFhirResource } from '@/fhirworks';

export const resourcePriority = {
    Patient: 1,
    Organization: 2,
    Practitioner: 3,
    User: 4,
};

export default {
    search(queryString, resourcesToSearch = ['patient', 'organization', 'practitioner'], filterByBrand, enforceAccess = true) {
        const authStore = useAuthStore();
        let entry = [];

        let patientIndex, organizationIndex, practitionerIndex;

        const customSearchKeys = ['brandUsers', 'locationUsers', 'recent'];
        if (customSearchKeys.includes(queryString)) {
            // custom search based on a search identifier
            if (queryString === 'locationUsers') {
                entry.push({
                    request: {
                        method: 'GET',
                        url: practitionerApi.getLocationUsersQuery(filterByBrand?.location || authStore.brand.location),
                    },
                });
            } else if (queryString === 'brandUsers') {
                entry.push({
                    request: {
                        method: 'GET',
                        url: practitionerApi.getBrandUsersQuery(filterByBrand?.brand || authStore.brand),
                    },
                });
            } else if (queryString === 'recent') {
                const query =
                    'BN_List?type=contactAccessLog&owner=' +
                    authStore.practitioner.id +
                    '&_include=BN_List:itemReference:Practitioner' +
                    '&_include=BN_List:itemReference:Organization' +
                    '&_include=BN_List:itemReference:Patient';
                entry.push({
                    request: {
                        method: 'GET',
                        url: query,
                    },
                });
            }
        } else {
            // regular search
            if (resourcesToSearch.includes('patient')) {
                entry.push({
                    request: {
                        method: 'GET',
                        url: patientApi.getTransactionQuery(queryString),
                    },
                });
                patientIndex = entry.length - 1;
            }
            if (resourcesToSearch.includes('organization')) {
                entry.push({
                    request: {
                        method: 'GET',
                        url: organizationApi.getTransactionQuery(queryString),
                    },
                });
                organizationIndex = entry.length - 1;
            }
            if (resourcesToSearch.includes('practitioner')) {
                entry.push({
                    request: {
                        method: 'GET',
                        url: practitionerApi.getTransactionQuery(queryString, filterByBrand),
                    },
                });
                practitionerIndex = entry.length - 1;
            }
        }

        let returnPromise = $httpFhirApi.post('/', {
            type: 'transaction',
            resourceType: 'Bundle',
            entry: entry,
        });

        return Promise.resolve(returnPromise).then(async (values) => {
            let returnResults = [];

            // RECENT CONTACTS
            if ('recent' === queryString) {
                let contacts = [];
                const recent = values.originalData.entry[0].resource.entry;
                const accessLog = recent.find((item) => item.resource.resourceType === 'BN_List');
                if (!accessLog) return contacts;

                accessLog?.resource.item.forEach((item) => {
                    const contact = recent.find((contact) => contact.resource.id === item.value.Reference.id);
                    if (contact) {
                        contacts.push(createFhirResource(contact.resource.resourceType, contact.resource));
                    }
                });
                return contacts;
            }

            if (['locationUsers', 'brandUsers'].includes(queryString)) {
                returnResults = returnResults.concat(practitionerApi.processBrandUsersResponse(values.originalData.entry[0].resource));
                return sortBy(returnResults, ['fullName']);
            }

            if (resourcesToSearch.includes('organization')) {
                returnResults = returnResults.concat(organizationApi.processOrganizationResponse(values.originalData.entry[organizationIndex].resource));
            }

            if (resourcesToSearch.includes('practitioner')) {
                returnResults = returnResults.concat(practitionerApi.processPractitionerResponse(values.originalData.entry[practitionerIndex].resource));
            }

            // Patient is last because it's an asyncronous function that runs 2 queries.  So we can process returned data while Patient is still querying
            if (resourcesToSearch.includes('patient')) {
                returnResults = returnResults.concat(await patientApi.processPatientResponse(values.originalData.entry[patientIndex].resource, enforceAccess));
            }

            return sortBy(returnResults, ['_group', (o) => resourcePriority[o.resourceType], 'fullName']);
        });
    },
};
