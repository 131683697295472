import merge from 'lodash/merge';
import get from 'lodash/get';
import DomainResource from './DomainResource';
import CoverageEligibilityRequestSupportingInfo from './CoverageEligibilityRequestSupportingInfo';
import CoverageEligibilityRequestInsurance from './CoverageEligibilityRequestInsurance';
import CoverageEligibilityRequestItem from './CoverageEligibilityRequestItem';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import ServicedX from './types/ServicedX';

export default class CoverageEligibilityRequest extends DomainResource {
    static __className = 'CoverageEligibilityRequest';

    __objectStructure = {
        identifier: [Identifier],
        status: String, // active | cancelled | draft | entered-in-error
        priority: CodeableConcept,
        purpose: [String], // auth-requirements | benefits | discovery | validation
        patient: Reference,
        serviced: ServicedX,
        created: String,
        enterer: Reference,
        provider: Reference,
        insurer: Reference,
        facility: Reference,
        supportingInfo: [CoverageEligibilityRequestSupportingInfo],
        insurance: [CoverageEligibilityRequestInsurance],
        item: [CoverageEligibilityRequestItem],
        /**
         * First Class extensions
         */
        transactionNumber: String,
    };

    constructor(constructJson, className = 'CoverageEligibilityRequest') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        let fhirServicedDate = get(constructJson, 'servicedDate', undefined);
        if (fhirServicedDate) {
            this.servicedDate = fhirServicedDate;
        }

        let fhirServicedPeriod = get(constructJson, 'servicedPeriod', undefined);
        if (fhirServicedPeriod) {
            this.servicedPeriod = fhirServicedPeriod;
        }

        this.originalObjJson = this.toJSON();
    }

    get servicedDate() {
        if (!this.serviced) {
            return undefined;
        }
        return this.serviced.servicedDate;
    }

    set servicedDate(value) {
        if (!this.serviced) {
            this.serviced = new ServicedX();
        }
        this.serviced.servicedDate = value;
    }

    get servicedPeriod() {
        if (!this.serviced) {
            return undefined;
        }
        return this.serviced.servicedPeriod;
    }
    set servicedPeriod(value) {
        if (!this.serviced) {
            this.serviced = new ServicedX();
        }
        this.serviced.servicedPeriod = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
