import merge from 'lodash/merge';
import DomainResource from './DomainResource';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';
import Reference from '@/fhirworks/types/Reference';
import FamilyMemberHistoryCondition from './FamilyMemberHistoryCondition';

export default class FamilyMemberHistory extends DomainResource {
    static __className = 'FamilyMemberHistory';
    __objectStructure = {
        patient: Reference,
        instantiatesUri: [String],
        status: String,
        relationship: CodeableConcept,
        condition: [FamilyMemberHistoryCondition],
    };

    constructor(constructJson, className = 'FamilyMemberHistory') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
