export default [
    {
        path: 'payment-reconciliation',
        redirect: { name: 'era-page' },
        name: 'payment-reconciliation-page',
        component: () => import('@/views/PaymentReconciliationPage.vue'),
        children: [
            /**
             * ERA Routes
             */
            {
                path: 'era',
                name: 'era-page',
                component: () => import('@/views/paymentReconciliation/EraPage.vue'),
                props: true,
                meta: { label: 'ERA' },
            },
            {
                path: 'payment',
                name: 'payment-page',
                component: () => import('@/views/paymentReconciliation/payment-page'),
                props: true,
                meta: { label: 'PAYMENT' },
                devOnly: true,
            },
            {
                path: 'post',
                name: 'post-payment-insurance',
                component: () => import('@/views/paymentReconciliation/payment/post-payment-insurance'),
                props: true,
                meta: { label: 'POST' },
                devOnly: true,
            },
        ],
    },
];
