import { h } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export const aliases = {
    /*
     * REQUIRED BY VUETIFY COMPONENTS
     */
    collapse: 'fas caret-up',
    complete: 'far circle',
    cancel: 'far ban',
    close: 'far times',
    delete: 'far times-circle',
    clear: 'far times',
    success: 'far check-circle',
    info: 'far info-circle',
    warning: 'far triangle-exclamation',
    error: 'far circle-exclamation',
    prev: 'far angle-left',
    next: 'far angle-right',
    checkboxOn: 'far check-square',
    checkboxOff: 'far square',
    checkboxIndeterminate: 'far minus-square',
    delimiter: 'fas circle',
    sort: 'fas sort',
    sortAsc: 'fas sort-up',
    sortDesc: 'fas sort-down',
    expand: 'fas caret-down',
    menu: 'far bars', // size 20
    subgroup: 'far angle-down',
    dropdown: 'fas caret-down',
    radioOn: 'far dot-circle',
    radioOff: 'far circle',
    ratingEmpty: 'far star',
    ratingFull: 'fas star',
    ratingHalf: 'fas star-half-alt',
    loading: 'fas spinner',
    first: 'far step-backward',
    last: 'far step-forward',
    unfold: 'far eye',
    file: 'far paperclip',
    plus: 'far plus',
    minus: 'far minus-circle',
    minusSquare: 'far minus-square',
    /*
     * ICONS USED BY Fresh
     */
    camera: 'far camera',
    check: 'far check',
    checkSquare: 'far check-square',
    clinicalFormulationNMedical: 'far notes-medical',
    diagnoses: 'far user-doctor-message',
    edit: 'far edit',
    eye: 'far eye',
    eyeslash: 'far eye-slash',
    handDots: 'far hand-dots',
    sackXMark: 'far sack-xmark',
    hourglass: 'far hourglass',
    addressCard: 'far address-card',
    alignJustify: 'far align-justify', //size: 'xs',
    angleUp: 'far angle-up',
    bracketsCurly: 'far brackets-curly',
    calendarCheck: 'far calendar-check',
    calendarDay: 'far calendar-day',
    calendarLines: 'far calendar-lines',
    clock: 'far clock',
    commentLines: 'far comment-lines',
    directions: 'far directions',
    fileAlt: 'far file-alt',
    fileContract: 'far file-contract',
    gavel: 'far gavel',
    hashtag: 'far hashtag',
    inboxIn: 'far inbox-in',
    insuranceCoverage: 'far umbrella',
    listTimeline: 'far list-timeline',
    mapMarkerAlt: 'far map-marker-alt',
    medkit: 'far medkit',
    peopleArrows: 'far people-arrows',
    person: 'far user',
    quoteLeft: 'far quote-left',
    quotes: 'far quotes',
    rectangleHistoryCircleUser: 'far rectangle-history-circle-user',
    repeat: 'far repeat',
    search: 'far search',
    tags: 'far tags',
    truckMedical: 'far truck-medical',
    userCheck: 'far user-check',
    userDoctorMessage: 'far user-doctor-message',
    users: 'far users',
    usersRectangle: 'far users-rectangle',
    shieldExclamation: 'far shield-exclamation',
    bullseye: 'far bullseye',
    circle: 'far circle',
    severed: 'far link-slash',
    clipBoard: 'far clipboard-list',
    treatmentHistory: 'far note-medical',
    pills: 'far pills',
    group: 'far people-group',
};

const custom = {
    component: (props) => {
        let icon = props.icon.split(' ');
        return h(FontAwesomeIcon, { icon: icon });
    },
};

export { custom };

export default aliases;
