import BackboneElement from './BackboneElement';
import CodeableConcept from './types/CodeableConcept';
import Period from './types/Period';

import merge from 'lodash/merge';

export default class CoverageCostToBeneficiaryException extends BackboneElement {
    static __className = 'CoverageCostToBeneficiaryException';

    __objectStructure = {
        type: CodeableConcept,
        period: Period,
    };

    constructor(constructJson, className = 'CoverageCostToBeneficiaryException') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
