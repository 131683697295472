import BackboneElement from './BackboneElement';
import CodeableConcept from './types/CodeableConcept';

import merge from 'lodash/merge';
import Reference from './types/Reference';

export default class ConsentProvisionActor extends BackboneElement {
    static __className = 'ConsentProvisionActor';

    __objectStructure = {
        role: CodeableConcept,
        reference: Reference,
    };

    constructor(constructJson, className = 'ConsentProvisionActor') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
