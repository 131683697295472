import DomainResource from './DomainResource';
import merge from 'lodash/merge';

export default class BN_ProfileOwner extends DomainResource {
    static __className = 'BN_ProfileOwner';

    __objectStructure = {
        name: String,
    };

    constructor(constructJson, className = 'BN_ProfileOwner') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
