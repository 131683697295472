import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import ValueSetComposeInclude from '@/fhirworks/ValueSetComposeInclude';

export default class ValueSetCompose extends BackboneElement {
    static __className = 'ValueSetCompose';

    __objectStructure = {
        lockedDate: String, // Fixed date for references with no specified version (transitive)
        inactive: Boolean, // Whether inactive codes are in the value set
        include: [ValueSetComposeInclude], // Include one or more codes from a code system or other value set(s) + Rule: A value set include/exclude SHALL have a value set or a system + Rule: A value set with concepts or filters SHALL include a system + Rule: Cannot have both concept and filter
        exclude: [ValueSetComposeInclude], // Explicitly exclude codes from a code system or other value sets
    };

    constructor(constructJson, className = 'ValueSetCompose') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
