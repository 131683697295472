const focus = {
    install(app) {
        app.directive('focus', {
            inserted: function (el) {
                el.focus();
            },
        });
    },
};

export default focus;
