import Money from './types/Money';
import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import Coding from './types/Coding';
import CodeableConcept from './types/CodeableConcept';
import Period from './types/Period';
import BN_ERA_PatientProcedureAdjustments from './BN_ERA_PatientProcedureAdjustments';

export default class BN_ERA_PatientProcedureCodeInfo extends BackboneElement {
    static __className = 'BN_ERA_PatientProcedureCodeInfo';

    __objectStructure = {
        allowedAmount: Money,
        billedAmount: Money,
        claimAdjustmentReasonCode: Coding,
        claimAdjustmentReasonCodeAmount: Money,
        coinsurance: Money,
        deductible: Money,
        interestAmount: Money,
        lateFilingCharge: Money,
        paidNumberofServices: Number,
        patientResponsibility: Money,
        procedureCode: CodeableConcept,
        procedureCodeModifiers: [Coding],
        providerPaidAmount: Money,
        serviceDate: Period,
        healthCareRemarkCode: [Coding],
        refLineItemControlNumber: String,
        adjustments: [BN_ERA_PatientProcedureAdjustments],
        copay: Money,
    };

    constructor(constructJson, className = 'BN_ERA_PatientProcedureCodeInfo') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
