import { Organization } from '@/fhirworks';
import _ from 'lodash';
import { useAuthStore } from '@/stores/auth';

function getTransactionQuery(queryString) {
    queryString = encodeURI(queryString.split(' ').filter(Boolean).join('+'));

    return 'Organization/$lookup?by=name,identifier.value;address.city,address.line;telecom.value&sort=name&count=50&q=' + queryString;
}

function processOrganizationResponse(res) {
    const authStore = useAuthStore();
    const authAccoutId = authStore.account.id;
    let returnedOrganizations = [];

    res.entry.forEach((resultRow) => {
        // TODO: Change when AidBox LookUp function is updated to allow filtering
        if (resultRow.resource.id === authAccoutId || (resultRow.resource.partOf && resultRow.resource.partOf.id === authAccoutId)) {
            return;
        }

        let organization = new Organization(resultRow.resource);
        organization._group = _.get(resultRow, '_group', '');

        returnedOrganizations.push(organization);
    });

    return returnedOrganizations;
}

export default {
    getTransactionQuery: getTransactionQuery,
    processOrganizationResponse: processOrganizationResponse,
};
