import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import BN_ERA_PatientProcedureAdjustmentsReasons from './BN_ERA_PatientProcedureAdjustmentsReasons';

export default class BN_ERA_PatientProcedureAdjustments extends BackboneElement {
    static __className = 'BN_ERA_PatientProcedureAdjustments';

    __objectStructure = {
        groupCode: String,
        reason: [BN_ERA_PatientProcedureAdjustmentsReasons],
    };

    constructor(constructJson, className = 'BN_ERA_PatientProcedureAdjustments') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
