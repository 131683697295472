import get from 'lodash/get';
import merge from 'lodash/merge';
import FHIRObjectBase from './FHIRObjectBase';

export default class UserInvite extends FHIRObjectBase {
    static __className = 'UserInvite';

    constructor(userJson, className = 'UserInvite') {
        super(userJson, className);

        this.__createdAt = get(userJson, 'createdAt', null);
        this.__id = get(userJson, 'id', null);
        this.__loginEmail = get(userJson, 'loginEmail', null);

        this.originalObjJson = userJson;
    }

    // Properties

    get createdAt() {
        return this.__createdAt;
    }

    set createdAt(value) {
        this.__createdAt = value;
    }

    get id() {
        return this.__id;
    }

    set id(value) {
        this.__id = value;
    }

    get loginEmail() {
        return this.__loginEmail;
    }

    set loginEmail(value) {
        this.__loginEmail = value;
    }

    toJSON() {
        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                id: this.id,
                createdAt: this.createdAt,
                loginEmail: this.loginEmail,
            }),
        );
    }
}
