import Identifier from './Identifier';
import merge from 'lodash/merge';
import Element from '../Element';

/**
 * @property {String} display
 * @property {String} id
 * @property {Identifier} Identifier
 * @property {String} reference
 * @property {String} resourceType
 * @property {String} type
 */
export default class Reference extends Element {
    static __className = 'Reference';

    __objectStructure = {
        display: String, // FHIR Display Value
        id: String, // AidBox Reference Identifier
        identifier: Identifier, // FHIR Reference Identifier
        reference: String, // FHIR Resource Reference
        resourceType: String, // AidBox Resource Type e.g. Patient, Practitioner
        type: String, // FHIR Reference Resource Type
        uri: String,
    };

    constructor(constructJson, className = 'Reference') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
