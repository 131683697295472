import BackboneElement from './BackboneElement';
import Reference from '@/fhirworks/types/Reference';
import merge from 'lodash/merge';

export default class QuestionnaireResponseReviewer extends BackboneElement {
    static __className = 'QuestionnaireResponseReviewer';

    __objectStructure = {
        type: String,
        who: Reference,
        role: String,
        when: String,
        context: String,
        format: String,
        signature: String,
        ip: String,
        user: Reference,
    };

    constructor(constructJson, className = 'QuestionnaireResponseReviewer') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
