import Element from './Element';
import merge from 'lodash/merge';

export default class BN_FinancialClassServiceOverride extends Element {
    static __className = 'BN_FinancialClassServiceOverride';

    __objectStructure = {
        unitsRendered: Number,
        unitStart: Number,
        unitEnd: Number,
        unitCharge: Number,
    };

    constructor(constructJson, className = 'BN_FinancialClassServiceOverride') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
