import DomainResource from './DomainResource';
import get from 'lodash/get';
import merge from 'lodash/merge';

export default class BN_SimpleNoteType extends DomainResource {
    static __className = 'BN_SimpleNoteType';

    constructor(bnSimpleNoteType, className = 'BN_SimpleNoteType') {
        super(bnSimpleNoteType, className);

        this.__title = get(bnSimpleNoteType, 'title', undefined);

        this.originalObjJson = this.toJSON();
    }

    // Getters and Setters

    get title() {
        return this.__title;
    }

    set title(value) {
        this.__title = value;
    }

    // Methods
    toJSON() {
        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                title: this.title,
            }),
        );
    }
}
