import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import { contactPointTraits } from './trait/contactPointTraits';

export default class GoalAchievementStatusHistory extends BackboneElement {
    static __className = 'GoalAchievementStatusHistory';

    __objectStructure = {
        date: String,
        status: String,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'GoalAchievementStatusHistory') {
        super(constructJson, className);

        contactPointTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
