<script>
import { mergeProps } from 'vue';
import BnMenuItem from '@/components/BnMenuItem.vue';
import _ from 'lodash';
import { useUsersnap } from '@/composables/useUsersnap';

export default {
    name: 'BnMenu',
    components: { BnMenuItem },
    setup() {
        const { showUsersnap } = useUsersnap();

        return { mergeProps, showUsersnap };
    },
    emits: ['click'],
    props: {
        icon: {
            type: [String, Array, Boolean],
            default() {
                return ['far', 'ellipsis-v'];
            },
        },
        iconRight: [String, Array],
        iconSize: {
            type: String,
            default() {
                return 'xl';
            },
        },
        btnSize: {
            type: String,
            default() {
                return 'default';
            },
        },
        label: String,
        showUsersnapOption: { type: Boolean, default: false },
    },
    computed: {
        leftIcon() {
            if (typeof this.icon === 'string') return ['far', this.icon];
            else if (!this.icon) return false;
            else return this.icon;
        },
        rightIcon() {
            if (typeof this.iconRight === 'string') return ['far', this.iconRight];
            else return this.iconRight;
        },
        showActivator() {
            return typeof this.$attrs.absolute === 'undefined';
        },
        buttonAttributes() {
            let attrs = _.clone(this.$attrs);

            attrs.size = 'small';
            attrs.variant = 'text';
            if (this.icon && !this.label) {
                attrs.icon = true;
            }

            // attrs.icon = !!this.icon && !this.hasAttr('outlined') && !this.hasAttr('text');
            attrs.color = this.$attrs.color || 'secondary';

            return attrs;
        },
        maxWidth() {
            return this.$attrs['max-width'] ? 'max-width: ' + this.$attrs['max-width'] + 'px' : undefined;
        },
    },
    methods: {
        hasAttr(attr) {
            return this.$attrs[attr] === '';
        },
    },
};
</script>

<template>
    <v-menu v-bind="$attrs" :style="maxWidth">
        <template v-if="showActivator" #activator="{ props }">
            <v-btn v-bind="mergeProps(props, buttonAttributes)" :size="btnSize" @click="$emit('click')" :data-cy="$attrs['data-cy'] + 'Button'">
                <font-awesome-icon v-if="leftIcon" :icon="leftIcon" :size="iconSize" />
                <span v-if="label" v-html="label" class="text-truncate" :class="{ 'ml-2': icon, 'text-subtitle-2': hasAttr('fab') }" :style="maxWidth"></span>
                <font-awesome-icon v-if="rightIcon" :icon="rightIcon" :size="iconSize" class="ml-3" />
            </v-btn>
        </template>
        <v-list :density="$vuetify.display.mdAndUp ? 'compact' : 'default'" class="py-0 scroll-on no-select" style="max-height: 60vh;">
            <slot></slot>
            <bn-menu-item class="text-truncate" v-if="$vuetify.display.xs && showUsersnapOption" icon="bullhorn" label="Feedback" @click="showUsersnap"></bn-menu-item>
        </v-list>
    </v-menu>
</template>
