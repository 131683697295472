import { useWorkflow } from '@/common/workflowEngine/useWorkflow';
import { crud } from '@/common/crud';
import { BN_List, BN_ListItem, Reference } from '@/fhirworks';
import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from '@/stores/auth';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { isPatient, isUser } from '@/common/core';

const resetApplicationState = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    let existing = await $httpFhirApi.get('BN_List?type=calendarState&owner=' + data.practitioner.id);
    existing.data?.forEach((item) => {
        transaction.push(crud.delete({ id: item.id, resourceType: 'BN_List' }));
    });

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return transaction;
};

const setCalendarState = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    let items = [];

    // calendar view
    if (data.type) {
        items.push(
            new BN_ListItem({
                code: 'type',
                value: {
                    string: data.type,
                },
            }),
        );
    }
    if (data.hideDays) {
        items.push(
            new BN_ListItem({
                code: 'hideDays',
                value: {
                    string: data.hideDays.toString(),
                },
            }),
        );
    }
    if (data.focus && !data.defaultState) {
        items.push(
            new BN_ListItem({
                code: 'focus',
                value: {
                    string: data.focus,
                },
            }),
        );
    }
    if (data.start && !data.defaultState) {
        items.push(
            new BN_ListItem({
                code: 'start',
                value: {
                    string: JSON.stringify(data.start),
                },
            }),
        );
    }
    if (data.end && !data.defaultState) {
        items.push(
            new BN_ListItem({
                code: 'end',
                value: {
                    string: JSON.stringify(data.end),
                },
            }),
        );
    }

    // calendar contacts - default to current user
    let actors = [
        new BN_ListItem({
            code: 'actor',
            value: {
                Reference: {
                    id: data.user.id,
                    resourceType: 'Practitioner',
                },
            },
        }),
    ];
    if (data.actors) {
        actors = data.actors.map(
            (item) =>
                new BN_ListItem({
                    code: 'actor',
                    value: {
                        Reference: {
                            id: item.id,
                            resourceType: item.resourceType,
                        },
                    },
                }),
        );
    }
    items = [...items, ...actors];

    const authStore = useAuthStore();

    // Update existing
    const listType = data.defaultState ? 'calendarDefaultState' : 'calendarState';
    let existing = await $httpFhirApi.get('BN_List?type=' + listType + '&owner=' + data.user.id + '&version=' + data.location.id);
    if (existing.data.length) {
        const list = existing.data[0];
        list.item = items;
        transaction.push(crud.put(list));
    } else if (!authStore.isBnSupportUser) {
        // list doesn't exist so create it
        const id = uuidv4();
        const url = import.meta.env.VITE_PROTOCOL + authStore.account.fhirApiUri + '/BN_List/' + id;
        const newList = new BN_List({
            id: id,
            url: url,
            type: listType,
            owner: new Reference({ id: data.user.id, resourceType: 'Practitioner' }),
            version: data.location.id,
        });
        newList.item = items;
        transaction.push(crud.post(newList));
    }

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return transaction;
};

const getCalendarState = async (data, existingTransaction = false) => {
    const state = {
        actors: [
            {
                id: data.user.id,
                display: data.user.display,
                resourceType: data.user.resourceType,
                isUser: true,
                photo: data.user.photo,
            },
        ],
    };
    let response = await $httpFhirApi.get(
        'BN_List?type=calendarState,calendarDefaultState&owner=' +
            data.user.id +
            '&version=' +
            data.location.id +
            '&_include=itemReference&_elements=Patient.name,Patient.managingOrganization,Patient.photo,Practitioner.name,Practitioner.primaryLocation,Practitioner.photo,Practitioner.timeZone',
    );
    if (response.data.length) {
        const defaultState = response.data.find((item) => item.resourceType === 'BN_List' && item.type === 'calendarDefaultState') || {};
        let currentState = response.data.find((item) => item.resourceType === 'BN_List' && item.type === 'calendarState');
        if (!currentState || data.defaultState) {
            currentState = defaultState;
        }
        // populate type, dates, etc.
        state.type = currentState.item?.find((item) => item.code === 'type')?.valueX || 'day';
        state.hideDays = currentState.item?.find((item) => item.code === 'hideDays')?.valueX || '';
        state.focus = currentState.item?.find((item) => item.code === 'focus')?.valueX || '';
        // populate contacts/rooms
        const actors =
            currentState.item
                ?.filter((item) => item.code === 'actor')
                .flatMap((item) => {
                    const reference = item.valueX;
                    const actor = response.data.find((contact) => contact.id === reference.id);
                    if (!actor) return [];
                    return {
                        id: reference.id,
                        display: actor?.firstName ? actor.firstName + ' ' + actor.lastName.charAt(0) : actor.name,
                        resourceType: reference.resourceType,
                        photo: actor.photo,
                        isUser: isUser(actor),
                        isPatient: isPatient(actor),
                        timeZone: actor.timeZone,
                    };
                }) || [];
        if (actors.length) {
            state.actors = actors;
        }
    }
    return state;
};

export function useStateFlow() {
    return { resetApplicationState, setCalendarState, getCalendarState };
}
