import Money from './types/Money';
import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import Coding from './types/Coding';

export default class BN_ERA_plbProviderAdjustmentInfo extends BackboneElement {
    static __className = 'BN_ERA_plbProviderAdjustmentInfo';

    __objectStructure = {
        providerAdjustmentAmount: Money,
        providerAdjustmentReasonCode: Coding,
    };

    constructor(constructJson, className = 'BN_ERA_plbProviderAdjustmentInfo') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
