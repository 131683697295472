import DomainResource from './DomainResource';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import AppointmentParticipant from './AppointmentParticipant';
import Period from './types/Period';
import Reference from './types/Reference';
import merge from 'lodash/merge';
import AppointmentService from '@/fhirworks/AppointmentService';
import Annotation from '@/fhirworks/types/Annotation';

const appointmentStatusValues = {
    arrived: 'arrived',
    booked: 'booked',
    cancelled: 'cancelled',
    checkedIn: 'checked-in',
    enteredInError: 'entered-in-error',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    pending: 'pending',
    proposed: 'proposed',
    waitlist: 'waitlist',
};

const appointmentStatusDisplayValues = {
    arrived: 'Arrived',
    booked: 'Booked',
    cancelled: 'Cancelled',
    checkedIn: 'checked-in',
    enteredInError: 'Entered-in-error',
    fulfilled: 'Fulfilled',
    noshow: 'Noshow',
    pending: 'Pending',
    proposed: 'Proposed',
    waitlist: 'Waitlist',
};

const appointmentParticipantStatus = {
    accepted: 'accepted',
    declined: 'declined',
    tentative: 'tentative',
    needsAction: 'needs-action',
};

export default class Appointment extends DomainResource {
    static __className = 'Appointment';

    __objectStructure = {
        identifier: [Identifier], // External Ids for this item
        status: String, // proposed | pending | booked | arrived | fulfilled | cancelled | noshow | entered-in-error | checked-in | waitlist
        cancelationReason: CodeableConcept, // The coded reason for the appointment being cancelled
        serviceCategory: [CodeableConcept], // A broad categorization of the service that is to be performed during this appointment
        serviceType: [CodeableConcept], // The specific service that is to be performed during this appointment
        specialty: [CodeableConcept], // The specialty of a practitioner that would be required to perform the service requested in this appointment
        appointmentType: CodeableConcept, // The style of appointment or patient that has been booked in the slot (not service type)
        reasonCode: [CodeableConcept], // Coded reason this appointment is scheduled
        reasonReference: [Reference], // Reason the appointment is to take place Condition | Procedure | Observation | ImmunizationRecommendation
        priority: Number, // Used to make informed decisions if needing to re-prioritize
        description: String, // Shown on a subject line in a meeting request, or appointment list
        supportingInformation: [Reference], // Additional information to support the appointment
        start: String, // When appointment is to take place (string formated as: YYYY-MM-DDThh:mm:ss.sss+zz:zz)
        end: String, // When appointment is to conclude (string formated as: YYYY-MM-DDThh:mm:ss.sss+zz:zz)
        minutesDuration: Number, // Can be less than start/end (e.g. estimate)
        slot: [Reference], // The slots that this appointment is filling
        created: String, // The date that this appointment was initially created
        comment: String, // Additional comments
        patientInstruction: String, // Detailed information and instructions for the patient
        basedOn: [Reference], // The service request this appointment is allocated to assess
        participant: [AppointmentParticipant], // Participants involved in appointment
        requestedPeriod: [Period], // Potential date/time interval(s) requested to allocate the appointment within
        /**
         * First Class extensions
         */
        form: [String], // forms associated to the encounter/appointment
        note: [Annotation],
        pos: CodeableConcept,
        seriesId: String,
        seriesDescription: String,
        service: [AppointmentService],
        bnAppointmentType: Reference,
        paymentStatus: String,
        group: Reference,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'Appointment') {
        super(constructJson, className);

        if (constructJson instanceof Appointment) {
            constructJson = constructJson.toJSON();
        }

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}

export { appointmentStatusValues, appointmentStatusDisplayValues, appointmentParticipantStatus, Appointment };
