import merge from 'lodash/merge';
import Coding from './types/Coding';
import Quantity from './types/Quantity';
import Expression from './types/Expression';
import Extension from './Extension';
import QuestionnaireItemEnableCondition from './QuestionnaireItemEnableCondition';
import QuestionnaireAnswerOption from './QuestionnaireAnswerOption';
import QuestionnaireInitialValue from './QuestionnaireInitialValue';
import BackboneElement from './BackboneElement';
import QuestionnaireItem_text from '@/fhirworks/QuestionnaireItem_text';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';
import { htmlToText, isHtml } from '@/common/core';
import QuestionnaireItemWidgetProps from '@/fhirworks/QuestionnaireItemWidgetProps';

/**
 * @property {String} linkId
 * @property {String} definition
 * @property {Coding} code: [Coding], // Concept that represents the overall questionnaire
 * @property {String} prefix: String, // E.g. "1(a)", "2.5.3"
 * @property {String} text: String, // Primary text for the item
 * @property {String} type: String, // **Required group | display | boolean | decimal | integer | date | dateTime
 * @property {QuestionnaireItemEnableCondition} enableWhen: [QuestionnaireItemEnableCondition], // Only allow data when + Rule: If the operator is 'exists', the value must be a boolean
 * @property {String} enableBehavior: String, // all | any
 * @property {Boolean} required: Boolean, // Whether the item must be included in data results
 * @property {Boolean} repeats: Boolean, // Whether the item may repeat
 * @property {Boolean} readOnly: Boolean, // Don't allow human editing
 * @property {Number} maxLength: Number, // No more than this many characters
 * @property {String} answerValueSet: String, // Valueset containing permitted answers
 * @property {QuestionnaireAnswerOption} answerOption: [QuestionnaireAnswerOption], // Permitted answer
 * @property {QuestionnaireInitialValue} initial: [QuestionnaireInitialValue], // Initial value(s) when item is first rendered
 * @property {QuestionnaireItem} item: [QuestionnaireItem], // Nested questionnaire items
 * @property {Extension} extension: [Extension],
 */
export default class QuestionnaireItem extends BackboneElement {
    static __className = 'QuestionnaireItem';

    __objectStructure = {
        linkId: String, // Unique id for item in questionnaire
        definition: String, // ElementDefinition - details for the item
        code: [Coding], // Concept that represents the overall questionnaire
        prefix: String, // E.g. "1(a)", "2.5.3"
        text: String, // Primary text for the item
        _text: QuestionnaireItem_text,
        type: String, // **Required group | display | boolean | decimal | integer | date | dateTime
        enableWhen: [QuestionnaireItemEnableCondition], // Only allow data when + Rule: If the operator is 'exists', the value must be a boolean
        enableBehavior: String, // all | any
        required: Boolean, // Whether the item must be included in data results
        repeats: Boolean, // Whether the item may repeat
        readOnly: Boolean, // Don't allow human editing
        maxLength: Number, // No more than this many characters
        answerValueSet: String, // ValueSet containing permitted answers
        answerOption: [QuestionnaireAnswerOption], // Permitted answer
        initial: [QuestionnaireInitialValue], // Initial value(s) when item is first rendered
        item: [QuestionnaireItem], // Nested questionnaire items
        extension: [Extension],
        /**
         * First Class extensions
         */
        autoNote: [String],
        autoNoteCollection: [String],
        render: String,
        locked: Boolean,
        hidden: Boolean,
        width: Quantity,
        itemControl: CodeableConcept,
        minValue: Number,
        maxValue: Number,
        noAnswerValue: Boolean,
        sliderStepValue: Number,
        displayCategory: CodeableConcept,
        observationLinkPeriod: Quantity,
        answerAllowOther: Boolean,
        // first class extensions for SDC
        itemPopulationContext: Expression,
        itemExtractionContext: Expression,
        initialExpression: Expression,
        customWidget: String,
        editDemographics: Boolean,
        groupParticipantItem: Boolean,
        widgetProps: QuestionnaireItemWidgetProps,
        informationText: String,
        hideEmptyFields: Boolean,
        requireStrictDate: Boolean,
        isCarf: Boolean,
        isJointCommission: Boolean,
        isCms: Boolean,
        ehrOnly: Boolean,
        scoreExpression: Expression,
        deprecate: Boolean,
    };

    __objectDefaults = {
        type: 'string',
    };

    __widgetPropsLocal = undefined;

    constructor(constructJson, className = 'QuestionnaireItem') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.populateCustomPropertiesFromJson(constructJson, 'bnText');

        // Override getter/setter properties defined in FHIRObjectBase
        Object.defineProperty(this, 'widgetProps', {
            configurable: true,
            get() {
                if (this.__widgetPropsLocal === undefined) {
                    this.__widgetPropsLocal = new QuestionnaireItemWidgetProps();
                }
                return this.__widgetPropsLocal;
            },
            set(value) {
                if (value === '' || value === null) {
                    this.__widgetPropsLocal = undefined;
                } else {
                    this.__widgetPropsLocal = value;
                }
            },
        });

        this.originalObjJson = this.toJSON();

        if (this.__widgetProps !== undefined) {
            this.__widgetPropsLocal = new QuestionnaireItemWidgetProps(this.__widgetProps.toJSON());
        }
    }

    get bnText() {
        return this._text?.renderingXhtml || this.text;
    }

    set bnText(value) {
        if (!this._text) {
            this._text = new QuestionnaireItem_text();
        }

        if (value === '' || value === null) {
            this.text = undefined;
            this._text.renderingXhtml = undefined;
            return;
        }

        if (isHtml(value)) {
            this.text = htmlToText(value);
            this._text.renderingXhtml = value;
        } else {
            this.text = value;
            this._text.renderingXhtml = undefined;
        }
    }

    // set/get 'render' as a JSON object
    get bnRender() {
        return this.render ? JSON.parse(this.render) : undefined;
    }
    set bnRender(value) {
        this.render = JSON.stringify(value);
    }

    getQuestionnaireFieldCoding() {
        return this.code.find((e) => e.system === 'http://fhir.bestnotes.com/BN_QuestionnaireField');
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
