import DomainResource from './DomainResource';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import merge from 'lodash/merge';
import { contactPointTraits } from './trait/contactPointTraits';
import Period from '@/fhirworks/types/Period';
import CarePlanActivity from '@/fhirworks/CarePlanActivity';

export default class CarePlan extends DomainResource {
    static __className = 'CarePlan';

    __objectStructure = {
        identifier: [Identifier], // External Ids for this item
        basedOn: [Reference], // Fulfills CarePlan
        replaces: [Reference], // CarePlay replaced by this CarePlan
        partOf: [Reference], // Part of referenced CarePlan
        status: String, // *required* draft | active | on-hold | revoked | completed | entered-in-error | unknown
        intent: String, // *required* proposal | plan | order | option
        category: [CodeableConcept], // Type of plan
        title: String, // Human-friendly name for the CarePlan
        description: String, // Summary of nature of plan
        subject: Reference, // Time period team covers
        encounter: Reference, // Encounter created as part of
        period: Period, // Time period plan covers
        created: String, // Date record was first recorded
        author: Reference, // Who is the designated responsible party
        contributor: [Reference], // Who provided the content of the care plan
        goal: [Reference], // Desired outcome of plan
        activity: [CarePlanActivity], // Action to occur as part of plan

        // first-class extensions
        estEndDate: String,
        libraryId: String,
        order: Number,
    };

    constructor(constructJson, className = 'CarePlan') {
        super(constructJson, className);

        contactPointTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
