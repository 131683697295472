import BackboneElement from './BackboneElement';
import Reference from './types/Reference';
import merge from 'lodash/merge';

export default class PatientLink extends BackboneElement {
    static __className = 'PatientLink';

    __objectStructure = {
        other: Reference,
        type: String,
    };

    constructor(constructJson, className = 'PatientLink') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
