import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { Person } from '@/fhirworks';
import _ from 'lodash';

export default {
    search: (queryString) => {
        queryString = encodeURI(queryString.split(' ').filter(Boolean).join('+'));

        let returnPromise = $httpFhirApi.get(
            'Person/$lookup?by=name.family,name.given,birthDate,identifier.value;address.city,address.line;telecom.value&sort=name.given,name.family&count=50&q=' + queryString,
        );

        return returnPromise.then((res) => {
            let returnedPersons = [];

            res.originalData.entry.forEach((resultRow) => {
                let person = new Person(resultRow.resource);
                person._group = _.get(resultRow, '_group', '');

                returnedPersons.push(person);
            });

            return returnedPersons;
        });
    },
};
