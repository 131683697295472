import BaseElement from './structure/BaseElement';
import Extension from './Extension';
import extensionTraits from './trait/extensionTraits';

/**
 * @property {any} extension
 * @property {any} id
 */
export default class Element extends BaseElement {
    static __className = 'Element';

    __objectStructure = {
        id: String,
        extension: [Extension],
    };

    constructor(constructJson, className = 'Element') {
        super(constructJson, className);

        extensionTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.__bn = {};
        let dynamicExtensionValue = this.getDynamicExtensionValue('bn');
        if (dynamicExtensionValue) {
            this.extensionToJson(this.__bn, dynamicExtensionValue);
        }

        this.__temp = {};

        this.originalObjJson = this.toJSON();
        this.elementConstructorCompleted = true;
    }

    get bn() {
        if (this.__bn === undefined) {
            this.__bn = {};
        }
        return this.__bn;
    }

    set bn(value) {
        this.__bn = value;
    }

    get temp() {
        if (this.__temp === undefined || this.__temp === null) {
            this.__temp = {};
        }
        return this.__temp;
    }

    set temp(value) {
        this.__temp = value;
    }

    toJSON() {
        this.handleDynamicExtensionToJson?.('bn');

        return this.removeUndefinedObjProps({
            id: this.id,
            extension: this.arrayPropertyToJson(this.extension),
        });
    }
}
