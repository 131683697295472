// import { convert } from 'html-to-text';

import html2plaintext from 'html2plaintext';
import { sampleSize } from 'lodash';
import { encode, decode } from 'html-entities';

/**
 * Core/Global Methods
 */

// Plain text conversion: encode/decode htmlentities, striptags
export function htmlEncode(str) {
    return encode(str);
}
export function htmlDecode(str) {
    return decode(str);
}
// strip HTML tags from string
export function stripTags(str) {
    if (!str) return;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
}
// return plain text from an HTML or encoded string
export function plainText(str) {
    return stripTags(decode(str));
}

// is a resource a real patient?
export function isPatient(resource) {
    return resource?.resourceType === 'Patient' && (!!resource?.managingOrganization?.id || resource?.isPatient);
}

// is a resource a person? (i.e. - NOT A PATIENT)
export function isPerson(resource) {
    return resource?.resourceType === 'Patient' && !resource?.managingOrganization?.id;
}

// is a resource a user? (i.e. - Practitioner with an assigned Primary Location)
export function isUser(resource) {
    return resource?.resourceType === 'Practitioner' && (!!resource?.primaryLocation?.id || resource?.isUser);
}

// determine contact type from a resource or reference
export function getContactType(resource) {
    if (isPerson(resource) && !resource.isPatient) return 'Person';
    else if (isUser(resource) || resource.isUser) return 'User';
    else {
        return resource?.resourceType;
    }
}

// scroll to element by id or className
export function scrollToElement(id) {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

// convert object to generic JSON (resource to JSON)
export function jsonify(obj) {
    return typeof obj === 'object' ? JSON.parse(JSON.stringify(obj)) : obj;
}

export function htmlToText(htmlText) {
    if (typeof htmlText !== 'string') return htmlText;

    // Remove any strike out text
    htmlText = htmlText.replace(/<s>.*?<\/s>/gi, '');

    htmlText = html2plaintext(htmlText);
    // htmlText = convert(htmlText);

    // Remove any trailing carriage return
    return htmlText.replace(/\n$/gi, '');
}

export function nl2br(str, is_xhtml) {
    let breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export function isHtml(str) {
    // this regex looks for matching opening closing tags which will resolve true
    // for output from our RTE editor
    return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(str);
}

export function sortByList(source, list, prop1, prop2) {
    if (prop1) {
        // sorting by multiple properties
        source.sort((a, b) => {
            let aInList = list.indexOf(a[prop1]) >= 0;
            let bInList = list.indexOf(b[prop1]) >= 0;
            if (!aInList && !bInList && prop2) {
                return a[prop2].localeCompare(b[prop2]);
            } else if (!aInList) {
                return 1;
            } else if (!bInList) {
                return -1;
            } else {
                return list.indexOf(a[prop1]) - list.indexOf(b[prop1]);
            }
        });
        return source;
    } else {
        source.sort((a, b) => {
            let aInList = list.indexOf(a) >= 0;
            let bInList = list.indexOf(b) >= 0;
            if (!aInList && !bInList) {
                return a.localeCompare(b);
            } else if (!aInList) {
                return 1;
            } else if (!bInList) {
                return -1;
            } else {
                return list.indexOf(a) - list.indexOf(b);
            }
        });
        return source;
    }

    /**
     * This was a fun one...
     * Sort an array based on another array. Sometimes we want to bring certain elements to the top and
     * then sort the rest alpha.
     * sortByList([D,C,B,A,Z,X,Y], [Z,Y,X]) ==> [Z,Y,X,A,B,C,D]
     * You can add props as well to sort the first by prop1 and the second by prop2
     */
}

export function getUrlFromCanonical(canonicalUrl) {
    let [urlInfo, versionInfo] = canonicalUrl.split('|');
    // Break down the urls information based on '/'
    let urlParts = urlInfo.split('/');
    let entityId = urlParts[urlParts.length - 1];
    // Create the correct url query value (NO specific entity id or version info)
    let baseUrl = urlInfo.replace('/' + entityId, '');
    let urlBase = baseUrl;

    // next add the url to the query
    baseUrl += '?url=' + urlInfo;
    // if any version was passed add that to the query
    if (versionInfo) {
        baseUrl += '&version=' + versionInfo;
    }

    return { fullUrl: baseUrl, url: urlInfo, urlBase: urlBase, version: versionInfo };
}

// Diagnosis global functions
export function getDiagnosisVerificationStatusColor(color) {
    switch (color) {
        case 'unconfirmed':
            return '';
        case 'provisional':
            return 'warning';
        case 'differential':
            return 'warning';
        case 'confirmed':
            return 'success';
        case 'refuted':
            return 'patient';
        case 'entered-in-error':
            return 'error';
    }
}

export function getDiagnosisVerificationStatusChip(chip) {
    switch (chip) {
        case 'unconfirmed':
            return 'question-circle';
        case 'provisional':
            return 'pause-circle';
        case 'differential':
            return 'exclamation-circle';
        case 'confirmed':
            return 'check-circle';
        case 'refuted':
            return 'times-circle';
        case 'entered-in-error':
            return 'ban';
    }
}

export function createRandomString(length) {
    var chars = 'abcdefghijklmnopqrstufwxyz1234567890';
    var pwd = sampleSize(chars, length || 12);
    return pwd.join('');
}
