import Element from './Element';

import get from 'lodash/get';
import merge from 'lodash/merge';

export default class Narrative extends Element {
    static __className = 'Narrative';

    constructor(narrativeJson, className = 'Narrative') {
        // ToDo: Nothing was being passed to super
        super(narrativeJson, className);

        this.__status = get(narrativeJson, 'status', undefined);
        this.__div = get(narrativeJson, 'div', undefined);

        this.originalObjJson = this.toJSON();
    }

    // Properties

    get status() {
        return this.__status;
    }

    set status(value) {
        this.__status = value;
    }

    get div() {
        return this.__div;
    }

    set div(value) {
        this.__div = value;
    }

    toJSON() {
        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                status: this.status,
                div: this.div,
            }),
        );
    }
}
