import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import Reference from './types/Reference';

export default class BN_ProfileManagedItem extends BackboneElement {
    static __className = 'BN_ProfileManagedItem';

    __objectStructure = {
        resource: Reference, // BN_ProfileAsset
        status: String, // active, deprecated
    };

    constructor(constructJson, className = 'BN_ProfileManagedItem') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
