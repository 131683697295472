import DomainResource from './DomainResource';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import CodeablReference from './types/CodeableReference';
import merge from 'lodash/merge';
import CodeableConcept from './types/CodeableConcept';
import Period from '@/fhirworks/types/Period';
import DocumentReferenceContent from '@/fhirworks/DocumentReferenceContent';
import DocumentReferenceRelatesTo from '@/fhirworks/DocumentReferenceRelatesTo';
import DocumentReferenceAttester from '@/fhirworks/DocumentReferenceAttester';

export default class DocumentReference extends DomainResource {
    static __className = 'DocumentReference';

    __objectStructure = {
        identifier: [Identifier],
        version: String,
        basedOn: [Reference],
        status: String, // current | superseded | entered-in-error
        docStatus: String, // registered | partial | preliminary | final | amended | corrected | appended | cancelled | entered-in-error | deprecated | unknown
        modality: [CodeableConcept],
        type: CodeableConcept,
        category: [CodeableConcept],
        subject: Reference,
        context: [Reference],
        event: [CodeablReference],
        bodySite: [CodeablReference],
        facilityType: CodeableConcept,
        practiceSetting: CodeableConcept,
        period: Period,
        date: String,
        author: [Reference],
        attester: [DocumentReferenceAttester],
        custodian: Reference,
        relatesTo: [DocumentReferenceRelatesTo],
        description: String,
        securityLabel: [CodeableConcept],
        content: [DocumentReferenceContent],

        /**
         * First Class extensions
         */
        tag: [String],
    };

    __objectDefaults = {
        gender: 'unknown',
    };

    constructor(constructJson, className = 'DocumentReference') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.populateCustomPropertiesFromJson(constructJson, ['spokenLanguage', 'ssn']);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
