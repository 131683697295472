import Element from './Element';

import merge from 'lodash/merge';
import Coding from './types/Coding';
import Range from './types/Range';
import Quantity from './types/Quantity';
import CodeableConcept from './types/CodeableConcept';
import Reference from './types/Reference';

export default class UsageContext extends Element {
    static __className = 'UsageContext';

    __objectStructure = {
        code: Coding,
        valueCodeableConcept: CodeableConcept,
        valueQuantity: Quantity,
        valueRange: Range,
        valueReference: Reference,
    };

    constructor(constructJson, className = 'UsageContext') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
