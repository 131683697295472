import merge from 'lodash/merge';
import DomainResource from './DomainResource';
import Address from './Address';
import CoverageEligibilityResponseError from './CoverageEligibilityResponseError';
import CoverageEligibilityResponseInsurance from './CoverageEligibilityResponseInsurance';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import ServicedX from './types/ServicedX';
import Period from './types/Period';

import get from 'lodash/get';

export default class CoverageEligibilityResponse extends DomainResource {
    static __className = 'CoverageEligibilityResponse';

    __objectStructure = {
        identifier: [Identifier],
        status: String, // active | cancelled | draft | entered-in-error
        purpose: [String], // auth-requirements | benefits | discovery | validation
        patient: Reference,
        serviced: ServicedX,
        created: String,
        requestor: Reference,
        request: Reference,
        outcome: String,
        disposition: String,
        // disposition1: String,
        insurer: Reference,
        insurance: [CoverageEligibilityResponseInsurance],
        preAuthRef: String,
        form: CodeableConcept,
        error: [CoverageEligibilityResponseError],
        /**
         * First Class extensions
         */
        payorName: String,
        coverageDescription: String,
        insuranceType: String,
        effectiveDate: Period,
        payorAddress: Address,
        payorPhone: String,
        insuranceStatus: String,
        payor: String,
    };

    constructor(constructJson, className = 'CoverageEligibilityResponse') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        let fhirServicedDate = get(constructJson, 'servicedDate', undefined);
        if (fhirServicedDate) {
            this.servicedDate = fhirServicedDate;
        }

        let fhirServicedPeriod = get(constructJson, 'servicedPeriod', undefined);
        if (fhirServicedPeriod) {
            this.servicedPeriod = fhirServicedPeriod;
        }

        this.originalObjJson = this.toJSON();
    }

    get servicedDate() {
        if (!this.serviced) {
            return undefined;
        }
        return this.serviced.servicedDate;
    }

    set servicedDate(value) {
        if (!this.serviced) {
            this.serviced = new ServicedX();
        }
        this.serviced.servicedDate = value;
    }

    get servicedPeriod() {
        if (!this.serviced) {
            return undefined;
        }
        return this.serviced.servicedPeriod;
    }
    set servicedPeriod(value) {
        if (!this.serviced) {
            this.serviced = new ServicedX();
        }
        this.serviced.servicedPeriod = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
