<script>
export default {
    name: 'BnFooter',
};
</script>
<template>
    <ion-footer class="footer-ios-ample"></ion-footer>
</template>
<style>
.footer-ios-ample {
    padding-bottom: constant(safe-area-inset-bottom) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
    padding-bottom: var(--ion-safe-area-bottom, 0);
    background: #ffffff;
}
.dialog-footer {
    background: #ffffff !important;
}
</style>
