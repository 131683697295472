import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import Coding from './types/Coding';

export default class BN_ERA_PatientProcedureAdjustmentsReasons extends BackboneElement {
    static __className = 'BN_ERA_PatientProcedureAdjustmentsReasons';

    __objectStructure = {
        amount: Number,
        code: Coding,
        quantity: String,
    };

    constructor(constructJson, className = 'BN_ERA_PatientProcedureAdjustmentsReasons') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
