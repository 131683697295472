import PatientCommunication from '../PatientCommunication';
import Identifier from '../types/Identifier';
import CodeableConcept from '../types/CodeableConcept';

const ssnIdentifierSystemURI = 'http://hl7.org/fhir/sid/us-ssn';
const dlIdentifierSystemURI = 'urn:oid:2.16.840.1.113883.4.3';

const humanExtensionValueTraits = function () {
    //**********************************
    // SpokenLanguage OBJECT
    //**********************************
    Object.defineProperty(this, 'spokenLanguage', {
        get() {
            if (this.communication.length === 0) {
                return undefined;
            }

            // The Practitioner communication property is ONLY a CodeableConcept value
            if (this.resourceType === 'Practitioner') {
                return this.communication[0];
            }

            // Patient entity values are of PatinetCommunication type
            let preferred = this.communication.filter((item) => item.preferred);

            if (preferred.length) {
                return preferred[0];
            }

            return this.communication[0];
        },
        set(value) {
            if (value === undefined || value === '') {
                this.communication.splice(0);
                return;
            }

            // The Practitioner communication property is ONLY a CodeableConcept value
            if (this.resourceType === 'Practitioner') {
                if (!(value instanceof CodeableConcept)) {
                    return;
                }

                this.communication.splice(0, 1, value);

                return;
            }

            // Patient entity values are of PatinetCommunication type
            if (!(value instanceof PatientCommunication)) {
                return;
            }

            this.communication.splice(0, 1, value);
        },
    });

    //**********************************
    // SpokenLanguage - DISPLAY VALUE ONLY
    //**********************************
    Object.defineProperty(this, 'spokenLanguageDisplay', {
        get() {
            if (this.communication.length === 0) {
                return undefined;
            }

            let spokenLanguage;

            let preferred = this.communication.filter((item) => item.preferred);
            if (preferred.length) {
                spokenLanguage = preferred[0];
            } else spokenLanguage = this.communication[0];

            if (this.resourceType === 'Patient') {
                spokenLanguage = spokenLanguage.language;
            }

            return spokenLanguage.coding.find((coding) => coding.system === 'urn:ietf:bcp:47')?.display;
        },
    });

    //**********************************
    // Gender/GenderIdentity - DISPLAY VALUE ONLY
    //**********************************
    Object.defineProperty(this, 'genderDisplay', {
        get() {
            if (!this.gender && !this.genderIdentity) {
                return undefined;
            }
            return this.genderIdentity?.text ?? this.gender;
        },
    });

    //**********************************
    // Pronouns - DISPLAY VALUE ONLY
    //**********************************
    Object.defineProperty(this, 'pronounsDisplay', {
        get() {
            if (!this.pronouns) {
                return undefined;
            }
            return this.pronouns.display;
        },
    });

    //**********************************
    // Social Security Number
    //**********************************
    Object.defineProperty(this, 'ssn', {
        get() {
            let ssnIdentifier = this.identifier.filter((item) => item.system === ssnIdentifierSystemURI)[0];
            if (!ssnIdentifier) {
                return undefined;
            }

            let ssnValue = ssnIdentifier.value;
            if (ssnValue === undefined) {
                // Use the setter here so the Identifier will be removed
                this.ssn = undefined;
                return undefined;
            }

            ssnValue = ssnValue.replace(/\D/g, '');
            ssnValue = ssnValue.replace(/^(\d{3})/, '$1-');
            ssnValue = ssnValue.replace(/-(\d{2})/, '-$1-');
            ssnValue = ssnValue.replace(/(\d)-(\d{4}).*/, '$1-$2');
            return ssnValue;
        },
        set(value) {
            let identifier = this.identifier.filter((item) => item.system === ssnIdentifierSystemURI)[0];

            if (value === undefined && identifier) {
                this.identifier.splice(this.identifier.indexOf(identifier), 1);
                return;
            }

            if (value instanceof Identifier) {
                if (identifier) {
                    this.identifier.splice(this.identifier.indexOf(identifier), 1, value);
                    return;
                }

                this.identifier.push(value);
            }

            if (typeof value === 'string') {
                let newValue = value.replace(new RegExp('-', 'g'), '').substr(0, 9);

                // No value so remove the existing ssn identifer or just return
                if (newValue === '') {
                    if (identifier) {
                        this.identifier.splice(this.identifier.indexOf(identifier), 1);
                    }

                    return;
                }

                // We got a value so go updated or add it
                if (identifier) {
                    identifier.value = newValue || undefined;
                    return;
                }

                this.identifier.push(
                    new Identifier({
                        type: {
                            coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v2-0203', code: 'SS' }],
                        },
                        system: ssnIdentifierSystemURI,
                        value: newValue || undefined,
                    }),
                );
            }
        },
    });

    // --------------------------------
    //**********************************
    // Drivers License
    //**********************************
    Object.defineProperty(this, 'driversLicense', {
        get() {
            let dlIdentifier = this.identifier.filter((item) => item.system === dlIdentifierSystemURI)[0];
            if (!dlIdentifier) {
                return undefined;
            }

            let dlValue = dlIdentifier.value;
            if (dlValue === undefined) {
                // Use the setter here so the Identifier will be removed
                this.driversLicense = undefined;
                return undefined;
            }

            dlValue = dlValue.replace(/ /g, '');
            return dlValue;
        },
        set(value) {
            let identifier = this.identifier.filter((item) => item.system === dlIdentifierSystemURI)[0];

            if (value === undefined && identifier) {
                this.identifier.splice(this.identifier.indexOf(identifier), 1);
                return;
            }

            if (value instanceof Identifier) {
                if (identifier) {
                    this.identifier.splice(this.identifier.indexOf(identifier), 1, value);
                    return;
                }

                this.identifier.push(value);
            }

            if (typeof value === 'string') {
                // let newValue = value.replace(new RegExp('-', 'g'), '').substr(0, 9);
                let newValue = value.replace(/ /g, '');

                // No value so remove the existing ssn identifer or just return
                if (newValue === '') {
                    if (identifier) {
                        this.identifier.splice(this.identifier.indexOf(identifier), 1);
                    }

                    return;
                }

                // We got a value so go updated or add it
                if (identifier) {
                    identifier.value = newValue || undefined;
                    return;
                }

                this.identifier.push(
                    new Identifier({
                        type: {
                            coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v2-0203', code: 'DL' }],
                        },
                        system: dlIdentifierSystemURI,
                        value: newValue || undefined,
                    }),
                );
            }
        },
    });

    return this;
};

export default humanExtensionValueTraits;
