import BackboneElement from './BackboneElement';
import Reference from './types/Reference';
import merge from 'lodash/merge';

export default class BN_SuperBillInsurance extends BackboneElement {
    static __className = 'BN_SuperBillInsurance';

    __objectStructure = {
        sequence: Number,
        coverage: Reference, // "Coverage"
    };

    constructor(constructJson, className = 'BN_SuperBillInsurance') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
