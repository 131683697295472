import axios from 'axios';
import axiosRetry from 'axios-retry';
import { useAuthStore } from '@/stores/auth';
import { bnHttpErrorHandler } from '@/common/api/http.error.notifications';

const timeoutValue = 30000;
let axiosConfig = {
    baseURL: import.meta.env.VITE_API_PLATFORM_ENTRYPOINT,
    headers: {
        post: { Accept: 'application/json' },
        // prevent browsers from storing data in persistant storage, only memory
        // in cases where browsers store "no-cache" data in memory, advise it to validate it hasn't changed
        put: { Accept: 'application/json' },
        get: { Accept: 'application/json', 'cache-control': 'no-cache,no-store,must-revalidate,private', expires: 0, pragma: 'no-cache' },
        patch: { Accept: 'application/json' },
    },
    timeout: !import.meta.env.VITE_NO_TIMEOUT ? timeoutValue : 0,
};

if (window.location.host.includes('loca.lt')) {
    axiosConfig.baseURL = 'https://' + import.meta.env.VITE_MOBILE_TEST_NAME + '-bestnotes-fresh-api.loca.lt';
}

export const $http = axios.create(axiosConfig);

axiosRetry($http, { retries: 3 });

// Add a request interceptor
$http.interceptors.request.use(
    (config) => {
        const authStore = useAuthStore();
        if (authStore.token && !config?.noAuth) {
            config.headers.setAuthorization('Bearer ' + authStore.token);
        }
        return config;
    },
    (err) => {
        // Do something with request error
        return Promise.reject(err);
    },
);

// Add a response interceptor
$http.interceptors.response.use(
    (res) => {
        // response is a bundle. we want it to be an array of FHIRWorks objects
        if (res.data) {
            res.originalData = res.data;
            res.data = res.data['hydra:member'] || res.data;
        }

        return res;
    },
    async (err) => {
        return await bnHttpErrorHandler(err, timeoutValue);
    },
);
