import BackboneElement from './BackboneElement';

import merge from 'lodash/merge';
import Reference from './types/Reference';
import CodeableConcept from './types/CodeableConcept';

export default class EpisodeOfCareDiagnosis extends BackboneElement {
    static __className = 'EpisodeOfCareDiagnosis';

    __objectStructure = {
        condition: Reference, // Conditions/problems/diagnoses this episode of care is for
        role: CodeableConcept, // Role that this diagnosis has within the episode of care (e.g. admission, billing, discharge …)
        rank: Number, // Ranking of the diagnosis (for each role type)
    };

    constructor(constructJson, className = 'EpisodeOfCareDiagnosis') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
