import { reactive } from 'vue';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { structure, Reference } from '@/fhirworks';

// cannot use this because at the moment no way to get children ov RouterView component
// function matchingDescendants(vm, matcher) {
//     let descendants = _.clone(vm.$el.__vnode.children);
//     let descendant;
//     let matches = [];
//
//     for (let x = 0; x < descendants.length; x++) {
//         descendant = descendants[x];
//
//         if (matcher.test(descendant.type.name)) {
//             matches.push(descendant);
//         }
//
//         let children = descendant.children;
//
//         if (children && Object.prototype.hasOwnProperty.call(children, 'default')) {
//             children = children.default();
//         }
//
//         if (Array.isArray(children)) {
//             for (let y = 0, len = children.length; y < len; y++) {
//                 descendants.push(children[y]);
//             }
//         }
//     }
//
//     return matches.length === 0 ? [] : matches;
// }

let store = reactive({
    displays: [],
    references: [],
    resources: [],
});

export default {
    name: 'get-display-mixin',
    computed: {
        getUSPrivacy() {
            return (reference) => {
                if (!reference) return false;
                return reference.resourceType === 'Patient';
            };
        },
        getDisplay() {
            return (reference) => {
                if (!reference) return '';
                let referenceString = reference;

                // if reference is actual resource, grab display property from that directly
                if (reference instanceof structure.DomainResource) {
                    let displayProperty = this.getDisplayByResourceType(reference);

                    referenceString = reference?.resourceType + '/' + reference?.id;
                    if (displayProperty) {
                        store.displays[referenceString] = displayProperty;
                        return displayProperty;
                    }
                    if (typeof reference === 'object' && !reference?.id) {
                        return reference?.display || '';
                    }
                    if (store.displays[referenceString] !== undefined) {
                        return store.displays[referenceString];
                    }
                    if (!displayProperty) {
                        // if no display, element could be filtered out so try to grab it using loadResource
                        this.loadResource(referenceString, true);
                    }
                    store.displays[referenceString] = displayProperty;
                } else {
                    if (typeof reference === 'object' && !reference?.id) {
                        return reference?.display ? reference.display : '';
                    }
                    if (reference?.id && reference?.resourceType) {
                        referenceString = reference.resourceType + '/' + reference.id;
                    }
                    if (store.displays[referenceString] === undefined) {
                        store.displays[referenceString] = '';
                        this.loadResource(referenceString, true);
                    }
                }
                return store.displays[referenceString];
            };
        },
        getResource() {
            return (reference) => {
                if (!reference) return {};
                let resourceString = reference;

                // if reference is an actual resource just return it
                if (reference instanceof structure.DomainResource) {
                    return reference;
                } else {
                    if (typeof reference === 'object' && !reference?.id) {
                        return {};
                    }
                    if (reference?.id && reference?.resourceType) {
                        resourceString = reference.resourceType + '/' + reference.id;
                    }

                    if (store.resources[resourceString] === undefined) {
                        store.resources[resourceString] = { loading: true };
                        this.loadResource(resourceString);
                    }
                }

                return store.resources[resourceString];
            };
        },
        getReference() {
            return (resource, json = false) => {
                // if reference is an actual resource just return it
                if (resource?.id && resource?.resourceType) {
                    if (!(resource instanceof structure.DomainResource)) {
                        const resourceString = resource.resourceType + '/' + resource.id;
                        this.loadResource(resourceString);
                        const localResource = store.resources[resourceString];
                        if (localResource) {
                            resource = localResource;
                        }
                    }
                } else {
                    return undefined;
                }
                // create reference object
                const reference = {
                    id: resource.id,
                    resourceType: resource.resourceType,
                    display: this.getDisplayByResourceType(resource),
                };

                // return valid FHIR reference object
                if (!json) {
                    return new Reference(reference);
                }
                // include extra data
                if (resource.resourceType === 'Patient') {
                    reference.birthDate = resource.birthDate;
                    reference.managingOrganization = resource.managingOrganization;
                }
                return reference;
            };
        },
    },
    methods: {
        async loadResource(referenceString, display = false) {
            let resource = await this.findDataByReferenceString(referenceString);
            if (resource) {
                if (display) {
                    store.displays[referenceString] = this.getDisplayByResourceType(resource);
                } else {
                    store.resources[referenceString] = resource;
                }
                return;
            }
            // if no local resource get from server
            if (import.meta.env.DEV) {
                // console.warn('get' + (display ? 'Display' : 'Resource') + '() is trying to get ' + referenceString + '.  The resource is not loaded in a data-provider');
            }

            let serverResource = (await $httpFhirApi.get(referenceString)).data;
            if (Array.isArray(serverResource)) {
                serverResource = serverResource[0];
            }
            if (display) {
                store.displays[referenceString] = this.getDisplayByResourceType(serverResource);
            } else {
                store.resources[referenceString] = serverResource;
            }
        },
        getDisplayByResourceType(resource) {
            switch (resource.resourceType) {
                case 'EpisodeOfCare':
                    return resource.originalComplaint;
                case 'Group':
                    return resource.name;
                case 'HealthcareService':
                    return resource.name + (resource.billingCode ? ' ( ' + resource.billingCode + ' )' : '');
                case 'Patient':
                case 'Practitioner':
                case 'Person':
                    return resource.fullName;
                case 'Questionnaire':
                    return resource.title;
                case 'RelatedPerson':
                    return resource.fullName;
                case 'Task':
                    return resource?.code?.text;
                case 'DocumentReference':
                    return resource.description;
                default:
                    return resource.name || resource.display || '';
            }
        },
        async findDataByReferenceString(referenceString) {
            // check for local resource before processing for canonical or getting data from server
            // @TODO: enable a way to grab display data from data providers that use a url or canonicalUrl
            // is a canonical url
            let searchString = referenceString;
            if (typeof referenceString === 'string' && referenceString.includes('http')) {
                let preUrl = new URL(referenceString);

                // Separate url value from version value
                let urlParse = referenceString.split(/\||\?/); // split on either | or ?
                let urlInfo = urlParse[0];
                let versionInfo = urlParse[1];
                // Break down the urls information based on '/'
                let urlParts = urlInfo.split('/');
                let entityId = urlParts[urlParts.length - 1];
                // Create the correct url query value (NO specific entity id or version info)
                let baseUrl = urlInfo.replace('/' + entityId, '');

                let url = new URL(baseUrl);

                url.searchParams.append('url', urlInfo);
                // if any version was passed add that to the query
                if (versionInfo) {
                    url.searchParams.append('version', versionInfo);
                }

                for (const [key, value] of preUrl.searchParams.entries()) {
                    url.searchParams.append(key, value);
                }
                searchString = url.toString();

                let serverResource = (await $httpFhirApi.get(searchString)).data;

                if (Array.isArray(serverResource)) {
                    serverResource = serverResource[0];
                }

                return serverResource;
            }
        },
    },
};
