import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import ConsentProvisionActor from '@/fhirworks/ConsentProvisionActor';
import Coding from '@/fhirworks/types/Coding';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';

export default class ConsentProvision extends BackboneElement {
    static __className = 'ConsentProvision';

    __objectStructure = {
        actor: [ConsentProvisionActor],
        securityLabel: [Coding], // Condition | Observation | Media
        action: [CodeableConcept], // Actions controlled by this consent
        purpose: [Coding], // Context of activities covered by this consent
    };

    constructor(constructJson, className = 'ConsentProvision') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
