import BackboneElement from './BackboneElement';

import merge from 'lodash/merge';

export default class BundleLink extends BackboneElement {
    static __className = 'BundleLink';

    __objectStructure = {
        relation: String,
        url: String,
    };

    constructor(constructJson, className = 'BundleLink') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
