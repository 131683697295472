import merge from 'lodash/merge';

import DomainResource from './DomainResource';
import Annotation from './types/Annotation';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import ListEntry from './ListEntry';

export default class List extends DomainResource {
    static __className = 'List';

    __objectStructure = {
        // from Resource: id, meta, implicitRules, and language
        // from DomainResource: text, contained, extension, and modifierExtension
        identifier: [Identifier], // Business identifier
        status: String, // current | retired | entered-in-error
        mode: String, // working | snapshot | changes
        title: String, // Descriptive name for the list
        code: CodeableConcept, // What the purpose of this list is
        subject: Reference, // Patient | Group | Device | Location +BestNotes Organization
        encounter: Reference, // Context in which list created
        date: String, // When the list was prepared
        source: Reference, // Who and/or what defined the list contents (aka Author)
        orderedBy: CodeableConcept, // What order the list has List Order Codes (Preferred)
        note: [Annotation], // Comments about the list
        entry: [ListEntry], // Entries in the list
        emptyReason: CodeableConcept, // Why list is empty List Empty Reasons (Preferred)
        /**
         * AidBox First Class extensions
         */
        organization: Reference,
    };

    constructor(constructJson, className = 'List') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
