import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Coding from './types/Coding';

export default class BN_PaymentPostingConfiguration extends DomainResource {
    static __className = 'BN_PaymentPostingConfiguration';

    __objectStructure = {
        tenantId: String,
        intervalMode: String,
        startTime: String,
        multipleMode: Coding,
        scheduleOn: [Coding],
        automaticAdjustment: Boolean,
    };

    constructor(constructJson, className = 'BN_PaymentPostingConfiguration') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
