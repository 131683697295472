import { v4 as uuidv4 } from 'uuid';

/**
 * Workflow Engine
 * - handle incoming triggers
 * - route requests to the proper rules
 */

//  POST, GET, DELETE - execute parameter will cause the transaction to execute and return results
const crud = {};
crud.patch = function (resource) {
    let entry;
    const patch = resource?.resourceJsonPatch() || [];
    if (patch.length) {
        entry = {
            request: {
                method: 'PATCH',
                url: '/' + resource.resourceType + '/' + resource.id + '?_method=json-patch',
            },
            resource: patch,
            postProcess: { resource: resource, action: 'sync' },
        };
    } else return;

    return entry;
};
crud.post = function (resource) {
    let entry;
    if (resource?.resourceType) {
        entry = {
            fullUrl: 'urn:uuid:' + uuidv4(),
            request: {
                method: 'POST',
                url: '/' + resource.resourceType,
            },
            resource: resource.toJSON(),
        };
    } else return;

    return entry;
};
crud.put = function (resource) {
    let entry;
    if (resource?.resourceType) {
        entry = {
            request: {
                method: 'PUT',
                url: '/' + resource.resourceType + '/' + resource.id,
            },
            resource: resource.toJSON(),
        };
    } else return;

    return entry;
};
crud.delete = function (resource) {
    let entry;
    if (resource?.resourceType && resource.id) {
        entry = {
            request: {
                method: 'DELETE',
                url: '/' + resource.resourceType + '/' + resource.id,
            },
        };
    } else return;

    return entry;
};
export { crud };
