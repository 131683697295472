/** @param {Address} address **/
export const singleLineAddress = function (address, passedConfig) {
    if (!address) return '';

    const defaultconfig = {
        line1: true,
        line2: true,
        city: true,
        state: true,
        postalCode: true,
    };

    let config = { ...defaultconfig, ...passedConfig };

    var getCommaSeperatorStr = (addressStr) => (addressStr.length ? ', ' : '');

    let returnAddress = config.line1 ? (address.getAddressLine() ? address.getAddressLine() : '') : '';
    returnAddress += config.line2 ? (address.getAddressLine(1) ? ' ' + address.getAddressLine(1) : '') : '';
    returnAddress = returnAddress.trim() + (config.city ? (address.city ? getCommaSeperatorStr(returnAddress) + address.city : '') : '');
    returnAddress += config.state ? (address.state ? getCommaSeperatorStr(returnAddress) + address.state : '') : '';
    returnAddress += config.postalCode ? (address.postalCode ? ' ' + address.postalCode : '') : '';

    return returnAddress.trim();
};
