import Element from './Element';
import CodeableConcept from './types/CodeableConcept';
import Coding from './types/Coding';
import Period from './types/Period';
import Reference from './types/Reference';

import merge from 'lodash/merge';

/**
 * @property {CodeableConcept[]} type // Role of participant in the appointment
 * @property {Period} period //Participation period of the actor
 */
export default class EncounterParticipant extends Element {
    static __className = 'EncounterParticipant';

    __objectStructure = {
        type: [CodeableConcept], // Role of participant in the appointment
        period: Period, //Participation period of the actor
        individual: Reference, //Practitioner | PractitionerRole | RelatedPerson
    };

    constructor(constructJson, className = 'EncounterParticipant') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.__role = [];

        this.originalObjJson = this.toJSON();
    }

    get role() {
        this.__role.splice(0);
        // ONLY codings from the 1ST codeableConcept are loaded
        // into the role property
        if (this.__type.length) {
            this.__type[0].coding.forEach((coding) => {
                this.__role.push(coding);
            });
        }
        return this.__role;
    }

    set role(value) {
        if (Array.isArray(value)) {
            this.__type.splice(0);
            this.__role.splice(0);

            value.forEach((coding) => {
                if (this.__type.length) {
                    this.__type[0].coding.push(new Coding(coding));
                } else {
                    this.__type.push(new CodeableConcept({ coding: [coding] }));
                }

                this.__role.push(new Coding(coding));
            });
            return;
        }

        if (value instanceof CodeableConcept) {
            if (this.__type.length) {
                this.__type[0].coding = value.coding[0];
            } else {
                this.__type.push(value);
            }
            this.__role.push(value.coding[0]);
            return;
        }

        if (this.__type.length) {
            this.__type[0].coding.push(new Coding(value));
        } else {
            this.__type.push(new CodeableConcept({ coding: [value] }));
        }
        this.__role.push(new Coding(value));
    }

    removeRole(searchCoding) {
        if (!(searchCoding instanceof Coding)) {
            return false;
        }

        if (!this.__type.length) {
            return false;
        }

        let codingIndex = this.__type[0].coding.findIndex((codingElement) => {
            if (searchCoding.id) {
                return codingElement.id === searchCoding.id;
            }

            return codingElement.code === searchCoding.code && codingElement.display === searchCoding.display;
        });

        if (codingIndex !== -1) {
            this.__role.splice(codingIndex, 1);
            this.__type[0].coding.splice(codingIndex, 1);
        }

        return codingIndex !== -1;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
