import { Reference } from './index';
import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';

export default class BN_SuperBillLineItemAddOn extends BackboneElement {
    static __className = 'BN_SuperBillLineItemAddOn';

    __objectStructure = {
        addOnCode: Reference,
        unit: Number,
    };

    constructor(constructJson, className = 'BN_SuperBillLineItemAddOn') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
