import { ContactDetail, UsageContext } from './index';
import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Identifier from './types/Identifier';
import CodeableConcept from './types/CodeableConcept';
import ValueSetCompose from '@/fhirworks/ValueSetCompose';
import ValueSetExpansion from '@/fhirworks/ValueSetExpansion';

export default class ValueSet extends DomainResource {
    static __className = 'ValueSet';

    __objectStructure = {
        url: String, // Canonical identifier for this value set, represented as a URI (globally unique)
        identifier: [Identifier], // Additional identifier for the value set (business identifier)
        version: String, // Business version of the value set
        name: String, // Name for this value set (computer friendly)
        title: String, // Name for this value set (human friendly)
        status: String, // !R draft | active | retired | unknown
        experimental: Boolean, // For testing purposes, not real usage
        date: String, // Date last changed
        publisher: String, // Name of the publisher (organization or individual)
        contact: [ContactDetail], // Contact details for the publisher
        description: String, // (markdown) Natural language description of the value set
        useContext: [UsageContext], // The context that the content is intended to support
        jurisdiction: [CodeableConcept], // Intended jurisdiction for value set (if applicable)
        immutable: Boolean, // Indicates whether or not any change to the content logical definition may occur
        purpose: String, // Why this value set is defined
        copyright: String, // Use and/or publishing restrictions
        compose: ValueSetCompose, // Content logical definition of the value set (CLD)
        expansion: ValueSetExpansion, //Used when the value set is "expanded"
    };

    constructor(constructJson, className = 'ValueSet') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
