import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import ValueX from '@/fhirworks/types/ValueX';

export default class ValueSetExpansionParameter extends BackboneElement {
    static __className = 'ValueSetExpansionParameter';

    __objectStructure = {
        name: String, // !R Name as assigned by the client or server
        valueString: String,
        valueBoolean: Boolean,
        valueInteger: Number,
        valueDecimal: Number,
        valueUri: String,
        valueCode: String,
        valueDateTime: String,
        value: ValueX, // Value of the named parameter
    };

    constructor(constructJson, className = 'ValueSetExpansionParameter') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
