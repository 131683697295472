import merge from 'lodash/merge';
import DomainResource from './DomainResource';
import Coding from './types/Coding';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Period from './types/Period';
import EncounterParticipant from './EncounterParticipant';
import EncounterLocation from './EncounterLocation';
import EncounterStatusHistory from './EncounterStatusHistory';
import EncounterClassHistory from './EncounterClassHistory';
import EncounterDiagnosis from './EncounterDiagnosis';
import get from 'lodash/get';

export default class Encounter extends DomainResource {
    static __className = 'Encounter';

    __transfer = null;

    __objectStructure = {
        identifier: [Identifier],
        status: String, // planned | arrived | triaged | in-progress | onleave | finished | cancelled +
        statusHistory: [EncounterStatusHistory],
        class: Coding,
        classHistory: [EncounterClassHistory],
        type: [CodeableConcept],
        serviceType: CodeableConcept,
        priority: CodeableConcept,
        subject: Reference,
        episodeOfCare: [Reference],
        basedOn: [Reference],
        participant: [EncounterParticipant],
        appointment: [Reference],
        period: Period,
        // length: Duration,
        reasonCode: [CodeableConcept],
        reasonReference: [Reference],
        diagnosis: [EncounterDiagnosis],
        account: [Reference],
        // hospitalization: Hospitalization,
        location: [EncounterLocation],
        serviceProvider: Reference,
        partOf: Reference,

        /**
         * first-class extensions
         */
        superbillStatus: String,
        superbill: Reference,
        officeCode: String,
        codeDescription: String,
        healthcareService: Reference,
        referralSource: Reference,
        transferOrigin: Reference,
        transferDestination: Reference,
        selfPay: Boolean,
        portalPacketSent: Boolean,
        portalPacketUpdate: Boolean,
        setting: String,
        mode: String,
    };

    constructor(constructJson, className = 'Encounter') {
        super(constructJson, className);

        this._parentObj = this;

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        // Look for the transfer property in the constructor json
        let transferProperty = get(constructJson, 'transfer', undefined);
        if (transferProperty) {
            if (transferProperty.origin) this.transferOrigin = transferProperty.origin;
            if (transferProperty.destination) this.transferDestination = transferProperty.destination;
        }

        this.originalObjJson = this.toJSON();
    }

    // *************** Extension PROPERTIES ***************
    get transfer() {
        if (!this.__transfer) {
            this.__transfer = {
                get origin() {
                    return this.parent.transferOrigin;
                },
                set origin(value) {
                    this.parent.transferOrigin = value;
                },
                get destination() {
                    return this.parent.transferDestination;
                },
                set destination(value) {
                    this.parent.transferDestination = value;
                },
                parent: this,
            };
        }

        return this.__transfer;
    }
    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
