<script>
export default {
    name: 'BnLoading',
    inheritAttrs: false,
    props: {
        card: Boolean,
        line: Boolean,
        overlay: Boolean,
        spinner: Boolean,
        alert: {
            type: String,
            default: undefined,
        },
        message: {
            type: String,
            default: undefined,
        },
        skeleton: {
            type: String,
            default: undefined,
        },
        height: {
            type: [String, Number],
            default: undefined,
        },
        color: {
            type: String,
            default: 'info',
        },
    },
    computed: {
        computedStyle() {
            let style = '';
            if (this.height) style += 'height:' + this.height + 'px;';
            return style;
        },
        computedMessage() {
            if (this.skeleton && !this.message) return '';
            else return this.message || 'Loading, please wait...';
        },
    },
};
</script>

<template>
    <!-- Overlay -->
    <v-overlay v-if="overlay" :model-value="overlay" @update:model-value="overlay = $event" v-bind="$attrs" class="align-center justify-center">
        <div>
            <v-alert v-if="alert && message" variant="flat" :type="alert" :text="message" />
            <v-progress-circular v-else-if="spinner" indeterminate class="mr-4 align-self-center"></v-progress-circular>
            <div v-else-if="message" class="align-self-center" v-html="message"></div>
        </div>
    </v-overlay>

    <!-- Card -->
    <v-card v-else-if="card" v-bind="$attrs" flat tile :class="{ 'fill-height': !height }" :style="computedStyle" style="z-index: 100 !important;">
        <v-progress-circular v-if="spinner" indeterminate :color="color" size="24" class="mr-2" />
        <v-progress-linear v-else :color="color" height="5" indeterminate />
        <span v-if="computedMessage" v-html="computedMessage"></span>
        <v-skeleton-loader v-if="skeleton" :type="skeleton" />
    </v-card>

    <!-- Circular / Spinner -->
    <div v-else-if="spinner" v-bind="$attrs" class="d-flex">
        <v-progress-circular indeterminate :color="color" class="mr-4 align-self-center"></v-progress-circular>
        <div class="align-self-center" v-html="message"></div>
    </div>

    <!-- Default Chip -->
    <v-chip v-else v-bind="$attrs" variant="outlined" size="x-small" :color="color">
        <v-progress-circular size="18" width="1" indeterminate :color="color" class="mr-2"></v-progress-circular>
        {{ message }}
    </v-chip>
</template>
