import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';

export default class BN_SuperBillSelf extends BackboneElement {
    static __className = 'BN_SuperBillSelf';

    __objectStructure = {
        value: Boolean,
        type: String,
    };

    constructor(constructJson, className = 'BN_SuperBillSelf') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
