import { $http } from '@/common/api/http.service';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { useSignedUrlStore } from '@/stores/signedUrl';
import { useAuthStore } from '@/stores/auth';

export default {
    // Image methods
    uploadImage: async (fileToUpload, isPublic = false) => {
        let formData = new FormData();
        formData.append('file', fileToUpload);
        if (isPublic) formData.append('isPublic', isPublic);

        const authStore = useAuthStore();
        let uploadFileResponse = await $http.post('/api/account/' + authStore.account.id + '/images/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        return uploadFileResponse.data;
    },
    uploadImages: async (filesToUpload, isPublic = false) => {
        let formData = new FormData();

        Object.keys(filesToUpload).forEach((size) => formData.append(size, filesToUpload[size]));

        if (isPublic) formData.append('isPublic', isPublic);

        const authStore = useAuthStore();
        let uploadFileResponse = await $http.post('/api/account/' + authStore.account.id + '/images/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        return uploadFileResponse.data;
    },
    deleteImage: async (imageToDelete) => {
        let formData = new FormData();

        formData.append('objectName', imageToDelete.url);

        const authStore = useAuthStore();
        let deleteFileResponse = await $http.post('/api/account/' + authStore.account.id + '/images/delete', formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        return deleteFileResponse.data;
    },

    // File methods
    uploadFile: async (fileToUpload, isPublic = false) => {
        let formData = new FormData();
        formData.append('file', fileToUpload);
        if (isPublic) formData.append('isPublic', isPublic);

        const authStore = useAuthStore();
        let uploadFileResponse = await $http.post('/api/account/' + authStore.account.id + '/files/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        return uploadFileResponse.data;
    },
    uploadFiles: async (filesToUpload, isPublic = false) => {
        let formData = new FormData();

        Object.keys(filesToUpload).forEach((size) => formData.append(size, filesToUpload[size]));

        if (isPublic) formData.append('isPublic', isPublic);

        const authStore = useAuthStore();
        let uploadFileResponse = await $http.post('/api/account/' + authStore.account.id + '/files/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        return uploadFileResponse.data;
    },
    deleteFile: async (fileToDelete) => {
        let formData = new FormData();

        formData.append('objectName', fileToDelete.url);

        const authStore = useAuthStore();
        let deleteFileResponse = await $http.post('/api/account/' + authStore.account.id + '/files/delete', formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        return deleteFileResponse.data;
    },

    // Utility Methods
    getImageSignedUrl: async (uploadedFile, duration = '') => {
        const signedUrlStore = useSignedUrlStore();

        return await signedUrlStore.getImageSignedUrl(uploadedFile, duration);
    },
    getFileSignedUrl: async (uploadedFile, duration = '') => {
        const signedUrlStore = useSignedUrlStore();

        return await signedUrlStore.getFileSignedUrl(uploadedFile, duration);
    },
    filePatch: async (resource) => {
        let patch = resource.resourceJsonPatch();
        let filePatch = await $httpFhirApi.patch(resource.resourceType + '/' + resource.id + '?_method=json-patch', patch);

        if (filePatch) {
            return true;
        }
    },
};
