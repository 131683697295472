<script>
/** ---- PROOF OF CONCEPT - WORK IN PROGRESS - DO NOT USE!!!
 * Sample:
     <bn-list-select-box
         title="Qualifications"
         :items="itemsArray"
         item-text="display"
         item-icon="badge"
         multiple
         :dialog="{ title: 'Dialog Title', info: 'Info alert line' }"
     />

 */
import BnInfo from '@/components/BnInfo.vue';
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
export default {
    name: 'BnListSelectBox',
    components: { BnToolbarBtn, BnInfo },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
        //header
        title: String,
        info: String,
        message: String,
        // items to display
        items: {
            type: Array,
            default: () => [],
        },
        itemIcon: [Array, String],
        itemText: [Object, String, Array],
        itemValue: String,
        itemInfo: String,
        defaultItems: {
            type: Array,
            default: () => [],
        },
        addedItems: {
            type: Array,
            default: () => [],
        },
        // properties
        dense: Boolean,
        multiple: Boolean,
        dialog: Object,
        clearable: Boolean,
        disabled: Boolean,
    },
    data: function () {
        return {
            selectedItems: this.defaultItems,
        };
    },
    computed: {
        iconItem() {
            return typeof this.itemIcon === 'string' ? ['far', this.itemIcon] : this.itemIcon;
        },
    },
    methods: {
        getItemIcon(item) {
            if (item.icon) {
                return typeof item.icon === 'string' ? ['far', item.icon] : item.icon;
            } else return this.iconItem;
        },
        getItemTitle(item, separator = ' - ') {
            if (typeof this.itemText === 'object' && !Array.isArray(this.itemText)) {
                return item[this.itemText.title];
            } else if (this.itemText) {
                if (Array.isArray(this.itemText) && this.itemText.length >= 2) {
                    let stringFromArray = '';
                    this.itemText.forEach((e, index) => {
                        if (item[e.toString()]) {
                            stringFromArray += item[e.toString()];
                            if (index !== this.itemText.length - 1) {
                                stringFromArray += separator;
                            }
                        }
                    });
                    return stringFromArray;
                }
                return item[this.itemText];
            } else return item?.display || item?.text;
        },
        getItemSubTitle(item) {
            return item[this.itemText?.subtitle];
        },
        getItemValue(item) {
            return this.itemValue ? item[this.itemValue] : item;
        },
        getItemChip(item) {
            return item.chip || {}; // { text: 'text', icon: 'plus', color: 'green', dark: true };
        },
        clickItem(item) {
            const alreadySelected = this.selectedItems.findIndex((i) => i[this.itemValue] === item[this.itemValue]);
            if (alreadySelected > -1) {
                this.selectedItems.splice(alreadySelected, 1);
            } else {
                if (this.multiple) {
                    this.selectedItems.push(item);
                } else {
                    this.selectedItems = [item];
                }
            }
            this.$emit('update:modelValue', this.selectedItems);
        },
        clearSelectedItems() {
            this.selectedItems = [];
            this.$emit('update:modelValue', this.selectedItems);
        },
    },
};
</script>

<template>
    <v-card flat class="d-flex flex-column">
        <template v-if="dialog">
            <ion-header>
                <ion-toolbar>
                    <v-toolbar density="compact" flat>
                        <v-toolbar-title>{{ dialog.title || title }}</v-toolbar-title>
                        <bn-toolbar-btn type="text" :label="dialog.cancel || 'Cancel'" icon="times" @click="$emit('cancel')" />
                        <bn-toolbar-btn type="outlined" right :label="dialog.save || 'Save'" icon="check" color="primary" @click="$emit('save')" />
                    </v-toolbar>
                </ion-toolbar>
            </ion-header>
            <v-divider />
            <!-- Info/Warning message -->
            <bn-info v-if="dialog.info" type="outlined">{{ dialog.info }}</bn-info>
            <bn-info v-if="dialog.warning" type="outlined" color="warning">{{ dialog.warning }}</bn-info>
        </template>
        <div class="scroll-on" :class="{ 'pa-4': dialog }">
            <div v-if="title || message || info" class="d-flex">
                <div class="bn-section-header">{{ title }}</div>
                <v-spacer></v-spacer>
                <!-- message text -->
                <div class="text-caption align-self-center ml-4" v-html="message"></div>
                <div class="ml-4">
                    <!-- info icon/button -->
                    <bn-info v-if="info" type="dialog" class="ml-2">{{ info }}</bn-info>
                </div>
                <!-- Regular add button -->
                <v-btn v-if="clearable" :disabled="disabled" icon color="secondary" @click="clearSelectedItems" class="ml-2" :data-cy="$attrs['data-cy'] + 'ClearButton'">
                    <font-awesome-icon :icon="['far', 'times']" size="lg" />
                </v-btn>
            </div>
            <v-card outlined flat>
                <v-card v-if="!items.length" flat class="d-flex text-caption pa-2">
                    Empty List
                </v-card>
                <template v-else v-for="(item, index) in items" :key="index">
                    <v-divider v-if="index"></v-divider>
                    <v-list-item
                        :disabled="addedItems.some((i) => i[itemValue] === item[itemValue])"
                        color="bn-color-list-item"
                        class="pr-0"
                        :active="selectedItems.some((i) => i[itemValue] === item[itemValue])"
                        @click="clickItem(item)"
                        :data-cy="$attrs['data-cy'] + 'Item'"
                    >
                        <template #prepend="{ isActive }">
                            <font-awesome-icon :icon="getItemIcon(item)" class="mr-2 align-self-center" :class="{ 'text-black': !isActive }" />
                        </template>
                        <template #default>
                            <div class="text-black">
                                <v-list-item-title>{{ getItemTitle(item) }}</v-list-item-title>
                                <v-list-item-subtitle v-if="getItemSubTitle(item)">{{ getItemSubTitle(item) }}</v-list-item-subtitle>
                            </div>
                            <!-- Chip -->
                            <div v-if="getItemChip(item).text">
                                <v-chip x-small :dark="getItemChip(item).dark" :color="getItemChip(item).color" class="mr-2 px-2">
                                    <font-awesome-icon v-if="getItemChip(item).icon && !$vuetify.display.smAndDown" :icon="['far', getItemChip(item).icon]" class="mr-1" />
                                    <span v-text="getItemChip(item).text"></span>
                                </v-chip>
                            </div>
                            <!-- Info -->
                            <div v-if="item[itemInfo]">
                                <bn-info type="dialog">{{ item[itemInfo] }}</bn-info>
                            </div>
                        </template>
                        <template #append="{ isActive }">
                            <div class="align-self-center mr-2">
                                <!-- Selected Indicator -->
                                <div v-if="!addedItems.some((i) => i[itemValue] === item[itemValue])" class="align-self-center" :class="{ 'grey--text text--lighten-2': !isActive }">
                                    <font-awesome-icon :icon="['far', 'check']" size="lg" />
                                </div>
                                <v-chip v-else x-small>added</v-chip>
                            </div>
                        </template>
                    </v-list-item>
                </template>
            </v-card>
        </div>
    </v-card>
</template>

<style lang="scss"></style>
