import Address from '../Address';
import { singleLineAddress } from '@/filters/addressFilters';

const addressUseValues = {
    home: 'home',
    work: 'work',
    temp: 'temp',
    old: 'old',
    billing: 'billing',
};

const addressTypeValues = {
    postal: 'postal',
    physical: 'physical',
    both: 'both',
};

const addressTraits = function () {
    Object.defineProperty(this, 'homeAddressIndex', { value: null, writable: true });
    Object.defineProperty(this, 'workAddressIndex', { value: null, writable: true });
    Object.defineProperty(this, 'tempAddressIndex', { value: null, writable: true });
    Object.defineProperty(this, 'oldAddressIndex', { value: null, writable: true });
    Object.defineProperty(this, 'billingAddressIndex', { value: null, writable: true });
    Object.defineProperty(this, 'defaultAddressIndex', { value: null, writable: true });

    Object.defineProperty(this, 'defaultAddress', {
        get() {
            if (this.defaultAddressIndex !== null) {
                return this.address[this.defaultAddressIndex];
            }
            let preferredAddress = this.address.find((adr) => adr.preferred);
            let homeAddress = this.getAddressesByUse(addressUseValues.home, false);
            let billingAddress = this.getAddressesByUse(addressUseValues.billing, false);
            let workAddress = this.getAddressesByUse(addressUseValues.work, false);
            let defaultAddress = preferredAddress || homeAddress || billingAddress || workAddress;

            if (!defaultAddress) {
                return false;
            }

            this.defaultAddressIndex = this.address.indexOf(defaultAddress);
            return defaultAddress;
        },
        set(value) {
            if (this.defaultAddressIndex !== null) {
                this.address[this.defaultAddressIndex] = value;
            }
        },
    });
    Object.defineProperty(this, 'defaultAddressValue', {
        get() {
            return singleLineAddress(this.defaultAddress);
        },
    });

    Object.defineProperty(this, 'homeAddress', {
        get() {
            let homeAddress = this.getAddressesByUse(addressUseValues.home);
            if (homeAddress) {
                this.homeAddressIndex = this.address.indexOf(homeAddress);
                return homeAddress;
            }
            return undefined;
        },
        set(value) {
            if (this.homeAddressIndex !== null && this.homeAddressIndex >= 0) {
                // HomeAddress is being cleared
                if (value === undefined) {
                    this.homeAddressIndex = null;
                    this.address.splice(this.homeAddressIndex, 1);
                    return;
                }

                if (!(value instanceof Address)) {
                    value = new Address(value);
                }
                this.address.splice(this.homeAddressIndex, 1, value);
                return;
            }

            // No HomeAddress exist so add passed value
            this.address = value;
            this.homeAddressIndex = this.address.indexOf(value);
        },
    });
    Object.defineProperty(this, 'homeAddressValue', {
        get() {
            return singleLineAddress(this.homeAddress);
        },
    });

    Object.defineProperty(this, 'workAddress', {
        get() {
            let workAddress = this.getAddressesByUse(addressUseValues.work);
            if (workAddress) {
                this.workAddressIndex = this.address.indexOf(workAddress);
                return workAddress;
            }
            return undefined;
        },
        set(value) {
            if (this.workAddressIndex !== null && this.workAddressIndex >= 0) {
                // WorkAddress is being cleared
                if (value === undefined) {
                    this.workAddressIndex = null;
                    this.address.splice(this.workAddressIndex, 1);
                    return;
                }

                if (!(value instanceof Address)) {
                    value = new Address(value);
                }
                this.address.splice(this.workAddressIndex, 1, value);
                return;
            }

            // No WorkAddress exist so add passed value
            this.address = value;
            this.workAddressIndex = this.address.indexOf(value);
        },
    });
    Object.defineProperty(this, 'workAddressValue', {
        get() {
            return singleLineAddress(this.workAddress);
        },
    });

    Object.defineProperty(this, 'tempAddress', {
        get() {
            let tempAddress = this.getAddressesByUse(addressUseValues.temp);
            if (tempAddress) {
                this.tempAddressIndex = this.address.indexOf(tempAddress);
                return tempAddress;
            }
            return undefined;
        },
        set(value) {
            if (this.tempAddressIndex !== null && this.tempAddressIndex >= 0) {
                // TempAddress is being cleared
                if (value === undefined) {
                    this.tempAddressIndex = null;
                    this.address.splice(this.tempAddressIndex, 1);
                    return;
                }

                if (!(value instanceof Address)) {
                    value = new Address(value);
                }
                this.address.splice(this.tempAddressIndex, 1, value);
                return;
            }

            // No TempAddress exist so add passed value
            this.address = value;
            this.tempAddressIndex = this.address.indexOf(value);
        },
    });
    Object.defineProperty(this, 'tempAddressValue', {
        get() {
            return singleLineAddress(this.tempAddress);
        },
    });

    Object.defineProperty(this, 'oldAddress', {
        get() {
            let oldAddress = this.getAddressesByUse(addressUseValues.old);
            if (oldAddress) {
                this.oldAddressIndex = this.address.indexOf(oldAddress);
                return oldAddress;
            }
            return undefined;
        },
        set(value) {
            if (this.oldAddressIndex !== null && this.oldAddressIndex >= 0) {
                // TempAddress is being cleared
                if (value === undefined) {
                    this.oldAddressIndex = null;
                    this.address.splice(this.oldAddressIndex, 1);
                    return;
                }

                if (!(value instanceof Address)) {
                    value = new Address(value);
                }
                this.address.splice(this.oldAddressIndex, 1, value);
                return;
            }

            // No TempAddress exist so add passed value
            this.address = value;
            this.oldAddressIndex = this.address.indexOf(value);
        },
    });
    Object.defineProperty(this, 'oldAddressValue', {
        get() {
            return singleLineAddress(this.oldAddress);
        },
    });

    Object.defineProperty(this, 'billingAddress', {
        get() {
            let billingAddress = this.getAddressesByUse(addressUseValues.billing);
            if (billingAddress) {
                this.billingAddressIndex = this.address.indexOf(billingAddress);
                return billingAddress;
            }
            return undefined;
        },
        set(value) {
            if (this.billingAddressIndex !== null && this.billingAddressIndex >= 0) {
                // TempAddress is being cleared
                if (value === undefined) {
                    this.billingAddressIndex = null;
                    this.address.splice(this.billingAddressIndex, 1);
                    return;
                }

                if (!(value instanceof Address)) {
                    value = new Address(value);
                }
                this.address.splice(this.billingAddressIndex, 1, value);
                return;
            }

            // No TempAddress exist so add passed value
            this.address = value;
            this.billingAddressIndex = this.address.indexOf(value);
        },
    });
    Object.defineProperty(this, 'billingAddressValue', {
        get() {
            return singleLineAddress(this.billingAddress);
        },
    });

    this.getAddressesByUse = function (addressUse, createIfNotExists = false, typeFilter) {
        let defaultUse = addressUse ? addressUse : addressUseValues.home;
        let defaultType = typeFilter ? typeFilter : addressTypeValues.both;
        let defaultCountry = 'USA';

        let addressesToProcess = this.getAllAddressesByUse(addressUse);

        if (typeFilter) {
            let typeMatchArray = [];
            addressesToProcess.forEach((address) => {
                if (address.type === typeFilter) {
                    typeMatchArray.push(address);
                }
            });
            addressesToProcess = typeMatchArray;
        }

        if (addressesToProcess.length === 0 && createIfNotExists) {
            let newAddress = new Address({
                use: defaultUse,
                type: defaultType,
                country: defaultCountry,
            });

            this.address = newAddress;
            return newAddress;
        }

        return addressesToProcess[0];
    };

    this.getAllAddressesByUse = function (addressUse) {
        if (!addressUse) {
            return this.address;
        }

        let addressesOfUse = [];

        this.address.forEach((address) => {
            if (addressUse && address.use === addressUse) {
                addressesOfUse.push(address);
            }
        });

        return addressesOfUse;
    };

    return this;
};

export default addressTraits;
export { addressUseValues, addressTypeValues, addressTraits };
