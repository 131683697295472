import BackboneElement from './BackboneElement';
import CodeableConcept from './types/CodeableConcept';
import merge from 'lodash/merge';

export default class PatientCommunication extends BackboneElement {
    static __className = 'PatientCommunication';

    __objectStructure = {
        language: CodeableConcept,
        preferred: Boolean,
    };

    constructor(constructJson, className = 'PatientCommunication') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
