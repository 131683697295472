import Element from './Element';
import merge from 'lodash/merge';
import AnswerX from './types/AnswerX';
import get from 'lodash/get';

export default class QuestionnaireItemEnableCondition extends Element {
    static __className = 'QuestionnaireItemEnableCondition';

    __objectStructure = {
        question: String, // R!  Question that determines whether item is enabled
        operator: String, // R!  exists | = | != | > | < | >= | <=
        answer: AnswerX,
    };

    constructor(constructJson, className = 'QuestionnaireItemEnableCondition') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        let fhirAnswerBoolean = get(constructJson, 'answerBoolean', undefined);
        if (fhirAnswerBoolean) {
            this.answerBoolean = fhirAnswerBoolean;
        }

        let fhirAnswerDecimal = get(constructJson, 'answerDecimal', undefined);
        if (fhirAnswerDecimal) {
            this.answerDecimal = fhirAnswerDecimal;
        }

        let fhirAnswerInteger = get(constructJson, 'answerInteger', undefined);
        if (fhirAnswerInteger) {
            this.answerInteger = fhirAnswerInteger;
        }

        let fhirAnswerDate = get(constructJson, 'answerDate', undefined);
        if (fhirAnswerDate) {
            this.answerDate = fhirAnswerDate;
        }

        let fhirAnswerDateTime = get(constructJson, 'answerDateTime', undefined);
        if (fhirAnswerDateTime) {
            this.answerDateTime = fhirAnswerDateTime;
        }

        let fhirAnswerTime = get(constructJson, 'answerTime', undefined);
        if (fhirAnswerTime) {
            this.answerTime = fhirAnswerTime;
        }

        let fhirAnswerString = get(constructJson, 'answerString', undefined);
        if (fhirAnswerString) {
            this.answerString = fhirAnswerString;
        }

        let fhirAnswerCoding = get(constructJson, 'answerCoding', undefined);
        if (fhirAnswerCoding) {
            this.answerCoding = fhirAnswerCoding;
        }

        let fhirAnswerQuantity = get(constructJson, 'answerQuantity', undefined);
        if (fhirAnswerQuantity) {
            this.answerQuantity = fhirAnswerQuantity;
        }

        let fhirAnswerReference = get(constructJson, 'answerReference', undefined);
        if (fhirAnswerReference) {
            this.answerReference = fhirAnswerReference;
        }

        this.originalObjJson = this.toJSON();
    }

    get answerBoolean() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerBoolean;
    }

    set answerBoolean(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerBoolean = value;
    }

    get answerDecimal() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerDecimal;
    }

    set answerDecimal(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerDecimal = value;
    }

    get answerInteger() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerInteger;
    }

    set answerInteger(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerInteger = value;
    }

    get answerDate() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerDate;
    }

    set answerDate(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerDate = value;
    }

    get answerDateTime() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerDateTime;
    }

    set answerDateTime(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerDateTime = value;
    }

    get answerTime() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerTime;
    }

    set answerTime(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerTime = value;
    }

    get answerString() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerString;
    }

    set answerString(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerString = value;
    }

    get answerCoding() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerCoding;
    }

    set answerCoding(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerCoding = value;
    }

    get answerQuantity() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerQuantity;
    }

    set answerQuantity(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerQuantity = value;
    }

    get answerReference() {
        if (!this.answer) {
            return undefined;
        }
        return this.answer.answerReference;
    }

    set answerReference(value) {
        if (!this.answer) {
            this.answer = new AnswerX();
        }
        this.answer.answerReference = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
