import DomainResource from './DomainResource';
import CodeableConcept from './types/CodeableConcept';
import ContactPoint from './ContactPoint';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Attachment from './types/Attachment';

import merge from 'lodash/merge';
import HealthcareServiceModifier from '@/fhirworks/HealthcareServiceModifier';
import HealthcareServiceAddOn from '@/fhirworks/HealthcareServiceAddOn';
import HealthcareServiceRevCode from '@/fhirworks/HealthcareServiceRevCode';

export default class HealthcareService extends DomainResource {
    static __className = 'HealthcareService';

    __objectStructure = {
        identifier: [Identifier],
        active: Boolean,
        providedBy: Reference,
        category: [CodeableConcept],
        type: [CodeableConcept],
        specialty: [CodeableConcept],
        location: [Reference],
        name: String,
        comment: String,
        extraDetails: String,
        photo: Attachment,
        telecom: [ContactPoint],
        coverageArea: [Reference],
        serviceProvisionCode: [CodeableConcept],
        // eligibility: [],
        program: [CodeableConcept],
        characteristic: [CodeableConcept],
        communication: [CodeableConcept],
        referralMethod: [CodeableConcept],
        appointmentRequired: Boolean,
        // availableTime: [],
        // notAvaiable: [],
        availabilityExceptions: String,
        endpoint: [Reference],
        /**
         * First Class extensions
         */
        modifier: [HealthcareServiceModifier],
        addOn: [HealthcareServiceAddOn],
        isAddOn: Boolean,
        baseCharge: Number,
        billingCode: String,
        description: String,
        derivedFrom: String,
        eligibleProviderTypes: CodeableConcept,
        encounterType: [String],
        form: [String],
        isAuthorized: Boolean,
        isBillInSeparateLines: Boolean,
        isOneServicePerDay: Boolean,
        isProgram: Boolean,
        makerCategory: String,
        perUnitDuration: Number,
        revCodes: [HealthcareServiceRevCode],
        uomType: String,
        url: String,
        inactiveLocation: [Reference],
        // servicesPerDay: removed, couldn't find in app
        // authorsCount: removed, couldn't find in app
    };

    constructor(constructJson, className = 'HealthcareService') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
