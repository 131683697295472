import { ref, watch, onUnmounted } from 'vue';

import { parseISO, addMilliseconds, differenceInMilliseconds } from 'date-fns';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';
import { useRoute } from 'vue-router';
import Storage from 'vue-ls';
import { storeToRefs } from 'pinia';
import VueCookies from 'vue-cookies';
import { useIntervalFn, useTimeoutFn } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';

export function useLockout() {
    const lockOutCountDown = ref(null);
    const lockoutPaused = ref(false);

    const authStore = useAuthStore();
    const route = useRoute();

    const { ls } = Storage.useStorage({
        namespace: '',
        name: 'ls',
        storage: 'local',
    });

    const cookies = useCookies();

    const changeListener = ({ name, value }) => {
        if (name === 'token') {
            tokenChange(value);
        } else if (name === 'lockOutCountDown' && value) {
            if (lockoutPaused.value) return;
            lockOutCountDown.value = value;
            if (!lockoutCountdownActive.value) {
                startLockoutTimer();
            }
        } else if (name === 'user' && !value) {
            router.push({ name: 'login' });
        } else if (name === 'account') {
            if (!value) {
                router.push({ name: 'login' });
            } else if (value?.id !== authStore.account.id) {
                router.push({ name: 'invalid-tab' });
            }
        }
    };

    cookies.addChangeListener(changeListener);

    const tokenChange = (token) => {
        if (token) {
            let isLocked = authStore.isLockedOut; // have to check before setting token
            authStore.setToken(token);

            if (isLocked) {
                router.push(authStore.lockoutPage);
            }
        } else if (route && !['lockout', 'invalid-tab'].includes(route.name)) {
            executeLockout();
        }
    };

    const {
        pause: pauseLockoutInterval,
        resume: resumeLockoutInterval,
        isActive: lockoutIntervalActive,
    } = useIntervalFn(
        () => {
            let timeToLockout = authStore.lockoutLastActivityTime;
            timeToLockout = addMilliseconds(timeToLockout, authStore.lockoutTime);
            if (timeToLockout.getTime() < Date.now()) {
                startLockoutTimer();
            }
        },
        2500,
        { immediate: false, immediateCallback: true },
    );

    const {
        pause: pauseLockoutCountdown,
        resume: resumeLockoutCountdown,
        isActive: lockoutCountdownActive,
    } = useIntervalFn(
        () => {
            if (lockOutCountDown.value === null) return;
            lockOutCountDown.value -= 1;
            VueCookies.set('lockOutCountDown', lockOutCountDown.value);
            if (lockOutCountDown.value < 1) {
                lockOutCountDown.value = null;
                VueCookies.remove('lockOutCountDown');
                executeLockout();
            }
        },
        1000,
        { immediate: false, immediateCallback: true },
    );

    const localStorageOnLastTokenRefreshTime = (lastTokenRefreshTime) => {
        authStore.setLastTokenRefreshTime(parseISO(lastTokenRefreshTime));
    };

    const localStorageOnLockoutLastActivityTime = (lockoutLastActivityTime) => {
        resetLockoutActivity(lockoutLastActivityTime);
    };

    ls.on('lastTokenRefreshTime', localStorageOnLastTokenRefreshTime);
    ls.on('lockoutLastActivityTime', localStorageOnLockoutLastActivityTime);

    onUnmounted(() => {
        cookies.removeChangeListener(changeListener);

        ls.off('lastTokenRefreshTime', localStorageOnLastTokenRefreshTime);
        ls.off('lockoutLastActivityTime', localStorageOnLockoutLastActivityTime);
    });

    const resetLockoutActivity = (lockoutLastActivityTime = null) => {
        if (lockoutPaused.value) return;

        pauseLockoutCountdown();
        lockOutCountDown.value = null;
        VueCookies.remove('lockOutCountDown');
        if (lockoutLastActivityTime) {
            authStore.setLockoutLastActivityTime(parseISO(lockoutLastActivityTime));
        } else {
            authStore.resetLockoutActivityTime();
        }

        if (!lockoutIntervalActive.value) {
            resumeLockoutInterval();
        }
    };

    const startLockoutTimer = () => {
        // if not logged in do nothing
        if (!authStore.isAuthenticated || authStore.isLockedOut || !authStore.lockoutLastActivityTime) {
            authStore.resetLockoutActivityTime();
            return;
        }

        pauseLockoutInterval();

        // open lockout snackbar
        lockOutCountDown.value = 30;
        VueCookies.set('lockOutCountDown', 30);

        // count down to auto-lock
        resumeLockoutCountdown();
    };

    const pauseLockout = () => {
        lockoutPaused.value = true;
        pauseLockoutInterval();
        pauseLockoutCountdown();
    };

    const resumeLockout = () => {
        lockoutPaused.value = false;
        resetLockoutActivity();
    };

    const executeLockout = () => {
        // if not logged in do nothing
        if (!authStore.isAuthenticated || authStore.isLockedOut || !authStore.lockoutLastActivityTime) {
            return;
        }
        // navigate to lockout page
        stopTokenRefreshTimer();
        router.push({ name: 'lockout' });
    };

    const refreshTokenTimeout = ref(5 * 60000); // 5 mins
    const { start: startTokenRefreshTimer, stop: stopTokenRefreshTimer } = useTimeoutFn(async () => {
        await authStore.refreshAuthToken();
        resetTokenRefreshTimer();
    }, refreshTokenTimeout);

    const resetTokenRefreshTimer = () => {
        stopTokenRefreshTimer();
        let timerTimeout = authStore.tokenRefreshTime;

        if (authStore.lastTokenRefreshTime) {
            let refreshTimeAgo = differenceInMilliseconds(new Date(), authStore.lastTokenRefreshTime);
            // timeout is the timerTimeout minus the time ago it was last refreshed
            timerTimeout = timerTimeout - refreshTimeAgo;
            // make sure timerTimeout isn't negative, if so set to 0
            refreshTokenTimeout.value = timerTimeout < 0 ? 0 : timerTimeout;
        }

        startTokenRefreshTimer();
    };

    const { isAuthenticated } = storeToRefs(authStore);
    watch(
        isAuthenticated,
        (val) => {
            if (!val) {
                pauseLockout();
                stopTokenRefreshTimer();
                return;
            }

            resumeLockout();
            resetTokenRefreshTimer();
        },
        { immediate: true },
    );

    return {
        resetLockoutActivity,
        lockOutCountDown,
        pauseLockout,
        resumeLockout,
        stopTokenRefreshTimer,
    };
}
