import upperFirst from 'lodash/upperFirst';
import { Organization } from '@/fhirworks';

export default function (createTextValue, entityData) {
    if (createTextValue) {
        let text = createTextValue;
        let firstName, lastName, phoneNumber;

        if (text.match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
            phoneNumber = text;
        } else if (text.includes(', ')) {
            [lastName, firstName] = text.split(', ', 2);
        } else if (text.includes(' ')) {
            [firstName, lastName] = text.split(' ', 2);
        } else {
            // default to firstName if only one name is passed in
            firstName = text;
        }

        if (entityData instanceof Organization) {
            if (phoneNumber) {
                entityData.workPhone.value = phoneNumber;
            } else {
                entityData.name = upperFirst(text);
            }
            return;
        }

        // Handle human type entities
        entityData.firstName = upperFirst(firstName) || undefined;
        entityData.lastName = upperFirst(lastName) || undefined;

        // populate default phone number
        if (phoneNumber) {
            entityData.primaryPhone.value = phoneNumber;
        }
    }
}
