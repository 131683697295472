import { $http } from '@/common/api/http.service';
import { useAuthStore } from '@/stores/auth';

const basePath = '/api';

export default {
    getNewToken: async (data, config) => {
        const authStore = useAuthStore();
        // https://stackoverflow.com/questions/46611275/axios-post-is-sending-a-get-request
        // POST was changing to a GET and causing problems, forcing post with method prop in config
        if (!authStore.isAuthenticated) return;
        config = { ...config, ...{ method: 'post' } };
        let res = await $http.post(basePath + '/account/' + authStore.account.id + '/subscription-token-request', data, config);
        return res.data.token;
    },
};
