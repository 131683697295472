import merge from 'lodash/merge';
import BackboneElement from './BackboneElement';

export default class TaskKanban extends BackboneElement {
    static __className = 'TaskKanban';

    __objectStructure = {
        priority: String,
    };

    constructor(constructJson, className = 'TaskKanban') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
