<script>
import bnFormMixin from '@/components/BnFormMixin';
const url = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCDj-LE0ATM-oqFYt9A-F64FK-UI_hr9SQ&place_id=';

export default {
    name: 'BnFormGoogleaddressText',
    extends: bnFormMixin,
    emits: ['update:modelValue', 'placeChanged'],
    props: {
        modelValue: [String, Number, Object],
    },
    data() {
        return {
            items: [],
            search: null,
            menu: null,
        };
    },
    watch: {
        search() {
            // if (this.search === this.modelValue) {
            //     this.menu = false;
            //     return;
            // }
            this.googleSearch();
        },
    },
    methods: {
        updateValue: function (value) {
            this.$emit('update:modelValue', value);
        },
        chooseAddress: function (inputValue) {
            this.menu = false;
            fetch(url + inputValue.raw?.place_id).then(async (response) => {
                let jsonResult = await response.json();
                let addressResult = jsonResult.results[0];

                let addressNumber = this.getAcTypeValue(addressResult.address_components, 'street_number', 'short_name');
                let addressStreet = this.getAcTypeValue(addressResult.address_components, 'route', 'long_name');

                let address = {};
                address.line1 = ((addressNumber ? addressNumber + ' ' : '') + (addressStreet ? addressStreet : '')).trim();
                address.line2 = '';
                address.city = this.getAcTypeValue(addressResult.address_components, 'locality', 'long_name');
                address.state = this.getAcTypeValue(addressResult.address_components, 'administrative_area_level_1', 'short_name');
                address.stateLong = this.getAcTypeValue(addressResult.address_components, 'administrative_area_level_1', 'long_name');
                address.postalCode = this.getAcTypeValue(addressResult.address_components, 'postal_code', 'short_name');
                address.country = this.getAcTypeValue(addressResult.address_components, 'country', 'short_name');
                address.geometry = addressResult.geometry;

                this.$emit('update:modelValue', address.line1);
                this.$emit('placeChanged', address);

                this.$nextTick(() => (this.menu = false));
            });
        },
        googleSearch() {
            // eslint-disable-next-line no-undef
            const service = new google.maps.places.AutocompleteService();
            service.getPlacePredictions({ input: this.search, locationBias: 'IP_BIAS', types: ['geocode'] }, (predictions) => {
                if (!predictions?.length) return;

                this.items = predictions.map((item) => {
                    return {
                        title: item.description,
                        place_id: item.place_id,
                    };
                });
            });
        },
        getAcTypeValue: function (addressComponents, searchType, valueType) {
            let componentPart = addressComponents?.find((element) => {
                return element.types[0] === searchType;
            });

            return componentPart ? componentPart[valueType.toString()] : '';
        },
    },
};
</script>

<template>
    <v-combobox
        :id="id"
        ref="bnGoogleAddressAutoComplete"
        v-bind="$attrs"
        data-cy="googleAddress"
        label="Line 1"
        :model-value="modelValue"
        @update:modelValue="updateValue"
        v-model:search="search"
        v-model:menu="menu"
        :items="items"
        autocomplete="off"
        :filter="(d) => d"
        return-object
    >
        <template #item="{ props, item }">
            <v-list-item @click="chooseAddress(item)" v-bind="props" :title="item?.title"></v-list-item>
        </template>
    </v-combobox>
</template>
