<script setup>
const emit = defineEmits(['click']);
const props = defineProps({
    icon: String,
    label: String,
    disabled: Boolean,
    kitIcon: Boolean,
});
const click = () => {
    emit('click');
};
</script>

<template>
    <slot v-if="$slots.default" />
    <v-list-item v-else :disabled="props.disabled" class="no-select" @click="click">
        <template #prepend>
            <i v-if="props.kitIcon" :class="'fa-kit ' + props.icon"></i>
            <font-awesome-icon v-else :icon="['far', icon]" />
        </template>
        <v-list-item-title class="ml-2">{{ props.label }}</v-list-item-title>
    </v-list-item>
</template>
