import BackboneElement from './BackboneElement';
import CodeableConcept from './types/CodeableConcept';

import merge from 'lodash/merge';

export default class CoverageEligibilityResponseError extends BackboneElement {
    static __className = 'CoverageEligibilityResponseError';

    __objectStructure = {
        code: CodeableConcept,
    };

    constructor(constructJson, className = 'CoverageEligibilityResponseError') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
