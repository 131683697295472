<script setup>
import { ref, computed, getCurrentInstance } from 'vue';
import { IonPage, IonContent } from '@ionic/vue';
import BnImage from '@/components/BnImage.vue';

const app = getCurrentInstance();
const isPortal = app.appContext.config.globalProperties?.isPortal;

const accountDetails = ref(null);

const setAccountDetails = (details) => {
    accountDetails.value = details;
};

const portalHeader = computed(() => {
    return isPortal ? ' Portal' : '';
});
</script>

<template>
    <ion-page>
        <ion-header class="ion-no-border">
            <ion-toolbar>
                <v-toolbar flat density="default">
                    <v-toolbar-title v-if="accountDetails">
                        <div @click="$router.push({ name: 'home' })">
                            <v-avatar v-if="accountDetails.logo" :size="50" class="mr-3">
                                <bn-image :alt="accountDetails.name + ' Logo'" :img-src-prop="accountDetails.logo" :is-public="true" />
                            </v-avatar>
                            <span>{{ accountDetails.name }}</span>
                        </div>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <div class="d-flex align-center" @click="$router.push({ name: 'home' })">
                            <v-avatar :size="50" class="mr-3">
                                <picture style="width: 50px; height: 50px">
                                    <source srcset="/img/icons/bnlogo_50x50.webp" type="image/webp" />
                                    <img src="/img/icons/bnlogo_50x50.png" alt="BestNotes Logo" />
                                </picture>
                            </v-avatar>
                            <span class="bold" style="font-family: 'Nexa, Poppins', sans-serif; font-size: 2.25rem; font-weight: 600">BestNotes{{ portalHeader }}</span>
                        </div>
                    </v-toolbar-title>
                </v-toolbar>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <div class="mt-12">
                <v-main>
                    <v-container :class="$vuetify.display.name == 'xs' ? 'pa-0' : ''">
                        <div>
                            <router-view v-slot="{ Component }" @setAccountDetails="setAccountDetails">
                                <transition name="fade" mode="out-in">
                                    <component :is="Component" />
                                </transition>
                            </router-view>
                        </div>
                    </v-container>
                </v-main>
            </div>
        </ion-content>
        <ion-footer class="ion-no-border ion-no-padding">
            <ion-toolbar class="ion-no-padding">
                <v-footer app absolute>
                    <div class="text-center text-body-2 font-weight-medium">&copy; {{ new Date().getFullYear() }} BestNotes, LLC. All rights reserved</div>
                </v-footer>
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>

<style lang="scss">
@import '@/assets/scss/bn.scss';

/* A bit of a hack to get Ionic and Vuetify to work together better. */
*,
::before,
::after,
.v-input__slot {
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
}
// ios ample touch footer css
ion-footer.footer-toolbar-padding ion-toolbar:last-of-type {
    padding-bottom: var(--ion-safe-area-bottom, 0);
    background: #ffffff !important;
    --background: #ffffff !important;
}
ion-footer ion-item {
    --min-height: 32px !important;
    --padding-start: 0px !important;
}
</style>
