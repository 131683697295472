import { defineStore } from 'pinia';
import { $log } from '@/common/api/log.service';
import { LOG_CRITICAL, LOG_DEBUG, LOG_ERROR, LOG_INFO, LOG_NOTICE, LOG_WARNING } from '@/common/log/log';

const defaultDeferredLogTimer = 60000;

export const useLogStore = defineStore('log', {
    state() {
        return {
            deferred: [],
            deferredTimeout: null,
        };
    },
    actions: {
        async logEntry(entry) {
            return $log.log(entry.level, entry.summary, entry.description, entry.trace);
        },
        async logCritical(entry) {
            return this.logEntry({ ...entry, level: LOG_CRITICAL });
        },
        async logError(entry) {
            return this.logEntry({ ...entry, level: LOG_ERROR });
        },
        async logWarning(entry) {
            return this.logEntry({ ...entry, level: LOG_WARNING });
        },
        async logNotice(entry) {
            return this.logEntry({ ...entry, level: LOG_NOTICE });
        },
        async logInfo(entry) {
            return this.logEntry({ ...entry, level: LOG_INFO });
        },
        async logDebug(entry) {
            return this.logEntry({ ...entry, level: LOG_DEBUG });
        },
        async deferLogEntry(entry) {
            this.deferred.push(entry);
            clearTimeout(this.deferredTimeout);
            this.deferredTimeout = setTimeout(async () => {
                await this.retryDeferredEntries();
            }, defaultDeferredLogTimer);
        },
        async retryDeferredEntries() {
            let deferredEntries = this.deferred.slice();
            this.deferred = [];
            let entry = deferredEntries.pop();
            while (entry !== undefined) {
                await this.logEntry(entry);
                entry = deferredEntries.pop();
            }
        },
    },
});
