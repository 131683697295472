import { $http } from '@/common/api/http.service';

const basePath = '/api';

const resourceName = 'auth';

export default {
    // Custom endpoints
    login: (data, config) => $http.post(basePath + '/' + resourceName + '/login', data, config),
    portalLogin: (data, config) => $http.post('/portal/' + resourceName + '/login', data, config),
    refreshToken: (config) => $http.get(basePath + '/system/refresh-token', config),
    refreshTokenPortal: (config) => $http.get('/portal/system/refresh-token', config),
    requestResetPassword: (data, config) => $http.post(basePath + '/' + resourceName + '/request-password-reset', data, config),
    requestResetPasswordPortal: (data, config) => $http.post('/portal/' + resourceName + '/request-password-reset', data, config),
    resetPassword: (data, config) => $http.post(basePath + '/' + resourceName + '/password-reset', data, config),
    resetPasswordPortal: (data, config) => $http.post('/portal/' + resourceName + '/password-reset', data, config),
    getInvitation: (id, token, config) => $http.get(basePath + '/auth/system/invitations/' + id + '?token=' + encodeURIComponent(token), config),
    getPortalInvitation: (id, token, config) => $http.get(basePath + '/auth/system/portal_invitations/' + id + '?token=' + encodeURIComponent(token), config),
    acceptInvitation: (data, config) => $http.post(basePath + '/system/accept-invitation', data, config),
    acceptPortalInvitation: (data, config) => $http.post('/portal/system/accept-portal-invitation', data, config),
    acceptInvitationCreateUser: (data, config) => $http.post(basePath + '/auth/accept-invitation-create-user', data, config),
    acceptPortalInvitationCreateUser: (data, config) => $http.post(basePath + '/auth/accept-portal-invitation-create-user', data, config),
    getAccountForLogin: (name, config) => $http.get(basePath + '/auth/accounts/' + name, config),
    registerPortalUser: (data, config) => $http.post('/portal/auth/register-portal-user', data, config),
    completePortalRegistration: (data, config) => $http.post('/portal/auth/complete-portal-registration', data, config),
};
