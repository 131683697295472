import get from 'lodash/get';
import Bundle from '../Bundle';

export default class BundleHandler {
    constructor(resourceUris) {
        this._resource = [];
        let resourceInfo = get(resourceUris, 'resource', []);
        resourceInfo.forEach((resourceItem) => {
            this._resource.push(resourceItem);
        });
    }

    get resource() {
        return this._resource;
    }

    set resource(value) {
        if (Array.isArray(value)) {
            this._resource = value;
            return;
        }
        this._resource.push(value);
    }

    /**
     * This method creates a bundle object that you can use to get resources by adding Aidbox references to the resource array
     */

    getBundle() {
        const requestBase = new Bundle({
            resourceType: 'Bundle',
            type: 'transaction',
            entry: [],
        });

        this._resource.forEach((item) => {
            requestBase.entry.push({
                request: {
                    method: 'GET',
                    url: '/' + item.resourceType + '/' + item.id,
                },
            });
        });

        return requestBase;
    }
}
