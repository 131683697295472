import * as permissions from '@/common/permissionConstants';
import { useAuthStore } from '@/stores/auth';

export default [
    {
        path: 'settings',
        name: 'settings',
        redirect: () => {
            const authStore = useAuthStore();
            if (authStore.isBnSupport) {
                return { name: 'account' };
            }
            return { name: 'me' };
        },
        component: () => import('../views/SettingsPage.vue'),
        children: [
            {
                path: 'me',
                name: 'me',
                meta: { label: 'My settings' },
                component: () => import('../views/settings/MePage.vue'),
                props: true,
            },
            {
                path: 'account',
                name: 'account',
                meta: { label: 'Account details', hasPermissions: permissions.MANAGE_ACCOUNT },
                props: true,
                component: () => import('../views/settings/AccountPage.vue'),
            },
            {
                path: 'security-permission-groups',
                name: 'security-permission-groups',
                meta: { label: 'Security - Permission groups', hasPermissions: permissions.MANAGE_ROLES },
                component: () => import('../views/settings/SecurityPermissionGroupsPage.vue'),
            },
            {
                path: 'security-access-groups',
                name: 'security-access-groups',
                meta: { label: 'Security - Access groups', hasPermissions: permissions.MANAGE_ROLES },
                component: () => import('../views/settings/SecurityAccessGroupsPage.vue'),
            },
            {
                path: 'patient-portal',
                name: 'patient-portal',
                meta: { label: 'Patient portal', hasPermissions: permissions.MANAGE_ACCOUNT },
                component: () => import('../views/settings/PatientPortalPage.vue'),
                props: true,
            },
            {
                path: 'users',
                name: 'users',
                meta: { label: 'Users', hasPermissions: permissions.MANAGE_USERS },
                component: () => import('../views/settings/UsersPage.vue'),
            },
            {
                path: 'form-manager',
                name: 'form-manager',
                meta: { label: 'Form manager', hasPermission: [permissions.MANAGE_TEMPLATES] },
                component: () => import('../views/settings/FormManagerPage.vue'),
            },
            {
                path: 'form-packet-manager',
                name: 'form-packet-manager',
                meta: { label: 'Form packet manager', hasPermission: [permissions.MANAGE_ACCOUNT] },
                component: () => import('../views/settings/FormPacketManagerPage.vue'),
            },
            {
                path: 'live-form-manager',
                name: 'live-form-manager',
                meta: { label: 'Live forms', hasPermission: [permissions.MANAGE_TEMPLATES] },
                component: () => import('../views/settings/FormManagerPage.vue'),
            },
            {
                path: 'autonote-manager',
                name: 'autonote-manager',
                meta: { label: 'AutoNotes', hasPermission: [permissions.MANAGE_AUTONOTES] },
                component: () => import('../views/settings/AutonoteManagerPage.vue'),
            },
            {
                path: 'appointment-reminders',
                name: 'appointment-reminders',
                meta: { label: 'Appt. Reminders', hasPermissions: permissions.MANAGE_ACCOUNT },
                component: () => import('../views/settings/AppointmentRemindersPage.vue'),
            },
            {
                path: 'encounter-types',
                name: 'encounter-types',
                meta: { label: 'Encounter types' },
                component: () => import('../views/settings/EncounterTypesPage.vue'),
            },
            {
                path: 'payers',
                name: 'payers',
                meta: { label: 'Payers' },
                component: () => import('../views/settings/PayersPage.vue'),
            },
            {
                path: 'claim-preferences',
                name: 'claim-preferences',
                meta: { label: 'Claim preferences' },
                component: () => import('../views/settings/ClaimPreferencesPage.vue'),
            },
            {
                path: 'fee-schedule',
                name: 'fee-schedule',
                meta: { label: 'Fee schedule' },
                component: () => import('../views/settings/FeeSchedulePage.vue'),
            },
            {
                path: 'healthcareServices',
                name: 'healthcareServices',
                meta: { label: 'Health care services' },
                component: () => import('../views/settings/HealthcareServicesPage.vue'),
            },
            {
                path: 'locations',
                name: 'locations',
                meta: { label: 'Locations' },
                component: () => import('../views/settings/LocationsPage.vue'),
            },
            {
                path: 'programs',
                name: 'programs',
                meta: { label: 'Programs' },
                component: () => import('../views/settings/ProgramsPage.vue'),
            },
            {
                path: 'outcometools',
                name: 'outcometools',
                meta: { label: 'OutcomeTools' },
                component: () => import('@/views/settings/OutcomeToolsPage.vue'),
            },
        ],
    },
];
