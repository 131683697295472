import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import Coding from './types/Coding';

export default class ClaimQualifiers extends BackboneElement {
    static __className = 'ClaimQualifiers';

    __objectStructure = {
        date: String,
        type: Coding,
    };

    constructor(constructJson, className = 'ClaimQualifiers') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
