import ContactPoint from '../ContactPoint';

const contactPointSystemValues = {
    phone: 'phone',
    fax: 'fax',
    email: 'email',
    pager: 'pager',
    url: 'url',
    sms: 'sms',
    other: 'other',
};

const contactPointUseValues = [
    { code: 'home', display: 'Home' },
    { code: 'work', display: 'Work' },
    { code: 'temp', display: 'Temp' },
    { code: 'old', display: 'Old' },
    { code: 'mobile', display: 'Mobile' },
    { code: 'sms', display: 'SMS' },
];

const contactEmailUseValues = [
    { code: 'home', display: 'Home' },
    { code: 'work', display: 'Work' },
    { code: 'temp', display: 'Temp' },
    { code: 'old', display: 'Old' },
];

function contactPointRankSort(contactPointArray = []) {
    // Returning 100 if rank is not defined to move value to top of array
    contactPointArray.sort((a, b) => (a.rank || 100) - (b.rank || 100));
}

var contactPointTraits = function () {
    Object.defineProperty(this, 'mobilePhone', {
        get() {
            let mobilePhone = this.getContactPointsBySystem(contactPointSystemValues.phone, false, 'mobile', 1);

            return mobilePhone[0] || undefined;
        },
        set(value) {
            let mobilePhone = this.getContactPointsBySystem(contactPointSystemValues.phone, true, 'mobile', 1);
            let index = this.telecom.indexOf(mobilePhone[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });
    Object.defineProperty(this, 'mobilePhoneValue', {
        get() {
            let mobilePhone = this.getContactPointsBySystem(contactPointSystemValues.phone, false, 'mobile', 1);
            return mobilePhone[0]?.value || undefined;
        },
        set(value) {
            let mobilePhone = this.getContactPointsBySystem(contactPointSystemValues.phone, true, 'mobile', 1);
            let index = this.telecom.indexOf(mobilePhone[0]);

            this.telecom[index].value = value;
        },
    });

    Object.defineProperty(this, 'homePhone', {
        get() {
            let homePhone = this.getContactPointsBySystem(contactPointSystemValues.phone, false, 'home', 1);

            return homePhone[0] || undefined;
        },
        set(value) {
            let homePhone = this.getContactPointsBySystem(contactPointSystemValues.phone, true, 'home', 1);
            let index = this.telecom.indexOf(homePhone[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });
    Object.defineProperty(this, 'homePhoneValue', {
        get() {
            let homePhone = this.getContactPointsBySystem(contactPointSystemValues.phone, false, 'home', 1);
            return homePhone[0]?.value || undefined;
        },
        set(value) {
            let homePhone = this.getContactPointsBySystem(contactPointSystemValues.phone, true, 'home', 1);
            let index = this.telecom.indexOf(homePhone[0]);

            this.telecom[index].value = value;
        },
    });

    Object.defineProperty(this, 'workPhone', {
        get() {
            let workPhone = this.getContactPointsBySystem(contactPointSystemValues.phone, false, 'work', 1);

            return workPhone[0] || undefined;
        },
        set(value) {
            let workPhone = this.getContactPointsBySystem(contactPointSystemValues.phone, true, 'work', 1);
            let index = this.telecom.indexOf(workPhone[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });
    Object.defineProperty(this, 'workPhoneValue', {
        get() {
            let workPhone = this.getContactPointsBySystem(contactPointSystemValues.phone, false, 'work', 1);
            return workPhone[0]?.value || undefined;
        },
        set(value) {
            let workPhone = this.getContactPointsBySystem(contactPointSystemValues.phone, true, 'work', 1);
            let index = this.telecom.indexOf(workPhone[0]);

            this.telecom[index].value = value;
        },
    });

    Object.defineProperty(this, 'primaryPhone', {
        get() {
            let allPhones = this.getContactPointsBySystem(contactPointSystemValues.phone, false);
            contactPointRankSort(allPhones);

            return allPhones[0] || undefined;
        },
        set(value) {
            let allPhones = this.getContactPointsBySystem(contactPointSystemValues.phone, true);
            contactPointRankSort(allPhones);
            let index = this.telecom.indexOf(allPhones[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });
    Object.defineProperty(this, 'primaryPhoneValue', {
        get() {
            let allPhones = this.getContactPointsBySystem(contactPointSystemValues.phone, false);
            contactPointRankSort(allPhones);

            return allPhones[0]?.value || undefined;
        },
        set(value) {
            let allPhones = this.getContactPointsBySystem(contactPointSystemValues.phone, true);
            contactPointRankSort(allPhones);
            let index = this.telecom.indexOf(allPhones[0]);

            this.telecom[index].value = value;
        },
    });

    Object.defineProperty(this, 'allPhones', {
        get() {
            let allPhones = this.getContactPointsBySystem(contactPointSystemValues.phone);

            contactPointRankSort(allPhones);
            return allPhones;
        },
    });

    Object.defineProperty(this, 'allPhonesWithFax', {
        get() {
            let allPhonesWithFax = this.telecom.filter((contactPoint) => contactPoint.system === contactPointSystemValues.phone || contactPoint.system === contactPointSystemValues.fax);

            // Sort by system then rank
            allPhonesWithFax.sort((a, b) => a.system.localeCompare(b.system) || (a.rank || 100) - (b.rank || 100));

            return allPhonesWithFax;
        },
    });

    Object.defineProperty(this, 'allFax', {
        get() {
            let allFax = this.getContactPointsBySystem(contactPointSystemValues.fax);

            contactPointRankSort(allFax);
            return allFax;
        },
    });

    Object.defineProperty(this, 'email', {
        get() {
            let email = this.getContactPointsBySystem(contactPointSystemValues.email, false, 'home', 1);

            return email[0] || undefined;
        },
        set(value) {
            let email = this.getContactPointsBySystem(contactPointSystemValues.email, true, 'home', 1);
            let index = this.telecom.indexOf(email[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });

    Object.defineProperty(this, 'homeEmail', {
        get() {
            let homeEmail = this.getContactPointsBySystem(contactPointSystemValues.email, false, 'home', 1);

            return homeEmail[0] || undefined;
        },
        set(value) {
            let homeEmail = this.getContactPointsBySystem(contactPointSystemValues.email, true, 'home', 1);
            let index = this.telecom.indexOf(homeEmail[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });
    Object.defineProperty(this, 'homeEmailValue', {
        get() {
            let homeEmail = this.getContactPointsBySystem(contactPointSystemValues.email, false, 'home', 1);
            return homeEmail[0]?.value || undefined;
        },
        set(value) {
            let homeEmail = this.getContactPointsBySystem(contactPointSystemValues.email, true, 'home', 1);
            let index = this.telecom.indexOf(homeEmail[0]);

            this.telecom[index].value = value;
        },
    });

    Object.defineProperty(this, 'personalEmail', {
        get() {
            return this.homeEmail;
        },
        set(value) {
            this.homeEmail = value;
        },
    });
    Object.defineProperty(this, 'personalEmailValue', {
        get() {
            return this.homeEmailValue;
        },
        set(value) {
            this.homeEmailValue = value;
        },
    });

    Object.defineProperty(this, 'workEmail', {
        get() {
            let workEmail = this.getContactPointsBySystem(contactPointSystemValues.email, false, 'work', 1);

            return workEmail[0] || undefined;
        },
        set(value) {
            let workEmail = this.getContactPointsBySystem(contactPointSystemValues.email, true, 'work', 1);
            let index = this.telecom.indexOf(workEmail[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });
    Object.defineProperty(this, 'workEmailValue', {
        get() {
            let workEmail = this.getContactPointsBySystem(contactPointSystemValues.email, false, 'work', 1);
            return workEmail[0]?.value || undefined;
        },
        set(value) {
            let workEmail = this.getContactPointsBySystem(contactPointSystemValues.email, true, 'work', 1);
            let index = this.telecom.indexOf(workEmail[0]);

            this.telecom[index].value = value;
        },
    });

    Object.defineProperty(this, 'primaryEmail', {
        get() {
            let allEmails = this.getContactPointsBySystem(contactPointSystemValues.email, false);

            contactPointRankSort(allEmails);
            return allEmails[0] || undefined;
        },
        set(value) {
            let allEmails = this.getContactPointsBySystem(contactPointSystemValues.email, true);
            contactPointRankSort(allEmails);
            let index = this.telecom.indexOf(allEmails[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });
    Object.defineProperty(this, 'primaryEmailValue', {
        get() {
            let allEmails = this.getContactPointsBySystem(contactPointSystemValues.email, false);

            contactPointRankSort(allEmails);
            return allEmails[0]?.value || undefined;
        },
        set(value) {
            let allEmails = this.getContactPointsBySystem(contactPointSystemValues.email, true);
            contactPointRankSort(allEmails);
            let index = this.telecom.indexOf(allEmails[0]);

            this.telecom[index].value = value;
        },
    });

    Object.defineProperty(this, 'allEmails', {
        get() {
            let allEmails = this.getContactPointsBySystem(contactPointSystemValues.email);

            contactPointRankSort(allEmails);
            return allEmails;
        },
    });

    Object.defineProperty(this, 'url', {
        get() {
            let urls = this.getContactPointsBySystem(contactPointSystemValues.url, false, 'home', 1);

            return urls[0] || undefined;
        },
        set(value) {
            let urls = this.getContactPointsBySystem(contactPointSystemValues.url, true, 'home', 1);
            let index = this.telecom.indexOf(urls[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });

    Object.defineProperty(this, 'workUrl', {
        get() {
            let workUrl = this.getContactPointsBySystem(contactPointSystemValues.url, false, 'work', 1);

            return workUrl[0] || undefined;
        },
        set(value) {
            let urls = this.getContactPointsBySystem(contactPointSystemValues.url, true, 'work', 1);
            let index = this.telecom.indexOf(urls[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });
    Object.defineProperty(this, 'workUrlValue', {
        get() {
            let workUrl = this.getContactPointsBySystem(contactPointSystemValues.url, false, 'work', 1);
            return workUrl[0]?.value || undefined;
        },
        set(value) {
            let workUrl = this.getContactPointsBySystem(contactPointSystemValues.url, true, 'work', 1);
            let index = this.telecom.indexOf(workUrl[0]);

            this.telecom[index].value = value;
        },
    });

    Object.defineProperty(this, 'primaryUrl', {
        get() {
            let allUrls = this.getContactPointsBySystem(contactPointSystemValues.url, false);
            contactPointRankSort(allUrls);

            return allUrls[0] || undefined;
        },
        set(value) {
            let allUrls = this.getContactPointsBySystem(contactPointSystemValues.url, true);
            let index = this.telecom.indexOf(allUrls[0]);

            if (!(value instanceof ContactPoint)) {
                value = new ContactPoint(value);
            }
            this.telecom[index] = value;
        },
    });
    Object.defineProperty(this, 'primaryUrlValue', {
        get() {
            let allUrls = this.getContactPointsBySystem(contactPointSystemValues.url, false);

            contactPointRankSort(allUrls);
            return allUrls[0]?.value || undefined;
        },
        set(value) {
            let allUrls = this.getContactPointsBySystem(contactPointSystemValues.url, true);
            contactPointRankSort(allUrls);
            let index = this.telecom.indexOf(allUrls[0]);

            this.telecom[index].value = value;
        },
    });

    Object.defineProperty(this, 'allUrls', {
        get() {
            let allUrls = this.getContactPointsBySystem(contactPointSystemValues.url);

            contactPointRankSort(allUrls);
            return allUrls;
        },
    });

    // methods

    this.addTelecom = function (type) {
        if (type === 'phone') {
            return (this.telecom = new ContactPoint({ system: 'phone' }));
        }
        if (type === 'email') {
            return (this.telecom = new ContactPoint({ system: 'email' }));
        }
    };

    this.removeTelecom = function (index) {
        if (index !== -1) {
            return this.telecom.splice(index, 1);
        }
    };

    this.getContactPointsBySystem = function (system, createIfNotExists = false, useFilter, rank) {
        let defaultSystem = system ? system : this.defaultContactPointSystemValue;
        let defaultUse = useFilter ? useFilter : this.defaultContactPointUseType;
        let defaultRank = rank ? rank : 1;

        let contactPointsToProcess = this.getAllContactPointsBySystem(system);

        if (useFilter) {
            let useMatchArray = [];
            contactPointsToProcess.forEach((contactPoint) => {
                if (contactPoint.use === useFilter) {
                    useMatchArray.push(contactPoint);
                }
            });
            contactPointsToProcess = useMatchArray;
        }

        if (contactPointsToProcess.length === 0 && createIfNotExists) {
            let newContactPoint = new ContactPoint({
                system: defaultSystem,
                use: defaultUse,
                rank: defaultRank,
            });

            this.telecom = newContactPoint;
            return [newContactPoint];
        }

        return contactPointsToProcess;
    };

    this.getAllContactPointsBySystem = function (system) {
        if (!system) {
            return this.telecom;
        }

        let contactPointsOfSystem = [];

        this.telecom.forEach((contactPoint) => {
            if (system && contactPoint.system === system) {
                contactPointsOfSystem.push(contactPoint);
            }
        });

        return contactPointsOfSystem;
    };

    return this;
};

export default contactPointTraits;
export { contactPointSystemValues, contactPointUseValues, contactEmailUseValues, contactPointTraits };
