import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import CodeableConcept from './types/CodeableConcept';
import AllergyIntoleranceReaction from './AllergyIntoleranceReaction';
import OnsetX from '@/fhirworks/types/OnsetX';

export default class AllergyIntolerance extends DomainResource {
    static __className = 'AllergyIntolerance';

    __objectStructure = {
        clinicalStatus: CodeableConcept, // active | inactive | resolved
        verificationStatus: CodeableConcept, // unconfirmed | confirmed | refuted | entered-in-error
        category: [String],
        code: CodeableConcept,
        patient: Reference,
        criticality: String,
        onset: OnsetX,
        recordedDate: String,
        recorder: Reference,
        reaction: [AllergyIntoleranceReaction],
    };

    constructor(constructJson, className = 'AllergyIntolerance') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
