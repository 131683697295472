import BackboneElement from './BackboneElement';
import CodeableConcept from './types/CodeableConcept';

import merge from 'lodash/merge';

export default class OperationOutcomeIssue extends BackboneElement {
    static __className = 'OperationOutcomeIssue';

    __objectStructure = {
        severity: String, // fatal | error | warning | information
        code: String, // Error or warning code
        details: CodeableConcept, // additional details about the error
        diagnostics: String, // Additional diagnostic information about the issue
        location: [String], // Deprecated: Path of element(s) related to issue
        expression: [String], // FHIRPath of element(s) related to issue
    };

    constructor(constructJson, className = 'OperationOutcomeIssue') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
