import merge from 'lodash/merge';
import DomainResource from './DomainResource';
import Reference from './types/Reference';
import BN_ListItem from './BN_ListItem';

export default class BN_List extends DomainResource {
    static __className = 'BN_List';

    __objectStructure = {
        type: String,
        name: String,
        owner: Reference,
        linkId: String,
        version: String,
        status: String,
        item: [BN_ListItem],
        url: String,
    };

    constructor(constructJson, className = 'BN_List') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
