import Attachment from './types/Attachment';
import merge from 'lodash/merge';
import BackboneElement from '@/fhirworks/BackboneElement';
import DocumentReferenceContentProfile from '@/fhirworks/DocumentReferenceContentProfile';

export default class DocumentReferenceContent extends BackboneElement {
    static __className = 'DocumentReferenceContent';

    __objectStructure = {
        attachment: Attachment,
        profile: [DocumentReferenceContentProfile],
    };

    constructor(constructJson, className = 'DocumentReferenceContent') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }
    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
