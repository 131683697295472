import { getCurrentInstance } from 'vue';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { createFhirResource, Bundle } from '@/fhirworks';
import { useAuthStore } from '@/stores/auth';
import { bnHttpErrorHandler } from '@/common/api/http.error.notifications';

var $httpFhirApi;
const timeoutValue = 90000;
export const makeHttpFhir = () => {
    let axiosConfig = {
        headers: {
            post: { Accept: 'application/json' },
            // prevent browsers from storing data in persistant storage, only memory
            // in cases where browsers store "no-cache" data in memory, advise it to validate it hasn't changed
            get: { Accept: 'application/json', 'cache-control': 'no-cache,no-store,must-revalidate,private', expires: 0, pragma: 'no-cache' },
            put: { Accept: 'application/json' },
            patch: { Accept: 'application/json' },
        },
        timeout: !import.meta.env.VITE_NO_TIMEOUT ? timeoutValue : 0,
    };

    if (window.location.host.includes('loca.lt')) {
        axiosConfig.baseURL = 'https://' + import.meta.env.VITE_MOBILE_TEST_NAME + '-bestnotes-fresh-aidbox.loca.lt';
    }

    var httpFhir = axios.create(axiosConfig);

    axiosRetry(httpFhir, { retries: 3 });

    // Add a request interceptor
    httpFhir.interceptors.request.use(
        (config) => {
            const authStore = useAuthStore();

            if (authStore.token && !config.url.startsWith('https://bnprofile.fhir.bestnotesfhir.com') && (!config?.terminology || import.meta.env.VITE_TERMINOLOGY_LOCAL)) {
                config.headers.setAuthorization('Bearer ' + authStore.token);
            }

            // If request is not a terminology request or we have TERMINOLOGY_LOCAL true, set baseURL
            if (!config?.terminology || import.meta.env.VITE_TERMINOLOGY_LOCAL) {
                config.baseURL = import.meta.env.VITE_PROTOCOL + authStore.account.fhirApiUri;
            }

            return config;
        },
        (err) => {
            // Do something with request error
            return Promise.reject(err);
        },
    );

    // Add a response interceptor
    httpFhir.interceptors.response.use(
        (res) => {
            // Pull the total to the root.
            if (res.data.total) {
                res.total = res.data.total;
            }

            // handles custom queries returned data
            if (res.data?.data) {
                res.data.entry = res.data.data;
                res.data.resourceType = 'Bundle';
            }

            // response is a bundle. we want it to be an array of FHIRWorks objects
            if (res.data?.entry && res.data.resourceType === 'Bundle') {
                res.originalData = JSON.parse(JSON.stringify(res.data));

                // Convert the response data into a FHIR Bundle object
                res.bundle = new Bundle(res.data);

                // This copies the data from the bundle so that the bundle stays the same if the data
                // ends up getting modified.
                let entries = res.bundle.entry.slice();
                res.data = entries.map((entry) => entry.resource);

                // if bundles of bundles (aka Transaction) with get requests. we want to convert all those bundles the same way as we do above
                for (let innerEntry of res.data.filter((e) => e.__className === 'Bundle' && e.entry)) {
                    let entries = innerEntry.entry.slice();
                    innerEntry.entry = entries.map((entry) => entry.resource);
                }
            }
            // response is an object.  we want to create a FHIR resource
            else if (res.data && res.data.resourceType) {
                res.originalData = res.data;
                res.data = createFhirResource(res.data.resourceType, res.data);
            }

            return res;
        },
        async (err) => {
            return await bnHttpErrorHandler(err, timeoutValue);
        },
    );

    return httpFhir;
};

$httpFhirApi = makeHttpFhir();

export const updateHttpFhirService = () => {
    $httpFhirApi = makeHttpFhir();
    let app = getCurrentInstance();

    if (app?.appContext) {
        app.appContext.config.globalProperties.$httpFhirApi = $httpFhirApi; // update here whenever $fhirApi changes (token, apiUri)
    }
};

export { $httpFhirApi };
