import merge from 'lodash/merge';
import DomainResource from './DomainResource';
import Identifier from '@/fhirworks/types/Identifier';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';
import Reference from '@/fhirworks/types/Reference';
import Annotation from '@/fhirworks/types/Annotation';
import OnsetX from '@/fhirworks/types/OnsetX';
import AbatementX from '@/fhirworks/types/AbatementX';
import ConditionStage from '@/fhirworks/ConditionStage';
import ConditionEvidence from '@/fhirworks/ConditionEvidence';

export default class Condition extends DomainResource {
    static __className = 'Condition';
    __objectStructure = {
        identifier: [Identifier],
        clinicalStatus: CodeableConcept,
        verificationStatus: CodeableConcept,
        category: [CodeableConcept],
        severity: CodeableConcept,
        code: CodeableConcept,
        bodySite: [CodeableConcept],
        subject: Reference,
        encounter: Reference,
        onset: OnsetX,
        abatement: AbatementX,
        recordedDate: String,
        recorder: Reference,
        asserter: Reference,
        stage: [ConditionStage],
        evidence: [ConditionEvidence],
        note: [Annotation],

        // first class extension
        updatedBy: Reference,
        TPLId: String,
        TPLDisorderId: String,
        basedOn: [Reference],
        externalDiagnosis: Boolean,
        externalDiagnosisAuthor: String,
        order: Number,
        conditionType: String,
    };

    constructor(constructJson, className = 'Condition') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
