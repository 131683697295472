import Element from './Element';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import BN_FinancialClassServiceOverride from './BN_FinancialClassServiceOverride';

export default class BN_FinancialClassService extends Element {
    static __className = 'BN_FinancialClassService';

    __objectStructure = {
        healthcareService: Reference,
        uomType: String,
        perUnitDuration: Number,
        procedureCode: String,
        perUnitCharge: Number,
        serviceOverride: [BN_FinancialClassServiceOverride],
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'BN_FinancialClassService') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
