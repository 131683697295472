<script setup>
/**
 * Sample:
     <bn-list-box
         title="Qualifications"
         :items="itemsArray"
         item-text="display"
         item-icon="badge"
         inactive
         add="Add qualification"
         add-icon="plus"
         :add-menu="[
             { icon: 'plus', label: 'Item 1', value: '1' },
             { icon: 'plus', label: 'Item 2', value: '2' },
         ]"
         remove
         restore
         @add="addQualificationDialog"
         @remove="removeQualification"
     />

    itemsArray items can have the following properties:
        icon: ['far', 'plus'] or 'plus'
        chip: { value: 'text', icon: 'plus', color: 'green', dark: true }
        archived: true
 */
import { ref, computed } from 'vue';
import BnInfo from '@/components/BnInfo.vue';
import BnMenu from '@/components/BnMenu.vue';
import BnMenuItem from '@/components/BnMenuItem.vue';
import BnLoading from '@/components/BnLoading.vue';
import draggable from 'vuedraggable';

const emits = defineEmits(['click', 'add', 'remove', 'restore', 'link', 'info', 'reorder']);

const props = defineProps({
    //header
    title: String,
    message: String,
    info: {
        type: [Boolean, String, Object],
        default() {
            return false;
        },
    },
    error: {
        type: [String, Object],
        default() {
            return {};
        },
    },
    warning: {
        type: [String, Object],
        default() {
            return {};
        },
    },
    add: [Boolean, String, Object],
    addIcon: {
        type: [Array, String],
        default: () => 'plus',
    },
    addMenu: {
        type: [Array],
        default: undefined,
    },
    // items to display
    items: {
        type: Array,
        default: () => [],
    },
    itemIcon: [Array, String],
    itemText: [String, Object, Array],
    itemValue: String,
    itemInfo: String,
    groupBy: String,
    groupIcon: String,
    addedItems: {
        type: Array,
        default: () => [],
    },
    alwaysShowArchiveMenu: Boolean,
    archiveLabel: {
        type: String,
        default: 'Archived',
    },
    // item actions
    remove: [Boolean, Function],
    restore: Boolean,
    link: Boolean,
    linkIcon: {
        type: [Array, String],
        default: () => 'link',
    },
    // properties
    dense: Boolean,
    disabled: Boolean,
    inactive: Boolean,
    hideEmpty: {
        type: Boolean,
        default: false,
    },
    clickable: {
        type: Boolean,
        default: false,
    },
    required: Boolean,
    statusChip: Boolean,
    loading: Boolean,
    usprivacy: Boolean,
    dataCy: {
        type: String,
        default: 'bnListBox',
    },
    orderable: { type: Boolean, default: false },
});

const showArchived = ref(false);
const hideEmptyList = computed(() => {
    return props.hideEmpty && !props.items.length;
});
const iconAdd = computed(() => {
    return typeof props.addIcon === 'string' ? ['far', props.addIcon] : props.addIcon;
});
const iconItem = computed(() => {
    return typeof props.itemIcon === 'string' ? ['far', props.itemIcon] : props.itemIcon;
});
const iconLink = computed(() => {
    return typeof props.linkIcon === 'string' ? ['far', props.linkIcon] : props.linkIcon;
});
const itemClickHandler = computed(() => {
    return props.clickable ? 'click' : null;
});
const showArchiveMenu = computed(() => {
    return hasArchivedItems.value || props.alwaysShowArchiveMenu;
});
const hasArchivedItems = computed(() => {
    return props.items?.some((item) => item.archived || item.discontinued);
});
const visibleItems = computed(() => {
    return showArchived.value ? props.items : props.items.filter((item) => !item.archived);
});
const groupedItems = computed(() => {
    if (props.groupBy) {
        let activeGroup = null;
        return props.items.map((item) => {
            const group = item[props.groupBy];
            if (group !== activeGroup) {
                activeGroup = group;
                item.listBoxGroup = group;
            }
            return item;
        });
    } else {
        return props.items;
    }
});
const allowRemove = computed(() => {
    if (props.required) {
        return props.items.filter((item) => !item.archived).length > 1;
    } else return true;
});
const requiredErrorMessage = computed(() => {
    return props.required && !props.items.length ? 'Required item' : undefined;
});
const getItemIcon = function (item) {
    if (typeof item.icon === 'string' && (item.icon?.startsWith('$') || item.icon?.startsWith('fa-kit'))) {
        return item.icon;
    }
    if (item.icon) {
        return typeof item.icon === 'string' ? ['far', item.icon] : item.icon;
    } else return iconItem.value;
};
const getItemTitle = function (item, separator = ' - ') {
    // simple string
    if (typeof item === 'string') {
        return { text: item };
    }
    // all other types
    if (typeof props.itemText === 'object' && !Array.isArray(props.itemText)) {
        let orderNumber = props.items.indexOf(item) + 1;
        const title = props?.orderable ? orderNumber + '. ' + item[props.itemText.title] : item[props.itemText.title];
        return typeof title === 'object' ? title : { text: title };
    } else if (props.itemText) {
        if (props.itemText === 'url') {
            return { text: props.getDisplay(item?.url) };
        }
        if (Array.isArray(props.itemText) && props.itemText.length >= 2) {
            let stringFromArray = '';
            props.itemText.forEach((e, index) => {
                if (item[e.toString()]) {
                    stringFromArray += item[e.toString()];
                    if (index !== props.itemText.length - 1) {
                        stringFromArray += separator;
                    }
                }
            });
            return { text: stringFromArray };
        }
        return { text: item[props.itemText] };
    } else {
        return { text: item?.display || item?.text || item?.name };
    }
};
const getItemSubTitle = function (item) {
    const subtitle = item[props.itemText?.subtitle];
    return typeof subtitle === 'object' ? subtitle : { text: subtitle };
};
const getItemValue = function (item) {
    return props.itemValue ? item[props.itemValue.toString()] : item;
};
const getItemChip = function (item) {
    if (Array.isArray(item.chip)) return item.chip;
    else return item.chip ? [item.chip] : [];
};
const addItem = function (menuItem) {
    emits('add', menuItem);
};
const removeItem = function (item) {
    emits('remove', getItemValue(item));
};
const restoreItem = function (item) {
    emits('restore', getItemValue(item));
};
const openLink = function (item) {
    emits('link', getItemValue(item));
};
const clickItem = function (item) {
    emits('click', getItemValue(item));
};
const clickInfo = function () {
    emits('info');
};
const setOrder = function (event) {
    const { oldIndex, newIndex } = event;
    const items = [...props.items];
    const [movedItem] = items.splice(oldIndex, 1);
    items.splice(newIndex, 0, movedItem);
    emits('reorder', props.items);
};
defineExpose({ showArchived });
</script>

<template>
    <v-card v-if="!hideEmptyList" :border="false" :data-cy="dataCy" class="no-select" :class="{ 'overflow-visible': requiredErrorMessage }">
        <div class="d-flex">
            <div class="bn-section-header" :class="{ 'bnList-required-field': requiredErrorMessage }">{{ title }}</div>
            <v-spacer></v-spacer>
            <!-- message text -->
            <div class="text-caption align-self-center ml-4" v-html="message"></div>
            <div class="ml-4 d-flex">
                <!-- ERROR icon/button -->
                <template v-if="error.message || requiredErrorMessage">
                    <bn-info type="dialog" :header="error.header || 'Attention'" :icon="error.icon || 'exclamation-circle'" :color="error.color || 'error'" class="ml-2 mt-2" size="lg">
                        {{ error.message || requiredErrorMessage }}
                    </bn-info>
                </template>
                <!-- WARNING icon/button -->
                <template v-if="warning.message">
                    <bn-info type="dialog" :header="warning.header || 'Attention'" :icon="warning.icon || 'exclamation-circle'" :color="warning.color || 'warning'" class="ml-2" size="lg">
                        {{ warning.message }}
                    </bn-info>
                </template>
                <!-- INFO icon/button -->
                <template v-if="info && !(error.message || requiredErrorMessage)">
                    <bn-info v-if="typeof info === 'string'" type="dialog" class="ml-2" :class="{ 'mr-2': showArchiveMenu }" :message="info" />
                    <bn-info
                        v-else-if="typeof info === 'object'"
                        :type="info.type || 'dialog'"
                        :header="info.header || 'info'"
                        :icon="info.icon || 'info-circle'"
                        :color="info.color || 'info'"
                        class="ml-2"
                        :class="{ 'mr-2': showArchiveMenu }"
                        :message="info.message"
                    />
                    <v-btn v-else variant="text" icon color="info" @click="clickInfo" class="ml-2" :class="{ 'mr-2': showArchiveMenu }">
                        <font-awesome-icon :icon="['far', 'info-circle']" size="lg" />
                    </v-btn>
                </template>
                <!-- archive ellipsis menu -->
                <bn-menu v-if="showArchiveMenu" offset-y left icon="ellipsis-v" class="ml-2" :data-cy="dataCy + 'ArchiveMenu'">
                    <bn-menu-item v-if="!showArchived" icon="eye" :label="'Show ' + archiveLabel" @click="showArchived = true" :disabled="!hasArchivedItems" :data-cy="dataCy + 'ArchiveMenuShow'" />
                    <bn-menu-item
                        v-if="showArchived"
                        icon="eye-slash"
                        :label="'Hide ' + archiveLabel"
                        @click="showArchived = false"
                        :disabled="!hasArchivedItems"
                        :data-cy="dataCy + 'ArchiveMenuHide'"
                    />
                </bn-menu>
                <!-- Add item to list -->
                <template v-if="add">
                    <!-- addMenu -->
                    <bn-menu v-if="addMenu" :disabled="disabled || add === 'disabled'" color="primary" :icon="iconAdd" class="ml-2 addBtn" :data-cy="dataCy + 'AddMenu'">
                        <template v-for="(menuItem, index) in addMenu" :key="menuItem + index">
                            <bn-menu-item
                                :icon="menuItem.icon"
                                :label="menuItem.label"
                                :disabled="menuItem.disabled"
                                @click="addItem(menuItem.value || menuItem.label)"
                                :data-cy="dataCy + 'AddMenuItem'"
                            />
                        </template>
                    </bn-menu>
                    <!-- Regular add button -->
                    <v-btn v-else :disabled="disabled || add === 'disabled'" variant="text" icon color="primary" @click="addItem" class="ml-2 addBtn" :data-cy="dataCy + 'AddItemButton'">
                        <font-awesome-icon :icon="iconAdd" size="lg" />
                    </v-btn>
                </template>
            </div>
        </div>
        <v-card class="mb-4" :class="{ usprivacy: usprivacy }">
            <!-- loading -->
            <bn-loading v-if="loading" type="card" class="pa-4" />
            <!-- Use slot -->
            <!-- Build the list -->
            <slot v-else>
                <v-card v-if="!visibleItems.length" :disabled="disabled" :border="false" class="d-flex text-caption pa-2">
                    <div v-if="add" class="ml-auto">
                        {{ add }}
                        <font-awesome-icon :icon="['far', 'level-up']" class="mr-3" />
                    </div>
                    <div v-else>None</div>
                </v-card>
                <draggable ghost-class="draggable-ghost" handle=".draggable-handle" item-key="title" :list="groupedItems" @end="setOrder">
                    <template #item="{ element, index }">
                        <div>
                            <!-- GroupBy header -->
                            <template v-if="element.listBoxGroup">
                                <v-divider v-if="index"></v-divider>
                                <v-list-item :key="'group' + index" density="compact" class="bn-color-list-group font-weight-medium">
                                    <font-awesome-icon v-if="groupIcon" :icon="['far', groupIcon]" class="mr-2" />
                                    {{ element?.listBoxGroup }}
                                </v-list-item>
                                <v-divider v-if="!index" :key="'groupdiv' + index"></v-divider>
                            </template>
                            <!-- List items -->
                            <template v-if="!element.archived || showArchived">
                                <v-divider v-if="(showArchived || groupedItems.filter((gi) => !gi.archived).length > 1) && index" :key="'div' + index"></v-divider>
                                <v-list-item
                                    :disabled="disabled || element.disabled || addedItems.some((i) => i[itemValue] === element[itemValue])"
                                    :inactive="inactive"
                                    class="pr-0"
                                    :key="'li' + index"
                                    :href="element.href"
                                    :target="element.href ? '_blank' : undefined"
                                    @[itemClickHandler]="clickItem(element)"
                                    :data-cy="dataCy + 'Item'"
                                >
                                    <template #prepend v-if="!dense">
                                        <div v-if="getItemIcon(element)" class="mr-4 align-self-center" :data-cy="dataCy + 'ItemIcon'">
                                            <v-icon v-if="typeof getItemIcon(element) === 'string' && getItemIcon(element)?.startsWith('$')" :icon="getItemIcon(element)" size="x-small"></v-icon>
                                            <i v-else-if="typeof getItemIcon(element) === 'string' && getItemIcon(element)?.startsWith('fa-kit')" :class="getItemIcon(element)" class="mr-n1"></i>
                                            <font-awesome-icon v-else :icon="getItemIcon(element)" />
                                        </div>
                                        <!-- Chip -->
                                        <div v-if="getItemChip(element).length" class="d-flex flex-column">
                                            <div v-for="(chip, index) of getItemChip(element)" :key="element.id + 'chip' + index" style="margin: 1px" class="d-flex align-content-right">
                                                <v-spacer></v-spacer>
                                                <v-chip
                                                    size="x-small"
                                                    :theme="chip.dark ? 'dark' : 'light'"
                                                    :label="chip.label"
                                                    :variant="chip.outlined ? 'outlined' : 'flat'"
                                                    :color="chip.color"
                                                    class="px-2 mr-2 font-weight-medium"
                                                >
                                                    <font-awesome-icon v-if="chip.icon && !$vuetify.display.smAndDown" :icon="['far', chip.icon]" class="mr-1" />
                                                    <span v-text="chip.value"></span>
                                                </v-chip>
                                            </div>
                                        </div>
                                    </template>
                                    <v-list-item-title>
                                        <font-awesome-icon v-if="getItemTitle(element).icon" :icon="['far', getItemTitle(element).icon]" class="mr-2" />
                                        <span v-html="getItemTitle(element).text" :class="{ 'line-wrap': element.isHTML }"></span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <font-awesome-icon
                                            v-if="getItemSubTitle(element).icon"
                                            :icon="['far', getItemSubTitle(element).icon]"
                                            class="mr-2"
                                            :class="{ 'ml-6': getItemTitle(element).icon }"
                                        />
                                        <span v-html="getItemSubTitle(element).text" :class="{ 'line-wrap': element.isHTML }"></span>
                                    </v-list-item-subtitle>
                                    <template #append>
                                        <!-- Status Chip -->
                                        <template v-if="statusChip">
                                            <v-chip v-if="!element.active" size="x-small" color="warning" class="mr-2 px-2">
                                                <font-awesome-icon v-if="!$vuetify.display.smAndDown" :icon="['far', 'archive']" class="mr-1" />
                                                <span>Archived</span>
                                            </v-chip>
                                            <v-chip v-else size="x-small" color="success" class="mr-2 px-2">
                                                <font-awesome-icon v-if="!$vuetify.display.smAndDown" :icon="['fad', 'spinner-third']" class="mr-1" />
                                                <span>Active</span>
                                            </v-chip>
                                        </template>
                                        <!-- Info -->
                                        <template v-if="element[itemInfo]">
                                            <bn-info type="dialog">{{ element[itemInfo] }}</bn-info>
                                        </template>
                                        <!-- Restore -->
                                        <template v-if="restore && (element.archived || element.inactive || element.active === false)">
                                            <v-chip size="x-small" color="warning">
                                                <font-awesome-icon v-if="!$vuetify.display.smAndDown" :icon="['far', 'archive']" class="mr-1" />
                                                <span>{{ archiveLabel }}</span>
                                            </v-chip>
                                            <template v-if="!disabled">
                                                <v-btn variant="text" icon color="green" @click.stop="restoreItem(element)" :data-cy="dataCy + 'RestoreItem'">
                                                    <font-awesome-icon :icon="['far', 'redo']" size="lg" />
                                                </v-btn>
                                            </template>
                                        </template>
                                        <!-- Remove -->
                                        <template v-else-if="typeof remove === 'function' ? remove(element) : remove && allowRemove && !disabled">
                                            <v-btn variant="text" icon color="red" @click.stop="removeItem(element)" :data-cy="dataCy + 'RemoveItem'">
                                                <font-awesome-icon :icon="['far', 'minus-circle']" />
                                            </v-btn>
                                        </template>
                                        <!-- Export/Open Link -->
                                        <template v-else-if="link && !disabled">
                                            <v-btn variant="text" icon color="success" @click.stop="openLink(element)">
                                                <font-awesome-icon :icon="iconLink" size="lg" />
                                            </v-btn>
                                        </template>
                                        <template v-if="orderable">
                                            <v-btn variant="text" icon size="small" class="draggable-handle">
                                                <font-awesome-icon :icon="['far', 'grip-lines']" size="lg" />
                                            </v-btn>
                                        </template>
                                    </template>
                                </v-list-item>
                            </template>
                        </div>
                    </template>
                </draggable>
            </slot>
        </v-card>
    </v-card>
</template>
