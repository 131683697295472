import BackboneElement from './BackboneElement';

import get from 'lodash/get';
import merge from 'lodash/merge';

export default class Position extends BackboneElement {
    static __className = 'Position';

    constructor(positionJson, className = 'Position') {
        // ToDo: Nothing was being passed to super
        super(positionJson, className);

        this.__longitude = get(positionJson, 'longitude', undefined);
        this.__latitude = get(positionJson, 'latitude', undefined);
        this.__altitude = get(positionJson, 'altitude', undefined);

        this.originalObjJson = this.toJSON();
    }

    // Properties

    get longitude() {
        return this.__longitude;
    }

    set longitude(value) {
        this.__longitude = value;
    }

    get latitude() {
        return this.__latitude;
    }

    set latitude(value) {
        this.__latitude = value;
    }

    get altitude() {
        return this.__altitude;
    }

    set altitude(value) {
        this.__altitude = value;
    }

    toJSON() {
        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                longitude: this.longitude,
                latitude: this.latitude,
                altitude: this.altitude,
            }),
        );
    }
}
