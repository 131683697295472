import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Reference from './types/Reference';

export default class BN_PatientAccountSummary extends DomainResource {
    static __className = 'BN_PatientAccountSummary';

    __objectStructure = {
        copay: Number,
        deductible: Number,
        totalAmount: Number,
        approvedAmount: Number,
        patientResponsibility: Number,
        payorResponsibility: Number,
        patientPaid: Number,
        payorPaid: Number,
        patientOutstanding: Number,
        payorOutstanding: Number,
        latecharge: Number,
        interest: Number,
        adjustments: Number,
        totalBilledAmount: Number,
        totalUnBilledAmount: Number,
        totalAppliedAmount: Number,
        totalUnAppliedAmount: Number,
        patient: Reference, // "Patient"
        superBill: Reference, // "BN_SuperBill"
    };

    constructor(constructJson, className = 'BN_PatientAccountSummary') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
