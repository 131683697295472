import {IStorageService } from './storageService'; 
import { Capacitor } from '@capacitor/core';

export interface IInitializeAppService {
    initializeApp(): Promise<boolean>
};

class InitializeAppService implements IInitializeAppService  {
    appInit = false;
    storageServ!: IStorageService;
    platform!: string;

    constructor(storageService: IStorageService) {
        this.storageServ = storageService;
        this.platform = Capacitor.getPlatform();
    }
    async initializeApp() : Promise<boolean> {
        if(!this.appInit) {
            try {
                if (this.platform === 'web') {
                    await this.storageServ.initWebStore();
                }
                // Initialize the freshdb database
                await this.storageServ.initializeDatabase();
                if( this.platform === 'web') {
                    await this.storageServ.saveToStore(this.storageServ.getDatabaseName());
                }
                this.appInit = true;
            } catch(error: any) {
                const msg = error.message ? error.message : error;
                throw new Error(`initializeAppError.initializeApp: ${msg}`);
            }
        }
        return this.appInit;
    }
}
export default InitializeAppService;
