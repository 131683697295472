import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';

export default class HealthcareServiceRevCode extends BackboneElement {
    static __className = 'HealthcareServiceRevCode';

    __objectStructure = {
        code: String,
        name: String,
    };

    constructor(constructJson, className = 'HealthcareServiceRevCode') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
