import Coding from '../types/Coding';

const codeableConceptTraits = function () {
    Object.defineProperty(this, 'codingZero', {
        get() {
            return this.coding?.[0];
        },
        set(value) {
            if (value === undefined) {
                this.coding.splice(0, 1);
                return;
            }

            if (value instanceof Coding) {
                this.coding.splice(0, 1, value);
                return;
            }

            this.coding.splice(0, 1, new Coding(value));
        },
    });

    Object.defineProperty(this, 'systemZero', {
        get() {
            return this.coding?.[0]?.system;
        },
        set(value) {
            if (!this.coding?.[0]) {
                this.coding.push(new Coding());
            }
            this.coding[0].system = value;
        },
    });

    Object.defineProperty(this, 'versionZero', {
        get() {
            return this.coding?.[0]?.version;
        },
        set(value) {
            if (!this.coding?.[0]) {
                this.coding.push(new Coding());
            }
            this.coding[0].version = value;
        },
    });

    Object.defineProperty(this, 'codeZero', {
        get() {
            return this.coding?.[0]?.code;
        },
        set(value) {
            if (!this.coding?.[0]) {
                this.coding.push(new Coding());
            }
            this.coding[0].code = value;
        },
    });

    Object.defineProperty(this, 'displayZero', {
        get() {
            return this.coding?.[0]?.display;
        },
        set(value) {
            if (!this.coding?.[0]) {
                this.coding.push(new Coding());
            }
            this.coding[0].display = value;
        },
    });

    Object.defineProperty(this, 'userSelectedZero', {
        get() {
            return this.coding?.[0]?.userSelected;
        },
        set(value) {
            if (!this.coding?.[0]) {
                this.coding.push(new Coding());
            }
            this.coding[0].userSelected = value;
        },
    });

    return this;
};

export default codeableConceptTraits;
