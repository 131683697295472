<script>
import BnResourceSelector from '@/components/BnResourceSelector';

export default {
    name: 'BnRichTextToolbar',
    props: {
        editor: [Object, null],
        toolbar: {
            type: Array,
            default: function () {
                return ['bold', 'italic', 'underline', 'text-align', 'heading'];
            },
        },
    },
    components: {
        BnResourceSelector,
    },
    data() {
        return {
            specialVarsDialog: false,
            iconMap: [
                { button: 'bold', icon: 'bold' },
                { button: 'italic', icon: 'italic' },
                { button: 'underline', icon: 'underline' },
                { button: 'strike', icon: 'strikethrough' },
                { button: 'left', icon: 'align-left' },
                { button: 'center', icon: 'align-center' },
                { button: 'right', icon: 'align-right' },
                { button: 'justify', icon: 'align-justify' },
                { button: 'paragraph', icon: 'paragraph' },
                { button: 'h0', icon: 'text-size' },
                { button: 'h1', icon: 'text-size' },
                { button: 'h2', icon: 'text-size' },
                { button: 'h3', icon: 'text-size' },
                { button: 'h4', icon: 'text-size' },
                { button: 'h5', icon: 'text-size' },
                { button: 'h6', icon: 'text-size' },
                { button: 'list-ul', icon: 'list-ul' },
                { button: 'list-ol', icon: 'list-ol' },
                { button: 'quote-right', icon: 'quote-right' },
                { button: 'horizontal-rule', icon: 'horizontal-rule' },
                { button: 'undo', icon: 'undo' },
                { button: 'redo', icon: 'redo' },
                { button: 'specialVars', icon: 'brackets-curly' },
                { button: 'heading', icon: 'text-size' },
                { button: 'text-align', icon: 'align-left' },
            ],
        };
    },
    created() {
        window.keepRTEOpen = false;
    },
    computed: {
        headingLevel() {
            return this.editor.getAttributes('heading')?.level;
        },
        textAlignMenuIcon() {
            if (this.editor.isActive({ textAlign: 'center' })) return this.getIcon('center');
            else if (this.editor.isActive({ textAlign: 'right' })) return this.getIcon('right');
            else return this.getIcon('left');
        },
    },
    methods: {
        getIcon(button) {
            return this.iconMap.find((i) => i.button === button)?.icon || 'bold';
        },
        async specialVariableSelector() {
            window.keepRTEOpen = true;
            this.editor.commands.focus();
            const options = {
                header: 'Special variables',
                width: 400,
                multiple: false,
            };
            const props = {
                preset: 'specialVariables',
                items: [],
            };
            this.$refs.specialVariableSelector.show(options, props).then((response) => {
                const token = response?.token;
                if (token) {
                    const transaction = this.editor.state.tr.insertText(token);
                    this.editor.view.dispatch(transaction);
                    this.$nextTick(() => {
                        this.editor.commands.focus();
                    });
                }
                window.keepRTEOpen = false;
            });
        },
        /**
         * Below is a workaround to support Safari since it does not recognize
         * blur => target correctly (event.relatedTarget)
         * We set window.keepRTEOpen = true to prevent the RTE editor from losing focus
         * and then execute the command. Menu's need to focus and then get out of the way
         */
        openMenu() {
            window.keepRTEOpen = true;
            this.editor.commands.focus();
            setTimeout(() => {
                window.keepRTEOpen = false;
            }, 300);
        },
        apply(command) {
            if (command) {
                window.keepRTEOpen = true;
                this.editor.commands.focus();
                command;
                setTimeout(() => {
                    window.keepRTEOpen = false;
                }, 300);
            }
        },
    },
};
</script>

<template>
    <div class="rte-toolbar d-flex">
        <!-- Disabled default buttons -->
        <template v-if="!editor">
            <v-btn v-for="item of toolbar" :key="item.icon" size="x-small" disabled variant="plain" class="rte-toolbar-button">
                <font-awesome-icon size="lg" :icon="['far', getIcon(item)]" />
            </v-btn>
        </template>
        <!-- Active buttons -->
        <template v-else>
            <!-- BOLD -->
            <v-btn
                v-if="toolbar.includes('bold')"
                variant="plain"
                size="x-small"
                density="compact"
                class="rte-toolbar-button"
                :class="{ 'rte-active-tool': editor.isActive('bold') }"
                @click="apply(editor.chain().focus().toggleBold().run())"
            >
                <font-awesome-icon size="lg" :icon="['far', getIcon('bold')]" />
            </v-btn>

            <!-- ITALIC -->
            <v-btn
                v-if="toolbar.includes('italic')"
                variant="plain"
                size="x-small"
                class="rte-toolbar-button"
                :class="{ 'rte-active-tool': editor.isActive('italic') }"
                @click="apply(editor.chain().focus().toggleItalic().run())"
            >
                <font-awesome-icon size="lg" :icon="['far', getIcon('italic')]" />
            </v-btn>

            <!-- UNDERLINE -->
            <v-btn
                v-if="toolbar.includes('underline')"
                variant="plain"
                size="x-small"
                class="rte-toolbar-button"
                :class="{ 'rte-active-tool': editor.isActive('underline') }"
                @click="apply(editor.chain().focus().toggleUnderline().run())"
            >
                <font-awesome-icon size="lg" :icon="['far', getIcon('underline')]" />
            </v-btn>

            <!-- STRIKE -->
            <v-btn
                v-if="toolbar.includes('strike')"
                variant="plain"
                size="x-small"
                class="rte-toolbar-button"
                :class="{ 'rte-active-tool': editor.isActive('strike') }"
                @click="apply(editor.chain().focus().toggleStrike().run())"
            >
                <font-awesome-icon size="lg" :icon="['far', getIcon('strike')]" />
            </v-btn>

            <!-- PARAGRAPH -->
            <v-btn
                v-if="toolbar.includes('paragraph')"
                variant="plain"
                size="x-small"
                class="rte-toolbar-button"
                :class="{ 'rte-active-tool': editor.isActive('paragraph') }"
                @click="apply(editor.chain().focus().toggleParagraph().run())"
            >
                <font-awesome-icon size="lg" :icon="['far', getIcon('paragraph')]" />
            </v-btn>

            <!-- HEADINGS -->
            <v-menu v-if="toolbar.includes('heading')" offset-y tile variant="flat" class="rte-toolbar-menu">
                <template #activator="{ props }">
                    <v-btn v-bind="props" variant="plain" size="x-small" class="rte-toolbar-button" :class="{ 'rte-active-tool': headingLevel }" @click="openMenu">
                        <font-awesome-icon size="lg" :icon="['far', getIcon(headingLevel ? 'h' + headingLevel : 'h0')]" />
                    </v-btn>
                </template>
                <div class="d-flex flex-column pa-0">
                    <!-- Regular Paragraph -->
                    <v-btn class="rte-toolbar-menu-button justify-start pa-2 h-auto" @click="apply(editor.chain().focus().setParagraph().run())">
                        <span class="text-body-1">Normal text</span>
                    </v-btn>
                    <!-- HEADING 1 -->
                    <v-btn
                        class="rte-toolbar-menu-button justify-start pa-2 h-auto"
                        :class="{ 'rte-active-tool': editor.isActive('heading', { level: 1 }) }"
                        @click="apply(editor.chain().focus().toggleHeading({ level: 1 }).run())"
                    >
                        <span class="text-h1">H1</span>
                    </v-btn>
                    <!-- HEADING 2 -->
                    <v-btn
                        class="rte-toolbar-menu-button justify-start pa-2 h-auto"
                        :class="{ 'rte-active-tool': editor.isActive('heading', { level: 2 }) }"
                        @click="apply(editor.chain().focus().toggleHeading({ level: 2 }).run())"
                    >
                        <span class="text-h2">H2</span>
                    </v-btn>
                    <!-- HEADING 3 -->
                    <v-btn
                        class="rte-toolbar-menu-button justify-start pa-2 h-auto"
                        :class="{ 'rte-active-tool': editor.isActive('heading', { level: 3 }) }"
                        @click="apply(editor.chain().focus().toggleHeading({ level: 3 }).run())"
                    >
                        <span class="text-h3">H3</span>
                    </v-btn>
                    <!-- HEADING 4 -->
                    <v-btn
                        class="rte-toolbar-menu-button justify-start pa-2 h-auto"
                        :class="{ 'rte-active-tool': editor.isActive('heading', { level: 4 }) }"
                        @click="apply(editor.chain().focus().toggleHeading({ level: 4 }).run())"
                    >
                        <span class="rte-heading-h4">H4</span>
                    </v-btn>
                </div>
            </v-menu>

            <!-- TEXT ALIGNMENT -->
            <v-menu v-if="toolbar.includes('text-align')" offset-y variant="flat" class="rte-toolbar-menu">
                <template #activator="{ props }">
                    <v-btn v-bind="props" variant="plain" size="x-small" class="rte-toolbar-button" @click="openMenu">
                        <font-awesome-icon size="lg" :icon="['far', textAlignMenuIcon]" />
                    </v-btn>
                </template>
                <div class="d-flex flex-column pa-0">
                    <!-- ALIGN LEFT -->
                    <v-btn class="rte-toolbar-menu-button" :class="{ 'rte-active-tool': editor.isActive({ textAlign: 'left' }) }" @click="apply(editor.chain().focus().setTextAlign('left').run())">
                        <font-awesome-icon :icon="['far', getIcon('left')]" />
                    </v-btn>
                    <!-- ALIGN CENTER -->
                    <v-btn class="rte-toolbar-menu-button" :class="{ 'rte-active-tool': editor.isActive({ textAlign: 'center' }) }" @click="apply(editor.chain().focus().setTextAlign('center').run())">
                        <font-awesome-icon :icon="['far', getIcon('center')]" />
                    </v-btn>
                    <!-- ALIGN RIGHT -->
                    <v-btn class="rte-toolbar-menu-button" :class="{ 'rte-active-tool': editor.isActive({ textAlign: 'right' }) }" @click="apply(editor.chain().focus().setTextAlign('right').run())">
                        <font-awesome-icon :icon="['far', getIcon('right')]" />
                    </v-btn>
                </div>
            </v-menu>

            <!-- BULLET LIST -->
            <v-btn
                v-if="toolbar.includes('list-ul')"
                variant="plain"
                size="x-small"
                class="rte-toolbar-button"
                :class="{ 'rte-active-tool': editor.isActive('bulletList') }"
                @click="apply(editor.chain().focus().toggleBulletList().run())"
            >
                <font-awesome-icon size="lg" :icon="['far', getIcon('list-ul')]" />
            </v-btn>

            <!-- ORDERED LIST -->
            <v-btn
                v-if="toolbar.includes('list-ol')"
                variant="plain"
                size="x-small"
                class="rte-toolbar-button"
                :class="{ 'rte-active-tool': editor.isActive('orderedList') }"
                @click="apply(editor.chain().focus().toggleOrderedList().run())"
            >
                <font-awesome-icon size="lg" :icon="['far', getIcon('list-ol')]" />
            </v-btn>

            <!-- BLOCKQUOTE -->
            <v-btn
                v-if="toolbar.includes('quote-right')"
                variant="plain"
                size="x-small"
                class="rte-toolbar-button"
                :class="{ 'rte-active-tool': isActive('blockquote') }"
                @click="apply(editor.chain().focus().toggleBlockquote().run())"
            >
                <font-awesome-icon size="lg" :icon="['far', getIcon('quote-right')]" />
            </v-btn>

            <!-- HORIZONTAL RULE -->
            <v-btn v-if="toolbar.includes('horizontal-rule')" variant="plain" size="x-small" class="rte-toolbar-button" @click="apply(editor.chain().focus().setHorizontalRule().run())">
                <font-awesome-icon size="lg" :icon="['far', getIcon('horizontal-rule')]" />
            </v-btn>

            <!-- UNDO -->
            <v-btn v-if="toolbar.includes('undo')" variant="plain" size="x-small" class="rte-toolbar-button" @click="apply(editor.chain().focus().undo())">
                <font-awesome-icon size="lg" :icon="['far', getIcon('undo')]" />
            </v-btn>

            <!-- REDO -->
            <v-btn v-if="toolbar.includes('redo')" variant="plain" size="x-small" class="rte-toolbar-button" @click="apply(editor.chain().focus().redo())">
                <font-awesome-icon size="lg" :icon="['far', getIcon('redo')]" />
            </v-btn>

            <!-- Special Variables -->
            <v-btn v-if="toolbar.includes('specialVars')" variant="plain" size="x-small" class="rte-toolbar-button" @click="specialVariableSelector">
                <font-awesome-icon size="lg" :icon="['far', getIcon('specialVars')]" />
            </v-btn>
        </template>
        <!-- Special Variables Dialog -->
        <bn-resource-selector ref="specialVariableSelector" />
    </div>
</template>

<style lang="scss"></style>
