import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import ValueSetComposeIncludeConceptDesignation from '@/fhirworks/ValueSetComposeIncludeConceptDesignation';

export default class ValueSetExpansionContains extends BackboneElement {
    static __className = 'ValueSetExpansionContains';

    __objectStructure = {
        system: String, // System value for the code
        abstract: Boolean, // If user cannot select this entry
        inactive: Boolean, // If concept is inactive in the code system
        version: String, // Version in which this code/display is defined
        code: String, // Code - if blank, this is not a selectable code
        display: String, // User display for the concept
        designation: [ValueSetComposeIncludeConceptDesignation], // Additional representations for this item
        contains: [ValueSetExpansionContains], // Codes contained under this entry
    };

    constructor(constructJson, className = 'ValueSetExpansionContains') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
