import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Reference from './types/Reference';

export default class BN_ProfileAsset extends DomainResource {
    static __className = 'BN_ProfileAsset';

    __objectStructure = {
        type: String, // Questionnaire, Bn_EncounterType
        sourceFullUrl: String,
        sourceVersion: String,
        localResource: Reference, // Questionnaire
        url: String,
        assetOwner: Reference, // Bn_ProfileOwner
        title: String,
    };

    constructor(constructJson, className = 'BN_ProfileAsset') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
