import { Practitioner } from '@/fhirworks';
import _ from 'lodash';

function getTransactionQuery(queryString, filterByBrand) {
    queryString = encodeURI(queryString.split(' ').filter(Boolean).join('+'));
    let query = 'Practitioner/$lookup?by=name.family,name.given,birthDate,identifier.value;address.city,address.line;telecom.value&sort=name.given,name.family&count=50&q=' + queryString;
    if (filterByBrand?.brand && filterByBrand?.type?.includes('Practitioner')) {
        query = 'Practitioner?_sort=name&_revinclude=PractitionerRole:practitioner&name=' + queryString;
    }

    return query;
}

function processPractitionerResponse(res, filterByBrand) {
    let returnedPractitioners = [];
    if (!res) return returnedPractitioners;

    const practitioners = res.entry?.filter((item) => item.resource.resourceType === 'Practitioner');
    const roles = res.entry?.filter((item) => item.resource.resourceType === 'PractitionerRole');

    practitioners?.forEach((resultRow) => {
        let practitioner = new Practitioner(resultRow.resource);
        practitioner._group = _.get(resultRow, '_group', '');

        // verify that the practitioner has access to the brand (i.e. PractitionerRole resource exists for that user/brand)
        if (filterByBrand?.brand && filterByBrand?.type?.includes('Practitioner')) {
            const role = roles.find((role) => role.resource.practitioner?.id === resultRow.resource.id && role.resource.organization.id === filterByBrand.brand.id);
            if (!role) {
                if (filterByBrand.action === 'disable') practitioner.disabled = true;
                else return;
            }
        }

        returnedPractitioners.push(practitioner);
    });

    return returnedPractitioners;
}

function processBrandUsersResponse(res) {
    let returnedPractitioners = [];
    const practitioners = res.entry.filter((item) => item.resource.resourceType === 'Practitioner');

    practitioners
        ?.sort((a, b) => {
            return a.fullName > b.fullName ? 1 : -1;
        })
        .forEach((resultRow) => {
            let practitioner = new Practitioner(resultRow.resource);
            practitioner._group = _.get(resultRow, '_group', '');
            returnedPractitioners.push(practitioner);
        });

    return returnedPractitioners;
}

export default {
    getTransactionQuery: getTransactionQuery,
    processPractitionerResponse: processPractitionerResponse,
    processBrandUsersResponse: processBrandUsersResponse,
    getBrandUsersQuery(brand) {
        return 'PractitionerRole?_include=practitioner&organization=' + brand.id;
    },
    getLocationUsersQuery(location) {
        return 'PractitionerRole?_include=practitioner&location=' + location.id;
    },
};
