import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import ValueSetComposeIncludeConceptDesignation from '@/fhirworks/ValueSetComposeIncludeConceptDesignation';

export default class ValueSetComposeIncludeConcept extends BackboneElement {
    static __className = 'ValueSetComposeIncludeConcept';

    __objectStructure = {
        code: String, // !R Code or expression from system
        display: String, // Text to display for this code for this value set in this valueset
        designation: [ValueSetComposeIncludeConceptDesignation], // Additional representations for this concept
        /**
         * First Class extensions
         */
        inactive: Boolean,
        score: Boolean,
    };

    constructor(constructJson, className = 'ValueSetComposeIncludeConcept') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
