import { $log } from '@/common/api/log.service';
import { mapStackTrace } from 'sourcemapped-stacktrace';
/* eslint-disable no-alert, no-console */

export default class errorHandler {
    static async processError(err, vm, info) {
        mapStackTrace(err?.stack, (mappedStack) => {
            $log.error(err.message, info, mappedStack.join('\n'));
            console.error(`[Global Error Handler] Error in ${info}: `, err);
        });
    }

    static async processWarning(err, vm, info) {
        mapStackTrace(err?.stack, (mappedStack) => {
            $log.warning(err.message, info, mappedStack.join('\n'));
            console.warn(`[Global Warning Handler] Warning in ${info}: `, err);
        });
    }
}
