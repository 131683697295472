import {
    Extension,
    FHIRObjectBase,
    defaultBNExtensionStructureDefinitionUrlBase,
    defaultFHIRExtensionStructureDefinitionUrlBase,
    defaultFHIRUSCoreExtensionStructureDefinitionUrlBase,
} from '../index';
import isNull from 'lodash/isNull';

const extensionTraits = function () {
    this.getExtensionValue = function (propertyName = '', extensionUrlBase = defaultFHIRExtensionStructureDefinitionUrlBase) {
        let extensionItem = this.getExtension(propertyName, extensionUrlBase);
        return extensionItem ? extensionItem.value : extensionItem;
    };

    this.getExtension = function (propertyName = '', extensionUrlBase = defaultFHIRExtensionStructureDefinitionUrlBase) {
        let extensionsArray = this.getExtensions(propertyName, extensionUrlBase);
        return extensionsArray.length > 0 ? extensionsArray[0] : undefined;
    };

    this.getExtensions = function (propertyName = '', extensionUrlBase = defaultFHIRExtensionStructureDefinitionUrlBase) {
        return this.extension.filter((extensionItem) => {
            if (!extensionItem.url) {
                return false;
            }

            let lookupUrl = extensionUrlBase + propertyName;
            if (propertyName) {
                return extensionItem.url === lookupUrl;
            } else {
                return extensionItem.url.startsWith(lookupUrl);
            }
        });
    };

    this.setExtensionValue = function (propertyName, value, valueType = 'String', extensionUrlBase = defaultFHIRExtensionStructureDefinitionUrlBase) {
        if (!propertyName) {
            throw 'Property Name Required';
        }

        let valuesResult = this.getExtensions(propertyName, extensionUrlBase);
        if (valuesResult.length > 1) {
            throw 'Multiple Extension Value Found, Aborted';
        }

        if (valuesResult.length === 0) {
            let newExtensionJson = {};
            newExtensionJson['url'] = extensionUrlBase + propertyName;
            newExtensionJson['value' + valueType] = value;

            this.extension = newExtensionJson;
            return;
        }

        valuesResult[0].value = { valueType: valueType, valueJson: value };
    };

    this.addExtensionValue = function (propertyName, value, valueType = 'String', extensionUrlBase = defaultFHIRExtensionStructureDefinitionUrlBase) {
        if (!propertyName) {
            throw 'Property Name Required';
        }

        let newExtensionJson = {};
        newExtensionJson['url'] = extensionUrlBase + propertyName;
        newExtensionJson['value' + valueType] = value;

        // setter will properly convert to an extension if needed
        this.extension = newExtensionJson;
    };

    this.addCustomBaseExtensionValue = function (propertyName, value, valueType = 'String', extStructureDefinitionUrlBase = defaultBNExtensionStructureDefinitionUrlBase) {
        if (propertyName && extStructureDefinitionUrlBase === defaultBNExtensionStructureDefinitionUrlBase) {
            propertyName = this.__className + '-' + propertyName;
        }
        return this.addExtensionValue(propertyName, value, valueType, extStructureDefinitionUrlBase);
    };

    this.addBnExtensionValue = function (propertyName, value, valueType = 'String') {
        return this.addCustomBaseExtensionValue(propertyName, value, valueType, defaultBNExtensionStructureDefinitionUrlBase);
    };

    // When using this method any "mirror" array getter and setter values will not maintain sync
    // Use cautiously in extension properties like  signature in QuestionnaireResponse.
    this.removeExtensionValuesByType = function (propertyName, extensionUrlBase = defaultFHIRExtensionStructureDefinitionUrlBase) {
        if (!propertyName) {
            throw 'Property Name Required';
        }

        let existingExtensions = this.getExtensions(propertyName, extensionUrlBase);
        existingExtensions.forEach((item) => {
            this.extension.splice(this.extension.indexOf(item), 1);
        });
    };

    this.removeCustomBaseExtensionValuesByType = function (propertyName, extStructureDefinitionUrlBase = defaultBNExtensionStructureDefinitionUrlBase) {
        if (propertyName && extStructureDefinitionUrlBase === defaultBNExtensionStructureDefinitionUrlBase) {
            propertyName = this.__className + '-' + propertyName;
        }
        return this.removeExtensionValuesByType(propertyName, extStructureDefinitionUrlBase);
    };

    this.removeBNExtensionValuesByType = function (propertyName) {
        return this.removeCustomBaseExtensionValuesByType(propertyName, defaultBNExtensionStructureDefinitionUrlBase);
    };

    this.getExtensionProperties = function (extensionUrlBase = defaultFHIRExtensionStructureDefinitionUrlBase, extensionBase) {
        let extensionProperties = [];
        this.extension.map((extensionItem) => {
            if (extensionBase === undefined) {
                extensionBase = this.__className.toLowerCase();
            }
            let lookupUrl = extensionUrlBase + extensionBase + '-';
            if (extensionItem.url.startsWith(lookupUrl)) {
                extensionProperties.push(extensionItem.url.replace(lookupUrl, ''));
            }
        });

        return [...new Set(extensionProperties)];
    };

    this.getCustomBaseExtensionValue = function (propertyName = '', extStructureDefinitionUrlBase = defaultBNExtensionStructureDefinitionUrlBase) {
        if (propertyName && extStructureDefinitionUrlBase === defaultBNExtensionStructureDefinitionUrlBase) {
            propertyName = this.__className + '-' + propertyName;
        }
        return this.getExtensionValue(propertyName, extStructureDefinitionUrlBase);
    };

    this.getBNExtensionValue = function (propertyName = '') {
        return this.getCustomBaseExtensionValue(propertyName, defaultBNExtensionStructureDefinitionUrlBase);
    };

    this.getBNExtension = function (propertyName = '') {
        return this.getExtension(propertyName ? this.__className + '-' + propertyName : '', defaultBNExtensionStructureDefinitionUrlBase);
    };

    this.getCustomBaseExtensions = function (propertyName = '', extStructureDefinitionUrlBase = defaultBNExtensionStructureDefinitionUrlBase) {
        if (propertyName && extStructureDefinitionUrlBase === defaultBNExtensionStructureDefinitionUrlBase) {
            propertyName = this.__className + '-' + propertyName;
        }
        return this.getExtensions(propertyName, extStructureDefinitionUrlBase);
    };

    this.getBNExtensions = function (propertyName = '') {
        return this.getCustomBaseExtensions(propertyName, defaultBNExtensionStructureDefinitionUrlBase);
    };

    this.setCustomBaseExtensionValue = function (propertyName, value, valueType = 'String', extStructureDefinitionUrlBase = defaultBNExtensionStructureDefinitionUrlBase) {
        if (propertyName && extStructureDefinitionUrlBase === defaultBNExtensionStructureDefinitionUrlBase) {
            propertyName = this.__className + '-' + propertyName;
        }
        return this.setExtensionValue(propertyName, value, valueType, extStructureDefinitionUrlBase);
    };

    this.setBNExtensionValue = function (propertyName, value, valueType = 'String') {
        return this.setCustomBaseExtensionValue(propertyName, value, valueType, defaultBNExtensionStructureDefinitionUrlBase);
    };

    this.getBNExtensionProperties = function () {
        return this.getExtensionProperties(defaultBNExtensionStructureDefinitionUrlBase, this.__className);
    };

    this.getUSCoreExtensionValue = function (propertyName = '') {
        return this.getCustomBaseExtensionValue(propertyName, defaultFHIRUSCoreExtensionStructureDefinitionUrlBase);
    };

    this.getUSCoreExtension = function (propertyName = '') {
        return this.getExtension(propertyName ? propertyName : '', defaultFHIRUSCoreExtensionStructureDefinitionUrlBase);
    };

    this.getUSCoreExtensions = function (propertyName = '') {
        return this.getCustomBaseExtensions(propertyName, defaultFHIRUSCoreExtensionStructureDefinitionUrlBase);
    };

    this.setUSCoreExtensionValue = function (propertyName, value, valueType = 'String') {
        return this.setExtensionValue(propertyName ? propertyName : '', value, valueType, defaultFHIRUSCoreExtensionStructureDefinitionUrlBase);
    };

    this.getUSCoreExtensionProperties = function () {
        return this.getExtensionProperties(defaultFHIRUSCoreExtensionStructureDefinitionUrlBase, 'us-core');
    };

    this.updateDynamicExtensionValue = function (propertyName) {
        let constructorCompleted = this.constructorCompleted;
        if (propertyName === 'bn') {
            constructorCompleted = this.elementConstructorCompleted;
        }

        if (!constructorCompleted) {
            return;
        }

        let currentDynamicExtensionValue = this.getExtension(propertyName ? this.__className + '-' + propertyName : '', defaultBNExtensionStructureDefinitionUrlBase);
        let currentDynamicPropertyValue = this['__' + propertyName] && Object.keys(this['__' + propertyName]).length > 0;

        if (currentDynamicPropertyValue) {
            let newExtensionValue = this.jsonToExtension(this['__' + propertyName]);

            // If there is no change in the value do not update the extension
            // was causing issue with circular refresh in the DataProvider
            if (JSON.stringify(currentDynamicExtensionValue?.value) === JSON.stringify(newExtensionValue.extension)) {
                return;
            }

            // If there is a current extension value and the new extension
            // value is empty, remove the current extension value
            if (currentDynamicExtensionValue?.value && !newExtensionValue.value.length) {
                this.extension.splice(this.extension.indexOf(currentDynamicExtensionValue), 1);
                return;
            }

            // If the newly generated extension doesn't have a value, abort
            if (!newExtensionValue.value.length) {
                return;
            }

            this.setDynamicExtensionValue(propertyName, newExtensionValue.extension, 'extension');
        } else if (currentDynamicExtensionValue) {
            this.extension.splice(this.extension.indexOf(currentDynamicExtensionValue), 1);
        }
    };

    this.getDynamicExtension = function (propertyName = '') {
        this.updateDynamicExtensionValue(propertyName);
        return this.getExtension(propertyName ? this.__className + '-' + propertyName : '', defaultBNExtensionStructureDefinitionUrlBase);
    };

    this.getDynamicExtensionValue = function (propertyName = '') {
        this.updateDynamicExtensionValue(propertyName);
        return this.getExtensionValue(propertyName ? this.__className + '-' + propertyName : '', defaultBNExtensionStructureDefinitionUrlBase);
    };

    this.setDynamicExtensionValue = function (propertyName, value, valueType = 'String') {
        return this.setExtensionValue(propertyName ? this.__className + '-' + propertyName : '', value, valueType, defaultBNExtensionStructureDefinitionUrlBase);
    };

    this.handleDynamicExtensionToJson = function (propertyNames) {
        let constructorCompleted = this.constructorCompleted;
        if (propertyNames === 'bn') {
            constructorCompleted = this.elementConstructorCompleted;
        }

        if (!constructorCompleted) {
            return;
        }

        if (!Array.isArray(propertyNames)) {
            propertyNames = [propertyNames];
        }

        propertyNames.forEach((propertyName) => this.updateDynamicExtensionValue(propertyName));
    };

    this.jsonToExtension = function (jsonObj, recursing = false) {
        let newExtArray = [];
        // Using forEach so that items can be ignored (undefined and null)
        Object.keys(jsonObj).forEach((key) => {
            let propertyValue = jsonObj[key];
            let propertyType = typeof jsonObj[key];

            if (propertyType === 'object') {
                if (propertyValue instanceof FHIRObjectBase) {
                    propertyType = 'fhir';
                } else if (Array.isArray(jsonObj[key])) {
                    propertyType = 'array';
                } else if (isNull(propertyValue)) {
                    // Discard properties with a null value
                    return;
                }
            } else if (propertyType === 'number' && propertyValue % 1 !== 0) {
                propertyType = 'decimal';
            }

            let extJson = undefined;
            switch (propertyType) {
                case 'string':
                    extJson = { url: key, valueString: propertyValue };
                    break;
                case 'number':
                    extJson = { url: key, valueInteger: propertyValue };
                    break;
                case 'decimal':
                    extJson = { url: key, valueDecimal: propertyValue };
                    break;
                case 'array':
                    var propertyExtensions = [];
                    propertyValue.forEach((item) => {
                        propertyExtensions.push(this.jsonToExtension({ arrayItem: item }, true)[0]);
                    });
                    extJson = { url: key, valueextension: propertyExtensions };
                    break;
                case 'fhir':
                    extJson = { url: key };
                    extJson['value' + propertyValue.__className] = propertyValue.toJSON();
                    break;
                case 'object':
                    extJson = { url: key, valueextension: this.jsonToExtension(propertyValue, true) };
                    break;
                case 'boolean':
                    extJson = { url: key, valueBoolean: propertyValue };
                    break;
                case 'undefined':
                    // Discard properties with an undefined value
                    return;
                default:
                    console.log('JsonToExtension Unhandled value type ' + propertyType);
            }
            newExtArray.push(extJson);
        });
        if (recursing) {
            return newExtArray;
        }

        return new Extension({ extension: newExtArray });
    };

    this.extensionToJson = function (jsonObj, extension) {
        extension.forEach((item) => {
            switch (item.__extensionValueType) {
                case 'String':
                    jsonObj[item.url] = item.value.toString();
                    break;
                case 'Integer':
                    jsonObj[item.url] = parseInt(item.value);
                    break;
                case 'Decimal':
                    jsonObj[item.url] = parseFloat(item.value);
                    break;
                case 'Boolean':
                    jsonObj[item.url] = !!item.value;
                    break;
                case 'extension':
                    if (item.value.find((extContents) => extContents.url === 'arrayItem')) {
                        jsonObj[item.url] = item.value.map((itemValue) => {
                            return this.extensionToJson({}, [itemValue], true).arrayItem;
                        });
                    } else {
                        jsonObj[item.url] = this.extensionToJson({}, item.value, true);
                    }
                    break;
                case undefined:
                    // If the valueType is undefined that means the valueX property didn't
                    // exit, in our case this indicates an empty array
                    jsonObj[item.url] = [];
                    break;
                default:
                    if (item.value instanceof FHIRObjectBase) {
                        jsonObj[item.url] = item.value;
                    } else {
                        console.log('ExtensionToJson Unhandled Type ' + item.__extensionValueType, item.value);
                    }
            }
        });
        return jsonObj;
    };

    this.arrayToBNExtension = function (value, propName, propType = 'String') {
        return this.arrayToCustomBaseExtension(value, propName, propType, defaultBNExtensionStructureDefinitionUrlBase);
    };

    // Extensions that function as an array
    this.arrayToCustomBaseExtension = function (value, propName, propType = 'String', extStructureDefinitionUrlBase = defaultBNExtensionStructureDefinitionUrlBase) {
        // Assigning an array, so replace existing values
        if (Array.isArray(value)) {
            this['__' + propName].splice(0);
            this.removeCustomBaseExtensionValuesByType(propName, extStructureDefinitionUrlBase);

            value.forEach((item) => {
                this.addCustomBaseExtensionValue(propName, item, propType, extStructureDefinitionUrlBase);
            });

            let allExtension = this.getCustomBaseExtensions(propName, extStructureDefinitionUrlBase);
            allExtension.forEach((item) => {
                this['__' + propName].push(item.value);
            });

            return;
        }

        // Passing undefined for a value to clear extension values
        if (value === undefined) {
            this['__' + propName].splice(0);
            this.removeCustomBaseExtensionValuesByType(propName, extStructureDefinitionUrlBase);
            return;
        }

        // Get array of existing extensions BEFORE adding to use in diff below
        let originalExtensions = this.getCustomBaseExtensions(propName, extStructureDefinitionUrlBase) || [];
        // Add item to extension array
        this.addCustomBaseExtensionValue(propName, value, propType, extStructureDefinitionUrlBase);
        let existingExtensions = this.getCustomBaseExtensions(propName, extStructureDefinitionUrlBase);
        // Determine which element was added (should only ever be one)
        let newExtensions = existingExtensions.filter((e) => originalExtensions.indexOf(e) === -1);
        // Add the value of the extension to internal property array
        newExtensions.forEach((ext) => this['__' + propName].push(ext.value));
    };

    this.removeArrayBNExtensionItem = function (itemValue, propName, itemKey) {
        return this.removeArrayCustomBaseExtensionItem(itemValue, propName, itemKey, defaultBNExtensionStructureDefinitionUrlBase);
    };

    // remove a single extension rather than updating all of them
    this.removeArrayCustomBaseExtensionItem = function (itemValue, propName, itemKey, extStructureDefinitionUrlBase = defaultBNExtensionStructureDefinitionUrlBase) {
        if (!propName) {
            throw 'Property Name Required';
        }

        let existingExtensions = this.getCustomBaseExtensions(propName, extStructureDefinitionUrlBase) || [];
        let extensionsToRemove;
        if (!itemKey) {
            extensionsToRemove = existingExtensions.filter((e) => e.value === itemValue);
        } else {
            extensionsToRemove = existingExtensions.filter((e) => e.value[itemKey] === itemValue);
        }
        extensionsToRemove.forEach((ext) => {
            this['__' + propName].splice(this['__' + propName].indexOf(ext.value), 1);
            this.extension.splice(this.extension.indexOf(ext), 1);
        });
    };

    return this;
};

export default extensionTraits;
