import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import Coding from './types/Coding';

export default class BN_Payment extends DomainResource {
    static __className = 'BN_Payment';

    __objectStructure = {
        type: Coding,
        mode: Coding,
        date: String,
        amount: Number,
        checkno: String, // "Payment Reference"
        comments: String,
        patient: Reference, // "Patient"
        source: Boolean,
        account: Reference, // "Account"
        payor: Reference, // "Organization,
        coverage: Reference, // "Coverage"
        responsibility: Coding,
        informationChannel: Coding,
        postedOn: String,
        paytype: [Coding], //Copay,Coinsurance,Deductible
        appliedAmount: Number,
        unappliedAmount: Number,
        numberOfClaims: Number,
        matchedClaims: Number,
        unmatchedClaims: Number,
        attachments: [Reference],
    };

    constructor(constructJson, className = 'BN_Payment') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
