import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { addHours } from 'date-fns';
import timezones from '@/assets/json/timezones.json';
import { DEFAULT_TIMEZONES, COMMON_TIMEZONES } from '@/common/config';
import groupBy from 'lodash/groupBy';

const defaultLocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timezone = {
    // TODO: add validation to the following methods
    format: function (dateString, stringFormat, timeZone = defaultLocalTimeZone) {
        if (!stringFormat) stringFormat = 'yyyy-MM-dd';
        return format(dateString, stringFormat, { timeZone: timeZone });
    },
    getLocalDateTime: function (utcDateString, stringFormat, localTimeZone = defaultLocalTimeZone) {
        return format(utcToZonedTime(utcDateString, localTimeZone), stringFormat || "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { timeZone: localTimeZone });
    },
    getGlobalDateTime: function (localDateString, stringFormat, localTimeZone = defaultLocalTimeZone) {
        return format(utcToZonedTime(zonedTimeToUtc(new Date(localDateString), localTimeZone), 'UTC'), stringFormat || "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { timeZone: 'UTC' });
    },
    convertToTimezone: function (dateString, stringFormat, fromTimeZone, toTimeZone) {
        const utc = timezone.getGlobalDateTime(dateString, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", fromTimeZone);
        return timezone.getLocalDateTime(utc, stringFormat, toTimeZone);
    },
    getTimezoneData: function (timezone) {
        return timezones.find((zone) => zone.timezone === timezone || zone.abbr === timezone || zone.abbrDST === timezone);
    },
    getLocalTimeZone: function () {
        return defaultLocalTimeZone;
    },
    getLocalTimeZoneAbbreviation: function () {
        const isDST = this.isDST(new Date().toString());
        const timeZone = this.getLocalTimeZone();

        return isDST ? this.getTimezoneData(timeZone).abbrDST : this.getTimezoneData(timeZone).abbr;
    },

    setGlobalTimeToDST: function (dateString) {
        // set timezone of browser (user creating event)
        const baseTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // determine if baseTimeZone uses DST
        const DST = timezone.getTimezoneData(baseTimeZone).isdst;
        // is the dateString currently in DST
        const isDST = timezone.isDST(dateString);
        // if the baseTimeZone (user creating the event) is in a DST timezone AND they are not currently in DST mode then add 1 hour to the event.
        if (!isDST && DST) dateString = timezone.getGlobalDateTime(addHours(new Date(dateString), 1), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
        // or return formatted dateString
        else dateString = timezone.getGlobalDateTime(new Date(dateString), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

        return dateString;
    },

    stdTimezoneOffset: function (dt) {
        const jan = new Date(dt.getFullYear(), 0, 1);
        const jul = new Date(dt.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    },

    // determine if dateString is in DST
    isDST: function (dateString) {
        const dt = new Date(dateString);
        return dt.getTimezoneOffset() < timezone.stdTimezoneOffset(dt);
    },

    loadPickerByRegion: function (region) {
        if (!region) region = DEFAULT_TIMEZONES;
        else if (region === 'all') region = null;
        // region can be either a string or array:  'America' or ['America', 'Pacific']
        let results = COMMON_TIMEZONES;
        const sourceData = region ? timezones.filter((zone) => region.includes(zone.region)) : timezones;
        for (const [key, value] of Object.entries(groupBy(sourceData, 'region'))) {
            if (results.find((item) => item.header === key)) continue;
            results.push({ header: key });
            value.forEach((zone) => {
                results.push({ timezone: zone.timezone });
            });
        }
        return results;
    },
    timeToMilitary: function (time) {
        // currently accepts time in this format:  12:00 am, 1:00 PM, 11:29 AM, etc.
        // this works for the current purpose, but we may want to extend it to allow for no space between mm and ampm.
        let hh, mm, ampm;
        [hh, mm, ampm] = time.split(/:| /);
        hh = Number(hh);
        if (ampm?.toLowerCase() === 'pm' && hh < 12) hh += 12;
        else if (ampm?.toLowerCase() === 'am' && hh === 12) hh -= 12;
        return hh.toString().padStart(2, '0') + ':' + mm;
    },

    /**
     * This is only here as a code reference. This is what I created to build assets/json/timezones.json
     * If we need to update the json we can run this again.
     */
    // buildTimezoneJson: async function () {
    //     let data = [];
    //     await axios.get('http://worldtimeapi.org/api/timezone').then((source) => {
    //         source.data.forEach((item) => {
    //             axios.get('http://worldtimeapi.org/api/timezone/' + item).then((response) => {
    //                 const tz = response.data;
    //                 const zone = tz.timezone.split('/');
    //                 let record = {};
    //                 record.name = zone[zone.length - 1].replace('_', ' ');
    //                 record.region = zone[0];
    //                 record.timezone = tz.timezone;
    //                 record.DST = tz.dst_offset > 0;
    //                 record.abbr = record.DST ? tz.abbreviation.replace('ST', 'T').replace('DT', 'ST') : tz.abbreviation;
    //                 record.abbrDST = tz.abbreviation;
    //                 record.utcOffset = tz.raw_offset;
    //                 record.utcOffsetDST = record.DST ? tz.raw_offset + 3600 : tz.raw_offset;
    //                 data.push(record);
    //             });
    //         });
    //     });
    //     return data;
    // },
};
export default timezone;
