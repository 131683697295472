import { useAuthStore } from '@/stores/auth';

export const useDevOnly = () => {
    const isDev = (...additionalDatabases) => import.meta.env.DEV || isTenant('freshqa', ...additionalDatabases);
    const devOnly = isDev;

    const isTenant = (...allowedDatabases) => {
        const authStore = useAuthStore();
        return allowedDatabases.some((db) => authStore.account.fhirApiUri.includes(db));
    };

    const isProfile = () => isTenant('bnprofile');

    const isBnSupport = () => {
        const authStore = useAuthStore();
        return authStore.isBnSupport;
    };

    const isBnSupportUser = () => {
        const authStore = useAuthStore();
        return authStore.isBnSupportUser;
    };

    return {
        devOnly,
        isDev,
        isTenant,
        isProfile,
        isBnSupport,
        isBnSupportUser,
    };
};
