import BackboneElement from './BackboneElement';
import CarePlanActivityDetail from '@/fhirworks/CarePlanActivityDetail';
import merge from 'lodash/merge';
import Annotation from '@/fhirworks/types/Annotation';

export default class CarePlanActivity extends BackboneElement {
    static __className = 'CarePlanActivity';

    __objectStructure = {
        detail: CarePlanActivityDetail,
        progress: [Annotation],
    };

    constructor(constructJson, className = 'CarePlanActivity') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
