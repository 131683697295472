export const getIconFromFileName = function (fileName) {
    let ext = fileName.split('.').pop().toLowerCase();

    let icon;

    switch (ext) {
        case 'jpg':
        case 'jpeg':
        case 'webp':
        case 'png':
        case 'gif':
            icon = 'file-image';
            break;
        case 'pdf':
            icon = 'file-pdf';
            break;
        case 'doc':
        case 'docx':
        case 'odt':
        case 'html':
            icon = 'file-word';
            break;
        case 'mp4':
        case 'mpeg':
        case 'm4a':
        case 'f4v':
        case 'm4b':
        case 'mov':
        case 'mkv':
        case 'avi':
            icon = 'file-video';
            break;
        case 'pptx':
        case 'pptm':
        case 'ppt':
        case 'odp':
            icon = 'file-powerpoint';
            break;
        case 'csv':
        case 'ods':
        case 'xls':
        case 'xlsx':
            icon = 'file-excel';
            break;
        default:
            icon = 'file';
    }

    return icon;
};
