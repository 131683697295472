import users from '@/common/api/users.api';
import auth from '@/common/api/auth.api';
import patientApi from '@/common/api/patient.api';
import personApi from '@/common/api/person.api';
import organizationApi from '@/common/api/organization.api';
import systemSearchApi from '@/common/api/systemSearch.api';
import subscriptions from '@/common/api/subscriptions.api';
import roles from '@/common/api/roles.api';
import { $http } from '@/common/api/http.service';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { $log } from '@/common/api/log.service';

import { useAuthStore } from '@/stores/auth';

export const basePath = (dataProvider) => {
    const authStore = useAuthStore();
    let baseRoute = '/api';
    if (window.page === 'portal') {
        baseRoute = '/portal';
    }

    switch (dataProvider) {
        case 'system':
            return baseRoute + '/system';
        case 'account':
            return baseRoute + '/account/' + authStore.account.id;
    }
};

const baseApiActions = (dataProvider, resourceName) => {
    return {
        post: (data, config) => $http.post(basePath(dataProvider) + '/' + resourceName, data, config),
        get: (config, query = '') => $http.get(basePath(dataProvider) + '/' + resourceName + query, config),
        getById: (id, config) => $http.get(basePath(dataProvider) + '/' + resourceName + '/' + id, config),
        put: (id, data, config) => $http.put(basePath(dataProvider) + '/' + resourceName + '/' + id, data, config),
        patch: (id, data, config) => $http.patch(basePath(dataProvider) + '/' + resourceName + '/' + id, data, config),
        delete: (id, config) => $http.delete(basePath(dataProvider) + '/' + resourceName + '/' + id, config),
    };
};

let accounts = {
    // Custom endpoints
    getPortal: (data, config) => {
        config = { headers: { Accept: 'application/ld+json' } };

        return $http.get('portal/system/accounts', config);
    },
};

export const $api = {
    $http, // Used for making CRUD calls that you do not want to add to the $api for some reason.  Use if you must.
    $httpFhirApi,
    $log,
    auth,
    patientApi,
    personApi,
    organizationApi,
    systemSearchApi,
    subscriptions,
    roles: { ...baseApiActions('account', 'roles'), ...roles },
    users: { ...baseApiActions('account', 'account_users'), ...users },
    accounts: { ...baseApiActions('system', 'accounts'), ...accounts },
    programs: { ...baseApiActions('account', 'programs') },
    contacts: { ...baseApiActions('account', 'contacts') },
    conversations: { ...baseApiActions('account', 'conversations') },
    messages: { ...baseApiActions('account', 'messages') },
};

export default {
    install(app) {
        // Add BestNotes API to Vue
        app.config.globalProperties.$api = $api;
        app.config.globalProperties.$log = $log;
    },
};
