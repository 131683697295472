import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Coding from './types/Coding';
import Reference from './types/Reference';
import BN_ClaimProfilePOSOverrideItem from '@/fhirworks/BN_ClaimProfilePOSOverrideItem';

export default class BN_ClaimProfile extends DomainResource {
    static __className = 'BN_ClaimProfile';

    __objectStructure = {
        claimType: Coding, // Professional,Institutional
        payer: Reference,
        location: [Reference],
        provider: Reference,
        billingAs: Coding, // Group, Individual
        overrideReferringProvider: Reference,
        referringProviderSecondaryIdentification: Coding,
        referringProviderReferenceIdentification: String,
        overridePlaceOfService: [BN_ClaimProfilePOSOverrideItem],
        renderingProviderSecondaryIdentification: Coding,
        renderingProviderReferenceIdentification: String,
        federalTaxIDNumber: Coding,
        acceptsAssignment: Coding,
        facilityLocationSecondaryIdentification: Coding,
        facilityLocationReferenceIdentification: String,
        billingProviderSecondaryIdentifier: Coding,
        billingProviderReferenceIdentification: String,
        active: Boolean,
    };

    constructor(constructJson, className = 'BN_ClaimProfile') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
