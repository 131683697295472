import 'vuetify/styles';
import { createVuetify } from 'vuetify';

import colors from 'vuetify/lib/util/colors';

import { aliases, custom } from '@/icons-custom.js';

const lightTheme = {
    dark: false,
    colors: {
        primary: colors.indigo.base,
        secondary: colors.indigo.lighten1,
        tertiary: colors.indigo.lighten3,
        accent: colors.indigo.accent2,
        disabled: colors.grey.lighten1,
        grayBackground: colors.grey.lighten4,
        success: colors.green.darken1,
        warning: colors.amber.darken1,
        info: colors.blue.base,
        pending: colors.deepPurple.lighten2,
        error: colors.red.darken3,
        inProgress: colors.lightGreen.darken1,
        user: colors.purple.base,
        practitioner: colors.blue.darken2,
        patient: colors.red.base,
        related: colors.teal.base,
        organization: colors.brown.base,
        'bn-color-list-group': colors.grey.lighten4,
        'bn-color-list-item': colors.deepPurple.base,
        'bn-color-list-filter': colors.grey.lighten3,
    },
};

const vuetify = createVuetify({
    theme: {
        defaultTheme: 'lightTheme',
        themes: {
            lightTheme,
        },
    },
    icons: {
        defaultSet: 'custom',
        aliases,
        sets: {
            custom,
        },
    },
    defaults: {
        global: {
            density: 'compact',
        },
        VBtn: {
            density: 'default',
        },
        VToolbar: {
            color: 'white',
        },
        VCard: {
            border: true,
            variant: 'flat',
        },
        VTabs: {
            density: 'default',
            color: 'primary',
        },
        VTab: {
            density: 'default',
            height: '100%',
        },
        VChip: {
            density: 'default',
            variant: 'flat',
            size: 'x-small',
        },
        VAlert: {
            variant: 'outlined',
        },
    },
});

export default vuetify;
