import BasicLayout from '@/views/layouts/BasicLayout.vue';
import { useAuthStore } from '@/stores/auth';

export default [
    {
        path: '/login',
        redirect: { name: 'login' },
    },
    {
        path: '/logout',
        redirect: { name: 'logout' },
    },
    {
        path: '/auth',
        name: 'auth',
        component: BasicLayout,
        children: [
            {
                path: 'login',
                name: 'login',
                meta: { authRequired: false },
                component: () => import('../views/auth/LoginPage.vue'),
            },
            {
                path: 'logout',
                name: 'logout',
                meta: { authRequired: false },
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                meta: { authRequired: false },
                component: () => import('../views/auth/ForgotPasswordPage.vue'),
            },
            {
                path: 'lockout',
                name: 'lockout',
                meta: { authRequired: false },
                props: true,
                component: () => import('../views/auth/LockoutPage.vue'),
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                meta: { authRequired: false },
                component: () => import('../views/auth/ResetPasswordPage.vue'),
            },
            {
                path: 'accept-invitation',
                name: 'accept-invitation',
                meta: { authRequired: false },
                props: true,
                component: () => import('../views/auth/AcceptInvitationPage.vue'),
            },
            {
                path: 'account-selection',
                name: 'account-selection',
                props: true,
                component: () => import('../views/auth/AccountSelectionPage.vue'),
            },
            {
                path: 'dev-management',
                name: 'dev-management',
                component: () => import('../views/DevManagement.vue'),
            },
        ],
    },
];
