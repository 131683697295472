import * as allConsturctors from './index';

const fhirClasses = new Map([
    ['Account', 'Account'],
    ['AccountCoverage', 'AccountCoverage'],
    ['AccountGuarantor', 'AccountGuarantor'],
    ['Address', 'Address'],
    ['AllergyIntolerance', 'AllergyIntolerance'],
    ['Annotation', 'types/Annotation'],
    ['Appointment', 'Appointment'],
    ['AppointmentParticipant', 'AppointmentParticipant'],
    ['BN_EncounterType', 'BN_EncounterType'],
    ['BN_Audit', 'BN_Audit'],
    ['BN_ClaimProfile', 'BN_ClaimProfile'],
    ['BN_EdiConfiguration', 'BN_EdiConfiguration'],
    ['BN_ERA', 'BN_ERA'],
    ['BN_PayorAddress', 'BN_PayorAddress'],
    ['BN_FinancialClass', 'BN_FinancialClass'],
    ['BN_File', 'BN_File'],
    ['BN_LedgerTransaction', 'BN_LedgerTransaction'],
    ['BN_List', 'BN_List'],
    ['BN_ListItem', 'BN_ListItem'],
    ['BN_Payment', 'BN_Payment'],
    ['BN_PatientAccountSummary', 'BN_PatientAccountSummary'],
    ['BN_PostPayment', 'BN_PostPayment'],
    ['BN_PaymentPostingConfiguration', 'BN_PaymentPostingConfiguration'],
    ['BN_PriorAuthorization', 'BN_PriorAuthorization'],
    ['BN_ProfileAsset', 'BN_ProfileAsset'],
    ['BN_ProfileOwner', 'BN_ProfileOwner'],
    ['BN_ProgramProfile', 'BN_ProgramProfile'],
    ['BN_ProgramProfileItem', 'BN_ProgramProfileItem'],
    ['BN_Relationship', 'BN_Relationship'],
    ['BN_SuperBill', 'BN_SuperBill'],
    ['BN_SuperBillLineItem', 'BN_SuperBillLineItem'],
    ['BN_PaymentTransaction', 'BN_PaymentTransaction'],
    ['BN_TreatmentPlanLibraryCategory', 'BN_TreatmentPlanLibraryCategory'],
    ['BN_TreatmentPlanLibraryGoal', 'BN_TreatmentPlanLibraryGoal'],
    ['BN_TreatmentPlanLibraryObjective', 'BN_TreatmentPlanLibraryObjective'],
    ['Bundle', 'Bundle'],
    ['CarePlan', 'CarePlan'],
    ['CareTeam', 'CareTeam'],
    ['CareTeamParticipant', 'CareTeamParticipant'],
    ['Claim', 'Claim'],
    ['ClinicalImpression', 'ClinicalImpression'],
    ['Concept', 'Concept'],
    ['Condition', 'Condition'],
    ['ConditionEvidence', 'ConditionEvidence'],
    ['ConditionStage', 'ConditionStage'],
    ['Coding', 'types/Coding'],
    ['CodeableConcept', 'types/CodeableConcept'],
    ['Consent', 'Consent'],
    ['Coverage', 'Coverage'],
    ['CoverageCostToBeneficiary', 'CoverageCostToBeneficiary'],
    ['CoverageEligibilityRequest', 'CoverageEligibilityRequest'],
    ['CoverageEligibilityRequestInsurance', 'CoverageEligibilityRequestInsurance'],
    ['CoverageEligibilityRequestItem', 'CoverageEligibilityRequestItem'],
    ['CoverageEligibilityRequestItemDiagnosis', 'CoverageEligibilityRequestItemDiagnosis'],
    ['CoverageEligibilityRequestSupportingInfo', 'CoverageEligibilityRequestSupportingInfo'],
    ['CoverageEligibilityResponse', 'CoverageEligibilityResponse'],
    ['CoverageEligibilityResponseError', 'CoverageEligibilityResponseError'],
    ['CoverageEligibilityResponseInsurance', 'CoverageEligibilityResponseInsurance'],
    ['CoverageEligibilityResponseInsuranceItem', 'CoverageEligibilityResponseInsuranceItem'],
    ['CoverageEligibilityResponseInsuranceItemBenefit', 'CoverageEligibilityResponseInsuranceItemBenefit'],
    ['DocumentReference', 'DocumentReference'],
    ['Encounter', 'Encounter'],
    ['EncounterParticipant', 'EncounterParticipant'],
    ['EpisodeOfCare', 'EpisodeOfCare'],
    ['EpisodeOfCareStatusHistory', 'EpisodeOfCareStatusHistory'],
    ['EpisodeOfCareDiagnosis', 'EpisodeOfCareDiagnosis'],
    ['Goal', 'Goal'],
    ['Group', 'Group'],
    ['FamilyMemberHistory', 'FamilyMemberHistory'],
    ['HealthcareService', 'HealthcareService'],
    ['Linkage', 'Linkage'],
    ['List', 'List'],
    ['Location', 'Location'],
    ['MedicationStatement', 'MedicationStatement'],
    ['MedicationRequest', 'MedicationRequest'],
    ['Money', 'types/Money'],
    ['Observation', 'Observation'],
    ['Organization', 'Organization'],
    ['OrganizationContact', 'OrganizationContact'],
    ['Patient', 'Patient'],
    ['PatientCommunication', 'PatientCommunication'],
    ['Period', 'types/Period'],
    ['Person', 'Person'],
    ['Practitioner', 'Practitioner'],
    ['PractitionerRole', 'PractitionerRole'],
    ['Procedure', 'Procedure'],
    ['Provenance', 'Provenance'],
    ['Reference', 'types/Reference'],
    ['RelatedPerson', 'RelatedPerson'],
    ['ServiceRequest', 'ServiceRequest'],
    ['Signature', 'Signature'],
    ['Task', 'Task'],
    ['User', 'User'],
    ['ValueSet', 'ValueSet'],
    ['BN_Role', 'BN_Role'],
    ['BN_AccessGroup', 'BN_AccessGroup'],
    ['Questionnaire', 'Questionnaire'],
    ['QuestionnaireResponse', 'QuestionnaireResponse'],
    ['BN_QuestionnaireField', 'BN_QuestionnaireField'],
]);

const constructorCache = new Map();

const createFhirResource = (resourceType, resourceJson = {}) => {
    resourceType = resourceType?.toString() || resourceJson?.resourceType;
    let constructor = undefined;

    if (constructorCache.has(resourceType)) {
        constructor = constructorCache.get(resourceType);
    } else if (fhirClasses.has(resourceType)) {
        // we pull all the constructors from index.js so we don't have to import asyncronously

        if (fhirClasses.get(resourceType).startsWith('types/')) {
            let name = fhirClasses.get(resourceType).slice(6);
            constructor = allConsturctors['types'][name];
        } else {
            constructor = allConsturctors[fhirClasses.get(resourceType)];
        }

        constructorCache.set(resourceType, constructor);
    }

    return constructor ? new constructor(resourceJson) : resourceJson;
};

export default createFhirResource;
