<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
import { IonPage, IonContent } from '@ionic/vue';
import BnAvatar from '@/components/BnAvatar.vue';
import BnContactSearch from '@/components/BnContactSearch.vue';
import BnMyAccount from '@/components/account/BnMyAccount.vue';
import BnContactEditDialog from '@/components/contacts/BnContactEditDialog.vue';
import BnButton from '@/components/BnButton.vue';
import BnMenu from '@/components/BnMenu.vue';
import BnMenuItem from '@/components/BnMenuItem.vue';
import BnDialog from '@/components/BnDialog.vue';

import { onClickOutside } from '@vueuse/core';
import startCase from 'lodash/startCase';
import { useUsersnap } from '@/composables/useUsersnap';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';
import BnFooter from '@/components/BnFooter';
import { removeNativePreviousNavigations } from '@/common/nativeBackButton';
import { addRefToBackBtnListiner, removeRefToBackBtnListiner } from '@/common/nativeBackButton';
import { Capacitor } from '@capacitor/core';

import { useDisplay } from 'vuetify';
const vuetifyDisplay = useDisplay();

const app = getCurrentInstance();
const isPortal = app.appContext.config.globalProperties?.isPortal;

import { useRoute } from 'vue-router';
const route = useRoute();
const authStore = useAuthStore();

const platform_ios = ref(true);
const drawer = ref(true);
const searchMenu = ref(false);

// template refs
const contactMobileSearch = ref(null);
const contactSearch = ref(null);
const bnDialogRef = ref(null);
const myAccountDialog = ref(null);
const navDrawerMain = ref(null);
const contactEditDialog = ref(null);

import { useNavBar } from '@/composables/useNavBar';
import { useStateFlow } from '@/common/workflowEngine/useStateFlow';
const { keepDrawerOpen, secondNavWidth } = useNavBar();

const mainNavWidth = 80;
const topBarHeight = 58;

const navItemsEhr = [
    {
        title: 'Home',
        icon: ['far', 'columns'],
        route: { name: 'dashboard' },
        activeRoutes: ['dashboard'],
    },
    {
        title: 'Contacts',
        icon: ['far', 'users'],
        route: { name: 'contacts' },
        activeRoutes: ['contacts', 'patient', 'practitioner', 'organization'],
    },
    {
        title: 'Calendar',
        icon: ['far', 'calendar-alt'],
        route: { name: 'calendar' },
        activeRoutes: ['calendar'],
        keepOpen: true,
    },
    {
        title: 'Reporting',
        icon: ['far', 'analytics'],
        route: { name: 'reports' },
        activeRoutes: ['reports'],
        keepOpen: true,
    },
    {
        title: 'Financial',
        icon: ['far', 'file-invoice-dollar'],
        route: { name: 'financial-page' },
        activeRoutes: ['financial'],
        keepOpen: false,
        devOnly: Capacitor.isNativePlatform() ? true : false,
    },
    {
        title: 'Payment',
        icon: ['far', 'money-check-alt'],
        route: { name: 'payment-reconciliation-page' },
        activeRoutes: ['payment-reconciliation'],
        keepOpen: false,
        devOnly: Capacitor.isNativePlatform() ? true : false,
    },
    {
        title: 'Settings',
        icon: ['far', 'cog'],
        route: { name: 'settings' },
        activeRoutes: ['settings'],
        keepOpen: true,
    },
    {
        title: 'Messages',
        icon: ['far', 'comment-alt'],
        route: { name: 'messages' },
        activeRoutes: ['messages'],
        devOnly: true,
    },
    {
        title: 'Batch',
        icon: ['fas', 'dollar-sign'],
        route: { name: 'batch-page' },
        activeRoutes: ['batch-eligibility'],
        devOnly: true,
    },
];

const navItemsPortal = [
    {
        title: 'Home',
        icon: ['fas', 'columns'],
        route: { name: 'dashboard' },
    },
];

const navDrawerContent = ref(null);
const twoNavs = ref(false);

let observer;

const setActiveAgency = async () => {
    let timeout = setTimeout(() => {
        setActiveBrand();
        clearTimeout(timeout);
        timeout = null;
    }, 250);
};

onMounted(() => {
    const { initializeUsersnap } = useUsersnap();
    // this.savingGetter = route.meta.savingGetter || 'isSaving';

    if (vuetifyDisplay.smAndDown.value) {
        drawer.value = false;
    }

    if (!vuetifyDisplay.xs.value) {
        initializeUsersnap();
    }

    window.addEventListener('defaultLayout.setActiveAgency', setActiveAgency);

    observer = new MutationObserver(() => {
        twoNavs.value = !!navDrawerContent.value?.children?.length;
    });

    observer.observe(navDrawerContent.value, { childList: true, subtree: true });
});

onBeforeUnmount(() => {
    window.removeEventListener('defaultLayout.setActiveAgency', setActiveAgency);
    observer?.disconnect();
});

// when clicking outside mobile search bar close search bar,  Need to set timeout for code to execute when opening a patient
onClickOutside(contactMobileSearch, () => {
    let timeout = setTimeout(() => {
        searchMenu.value = false;
        clearTimeout(timeout);
        timeout = null;
    }, 250);
});

const brandName = computed(() => {
    return authStore.brand?.name || authStore.organizationAccount?.name;
});

const location = computed(() => {
    return authStore.brand?.location;
});

const brandLogo = computed(() => {
    return authStore.brand?.logo || authStore.account.logo;
});

const userBrands = computed(() => {
    return authStore.userAgencies;
});

const multipleBrandsLocations = computed(() => {
    return userBrands.value?.length > 1 || userBrands.value.some((item) => item.locations?.length > 1);
});

const routes = computed(() => {
    let endRoutes = [];
    let computedRoutes = [];
    let routes = route.matched.filter((r) => r.name || r.meta.label || r.meta.path);

    routes.forEach((r) => {
        if (r.meta.path) {
            r.meta.path.forEach((val) => endRoutes.push(router.resolve({ name: val }).route));
        }

        endRoutes.push(r);
    });

    endRoutes.forEach((route) => {
        computedRoutes.push({
            text: getTitle(route),
            href: route.path,
            disabled: false,
            rawRoute: route,
        });
    });

    // disable last entry
    computedRoutes[computedRoutes.length - 1].disabled = true;

    return computedRoutes;
});

const isSaving = computed(() => {
    return false;
    // return this.$store.getters[this.savingGetter];
});

const navItems = computed(() => {
    if (isPortal) {
        return navItemsPortal;
    } else {
        return navItemsEhr;
    }
});

const browserTitle = computed(() => {
    let routeArray = Array.from(routes).reverse();
    let titleAddArray = routeArray.filter((i) => i.rawRoute.meta.includeInTitle).map((i) => i.rawRoute.meta.label || startCase(i.rawRoute.name));
    titleAddArray.unshift(getTitle());
    let newTitle = titleAddArray.length ? titleAddArray.join(' · ') : '';
    document.title = newTitle + ' | BestNotes';
    return newTitle;
});

const avatarResource = computed(() => {
    return {
        resourceType: 'Organization',
        logo: brandLogo.value,
        name: brandName.value,
    };
});

/**
 * Watchers
 */
watch(
    () => vuetifyDisplay.smAndDown,
    (newVal) => {
        drawer.value = !newVal;
    },
);

watch(drawer, () => {
    if (keepDrawerOpen.value) {
        drawer.value = true;
        keepDrawerOpen.value = false;
        navDrawerMain.value.isActive = true;
    }
});

/**
 * Methods
 */

const { showUsersnap } = useUsersnap();

const signOut = async () => {
    const options = {
        header: 'Sign out',
        message: 'Are you sure you want to sign out?',
        confirm: 'Sign out',
        cancel: 'Cancel',
        width: 400,
    };
    bnDialogRef.value.show(options).then(async (confirm) => {
        if (!confirm) return;
        if (!isPortal) {
            const { resetApplicationState } = useStateFlow();
            await resetApplicationState({ practitioner: authStore.practitioner });
        }
        router.push({ name: 'logout' });
    });
};

const setActiveBrand = (brandLocation) => {
    authStore.setBrand(brandLocation);
};

const addContact = (item) => {
    const options = {
        header: 'Add contact',
        persistent: true,
    };
    const props = {
        add: item,
    };
    addRefToBackBtnListiner(contactEditDialog, 'close', 'contactEditDialog');
    contactEditDialog.value.show(options, props).then(async (resource) => {
        removeRefToBackBtnListiner('contactEditDialog');
        if (resource) {
            router.push({ name: resource.resourceType.toLowerCase(), params: { id: resource.id, activeTab: 'contact' } });
        }
    });
    contactSearch.value.search = '';
    searchMenu.value = false;
};

const openContact = (item) => {
    searchMenu.value = false;
    router.push({ name: item.resourceType.toLowerCase(), params: { id: item.id } });
};

const getTitle = (r) => {
    if (!r) {
        r = route;
    }

    return r.meta.label || startCase(r.name);
};

const routeToPage = (item) => {
    // only navigate if the route has actually changed (avoid duplicate navigation error)
    if (item.route.name !== route.name) {
        router.push(item.route);

        if (!item.keepOpen && vuetifyDisplay.smAndDown.value === true) {
            drawer.value = false;
        }
    }
    removeNativePreviousNavigations();
};

const filterOutPayers = (e) => {
    if (e.resourceType === 'Organization') {
        return !e?.type?.some((f) => f.coding.some((i) => i.code === 'pay'));
    }
    return true;
};

const setDrawer = () => {
    drawer.value = !drawer.value;
};

const closeDrawer = () => {
    if (vuetifyDisplay.smAndDown.value) {
        drawer.value = false;
    }
};
</script>

<template>
    <ion-page>
        <div style="display: contents">
            <v-navigation-drawer
                ref="navDrawerMain"
                order="2"
                :absolute="!vuetifyDisplay.smAndDown.value"
                v-model="drawer"
                theme="dark"
                :permanent="!vuetifyDisplay.smAndDown.value"
                :temporary="vuetifyDisplay.smAndDown.value"
                rail
                :rail-width="mainNavWidth"
                class="pt-1"
                :class="{ openMainDrawer: drawer }"
                :style="`z-index: 1100; overflow-x: hidden; top: ${topBarHeight}px;`"
                disable-route-watcher
            >
                <v-list two-line class="text-center">
                    <v-list-item
                        v-for="item in navItems.filter((e) => ($devOnly() ? true : !e.devOnly))"
                        :key="item.title"
                        @click="routeToPage(item)"
                        :active="item.activeRoutes?.some((ar) => $route.path.includes(ar))"
                        class="px-0 justify-center"
                        active-class="border-left"
                        color="accent"
                    >
                        <font-awesome-icon :icon="item.icon" size="lg" style="color: white" />
                        <v-list-item-title class="text-caption text-white" style="font-size: 12px">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>

            <v-navigation-drawer
                ref="navDrawer"
                order="2"
                v-model="drawer"
                :permanent="!vuetifyDisplay.smAndDown.value"
                :temporary="vuetifyDisplay.smAndDown.value"
                :scrim="false"
                :absolute="!vuetifyDisplay.smAndDown.value"
                disable-route-watcher
                :width="twoNavs ? secondNavWidth : 0"
                :style="`z-index: 1100; overflow-x: hidden; top: ${topBarHeight}px;`"
                :class="{ ios_header_padding: platform_ios, openSecondaryDrawer: drawer, 'd-none': !twoNavs }"
            >
                <v-row class="flex-nowrap" no-gutters>
                    <v-list class="d-flex flex-column scroll-on">
                        <div @click="closeDrawer" id="navDrawer" ref="navDrawerContent"></div>
                    </v-list>
                </v-row>
            </v-navigation-drawer>

            <ion-header class="ion-no-border">
                <ion-toolbar>
                    <v-app-bar order="1" density="comfortable" flat color="#f5f5f5" short ref="appBar" style="z-index: 110">
                        <!-- Small devices - display navigation hamburger -->
                        <div class="ml-3">
                            <bn-button v-if="vuetifyDisplay.smAndDown.value" icon="bars" @click="setDrawer" />
                            <!-- Large devices - always display search -->
                            <div v-if="!isPortal && !vuetifyDisplay.smAndDown.value" style="width: 300px" class="mr-2 flex-grow-1">
                                <bn-contact-search
                                    variant="outlined"
                                    flat
                                    default-query="recent"
                                    density="compact"
                                    ref="contactSearch"
                                    label=""
                                    placeholder="Search/add contacts"
                                    @addNewClicked="addContact"
                                    @itemClicked="openContact"
                                    :filter="filterOutPayers"
                                    data-cy="primaryContactSearch"
                                />
                            </div>
                        </div>

                        <!-- All devices - Show active brand with selector if required -->
                        <div class="d-flex align-center text-truncate">
                            <bn-avatar v-if="vuetifyDisplay.smAndUp.value" :resource="avatarResource" no-badge is-public size="36"></bn-avatar>
                            <div class="text-truncate ml-2" data-cy="brandName">
                                {{ brandName }}
                                <div class="text-caption mt-n1" v-if="location">
                                    <font-awesome-icon :icon="['far', 'map-marker-alt']" />
                                    {{ location.display || getDisplay(location) }}
                                </div>
                            </div>
                            <template v-if="multipleBrandsLocations">
                                <bn-menu offset-y left :icon="['fas', 'caret-down']" icon-size="lg" class="ml-2">
                                    <template v-for="brand in userBrands" :key="brand.id">
                                        <bn-menu-item class="py-0" disabled :label="brand.name || getDisplay(brand)" icon="building" />
                                        <template v-for="brandLocation in brand.locations" :key="brandLocation.id">
                                            <bn-menu-item
                                                class="pl-6 py-0"
                                                icon="map-marker-alt"
                                                :label="brandLocation.display || getDisplay(brandLocation)"
                                                @click="setActiveBrand({ id: brand.id, name: brand.name, location: brandLocation })"
                                            />
                                        </template>
                                    </template>
                                </bn-menu>
                            </template>
                        </div>
                        <v-spacer></v-spacer>

                        <!-- Small devices - move search to button icon -->
                        <template v-if="!isPortal && vuetifyDisplay.smAndDown.value">
                            <v-menu v-model="searchMenu" :close-on-content-click="false" offset-y min-width="320">
                                <template #activator="{ props }">
                                    <v-btn v-bind="props" icon>
                                        <font-awesome-icon :icon="['far', 'search']" size="lg" />
                                    </v-btn>
                                </template>
                                <v-card ref="contactMobileSearch" min-width="300" class="mx-auto fill-width" variant="outlined">
                                    <bn-contact-search
                                        autofocus
                                        default-query="recent"
                                        density="compact"
                                        variant="outlined"
                                        :filter="filterOutPayers"
                                        label=""
                                        placeholder="Search contacts"
                                        ref="contactSearch"
                                        @addNewClicked="addContact"
                                        @itemClicked="openContact"
                                        data-cy="primaryContactSearch"
                                    />
                                </v-card>
                            </v-menu>
                        </template>
                        <v-menu>
                            <template #activator="{ props }">
                                <v-btn v-bind="props" rounded large class="ml-2 text-capitalize" text :icon="vuetifyDisplay.smAndDown.value" data-cy="userMenu">
                                    <span class="mr-2" v-if="!vuetifyDisplay.smAndDown.value"> Hi, {{ authStore.firstName }} </span>
                                    <bn-avatar :resource="authStore.aidboxPersonResource" no-badge is-public size="36" />
                                </v-btn>
                            </template>

                            <v-card class="mx-auto" outlined>
                                <picture>
                                    <source srcset="../../assets/img/docks_344x200.webp" type="image/webp" />
                                    <img src="../../assets/img/docks_344x200.jpg" alt="Docks" style="width: 344px; height: 200px" />
                                </picture>
                                <v-card-title class="mt-n16" style="color: white">{{ authStore.fullName || 'Unnamed User' }}</v-card-title>

                                <v-spacer></v-spacer>

                                <v-list>
                                    <v-list-item @click="$refs.myAccountDialog.show()">
                                        <template #prepend>
                                            <font-awesome-icon class="mr-2" :icon="['far', 'user-cog']" />
                                        </template>
                                        <v-list-item-title>My BestNotes account</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item v-if="authStore.showSwitchAccount" @click="router.push({ name: 'account-selection' })">
                                        <template #prepend>
                                            <font-awesome-icon class="mr-2" :icon="['far', 'random']" />
                                        </template>
                                        <v-list-item-title>Switch account</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item @click="showUsersnap">
                                        <template #prepend>
                                            <font-awesome-icon class="mr-2" :icon="['far', 'bullhorn']" />
                                        </template>
                                        <v-list-item-title>Feedback</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item @click="router.push({ name: 'lockout' })" data-cy="lockApplication">
                                        <template #prepend>
                                            <font-awesome-icon class="mr-2" :icon="['far', 'user-lock']" />
                                        </template>
                                        <v-list-item-title>Lock application</v-list-item-title>
                                    </v-list-item>
                                </v-list>

                                <v-card-actions class="p-2 d-flex flex-row-reverse">
                                    <v-btn @click="signOut" color="primary" variant="outlined">Sign out</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>

                        <div style="position: absolute; top: 74px; right: 16px">
                            <v-chip v-if="false" color="error">
                                <font-awesome-icon :icon="['fal', 'exclamation-triangle']"></font-awesome-icon>
                                <span class="ml-2">Retry has timed out after 5 minutes.</span>
                                <v-btn ref="retrySaves" @click="retrySaves" text color="white" class="ml-1">Retry</v-btn>
                            </v-chip>

                            <!--                        <v-chip v-else-if="hasNetworkIssues" color="error">-->
                            <!--                            <font-awesome-icon :icon="['fal', 'exclamation-triangle']"></font-awesome-icon>-->
                            <!--                            <span class="ml-2">Network Connectivity Issues, Retrying...</span>-->
                            <!--                        </v-chip>-->

                            <v-chip v-else-if="isSaving">
                                <font-awesome-icon :icon="['far', 'upload']"></font-awesome-icon>
                                <span class="ml-2">Saving...</span>
                            </v-chip>

                            <!--                        <v-chip v-else>-->
                            <!--                            <font-awesome-icon :icon="['far', 'save']"></font-awesome-icon>-->
                            <!--                            <span class="ml-2">Changes Saved</span>-->
                            <!--                        </v-chip>-->
                        </div>
                    </v-app-bar>
                </ion-toolbar>
            </ion-header>

            <ion-content>
                <v-main class="fill-height pt-0">
                    <v-container fluid class="fill-height align-baseline" :class="{ 'pa-0': vuetifyDisplay.smAndDown.value }">
                        <div style="width: inherit; height: inherit">
                            <router-view v-slot="{ Component }">
                                <transition name="fade" mode="out-in">
                                    <component :is="Component" />
                                </transition>
                            </router-view>
                        </div>
                        <!--Lower Notch Padding Protection-->
                        <br v-if="vuetifyDisplay.smAndDown.value" style="clear: both" />
                    </v-container>
                </v-main>
            </ion-content>

            <bn-contact-edit-dialog v-if="!isPortal" ref="contactEditDialog" :auth-store="authStore" />

            <bn-dialog ref="bnDialogRef" />

            <bn-my-account ref="myAccountDialog" :is-portal="isPortal" :full-name="authStore.fullName" />
            <div class="d-none">{{ browserTitle }}</div>

            <!-- ios ample touch footer bar -->
            <bn-footer></bn-footer>
        </div>
    </ion-page>
</template>

<style lang="scss">
@import '@/assets/scss/bn.scss';

.ml-80 {
    margin-left: 80px;
}

.openMainDrawer {
    left: 0 !important;
}

.openSecondaryDrawer {
    left: 80px !important;
}

.border-left {
    border-left: 2px solid #5867dd;
}

/* Hacky fixes to get Ionic and Vuetify to work together */
ion-header {
    z-index: 110;
}

.v-app-bar {
    position: relative !important;
}
/* End Hacky Fixes */
</style>
