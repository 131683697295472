import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Coding from './types/Coding';
import Identifier from './types/Identifier';
import Address from './Address';
import Reference from './types/Reference';
import Money from './types/Money';
import HumanName from './HumanName';
import ContactPoint from './ContactPoint';
import BN_ERA_PatientProcedureCodeInfo from './BN_ERA_PatientProcedureCodeInfo';
import BN_ERA_glossary from './BN_ERA_glossary';
import BN_ERA_plbProviderAdjustmentInfo from './BN_ERA_plbProviderAdjustmentInfo';
import Period from './types/Period';

export default class BN_ERA extends DomainResource {
    static __className = 'BN_ERA';

    __objectStructure = {
        fileName: String,
        interchangeIdQualifierISA05: Coding,
        interchangeSenderIdISA06: String,
        interchangeIdQualifierISA07: Coding,
        interchangeRecieverIdISA08: String,
        interchangeDateISA09: String,
        acknowledgmentRequestedISA14: Coding,
        interchangeUsageIndicatorISA15: Coding,
        carrierContractorNameN102: String,
        carrierContractorAddressN301: Address,
        carriersWebsite: Coding,
        carriersBusinessContactInformation: Coding,
        carrierTelephoneNumber: String,
        providerName: String,
        providerAddress: Address,
        npiNumber: String,
        applicationSendersCodeGS02: String,
        applicationRecieversCodeGS03: String,
        dateGS04: String,
        industryIdentifierCodeGS08: String,
        transactionSetControlNumberST02: String,
        transactionHandlingCodeBPR01: Coding,
        checkAmountBPR02: Money,
        creditDebitFlagCodeBPR03: Coding,
        paymentMethodCodeBPR04: Coding,
        paymentFormatCodeBPR05: Coding,
        idNumberQualifierBPR06: Coding,
        identificationNumberBPR07: String,
        accountNumberQualifierBPR08: Coding,
        accountNumberBPR09: String,
        originatingCompanyIdentifierBPR10: String,
        originatingCompanySupplementalCodeBPR11: String,
        idNumberQualifierBPR12: Coding,
        identificationNumberBPR13: String,
        accountNumberQualifierBPR14: Coding,
        accountNumberBPR15: String,
        checkDateBPR16: String,
        checkEftNumberTRN02: String,
        comment: String,
        numberOfClaims: Number,
        importedDate: String,
        status: String,
        reconciled: Boolean,
        posted: Number,
        createdBy: Reference, // Organization,PractitionerclaimStatusCode
        patientID: ContactPoint,
        patientAccountNumber: String,
        claimControlNumber: String,
        carrierContractorAddress: Address,
        claimAssignment: Boolean,
        patientProcedureCodeInfo: [BN_ERA_PatientProcedureCodeInfo],
        placeOfService: Coding,
        claimLevelRemarkCodes: [Coding],
        renderingProvider: Coding,
        glossary: [BN_ERA_glossary],
        plbProviderAdjustmentInfo: [BN_ERA_plbProviderAdjustmentInfo],
        matched: Boolean,
        claimStatusCode: Coding,
        payerId: String,
        crossoverCarrierName: String,
        crossoverCarrierIdentifier: String,
        patientFirstName: String,
        patientMiddleName: String,
        patientLastName: String,
        carrierTelecom: [Coding],
        senderIdNumberQualifier: Coding,
        senderIdentificationNumber: String,
        senderAccountNumberQualifier: Coding,
        senderAccountNumber: String,
        originatingCompanyIdentifier: String,
        originatingCompanySupplementalCode: String,
        receiverIdNumberQualifier: Coding,
        receiverIdentificationNumber: String,
        receiverAccountNumberQualifier: Coding,
        receiverAccountNumber: String,
        systemPatientID: String,
        systemPatientAccountNumber: String,
        serviceDate: Period,
        netAmount: Money,
        totalChargeAmountClp03: Money,
        patientResponsibility: Money,
        claimInformationForwardedTo: String,
        unmatched: Number,
        readyToPost: Number,
        partOf: [Reference],
        applicationReceiversCode: String,
        queueStatus: Boolean,
        lastPostedDate: String,
        lastPostedBy: String,
    };

    constructor(constructJson, className = 'BN_ERA') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
