import merge from 'lodash/merge';
import BackboneElement from './BackboneElement';
import Reference from '@/fhirworks/types/Reference';
import QuestionnaireResponseItemAnswer from './QuestionnaireResponseItemAnswer';
import QuestionnaireResponseItem_text from '@/fhirworks/QuestionnaireResponseItem_text';
import { htmlToText, isHtml } from '@/common/core';

const signatureExtensionPropName = 'questionnaireresponseitem-signature';

/**
 * @property {String} linkId // R!  Pointer to specific item from Questionnaire
 * @property {String} definition // ElementDefinition - details for the item
 * @property {String} text // Name for group or question text
 * @property {QuestionnaireResponseItemAnswer} answer
 * @property {QuestionnaireResponseItem} item // Nested questionnaire response items
 */
export default class QuestionnaireResponseItem extends BackboneElement {
    static __className = 'QuestionnaireResponseItem';

    __objectStructure = {
        linkId: String, // R!  Pointer to specific item from Questionnaire
        definition: String, // ElementDefinition - details for the item
        text: String, // Name for group or question text
        _text: QuestionnaireResponseItem_text,
        answer: [QuestionnaireResponseItemAnswer],
        item: [QuestionnaireResponseItem], // Nested questionnaire response items
        /**
         * First Class extensions
         */
        auditUser: Reference,
        auditLastUpdated: String,
        auditSource: Reference,
        questionnaireField: Reference,
        hideFieldSetSection: Boolean,
    };

    constructor(constructJson, className = 'QuestionnaireResponseItem') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.__signature = this.getExtensions(signatureExtensionPropName) || [];

        this.populateCustomPropertiesFromJson(constructJson, ['signature']);

        this.originalObjJson = this.toJSON();
    }

    get bnResponseText() {
        return this._text?.renderingXhtml || this.text;
    }

    set bnResponseText(value) {
        if (!this._text) {
            this._text = new QuestionnaireResponseItem_text();
        }

        if (!value) {
            this.text = undefined;
            this._text.renderingXhtml = undefined;
            return;
        }

        if (isHtml(value)) {
            this.text = htmlToText(value);
            this._text.renderingXhtml = value;
        } else {
            this.text = value;
            this._text.renderingXhtml = undefined;
        }
    }

    // Temporarily comment Signature getter/setter
    // ----------------------------------
    // get signature() {
    //     return this.__signature;
    // }
    //
    // set signature(value) {
    //     // Assigning an array, so replace existing values
    //     if (Array.isArray(value)) {
    //         this.removeExtensionValuesByType(signatureExtensionPropName);
    //
    //         value.forEach((item) => {
    //             this.addExtensionValue(signatureExtensionPropName, item, 'Signature');
    //         });
    //
    //         let allSignatures = this.getExtensions(signatureExtensionPropName);
    //         allSignatures.forEach((item) => {
    //             this.__signature.push(item);
    //         });
    //
    //         return;
    //     }
    //
    //     // Passing undefined for a value to clear extension values
    //     if (value === undefined) {
    //         this.__signature.splice(0);
    //         this.removeExtensionValuesByType(signatureExtensionPropName);
    //         return;
    //     }
    //
    //     // Adding and item to the extensions array
    //     this.addExtensionValue(signatureExtensionPropName, value, 'Signature');
    //
    //     // Get added extension to add to signature array
    //     let allSignatures = this.getExtensions(signatureExtensionPropName);
    //
    //     // Look for the new signature and add extension to array
    //     let newSignature = allSignatures.filter((sig) => this.__signature.indexOf(sig) == -1);
    //     newSignature.forEach((newSig) => this.__signature.push(newSig));
    // }
    //
    // removeSignature(id) {
    //     let allSignatures = this.getExtensions(signatureExtensionPropName);
    //     let extensionSignaturesToRemove = allSignatures.filter((extSig) => extSig.value.who.id === id);
    //     extensionSignaturesToRemove.forEach((extSig) => {
    //         this.__signature.splice(this.__signature.indexOf(extSig), 1);
    //         this.extension.splice(this.extension.indexOf(extSig), 1);
    //     });
    // }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
