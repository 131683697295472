import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import Coding from './types/Coding';
import Period from './types/Period';

export default class BN_Relationship extends DomainResource {
    static __className = 'BN_Relationship';

    __objectStructure = {
        subject: Reference,
        related: Reference,
        relationship: [Coding],
        period: Period,
        active: Boolean,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'BN_Relationship') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
