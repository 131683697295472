import { useWorkflow } from '@/common/workflowEngine/useWorkflow';
import { crud } from '@/common/crud';
import { BN_List, BN_ListItem, BN_Relationship, Reference } from '@/fhirworks';
import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from '@/stores/auth';
import { format } from 'date-fns';
import { $httpFhirApi } from '@/common/api/httpFhir.service';

async function addContact(data, existingTransaction = false) {
    let transaction = existingTransaction || [];

    transaction.push(crud.post(data.contact));

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return transaction;
}

async function saveContact(data, existingTransaction = false) {
    let transaction = existingTransaction || [];

    if (Array.isArray(data.contact)) {
        data.contact.forEach((item) => {
            transaction.push(crud.patch(item));
        });
    } else {
        transaction.push(crud.patch(data.contact));
    }

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return transaction;
}

async function saveRelationship(data, existingTransaction = false, relationshipActiveValue = true) {
    let transaction = existingTransaction || [];

    // set primary relationship
    const codedRelationship = [];
    codedRelationship.push(data.relationship);
    if (data.emergencyContact && relationshipActiveValue) {
        codedRelationship.push({
            system: 'http://terminology.hl7.org/CodeSystem/v2-0131',
            code: 'C',
            display: 'Emergency Contact',
        });
    }
    if (data.nextOfKin && relationshipActiveValue) {
        codedRelationship.push({
            system: 'http://terminology.hl7.org/CodeSystem/v2-0131',
            code: 'N',
            display: 'Next-of-Kin',
        });
    }
    if (data.legalGuardian && relationshipActiveValue) {
        codedRelationship.push({
            system: 'http://terminology.hl7.org/CodeSystem/v2-0131',
            code: 'G',
            display: 'Legal Guardian',
        });
    }
    if (data.primary?.id) {
        const primary = data.primary.getObjectCopy();
        primary.active = relationshipActiveValue;
        primary.relationship = codedRelationship;
        transaction.push(crud.patch(primary));
    } else {
        const primaryRelationship = {
            resourceType: 'BN_Relationship',
            active: relationshipActiveValue,
            subject: data.subject,
            related: data.related,
            relationship: codedRelationship,
            period: { start: format(new Date(), 'yyyy-MM-dd') },
        };
        transaction.push(crud.post(new BN_Relationship(primaryRelationship)));
    }

    // set inverse relationship
    if (data.twoWay) {
        if (data.inverse?.id) {
            const inverse = data.inverse.getObjectCopy();
            inverse.active = relationshipActiveValue;
            inverse.relationship = [data.inverseRelationship];
            transaction.push(crud.patch(inverse));
        } else {
            const inverseRelationship = {
                resourceType: 'BN_Relationship',
                active: relationshipActiveValue,
                subject: data.related,
                related: data.subject,
                relationship: [data.inverseRelationship],
                period: { start: format(new Date(), 'yyyy-MM-dd') },
            };
            transaction.push(crud.post(new BN_Relationship(inverseRelationship)));
        }
    } else {
        // remove inverse if not set
        if (data.inverse?.id) {
            transaction.push(crud.delete(data.inverse));
        }
    }

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return transaction;
}

async function deleteRelationship(data, existingTransaction = false) {
    let transaction = existingTransaction || [];

    if (data.primary?.id) {
        transaction.push(crud.delete(data.primary));
    }
    if (data.inverse?.id) {
        transaction.push(crud.delete(data.inverse));
    }

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return transaction;
}

async function severRelationship(data, existingTransaction = false) {
    let relationshipActiveValue = false;
    return saveRelationship(data, existingTransaction, relationshipActiveValue);
}
async function unseverRelationship(data, existingTransaction = false) {
    let relationshipActiveValue = true;
    return saveRelationship(data, existingTransaction, relationshipActiveValue);
}

async function contactAccessLog(data, existingTransaction = false) {
    let transaction = existingTransaction || [];

    const authStore = useAuthStore();
    const user = authStore.practitioner;
    const newLogItem = new BN_ListItem({
        value: {
            Reference: {
                id: data.contact.id,
                resourceType: data.contact.resourceType,
            },
        },
        status: new Date().toISOString(),
    });

    // Update existing access log
    let list = await $httpFhirApi.get('BN_List?type=contactAccessLog&owner=' + user.id);
    if (list.data.length) {
        const log = list.data[0];
        // remove old entries for this contact
        log.item = log.item.filter((item) => item.value.Reference.id !== data.contact.id);
        // add new item - max 25
        log.item = [newLogItem, ...log.item].sort((a, b) => (a.status > b.status ? -1 : 1)).slice(0, 25);
        transaction.push(crud.patch(log));
    } else if (!authStore.isBnSupportUser) {
        // log doesn't exist so create it
        const id = uuidv4();
        const url = import.meta.env.VITE_PROTOCOL + authStore.account.fhirApiUri + '/BN_List/' + id;
        const newLog = new BN_List({
            id: id,
            url: url,
            type: 'contactAccessLog',
            owner: new Reference({ id: user.id, resourceType: 'Practitioner' }),
        });
        newLog.item = [newLogItem];
        transaction.push(crud.post(newLog));
    }

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return transaction;
}

export function useContactFlow() {
    return { addContact, saveContact, saveRelationship, deleteRelationship, severRelationship, unseverRelationship, contactAccessLog };
}
