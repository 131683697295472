import DomainResource from './DomainResource';
import merge from 'lodash/merge';

export default class BN_TreatmentPlanLibraryGoal extends DomainResource {
    static _className = 'BN_TreatmentPlanLibraryGoal';

    _objectStructure = {
        categoryId: String,
        text: String,
    };

    constructor(constructJson, className = 'BN_TreatmentPlanLibraryGoal') {
        super(constructJson, className);
        this.createAndPopulateStructure(this._objectStructure, constructJson, this._objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this._objectStructure));
    }
}
