import ContactPoint from './ContactPoint';
import BackboneElement from './BackboneElement';
import Address from './Address';
import CodeableConcept from './types/CodeableConcept';
import HumanName from './HumanName';

import { contactPointSystemValues, contactPointTraits, contactPointUseValues } from './trait/contactPointTraits';

import merge from 'lodash/merge';

export default class OrganizationContact extends BackboneElement {
    static __className = 'OrganizationContact';

    __objectStructure = {
        purpose: CodeableConcept,
        name: HumanName,
        telecom: [ContactPoint],
        address: Address,
    };

    // Defaulting these values duplicates the original object structure
    __objectDefaults = {
        purpose: new CodeableConcept(),
        name: new HumanName(),
        address: new Address(),
    };

    constructor(constructJson, className = 'OrganizationContact') {
        super(constructJson, className);

        contactPointTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.defaultContactPointSystemValue = contactPointSystemValues.phone;
        this.defaultContactPointUseType = contactPointUseValues.work;

        this.originalObjJson = this.toJSON();
    }

    // Properties

    // HumanNameTrait can NOT be used because the trait assume there is
    // a Name property that is an array, this object only has a single value for name
    get firstName() {
        return this.name.firstName || '';
    }

    set firstName(value) {
        this.name.firstName = value;
    }

    get middleName() {
        return this.name.middleName || '';
    }

    set middleName(value) {
        this.name.middleName = value;
    }

    get lastName() {
        return this.name.lastName || '';
    }

    set lastName(value) {
        this.name.lastName = value;
    }

    get fullName() {
        return (this.firstName + ' ' + this.lastName).trim();
    }

    get fullNameLastFirst() {
        return this.lastName + (!this.lastName || !this.firstName ? '' : ', ') + this.firstName;
    }

    // Methods

    // *******************************************************************
    // This needs to be removed, I believe it is only used in the search
    // getEmail => getPrimaryEmail, getPhone => getPrimaryPhone
    // getPhoneNumber => getPrimaryEmail.value
    // *******************************************************************

    getEmail() {
        let emailItem = this.telecom.find((telecomItem) => telecomItem.isEmail());

        if (emailItem) {
            return emailItem.value;
        }

        return null;
    }

    getPhone() {
        let phoneItem = this.telecom.find((telecomItem) => telecomItem.isPhone());

        if (phoneItem) {
            return phoneItem;
        }

        return null;
    }

    getPhoneNumber() {
        let phoneItem = this.telecom.find((telecomItem) => telecomItem.isPhone());

        if (phoneItem) {
            return phoneItem.value;
        }

        return '';
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
