import BackboneElement from './BackboneElement';
import Reference from './types/Reference';

import merge from 'lodash/merge';

export default class CoverageEligibilityRequestInsurance extends BackboneElement {
    static __className = 'CoverageEligibilityRequestInsurance';

    __objectStructure = {
        focal: Boolean, // Applicable coverage
        coverage: Reference, // Insurance information
        businessArrangement: String, // Additional provider contract number
    };

    constructor(constructJson, className = 'CoverageEligibilityRequestInsurance') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
