import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import Coding from './types/Coding';

export default class BN_PriorAuthorizationService extends BackboneElement {
    static __className = 'BN_PriorAuthorizationService';

    __objectStructure = {
        requestStartDate: String,
        requestEndDate: String,
        healthcareService: Reference, // HealthcareService
        procedureCode: String,
        placeOfService: Coding,
        units: Number,
    };

    constructor(constructJson, className = 'BN_PriorAuthorizationService') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
