import DomainResource from './DomainResource';
import Reference from './types/Reference';
import merge from 'lodash/merge';
import Coding from './types/Coding';
import BN_SuperBillLineItemDiagnosis from './BN_SuperBillLineItemDiagnosis';
import BN_SuperBillLineItemModifier from './BN_SuperBillLineItemModifier';
import CodeableConcept from './types/CodeableConcept';
import BN_SuperBillLineItemAddOn from './BN_SuperBillLineItemAddOn';

export default class BN_SuperBillLineItem extends DomainResource {
    static __className = 'BN_SuperBillLineItem';

    __objectStructure = {
        healthcareService: Reference, // "HealthcareService"
        placeOfService: Coding,
        diagnosis: [BN_SuperBillLineItemDiagnosis],
        addOn: [BN_SuperBillLineItemAddOn],
        diagnosisPointer: [CodeableConcept],
        modifier: [BN_SuperBillLineItemModifier],
        revCode: Coding,
        revCodeDescription: String,
        unit: Number,
        unitCharge: Number,
        totalCharge: Number,
        authorizationRequired: Boolean,
        nonCoveredCharges: Number,
        status: String,
        patientResponsibility: Number,
        payorResponsibility: Number,
        dateOfServiceStart: String,
        dateOfServiceEnd: String,
        superbill: Reference,

        payorPercent: Number,
        allowedCharge: Number,
        isSelected: Boolean,
        copay: Number,
        coinsurance: Number,
        deductible: Number,
        paid: Number,
        allowed: Number,
        adjustment: Number,
        withhold: Number,
        remittanceCode: Coding,
    };

    constructor(constructJson, className = 'BN_SuperBillLineItem') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
