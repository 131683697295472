import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

const previousNavigations = [];

export function addRefToBackBtnListiner(eventRef, closeMethod, refName) {
    if (Capacitor.getPlatform() == 'android') {
        // avoid duplicate navigation error
        const arrayWithFilterObjects = previousNavigations.filter((item) => item.refName === refName);
        if (arrayWithFilterObjects.length == 0) {
            let eventData = { eventRef: eventRef, closeMethod: closeMethod, refName: refName };
            previousNavigations.push(eventData);
        }
    }
}
export function registerAndroidNativeBackbtn() {
    if (Capacitor.getPlatform() == 'android') {
        App.addListener('backButton', async (canBack) => {
            if (previousNavigations.length > 0) {
                let lastElement = previousNavigations[previousNavigations.length - 1];
                if (lastElement.refName === 'questionnaireFiller') {
                    if (lastElement.eventRef.accessGroupDialog) {
                        lastElement.eventRef.accessGroupDialog = false;
                        return;
                    }
                } else if (lastElement.refName === 'contactEditDialog' || lastElement.refName === 'userDialog') {
                    let contactModals = lastElement.eventRef.$refs.contactEditHuman;
                    if (contactModals.showContactPointDialog || contactModals.showAddressDialog) {
                        contactModals.showContactPointDialog = contactModals.showAddressDialog = false;
                        return;
                    } else if (contactModals.$refs.humanData.editImage) {
                        contactModals.$refs.humanData.toggleEditImage();
                        return;
                    }
                } else if (lastElement.refName === 'dagnosisDialog') {
                    if (lastElement.eventRef.diagnosticCriteriaDialog) {
                        lastElement.eventRef.closeDiagnosticCriteriaDialog();
                        return;
                    }
                } else if (lastElement.refName === 'agencyDialog' || lastElement.refName === 'accountDialog') {
                    let settingsAccountModals = lastElement.eventRef.$refs.contactEditOrganization;
                    if (settingsAccountModals.showContactPointDialog || settingsAccountModals.showAddressDialog || settingsAccountModals.showContactDialog) {
                        settingsAccountModals.showContactPointDialog = settingsAccountModals.showAddressDialog = settingsAccountModals.showContactDialog = false;
                        return;
                    } else if (settingsAccountModals.$refs.dpOrganization.editImage) {
                        settingsAccountModals.$refs.dpOrganization.toggleEditImage();
                        return;
                    }
                } else if (lastElement.refName === 'hcsEdit') {
                    let healthCareService = lastElement.eventRef;
                    if (healthCareService.serviceDialog || healthCareService.modifierDialog || healthCareService.addonCodeDialog) {
                        healthCareService.serviceDialog = healthCareService.modifierDialog = healthCareService.addonCodeDialog = false;
                        return;
                    }
                }
                lastElement.eventRef[lastElement.closeMethod]();
                removeRefToBackBtnListiner(lastElement.refName);
            } else if (canBack) {
                window.history.go(-1);
            } else {
                return;
            }
        });
    }
}
export function removeRefToBackBtnListiner(refEvent) {
    let index = previousNavigations.findIndex((x) => x.refName == refEvent);
    if (index > -1) {
        previousNavigations.splice(index, 1);
    }
}
export function removeNativePreviousNavigations() {
    previousNavigations.length = 0;
}
export function removeBackBtnListiner() {
    App.removeAllListeners();
}
