import _ from 'lodash';
import { applyPatch, createPatch } from 'rfc6902';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { Extension } from '@/fhirworks/index';

const validFhirExtensionTypes = new Map([
    ['Address', 'Address'],
    ['Age', 'Age'],
    ['Annotation', 'Annotation'],
    ['Attachment', 'Attachment'],
    ['Base64Binary', 'Base64Binary'],
    ['Boolean', 'Boolean'],
    ['Canonical', 'Canonical'],
    ['Code', 'Code'],
    ['CodeableConcept', 'CodeableConcept'],
    ['Coding', 'Coding'],
    ['ContactDetail', 'ContactDetail'],
    ['ContactPoint', 'ContactPoint'],
    ['Contributor', 'Contributor'],
    ['Count', 'Count'],
    ['DataRequirement', 'DataRequirement'],
    ['Date', 'Date'],
    ['DateTime', 'DateTime'],
    ['Decimal', 'Decimal'],
    ['Distance', 'Distance'],
    ['Dosage', 'Dosage'],
    ['Duration', 'Duration'],
    ['Expression', 'Expression'],
    ['HumanName', 'HumanName'],
    ['Id', 'Id'],
    ['Identifier', 'Identifier'],
    ['Instant', 'Instant'],
    ['Integer', 'Integer'],
    ['Markdown', 'Markdown'],
    ['Meta', 'Meta'],
    ['Money', 'Money'],
    ['Oid', 'Oid'],
    ['ParameterDefinition', 'ParameterDefinition'],
    ['Period', 'Period'],
    ['PositiveInt', 'PositiveInt'],
    ['Quantity', 'Quantity'],
    ['Range', 'Range'],
    ['Ratio', 'Ratio'],
    ['Reference', 'Reference'],
    ['RelatedArtifact', 'RelatedArtifact'],
    ['SampledData', 'SampledData'],
    ['Signature', 'Signature'],
    ['String', 'String'],
    ['Time', 'Time'],
    ['Timing', 'Timing'],
    ['TriggerDefinition', 'TriggerDefinition'],
    ['UnsignedInt', 'UnsignedInt'],
    ['Uri', 'Uri'],
    ['Url', 'Url'],
    ['UsageContext', 'UsageContext'],
    ['Uuid', 'Uuid'],
    ['extension', 'extension'],
]);

export default class FHIRObjectBase {
    static __className= 'FHIRObjectBase';

    __objectStructure = {};

    constructor(className) {
        this.__originalObjJson = {};
        this.__className = className;
    }

    toJSON() {
        return {};
    }

    getJsonForStructure(structure) {
        let json = {};

        /* eslint-disable security/detect-object-injection */
        for (let key in structure) {
            if (Object.prototype.hasOwnProperty.call(structure, key)) {
                if (Array.isArray(structure[key])) {
                    json[key] = this.arrayPropertyToJson(this[key]);
                } else if (structure[key] === String || structure[key] === Boolean || structure[key] === Number) {
                    json[key] = this[key];
                } else {
                    json[key] = this.propertyToJson(this[key]);
                }
            }
        }
        /* eslint-enable security/detect-object-injection */

        return this.removeUndefinedObjProps(json);
    }

    // Uses rfc6902 library to generate a JsonPatch
    jsonPatch(baseObj, existingObj) {
        return createPatch(baseObj, existingObj);
    }

    resourceJsonPatch() {
        return this.jsonPatch(this.__originalObjJson, this.toJSON());
    }

    getJsonPatchedObject(jsonPatch) {
        let originalObjJson = this.originalObjJson;
        let originalObjJsonSinceLastAutoSave = this.originalObjJsonSinceLastAutoSave ? this.originalObjJsonSinceLastAutoSave : undefined;

        let itemJson = this.toJSON();
        applyPatch(itemJson, jsonPatch);

        let item = new this.constructor(itemJson);
        item.originalObjJson = originalObjJson;
        item.originalObjJsonSinceLastAutoSave = originalObjJsonSinceLastAutoSave;
        return item;
    }

    setPropertyValues(objectJsonValues) {
        this.reset(objectJsonValues);
    }

    reset(objectJsonValues) {
        if (!Object.keys(this.__objectStructure).length) {
            throw new Error('Object does not contain structure definition.');
        }

        let objWithOldValue = new this.constructor(objectJsonValues ? objectJsonValues : this.__originalObjJson);

        Object.keys(this.__objectStructure).forEach((key) => {
            this[key] = objWithOldValue[key];
        });

        // If core FHIR Extension properties are found revert as well
        if (typeof this.getExtensionProperties === 'function') {
            this.getExtensionProperties().forEach((propertyName) => {
                this[propertyName] = objWithOldValue[propertyName];
            });
        }

        // If USCore Extension properties are found revert as well
        if (typeof this.getUSCoreExtensionProperties === 'function') {
            this.getUSCoreExtensionProperties().forEach((propertyName) => {
                this[propertyName] = objWithOldValue[propertyName];
            });
        }

        // If BestNotes Extension properties are found revert as well
        if (typeof this.getBNExtensionProperties === 'function') {
            this.getBNExtensionProperties().forEach((propertyName) => {
                this[propertyName] = objWithOldValue[propertyName];
            });
        }

        // If entity has an extension structure object, process all properties
        // to restore those value.  Some could have been done above but the
        // code above can not be removed to keep older objects functioning properly
        if (this.__extensionStructure && Object.keys(this.__extensionStructure).length) {
            Object.keys(this.__extensionStructure).forEach((key) => {
                this[key] = objWithOldValue[key];
            });
        }
    }

    needsSaved() {
        return !!this.jsonPatch(this.__originalObjJson, this.toJSON())?.length
    }

    setObjectSaved(patch) {
        if (!patch) {
            this.originalObjJson = this.toJSON();
            return;
        }

        applyPatch(this.originalObjJson, patch);
    }

    get originalObjJson() {
        return this.__originalObjJson;
    }

    set originalObjJson(value) {
        this.__originalObjJson = value;
    }

    get objectStructure() {
        return this.__objectStructure;
    }

    set objectStructure(value) {
        throw new Error('You cannot change the class structure at runtime.');
    }

    getObjectCopy() {
        return new this.constructor(this.toJSON());
    }

    arrayPropertyToJson(propertyArray) {
        if (!propertyArray) return [];

        let jsonArray = [];
        propertyArray.forEach((item) => {
            if (item) jsonArray.push(this.propertyToJson(item));
        });

        return jsonArray;
    }

    propertyToJson(property) {
        if (property && typeof property.toJSON === 'function') {
            return property.toJSON();
        }

        return property;
    }

    removeUndefinedObjProps(objToProcess) {
        Object.keys(objToProcess).forEach((key) => {
            // eslint-disable-next-line security/detect-object-injection
            if (objToProcess[key] == null || (Array.isArray(objToProcess[key]) && objToProcess[key].length === 0) || (_.isObject(objToProcess[key]) && _.isEmpty(objToProcess[key]))) {
                // eslint-disable-next-line security/detect-object-injection
                delete objToProcess[key];
            }
        });

        if (Object.getOwnPropertyNames(objToProcess).length === 0) {
            return undefined;
        }

        return objToProcess;
    }

    /**
     * This method creates the properties for an object and populates the data from a provided JSON object.
     * @param {Object} props The structure of the object.
     * @param {Object} data The JSON data to populate
     * @param {Object} defaults The JSON data to populate
     */
    createAndPopulateStructure(props, data, defaults = {}) {
        /* eslint-disable security/detect-object-injection */

        //Add create property functions here so that they are not accessible outside the creation script.
        /**
         * Create a primitive property type with a simple getter and setter.
         * @param {string} propName
         * @param {Object} subject
         * @returns {boolean}
         */
        const createPrimitiveProperty = (propName, subject) => {
            //Only create properties that don't already exist.
            if (!Object.prototype.hasOwnProperty.call(subject, propName)) {
                Object.defineProperty(subject, propName, {
                    configurable: true,
                    get() {
                        return this['__' + propName];
                    },
                    set(value) {
                        if (value === '' || value === null) {
                            this['__' + propName] = undefined;
                        } else {
                            this['__' + propName] = value;
                        }
                    },
                });
                return true;
            }

            return false;
        };

        /**
         * Create an object property on with a strict setter to enforce object type.
         * @param {string} propName
         * @param {Object} subject
         * @param {_typeof} objectType
         * @returns {boolean}
         */
        const createObjectProperty = (propName, subject, objectType) => {
            //Only create properties that don't already exist.
            if (!Object.prototype.hasOwnProperty.call(subject, propName)) {
                Object.defineProperty(subject, propName, {
                    configurable: true,
                    get() {
                        // eslint-disable-next-line security/detect-object-injection
                        return this['__' + propName];
                    },
                    set(value) {
                        if (value instanceof objectType) {
                            // eslint-disable-next-line security/detect-object-injection
                            this['__' + propName] = value;
                            return;
                        }

                        // eslint-disable-next-line security/detect-object-injection
                        this['__' + propName] = value !== undefined ? new objectType(value) : undefined;
                    },
                });
                return true;
            }

            return false;
        };

        /**
         * Create an array property on with a setter to enforce array values.
         * @param {string} propName
         * @param {Object} subject
         * @returns {boolean}
         */
        const createArrayPrimitiveProperty = (propName, subject) => {
            //Only create properties that don't already exist.
            if (!Object.prototype.hasOwnProperty.call(subject, propName)) {
                Object.defineProperty(subject, propName, {
                    configurable: true,
                    get() {
                        // eslint-disable-next-line security/detect-object-injection
                        return this['__' + propName];
                    },
                    set(value) {
                        if (Array.isArray(value)) {
                            // eslint-disable-next-line security/detect-object-injection
                            this['__' + propName] = value;
                            return;
                        }

                        // eslint-disable-next-line security/detect-object-injection
                        this['__' + propName].push(value);
                    },
                });
                return true;
            }

            return false;
        };

        /**
         * Create an array property on with a strict setter to enforce array object types.
         * @param {string} propName
         * @param {Object} subject
         * @param {typeof} objectType
         * @returns {boolean}
         */
        const createArrayObjectProperty = (propName, subject, objectType) => {
            //Only create properties that don't already exist.
            if (!Object.prototype.hasOwnProperty.call(subject, propName)) {
                // Must use name property, instanceOf causes circular referencing
                if (objectType?.__className === 'CodeableConcept') {
                    Object.defineProperty(subject, propName + 'Zero', {
                        configurable: true,
                        get() {
                            return this['__' + propName]?.[0];
                        },
                        set(value) {
                            if (value === undefined || value === null || (typeof value === 'string' && value.trim() === '')) {
                                this['__' + propName].splice(0, 1);
                                return;
                            }

                            if (value.__className === 'CodeableConcept') {
                                this['__' + propName].splice(0, 1, value);
                                return;
                            }

                            this['__' + propName].splice(0, 1, new objectType(value));
                        },
                    });
                }

                Object.defineProperty(subject, propName, {
                    configurable: true,
                    get() {
                        // eslint-disable-next-line security/detect-object-injection
                        return this['__' + propName];
                    },
                    set(value) {
                        if (Array.isArray(value)) {
                            this['__' + propName] = value;
                            return;
                        }

                        if (value instanceof objectType) {
                            this['__' + propName].push(value);
                            return;
                        }

                        if (value === undefined) {
                            this['__' + propName] = [];
                            return;
                        }

                        this['__' + propName].push(new objectType(value));
                    },
                });
                return true;
            }

            return false;
        };

        for (let key in props) {
            // Array Properties
            if (Array.isArray(props[key])) {
                //Find the object type
                const objectType = props[key][0];

                if (objectType === String || objectType === Boolean || objectType === Number) {
                    //Create the Primitive Getter and Setter
                    createArrayPrimitiveProperty(key, this);
                } else {
                    //Create the object getter and setter.
                    createArrayObjectProperty(key, this, objectType);
                }

                //Initialize the array
                this['__' + key] = defaults[key] || [];

                //Get the list from the construction JSON.
                let list = get(data, key, []);
                if (Array.isArray(list)) {
                    list.forEach((item) => {
                        if (objectType === String || objectType === Boolean || objectType === Number) {
                            //Add the value to the array
                            this['__' + key].push(item);
                        } else {
                            //Create and add the object to the array
                            this['__' + key].push(new objectType(item));
                        }
                    });
                }
            } else if (props[key] === String || props[key] === Boolean || props[key] === Number) {
                //Primitive Properties
                //Assigned a value upon construction.
                const dataValue = get(data, key, undefined);
                this['__' + key] = dataValue !== undefined ? dataValue : defaults[key];

                //Create the getter and setter
                createPrimitiveProperty(key, this);
            } else {
                //Object Properties
                const dataValue = get(data, key, undefined);
                let item = dataValue !== undefined ? dataValue : defaults[key];
                if (item instanceof props[key]) {
                    this['__' + key] = item;
                } else if (item) {
                    //Create and new object and assign the private property.
                    this['__' + key] = new props[key](item);
                } else {
                    //Initialize the private property as undefined.
                    this['__' + key] = undefined;
                }

                //Create the getter and setter.
                createObjectProperty(key, this, props[key]);
            }
        }
        /* eslint-enable security/detect-object-injection */
    }

    populateCustomPropertiesFromJson(jsonObj, properties = []) {
        if (!Array.isArray(properties)) {
            properties = [properties];
        }

        properties.forEach((property) => {
            let propertyValue = get(jsonObj, property, undefined);
            if (propertyValue) {
                this[property] = propertyValue;
            }
        });
    }

    /**
     * This method creates the extension based BN properties for an object and populates the constructorJsonData from a provided JSON object.
     * @param {Object} props The structure of the extension props.
     * @param {Object} constructorJsonData The constructorJsonData to create object
     * @param {Object} defaultValues The default property values
     */
    createAndPopulateExtensionStructure(props, constructorJsonData, defaultValues = {}) {
        /* eslint-disable security/detect-object-injection */

        //Add create property functions here so that they are not accessible outside the creation script.
        /**
         * Create a primitive property type with a simple getter and setter.
         * @param {string} propName
         * @param {string} objectType
         * @param {Object} subject
         * @param {string} extPropertyName
         * @param {string} extensionBaseUrl
         * @returns {boolean}
         */
        const createExtensionProperty = (propName, objectType, subject, extPropertyName, extensionBaseUrl) => {
            //Only create properties that don't already exist.
            if (!Object.prototype.hasOwnProperty.call(subject, propName)) {
                Object.defineProperty(subject, propName, {
                    configurable: true,
                    get() {
                        return this.getCustomBaseExtensionValue(extPropertyName, extensionBaseUrl);
                    },
                    set(value) {
                        if (value === undefined) {
                            this.removeCustomBaseExtensionValuesByType(extPropertyName, extensionBaseUrl);
                            return;
                        }

                        this.setCustomBaseExtensionValue(extPropertyName, value, objectType, extensionBaseUrl);
                    },
                });

                // Load existing extension values into the local property array
                // This will load any data passed as an extension value.
                let existingExtensionValue = subject.getCustomBaseExtensionValue(extPropertyName, extensionBaseUrl);
                if (existingExtensionValue) {
                    // Done using the newly configured setter and NOT directly to the local prop
                    subject[propName] = existingExtensionValue;
                } else if (defaultValues[propName]) {
                    // Load any default value if no existing/extension values found
                    // Done using the newly configured setter and NOT directly to the local prop
                    subject[propName] = defaultValues[propName];
                }
                return true;
            }
            return false;
        };

        /**
         * Create an array BnExtension property type with a simple getter and setter.
         * @param {string} propName
         * @param {string} objectType
         * @param {Object} subject
         * @param {string} extPropertyName
         * @param {string} extensionBaseUrl
         * @returns {boolean}
         */
        const createExtensionArrayProperty = (propName, objectType, subject, extPropertyName, extensionBaseUrl) => {
            //Only create properties that don't already exist.
            if (!Object.prototype.hasOwnProperty.call(subject, propName)) {
                Object.defineProperty(subject, propName, {
                    configurable: true,
                    get() {
                        return this['__' + extPropertyName];
                    },
                    set(value) {
                        this.arrayToCustomBaseExtension(value, extPropertyName, objectType, extensionBaseUrl);
                    },
                });

                subject['remove' + upperFirst(propName) + 'Item'] = function (itemValue, itemKey) {
                    this.removeArrayCustomBaseExtensionItem(itemValue, extPropertyName, itemKey, extensionBaseUrl);
                };

                // Setup the private property array
                subject['__' + extPropertyName] = [];

                // Load existing extension values into the local property array
                // This will load any data passed as an extension value.
                let existingExtensionValues = subject.getCustomBaseExtensions(extPropertyName, extensionBaseUrl);
                if (existingExtensionValues.length) {
                    // Done using the newly configured setter and NOT directly to the local prop
                    existingExtensionValues.forEach((e) => subject[propName].push(e.value));
                } else if (defaultValues[propName]) {
                    // Load any default value if no existing/extension values found
                    // Done using the newly configured setter and NOT directly to the local prop
                    subject[propName] = defaultValues[propName];
                }

                return true;
            }

            return false;
        };

        const createExtensionExtensionProperty = (propertyName, extensionObjDefinition, subject, extPropertyName, extBaseUrl) => {
            // Object that will be used to assign to a local property (prefaced
            // by an _) that will be used by the getter to return and object
            // that will contain getter/setter methods for the contained properties
            // to allow . notation access in under laying elements.
            const propertyObj = {};
            // Object used to keep track of extension url and user defined property names.
            const propertyExtensionLkUp = {};

            const rootExtPropertyValuesArrayName = '__' + propertyName + '_values';
            // Setup the private property array that will contain all extension items.
            // This array will be used for looking up properties in the object getter
            // and to make adding/removing items simpler
            this[rootExtPropertyValuesArrayName] = [];

            extensionObjDefinition.forEach((extensionItem) => {
                let extItemPropertyName = extensionItem.url;

                let extItemValueType;
                let extensionItemIsArray = Array.isArray(extensionItem.type);
                if (extensionItemIsArray) {
                    extItemValueType = extensionItem.type[0];
                    // Look for a custom property name ONLY on array type properties
                    if (Object.prototype.hasOwnProperty.call(extensionItem, 'propertyName')) {
                        extItemPropertyName = extensionItem.propertyName;
                    }
                } else {
                    extItemValueType = extensionItem.type;
                }
                // Store extension url value and corresponding property name
                propertyExtensionLkUp[extensionItem.url] = extItemPropertyName;

                // If this extension property is an array create an array property on the object
                // to hold all the values that the getter will return
                if (extensionItemIsArray) {
                    this['__' + propertyName + upperFirst(extItemPropertyName)] = [];
                }

                // Create the getter/setter methods for the individual extension item components
                Object.defineProperty(propertyObj, extItemPropertyName, {
                    configurable: true,
                    get() {
                        // If an array return the properties local array
                        if (extensionItemIsArray) {
                            return subject['__' + propertyName + upperFirst(extItemPropertyName)];
                        }
                        // This is just a single value so look it up in the main array and return it
                        return subject[rootExtPropertyValuesArrayName].find((item) => item.url === extItemPropertyName)?.value;
                    },
                    set(value) {
                        if (extensionItemIsArray) {
                            const propertyValueArray = subject['__' + propertyName + upperFirst(extItemPropertyName)];

                            if (Array.isArray(value)) {
                                // Loop backwards so that all items are processed
                                for (let i = propertyValueArray.length - 1; i >= 0; i--) {
                                    subject['remove' + upperFirst(propertyName) + upperFirst(extItemPropertyName) + 'Item'](propertyValueArray[i]);
                                }
                                value.forEach((item) => {
                                    let newExtensionJson = {};
                                    let itemJson = item;
                                    newExtensionJson['url'] = extensionItem.url;
                                    newExtensionJson['value' + extItemValueType] = itemJson;
                                    subject['add' + upperFirst(propertyName)](newExtensionJson);
                                });
                                return;
                            }

                            // Passing undefined for a value to clear extension values
                            if (value === undefined) {
                                // Loop backwards so that all items are processed
                                for (let i = propertyValueArray.length - 1; i >= 0; i--) {
                                    subject['remove' + upperFirst(propertyName) + upperFirst(extItemPropertyName) + 'Item'](propertyValueArray[i]);
                                }
                                return;
                            }

                            // JSON or Extension assignment
                            let itemJson = value;
                            let newExtensionJson = {};
                            newExtensionJson['url'] = extensionItem.url;
                            newExtensionJson['value' + extItemValueType] = itemJson;
                            subject['add' + upperFirst(propertyName)](newExtensionJson);

                            return;
                        }

                        // Property is NOT array based
                        if (value === undefined) {
                            let existingValue = subject[rootExtPropertyValuesArrayName].find((item) => item.url === extItemPropertyName);
                            if (existingValue) {
                                subject['remove' + upperFirst(propertyName)](existingValue);
                            }
                            return;
                        }

                        // JSON or Extension assignment
                        // Because this is a single value if a value already exist remove it first
                        if (subject[propertyName][extItemPropertyName]) {
                            subject[propertyName][extItemPropertyName] = undefined;
                        }
                        let itemJson = value;
                        let newExtensionJson = {};
                        newExtensionJson['url'] = extensionItem.url;
                        newExtensionJson['value' + extItemValueType] = itemJson;
                        subject['add' + upperFirst(propertyName)](newExtensionJson);
                    },
                });

                // Create a remove method for all array based extension items
                if (extensionItemIsArray) {
                    this['remove' + upperFirst(propertyName) + upperFirst(extItemPropertyName) + 'Item'] = function (removeItem) {
                        let removeResult = false;
                        this[rootExtPropertyValuesArrayName].some((extItem) => {
                            // extensionItem.url is used so that ONLY extension items in the array
                            // that match this items url signature will be processed.
                            // (since the user can change the property name, extensionItem.url must be used)
                            if (extItem.url === extensionItem.url && extItem.value === removeItem) {
                                removeResult = this['remove' + upperFirst(propertyName)](extItem);
                            }
                        });
                        return removeResult;
                    };
                }
            });

            // Setup private property that will return the object created about with
            // the various getters/setters that were created.  Used to implement
            // the extension.internalExtension access desired.
            this['__' + propertyName] = propertyObj;
            Object.defineProperty(subject, propertyName, {
                configurable: true,
                get() {
                    return this['__' + propertyName];
                },
            });

            // --------------------------------------------------------------
            // Remove(PropertyName) method
            // Used to remove extension items and keep various arrays
            // in sync as well as the entities native extension array.
            // Method requires that the parameter is an Extension
            // --------------------------------------------------------------
            this['remove' + upperFirst(propertyName)] = function (removeItem) {
                if (!(removeItem instanceof Extension)) {
                    return false;
                }

                const baseExtValuesArrayName = rootExtPropertyValuesArrayName;
                const propertyValuesArrayName = '__' + propertyName + upperFirst(propertyExtensionLkUp[removeItem.url] || removeItem.url);

                // Locate item in the properties array of all extension
                let baseExtArrayItemIndex = this[baseExtValuesArrayName].indexOf(removeItem);
                // If item is NOT found in the base array abort
                if (baseExtArrayItemIndex < 0) return false;

                // Locate item in the local property item array (if it exists)
                let propertyArrayItemIndex = Array.isArray(this[propertyValuesArrayName]) ? this[propertyValuesArrayName].indexOf(removeItem.value) : -1;
                // If there IS a property array for values defined and item was NOT found, abort
                if (Array.isArray(this[propertyValuesArrayName]) && propertyArrayItemIndex < 0) return false;

                // Remove item from property values array
                if (propertyArrayItemIndex >= 0) {
                    this[propertyValuesArrayName].splice(propertyArrayItemIndex, 1);
                }

                // If this is the only extension, remove the whole extension value
                if (this[baseExtValuesArrayName].length === 1) {
                    this.extension.splice(this.extension.indexOf(this[baseExtValuesArrayName]), 1);
                }
                this[baseExtValuesArrayName].splice(baseExtArrayItemIndex, 1);
                return true;
            };
            // --------------------------------------------------------------

            // --------------------------------------------------------------
            // Add(PropertyName) method
            // Used to add extension items and keep various arrays
            // in sync as well as the entities native extension array.
            // --------------------------------------------------------------
            this['add' + upperFirst(propertyName)] = function (newValue) {
                // Assigning an array, so remove existing values
                if (Array.isArray(newValue)) {
                    // Convert to JSON for call to setCustomBaseExtensionValue
                    if (newValue[0] instanceof Extension) {
                        newValue = newValue.map((item) => item.toJSON());
                    }

                    // Create the extension array values
                    this.setCustomBaseExtensionValue(extPropertyName, newValue, 'extension', extBaseUrl);
                    // Get extension array reference for internal property of extension values
                    this[rootExtPropertyValuesArrayName] = this.getCustomBaseExtensionValue(extPropertyName, extBaseUrl);

                    // Fill component arrays
                    Object.keys(propertyExtensionLkUp).forEach((extUrlName) => {
                        // PropertyExtensionLkUp object is used to lookup the proper name for property arrays.
                        const propertyArrayName = '__' + propertyName + upperFirst(propertyExtensionLkUp[extUrlName]);
                        // Check required here because if the property is NOT an array
                        // there will be no defined local property for storing the items
                        if (Array.isArray(this[propertyArrayName])) {
                            // Remove existing values
                            this[propertyArrayName].splice(0);
                            this[rootExtPropertyValuesArrayName].forEach((item) => {
                                if (item.url === extUrlName) {
                                    this[propertyArrayName].push(item.value);
                                }
                            });
                        }
                        // If a property does not have an internal array it is just stored in the
                        // local property of extension values
                    });
                    return;
                }

                // Adding and item
                if (!(newValue instanceof Extension)) {
                    newValue = new Extension(newValue);
                }

                // Check the passed in value to make sure it exists in the extension definition
                // by looking in the propertyExtensionLkUp object. If array not found, abort.
                if (!propertyExtensionLkUp[newValue.url]) {
                    return false;
                }

                if (this[rootExtPropertyValuesArrayName].length === 0) {
                    // No extension values so add item to the entity extensions array then link
                    this.setCustomBaseExtensionValue(extPropertyName, [newValue.toJSON()], 'extension', extBaseUrl);
                    // internal array of extension values to the object property of extensions values
                    this[rootExtPropertyValuesArrayName] = this.getCustomBaseExtensionValue(extPropertyName, extBaseUrl);
                    // MUST assign newValue to the value from the extensions array so that it can be used
                    // below when adding to the internal property array.  If NOT done, it will cause an issue
                    // when attempting to locate that item for removal.
                    newValue = this[rootExtPropertyValuesArrayName][0];
                } else {
                    this[rootExtPropertyValuesArrayName].push(newValue);
                }
                // Finally, push the new value on to the internal property array of values if present
                if (Array.isArray(this['__' + propertyName + upperFirst(propertyExtensionLkUp[newValue.url] || newValue.url)])) {
                    this['__' + propertyName + upperFirst(propertyExtensionLkUp[newValue.url])].push(newValue.value);
                }
            };
            // --------------------------------------------------------------

            // Load existing extension values into the local property array
            // This will load any data passed as an extension value.
            let existingExtensionValues = this.getCustomBaseExtensionValue(extPropertyName, extBaseUrl);
            if (existingExtensionValues?.length) {
                // This array will contain actual Extension objects
                this[rootExtPropertyValuesArrayName] = existingExtensionValues;

                // Fill component arrays. Use the propertyExtensionLkUp to keep track
                // of extension url and user defined property names.
                Object.keys(propertyExtensionLkUp).forEach((extUrlName) => {
                    // Check required here because if the property is NOT an array
                    // there will be no defined local property for storing the items
                    const objPropertyExtArrayName = '__' + propertyName + upperFirst(propertyExtensionLkUp[extUrlName] || extUrlName);
                    if (this[objPropertyExtArrayName]) {
                        this[rootExtPropertyValuesArrayName].forEach((item) => {
                            if (item.url === extUrlName) {
                                this[objPropertyExtArrayName].push(item.value);
                            }
                        });
                    }
                });
            } else if (defaultValues[propertyName]) {
                // Load any default value if no existing/extension values found
                // Done using the newly configured setter and NOT directly to the local prop
                for (let defaultObjPropertyName in defaultValues[propertyName]) {
                    let defaultPropertyName = propertyExtensionLkUp[defaultObjPropertyName] || defaultObjPropertyName;
                    let defaultPropertyValue = defaultValues[propertyName][defaultObjPropertyName];

                    if (Array.isArray(defaultPropertyValue)) {
                        defaultPropertyValue.forEach((defaultPropertyItemValue) => {
                            if (Array.isArray(this['__' + propertyName + upperFirst(defaultPropertyName)])) {
                                this[propertyName][defaultPropertyName] = defaultPropertyItemValue;
                            }
                        });
                    } else {
                        this[propertyName][defaultPropertyName] = defaultPropertyValue;
                    }
                }
            }

            // Assign any values passed in with the property name
            // in the constructor using setter method
            const constructorValue = get(constructorJsonData, propertyName, undefined);
            if (constructorValue) {
                Object.keys(constructorValue).forEach((constructorItem) => {
                    this[propertyName][propertyExtensionLkUp[constructorItem] || constructorItem] = constructorValue[constructorItem];
                });
            }
        };

        let jsonStructure = props;
        for (let propertyName in jsonStructure) {
            let propertyValue = jsonStructure[propertyName];
            let valueType;
            let extPropertyName = propertyName;
            let extBaseUrl;
            let valueIsArray = false;
            if (Array.isArray(propertyValue)) {
                valueIsArray = true;
                // JSON object definition
                if (typeof propertyValue[0] === 'object') {
                    if (Object.prototype.hasOwnProperty.call(propertyValue[0], 'type')) {
                        let parsedUrl = propertyValue[0].url.split('/');
                        extPropertyName = parsedUrl[parsedUrl.length - 1];
                        extBaseUrl = propertyValue[0].url.replace(extPropertyName, '');
                        valueType = propertyValue[0].type;
                    }
                    // Extension definitions are not supported in an array item (Single level only)
                } else {
                    // Counting on this being a string and the value being a normal extension type
                    valueType = propertyValue[0];
                }
            } else {
                // Standard object definitions
                if (typeof propertyValue === 'string') {
                    valueType = propertyValue;
                }
                // JSON object definition
                if (typeof propertyValue === 'object') {
                    if (Object.prototype.hasOwnProperty.call(propertyValue, 'type')) {
                        let parsedUrl = propertyValue.url.split('/');
                        extPropertyName = parsedUrl[parsedUrl.length - 1];
                        extBaseUrl = propertyValue.url.replace(extPropertyName, '');
                        valueType = propertyValue.type;
                    }
                    if (Object.prototype.hasOwnProperty.call(propertyValue, 'extension')) {
                        valueType = 'extension';

                        if (Object.prototype.hasOwnProperty.call(propertyValue, 'url')) {
                            let parsedUrl = propertyValue.url.split('/');
                            extPropertyName = parsedUrl[parsedUrl.length - 1];
                            extBaseUrl = propertyValue.url.replace(extPropertyName, '');
                        }

                        createExtensionExtensionProperty(propertyName, propertyValue['extension'], this, extPropertyName, extBaseUrl);
                        continue;
                    }
                }
            }

            if (!validFhirExtensionTypes.has(valueType)) {
                throw 'Invalid Extension Type: ' + valueType;
            }

            if (valueIsArray) {
                createExtensionArrayProperty(propertyName, valueType, this, extPropertyName, extBaseUrl);
            } else {
                createExtensionProperty(propertyName, valueType, this, extPropertyName, extBaseUrl);
            }

            // Assign any values passed in with the property name
            // in the constructor using setter method
            const constructorValue = get(constructorJsonData, propertyName, undefined);
            if (constructorValue) {
                this[propertyName] = constructorValue;
            }
        }

        /* eslint-enable security/detect-object-injection */
    }
}
