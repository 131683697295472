import BackboneElement from './BackboneElement';
import CoverageCostToBeneficiaryException from './CoverageCostToBeneficiaryException';
import CodeableConcept from './types/CodeableConcept';
import ValueX from './types/ValueX';

import merge from 'lodash/merge';
import get from 'lodash/get';

export default class CoverageCostToBeneficiary extends BackboneElement {
    static __className = 'CoverageCostToBeneficiary';

    __objectStructure = {
        type: CodeableConcept,
        value: ValueX,
        exception: [CoverageCostToBeneficiaryException],
    };

    constructor(constructJson, className = 'CoverageCostToBeneficiary') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        let fhirValueQuantity = get(constructJson, 'valueQuantity', undefined);
        if (fhirValueQuantity) {
            this.valueQuantity = fhirValueQuantity;
        }

        let fhirValueMoney = get(constructJson, 'valueMoney', undefined);
        if (fhirValueMoney) {
            this.valueMoney = fhirValueMoney;
        }

        this.originalObjJson = this.toJSON();
    }

    get valueQuantity() {
        if (!this.value) {
            return undefined;
        }
        return this.value.valueQuantity;
    }

    set valueQuantity(value) {
        if (!this.value) {
            this.value = new ValueX();
        }
        this.value.valueQuantity = value;
    }

    get valueMoney() {
        if (!this.value) {
            return undefined;
        }
        return this.value.valueMoney;
    }
    set valueMoney(value) {
        if (!this.value) {
            this.value = new ValueX();
        }
        this.value.valueMoney = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
