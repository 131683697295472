export function useBnNoAutocomplete() {
    const repairAutocomplete = (el) => {
        if (el?.querySelectorAll) {
            el.querySelectorAll(
                'input[type="number"]:not([autocomplete="new-password"]), input[type="text"]:not([autocomplete="new-password"]), input[type="tel"]:not([autocomplete="new-password"]), input[type="email"]:not([autocomplete="new-password"]), input[type="password"]:not([autocomplete="new-password"])',
            )?.forEach((it) => {
                it.setAttribute('autocomplete', 'new-password');
            });
        }
    };

    return { repairAutocomplete };
}
