export const config = {
    debounceTime: 1000, // in ms
    maxTime: 5000, // in ms
    /**
     * retry for 5 mins (15 seconds times 20 times)
     * only retry for 5 mins so if they leave the app and complete somewhere else there is no conflicts in saving when/if
     * comes back online
     */
    timeoutMilliseconds: 15000,
    maxRetries: 20,
};

/**
 * This function was taken from 'axios-retry', https://github.com/softonic/axios-retry/blob/master/es/index.js
 *
 * @param error
 * @returns boolean
 */
export function isRetryableError(error) {
    return error.code !== 'ECONNABORTED' && (!error.response || (error.response.status >= 500 && error.response.status <= 599));
}
export default config;
