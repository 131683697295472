// Import order of the following files are important to prevent circular issues.
// Do NOT reorder/sort/change import and exports in this section.
import FHIRObjectBase from './FHIRObjectBase';
export { FHIRObjectBase };
import BaseElement from './structure/BaseElement';
export { BaseElement };

import Extension from './Extension';
import { defaultBNExtensionStructureDefinitionUrlBase, defaultFHIRExtensionStructureDefinitionUrlBase, defaultFHIRUSCoreExtensionStructureDefinitionUrlBase } from './Extension';
export { defaultBNExtensionStructureDefinitionUrlBase, defaultFHIRExtensionStructureDefinitionUrlBase, defaultFHIRUSCoreExtensionStructureDefinitionUrlBase };

import extensionTraits from './trait/extensionTraits';
import Element from './Element';
export { Element };
import BackboneElement from './BackboneElement';
export { BackboneElement };
import Resource from './Resource';
export { Resource };

import Narrative from './Narrative';
import DomainResource from './DomainResource';
export { DomainResource };
// -------------------------------------------------------------

import AllowedX from './types/AllowedX';
import Annotation from './types/Annotation';
import AsNeededX from './types/AsNeededX';
import Attachment from './types/Attachment';
import Bundle from './Bundle';
import BundleEntry from './BundleEntry';
import BundleEntryRequest from './BundleEntryRequest';
import BundleEntryResponse from './BundleEntryResponse';
import BundleEntrySearch from './BundleEntrySearch';
import BundleHandler from './transaction/BundleHandler';
import BundleLink from './BundleLink';
import CodeableConceptType from './types/CodeableConcept';
import CodeableReferenceType from './types/CodeableReference';
import CodingType from './types/Coding';
import DiagnosisX from './types/DiagnosisX';
import Dosage from './types/Dosage';
import Identifier from './types/Identifier';
import MedicationX from './types/MedicationX';
import Money from './types/Money';
import OperationOutcome from './OperationOutcome';
import OperationOutcomeIssue from './OperationOutcomeIssue';
import PeriodType from './types/Period';
import QuantityType from './types/Quantity';
import RangeType from './types/Range';
import ReferenceType from './types/Reference';
import ServicedX from './types/ServicedX';
import TaskRestrictionType from './types/TaskRestriction';
import Timing from './types/Timing';
import UsedX from './types/UsedX';
import ValueX from './types/ValueX';
import LocationX from './types/LocationX';

import Signature from './Signature';

import createFhirResource from './FhirResourceFactory';

import contactPointTraits from './trait/contactPointTraits';
import addressTraits from './trait/addressTraits';

import Account from './Account';
import AccountCoverage from './AccountCoverage';
import AccountGuarantor from './AccountGuarantor';
import Appointment from './Appointment';
import AppointmentParticipant from './AppointmentParticipant';
import AllergyIntolerance from '@/fhirworks/AllergyIntolerance';
import Address from './Address';
import BN_EncounterType from './BN_EncounterType';
import BN_List from './BN_List';
import BN_ListItem from './BN_ListItem';
import BN_Relationship from './BN_Relationship';
import BN_SimpleNoteType from './BN_SimpleNoteType';
import CarePlan from './CarePlan';
import CareTeam from './CareTeam';
import CareTeamParticipant from './CareTeamParticipant';
import Claim from './Claim';
import ClaimAccident from './ClaimAccident';
import ClaimCareTeam from './ClaimCareTeam';
import ClaimDiagnosis from './ClaimDiagnosis';
import ClaimInsurance from './ClaimInsurance';
import ClaimItem from './ClaimItem';
import ClaimPayee from './ClaimPayee';
import ClaimRelated from './ClaimRelated';
import ClinicalImpression from './ClinicalImpression';
import ClinicalImpressionFinding from './ClinicalImpressionFinding';
import ClinicalImpressionInvestigation from './ClinicalImpressionInvestigation';
import Concept from './Concept';
import Condition from './Condition';
import ConditionStage from './ConditionStage';
import ConditionEvidence from './ConditionEvidence';
import ContactDetail from './ContactDetail';
import ContactPoint from './ContactPoint';
import Consent from './Consent';
import Coverage from './Coverage';
import CoverageClass from './CoverageClass';
import CoverageCostToBeneficiary from './CoverageCostToBeneficiary';
import CoverageCostToBeneficiaryException from './CoverageCostToBeneficiaryException';
import CoverageEligibilityRequest from './CoverageEligibilityRequest';
import CoverageEligibilityRequestInsurance from './CoverageEligibilityRequestInsurance';
import CoverageEligibilityRequestItem from './CoverageEligibilityRequestItem';
import CoverageEligibilityRequestItemDiagnosis from './CoverageEligibilityRequestItemDiagnosis';
import CoverageEligibilityRequestSupportingInfo from './CoverageEligibilityRequestSupportingInfo';
import CoverageEligibilityResponse from './CoverageEligibilityResponse';
import CoverageEligibilityResponseError from './CoverageEligibilityResponseError';
import CoverageEligibilityResponseInsurance from './CoverageEligibilityResponseInsurance';
import CoverageEligibilityResponseInsuranceItem from './CoverageEligibilityResponseInsuranceItem';
import CoverageEligibilityResponseInsuranceItemBenefit from './CoverageEligibilityResponseInsuranceItemBenefit';
import DocumentReference from './DocumentReference';
import Encounter from './Encounter';
import EncounterParticipant from './EncounterParticipant';
import EncounterDiagnosis from './EncounterDiagnosis';
import Endpoint from './Endpoint';
import EpisodeOfCare from './EpisodeOfCare';
import EpisodeOfCareStatusHistory from './EpisodeOfCareStatusHistory';
import EpisodeOfCareDiagnosis from './EpisodeOfCareDiagnosis';
import FamilyMemberHistory from './FamilyMemberHistory';
import Goal from './Goal';
import Group from './Group';
import HealthcareService from './HealthcareService';
import HealthcareServiceAddOn from './HealthcareServiceAddOn';
import HealthcareServiceModifier from './HealthcareServiceModifier';
import HealthcareServiceRevCode from './HealthcareServiceRevCode';
import HoursOfOperation from './HoursOfOperation';
import HumanName from './HumanName';
import List from './List';
import ListEntry from './ListEntry';
import Location from './Location';
import MedicationStatement from './MedicationStatement';
import MedicationRequest from './MedicationRequest';
import Meta from './Meta';
import Observation from './Observation';
import Organization from './Organization';
import OrganizationContact from './OrganizationContact';
import Patient from './Patient';
import PatientCommunication from './PatientCommunication';
import Person from './Person';
import Position from './Position';
import Practitioner from './Practitioner';
import PractitionerQualification from './PractitionerQualification';
import PractitionerRole from './PractitionerRole';
import PractitionerRoleAccess from './PractitionerRoleAccess';
import Procedure from './Procedure';
import Provenance from './Provenance';
import Questionnaire from './Questionnaire';
import QuestionnaireAnswerOption from './QuestionnaireAnswerOption';
import QuestionnaireInitialValue from './QuestionnaireInitialValue';
import QuestionnaireItem from './QuestionnaireItem';
import QuestionnaireItemEnableCondition from './QuestionnaireItemEnableCondition';
import QuestionnaireResponse from './QuestionnaireResponse';
import QuestionnaireResponseItem from './QuestionnaireResponseItem';
import QuestionnaireResponseItemAnswer from './QuestionnaireResponseItemAnswer';
import RelatedPerson from './RelatedPerson';
import Role from './Role';
import ServiceRequest from './ServiceRequest';
import Task from './Task';
import UsageContext from './UsageContext';
import User from './User';
import ValueSet from './ValueSet';
import BN_Role from './BN_Role';
import BN_AccessGroup from './BN_AccessGroup';

import BN_QuestionnaireField from './BN_QuestionnaireField';
import BN_SuperBill from './BN_SuperBill';
import BN_SuperBillInsurance from './BN_SuperBillInsurance';
import BN_SuperBillOccurenceCodeList from './BN_SuperBillOccurenceCodeList';
import BN_SuperBillOccurenceSpanCodeList from './BN_SuperBillOccurenceSpanCodeList';
import BN_SuperBillLineItem from './BN_SuperBillLineItem';
import BN_SuperBillLineItemDiagnosis from './BN_SuperBillLineItemDiagnosis';
import BN_SuperBillLineItemModifier from './BN_SuperBillLineItemModifier';
import BN_SuperBillLineItemAddOn from './BN_SuperBillLineItemAddOn';
import BN_SuperBillProcedureCode from './BN_SuperBillProcedureCode';
import BN_SuperBillValueCodeList from './BN_SuperBillValueCodeList';
import BN_FinancialClass from './BN_FinancialClass';
import BN_Payment from './BN_Payment';
import BN_PostPayment from './BN_PostPayment';
import BN_LedgerTransaction from './BN_LedgerTransaction';

import BN_PriorAuthorization from './BN_PriorAuthorization';
import BN_PriorAuthorizationService from './BN_PriorAuthorizationService';
import BN_ERA from './BN_ERA';
import BN_PayorAddress from './BN_PayorAddress';
import BN_EdiConfiguration from './BN_EdiConfiguration';
import BN_File from './BN_File';
import BN_PaymentPostingConfiguration from './BN_PaymentPostingConfiguration';
import BN_PaymentTransaction from './BN_PaymentTransaction';
import BN_ClaimProfile from './BN_ClaimProfile';
import BN_ClaimProfilePOSOverrideItem from './BN_ClaimProfilePOSOverrideItem';
import BN_PatientAccountSummary from './BN_PatientAccountSummary';

import BN_ProfileAsset from './BN_ProfileAsset';
import BN_ProfileOwner from './BN_ProfileOwner';
import BN_ProfileManaged from './BN_ProfileManaged';
import BN_ProfileManagedItem from './BN_ProfileManagedItem';

import BN_TreatmentPlanLibraryCategory from '@/fhirworks/BN_TreatmentPlanLibraryCategory';
import BN_TreatmentPlanLibraryGoal from '@/fhirworks/BN_TreatmentPlanLibraryGoal';
import BN_TreatmentPlanLibraryObjective from '@/fhirworks/BN_TreatmentPlanLibraryObjective';

import { appointmentStatusValues, appointmentStatusDisplayValues, appointmentParticipantStatus } from './Appointment';
import { episodeOfCareStatusValues, episodeOfCareStatusDisplayValues } from './EpisodeOfCare';
import { episodeOfCareStatusHistoryStatusValues } from './EpisodeOfCareStatusHistory';
import { serviceRequestSystemStatusValues } from './ServiceRequest';
import { taskStatusValues, taskIntentValues, taskIntentDisplayValues, taskCodeTaskTypeValues, taskStatusDisplayValues } from './Task';
import { contactPointSystemValues, contactPointUseValues, contactEmailUseValues } from './trait/contactPointTraits';
import { addressUseValues, addressTypeValues } from './trait/addressTraits';

export const constants = {
    appointmentStatusValues: appointmentStatusValues,
    appointmentStatusDisplayValues: appointmentStatusDisplayValues,
    appointmentParticipantStatus: appointmentParticipantStatus,
    episodeOfCareStatusValues: episodeOfCareStatusValues,
    episodeOfCareStatusDisplayValues: episodeOfCareStatusDisplayValues,
    episodeOfCareStatusHistoryStatusValues: episodeOfCareStatusHistoryStatusValues,
    serviceRequestSystemStatusValues: serviceRequestSystemStatusValues,
    defaultBNExtensionStructureDefinitionUrlBase: defaultBNExtensionStructureDefinitionUrlBase,
    taskStatusValues: taskStatusValues,
    taskIntentValues: taskIntentValues,
    taskCodeTaskTypeValues: taskCodeTaskTypeValues,
    taskStatusDisplayValues: taskStatusDisplayValues,
    taskIntentDisplayValues: taskIntentDisplayValues,
    contactPointSystemValues: contactPointSystemValues,
    contactPointUseValues: contactPointUseValues,
    contactEmailUseValues: contactEmailUseValues,
    addressUseValues: addressUseValues,
    addressTypeValues: addressTypeValues,
};

export const types = {
    AllowedX: AllowedX,
    Annotation: Annotation,
    AsNeededX: AsNeededX,
    Attachment: Attachment,
    CodeableConcept: CodeableConceptType,
    CodeableReference: CodeableReferenceType,
    Coding: CodingType,
    DiagnosisX: DiagnosisX,
    Dosage: Dosage,
    Identifier: Identifier,
    Money: Money,
    MedicationX: MedicationX,
    Period: PeriodType,
    Quantity: QuantityType,
    Range: RangeType,
    Reference: ReferenceType,
    ServicedX: ServicedX,
    TaskRestrictionType: TaskRestrictionType,
    Timing: Timing,
    UsedX: UsedX,
    ValueX: ValueX,
    LocationX: LocationX,
};

export const structure = {
    BaseElement: BaseElement,
    BackboneElement: BackboneElement,
    DomainResource: DomainResource,
    FHIRObjectBase: FHIRObjectBase,
    Element: Element,
    Resource: Resource,
};

export const request = {
    BundleHandler: BundleHandler,
};

export const traits = {
    contactPointTraits: contactPointTraits,
    addressTraits: addressTraits,
    extensionTraits: extensionTraits,
};

export { createFhirResource };
export { Account, AccountCoverage, AccountGuarantor, AllergyIntolerance, Appointment, AppointmentParticipant, Address, Attachment };
export { Bundle, BundleEntry, BundleEntryRequest, BundleEntryResponse, BundleEntrySearch, BundleLink };
export { BN_EncounterType, BN_List, BN_ListItem, BN_Relationship, BN_SimpleNoteType };
export { CarePlan, CareTeam, CareTeamParticipant, Claim, ClaimAccident, ClaimCareTeam, ClaimDiagnosis, ClaimInsurance };
export { ClaimItem, ClaimPayee, ClaimRelated, ClinicalImpression, ClinicalImpressionFinding, ClinicalImpressionInvestigation };
export { CodeableReferenceType as CodeableReference, Concept, Consent };
export { ContactDetail, ContactPoint, Condition, ConditionEvidence, ConditionStage };
export { Coverage, CoverageClass, CoverageCostToBeneficiary, CoverageCostToBeneficiaryException };
export { CoverageEligibilityRequest, CoverageEligibilityRequestInsurance, CoverageEligibilityRequestItem, CoverageEligibilityRequestItemDiagnosis };
export { CoverageEligibilityRequestSupportingInfo, CoverageEligibilityResponse, CoverageEligibilityResponseError };
export { CoverageEligibilityResponseInsurance, CoverageEligibilityResponseInsuranceItem, CoverageEligibilityResponseInsuranceItemBenefit };
export { DocumentReference };
export { Encounter, EncounterParticipant, EncounterDiagnosis, Endpoint, EpisodeOfCare, EpisodeOfCareStatusHistory, EpisodeOfCareDiagnosis };
export { Extension, Goal, Group, FamilyMemberHistory, HoursOfOperation, HumanName, List, ListEntry, Location, Meta };
export { HealthcareService, HealthcareServiceAddOn, HealthcareServiceModifier, HealthcareServiceRevCode };
export { MedicationStatement, MedicationRequest, Narrative, Observation, OperationOutcome, OperationOutcomeIssue, Organization, OrganizationContact, Patient };
export { PatientCommunication, Person, Position, Practitioner, PractitionerRole, PractitionerRoleAccess, PractitionerQualification };
export { Procedure, Provenance, Questionnaire, QuestionnaireAnswerOption, QuestionnaireInitialValue, QuestionnaireItem };
export { QuestionnaireItemEnableCondition, QuestionnaireResponse, QuestionnaireResponseItem, QuestionnaireResponseItemAnswer };
export { ReferenceType as Reference, RelatedPerson, Role, ServiceRequest, Signature, UsageContext, User, ValueSet, BN_Role, BN_AccessGroup, Task };
export { BN_QuestionnaireField, BN_SuperBill, BN_SuperBillInsurance, BN_SuperBillOccurenceCodeList, BN_SuperBillOccurenceSpanCodeList };
export { BN_SuperBillLineItem, BN_SuperBillLineItemDiagnosis, BN_SuperBillLineItemModifier, BN_SuperBillLineItemAddOn, BN_SuperBillProcedureCode };
export { BN_SuperBillValueCodeList, BN_FinancialClass, BN_PriorAuthorization, BN_PriorAuthorizationService };
export { BN_ProfileAsset, BN_ProfileOwner, BN_ProfileManaged, BN_ProfileManagedItem };
export { BN_Payment, BN_PostPayment, BN_LedgerTransaction, BN_ERA, BN_EdiConfiguration, BN_PayorAddress, BN_File };
export { BN_PaymentPostingConfiguration, BN_PaymentTransaction, BN_ClaimProfile, BN_ClaimProfilePOSOverrideItem, BN_PatientAccountSummary };
export { BN_TreatmentPlanLibraryCategory, BN_TreatmentPlanLibraryGoal, BN_TreatmentPlanLibraryObjective };
