import DomainResource from './DomainResource';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import merge from 'lodash/merge';
import Period from './types/Period';
import Reference from './types/Reference';
import TaskRestriction from './types/TaskRestriction';
import Annotation from './types/Annotation';
import TaskKanban from './TaskKanban';

const taskStatusValues = {
    draft: 'draft',
    requested: 'requested',
    received: 'received',
    accepted: 'accepted',
    rejected: 'rejected',
    ready: 'ready',
    cancelled: 'cancelled',
    inProgress: 'in-progress',
    onHold: 'on-hold',
    failed: 'failed',
    completed: 'completed',
    enteredInError: 'entered-in-error',
};

const taskIntentValues = {
    unknown: 'unknown',
    proposal: 'proposal',
    plan: 'plan',
    order: 'order',
    originalOrder: 'original-order',
    reflexOrder: 'reflex-order',
    fillerOrder: 'filler-order',
    instanceOrder: 'instance-order',
    option: 'option',
};

const taskCodeTaskTypeValues = {
    approve: 'approve',
    fulfill: 'fulfill',
    abort: 'abort',
    replace: 'replace',
    change: 'change',
    suspend: 'suspend',
    resume: 'resume',
};

const taskStatusDisplayValues = {
    draft: 'Draft',
    requested: 'Requested',
    received: 'Received',
    accepted: 'Accepted',
    rejected: 'Rejected',
    ready: 'Ready',
    cancelled: 'Cancelled',
    'in-progress': 'In Progress',
    'on-hold': 'On Hold',
    failed: 'Failed',
    completed: 'Completed',
    'entered-in-error': 'Entered in Error',
};

const taskIntentDisplayValues = {
    unknown: 'Unknown',
    proposal: 'Proposal',
    plan: 'Plan',
    order: 'Order',
    'original-order': 'Original Order',
    'reflex-order': 'Reflex Order',
    'filler-order': 'Filler Order',
    'instance-order': 'Instance Order',
    option: 'Option',
};

export default class Task extends DomainResource {
    static __className = 'Task';

    __objectStructure = {
        identifier: [Identifier], // Additional identifier for the questionnaire
        instantiatesCanonical: String,
        instantiatesUri: String,
        basedOn: [Reference],
        groupIdentifier: Identifier,
        partOf: [Reference],
        status: String,
        statusReason: String,
        businessStatus: String,
        intent: String,
        priority: String,
        code: CodeableConcept,
        description: String,
        focus: Reference,
        for: Reference,
        encounter: Reference,
        executionPeriod: Period,
        authoredOn: String,
        lastModified: String,
        requester: Reference,
        performerType: [CodeableConcept],
        owner: Reference,
        location: Reference,
        reasonCode: CodeableConcept,
        reasonReference: Reference,
        insurance: Reference,
        note: [Annotation],
        relevantHistory: Reference,
        restriction: TaskRestriction,
        /**
         * First Class extensions
         */
        groupTask: Reference,
        kanban: TaskKanban,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'Task') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}

export { taskStatusValues, taskIntentValues, taskCodeTaskTypeValues, taskStatusDisplayValues, taskIntentDisplayValues };
