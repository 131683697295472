import BackboneElement from './BackboneElement';
import merge from 'lodash/merge';
import Coding from './types/Coding';

export default class PatientEthnicity extends BackboneElement {
    static __className = 'PatientEthnicity';

    __objectStructure = {
        ombCategory: Coding,
        detailed: [Coding],
        text: String,
    };

    constructor(constructJson, className = 'PatientEthnicity') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        Object.defineProperty(this, 'text', {
            get() {
                let currentDesc = [];
                if (this.__ombCategory) {
                    currentDesc.push(this.__ombCategory.display);
                }
                this.__detailed?.forEach((item) => {
                    currentDesc.push(item.display);
                });

                return currentDesc.length ? currentDesc.join(',') : undefined;
            },
        });

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
