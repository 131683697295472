export default {
    inheritAttrs: false,
    emits: ['update:modelValue'],
    props: {
        id: [String, Number],
        modelValue: [String, Number],
        label: {
            type: String,
        },
        labelClass: String,
        description: {
            type: String,
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('update:modelValue', value);
        },
    },
};
