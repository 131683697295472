import DomainResource from './DomainResource';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import Coding from './types/Coding';
import BN_Adjustment from './BN_Adjustment';
import CodeableConcept from './types/CodeableConcept';

export default class BN_PostPayment extends DomainResource {
    static __className = 'BN_PostPayment';

    __objectStructure = {
        patient: Reference, // "Patient"
        provider: Reference, // "Practitioner"
        encounter: Reference, // "Encounter"
        superbillLineItem: Reference, // "BN_SuperBillLineItem"
        charge: Number,
        payment: Reference, // "BN_Payment"
        amount: Number,
        date: String,
        type: Coding,
        account: Reference, // "Account"
        superBill: Reference,
        lateCharge: Number,
        interest: Number,
        transferTo: Coding,
        claimStatus: Coding,
        copay: Number,
        coinsurance: Number,
        deductible: Number,
        paid: Number,
        allowed: Number,
        withhold: Number,
        adjustments: [BN_Adjustment],
        responsibility: Coding,
        adjustment: Number,
        CARCode: Coding,
        RARCode: [Coding],
        claim: Reference,
        checkno: String,
        procedureCode: CodeableConcept,
        ERA: Reference,
        refLineItemControlNumber: String,
        insuranceBalance: Number,
        patientBalance: Number,
        unit: Number,
        unitCharge: Number,
    };

    constructor(constructJson, className = 'BN_PostPayment') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
