<script>
export default {
    name: 'BnTooltip',
    props: {
        value: Boolean,
        icon: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'info',
        },
        message: {
            type: String,
            default: undefined,
        },
        nodelay: Boolean,
    },
    computed: {
        faIcon() {
            let icon;
            switch (this.type) {
                case 'info':
                    icon = 'info-circle';
                    break;
                case 'warning':
                case 'error':
                    icon = 'exclamation-circle';
                    break;
            }
            return icon;
        },
        color() {
            return 'text-' + this.type;
        },
    },
};
</script>

<template>
    <v-tooltip :value="value" v-bind="$attrs" close-delay="100" :open-delay="nodelay ? 0 : 300">
        <template #activator="{ props }">
            <div v-bind="props">
                <slot></slot>
            </div>
        </template>
        <v-card max-width="250" color="white" class="d-flex px-2 py-1 text-black">
            <div v-if="icon" class="mr-2" :class="color">
                <font-awesome-icon :icon="['far', faIcon]" />
            </div>
            <div>
                <span v-if="message">{{ message }}</span>
                <slot v-else name="message"></slot>
            </div>
        </v-card>
    </v-tooltip>
</template>
