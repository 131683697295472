import DomainResource from '@/fhirworks/DomainResource';
import Identifier from '@/fhirworks/types/Identifier';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';
import Reference from '@/fhirworks/types/Reference';
import Signature from '@/fhirworks/Signature';
import merge from 'lodash/merge';

export default class Provenance extends DomainResource {
    static __className = 'Provenance';

    __objectStructure = {
        identifier: [Identifier],
        target: [Reference],
        recorded: String,
        agent: [Signature],
        reason: [CodeableConcept],
        signature: [Signature],
    };
    constructor(constructJson, className = 'Consent') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
