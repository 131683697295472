const decimal = {
    install(app) {
        app.directive('decimal', {
            bind(el, binding, vnode) {
                let precision = binding.value?.precision || 2;
                let appendDecimal = binding.value?.appendDecimal || true;

                el.onkeydown = function (event) {
                    // allow normal keys to be pressed for navigation and deleting
                    if (event.code === 'Backspace' || event.code === 'Delete' || event.code === 'ArrowLeft' || event.code === 'ArrowRight' || event.code === 'Tab') {
                        return true;
                    }
                    // if decimal is pressed and there isn't one allow it.
                    if (event.srcElement.value.toString().indexOf('.') === -1 && [190, 110].includes(event.keyCode)) {
                        return true;
                    }
                    if (event.srcElement.value.toString() === '' && event.keyCode === 190) {
                        event.preventDefault();
                    }
                    if (
                        event.srcElement.value.toString().indexOf('.') !== -1 &&
                        event.srcElement.value.toString().substring(event.srcElement.value.toString().indexOf('.') + 1, event.srcElement.value.toString().length).length === precision
                    ) {
                        event.preventDefault();
                    }
                    if ((event.shiftKey || event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                        event.preventDefault();
                    }
                };

                if (appendDecimal) {
                    el.addEventListener(
                        'blur',
                        function (event) {
                            event.target.value = Number(event.target.value).toFixed(precision);

                            vnode.elm.dispatchEvent(new CustomEvent('input'));
                            event.srcElement.dispatchEvent(new CustomEvent('input'));
                        },
                        true,
                    );
                }
            },
            inserted(el, binding) {
                let precision = binding.value?.precision || 2;
                let appendDecimal = binding.value?.appendDecimal || true;

                if (appendDecimal) {
                    let elements = el.getElementsByTagName('input');

                    for (let element of elements) {
                        if (element.value) {
                            element.value = Number(element.value).toFixed(precision);
                            element.dispatchEvent(new CustomEvent('input'));
                        }
                    }
                }
            },
        });
    },
};

export default decimal;
