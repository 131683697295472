import BackboneElement from './BackboneElement';
import Period from './types/Period';

import merge from 'lodash/merge';

export default class EncounterStatusHistory extends BackboneElement {
    static __className = 'EncounterStatusHistory';

    __objectStructure = {
        status: String, // planned | arrived | triaged | in-progress | onleave | finished | cancelled +
        period: Period,
    };

    constructor(constructJson, className = 'EncounterStatusHistory') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
