export const contactIcon = function (resourceType) {
    switch (resourceType) {
        case 'Group':
            return ['far', 'people-group'];
        case 'Location':
            return ['far', 'map-marker-alt'];
        case 'Room':
            return ['far', 'door-closed'];
        case 'User':
            return ['far', 'user-md'];
        case 'Organization':
            return ['far', 'university'];
        case 'Patient':
            return ['far', 'heartbeat'];
        case 'Person':
        case 'Practitioner':
        default:
            return ['far', 'user'];
    }
};

export const contactColor = function (resourceType) {
    switch (resourceType) {
        case 'Location':
        case 'Room':
            return 'related';
        case 'Organization':
            return 'organization';
        case 'Patient':
            return 'patient';
        case 'Person':
            return 'related';
        case 'Practitioner':
            return 'practitioner';
        case 'User':
            return 'user';
        default:
            return 'primary';
    }
};
