import { useAuthStore } from '@/stores/auth';

export function usePatientAccess() {
    const hasAccessToContact = (item) => {
        const authStore = useAuthStore();
        // if bnSupport OR not a patient OR no programs exist that weren't entered-in-error give access to patient
        if (item.resourceType !== 'Patient' || !item.temp?.programs?.length || item.temp?.programs.every((p) => p.status === 'entered-in-error') || authStore.isBnSupportUser) {
            return true;
        }

        // loop through programs and see if user has same program/location
        let hasAccess = false;
        for (let i = 0; i < item.temp.programs.length; i++) {
            let program = item.temp.programs[i];

            if (program.status === 'entered-in-error') {
                continue;
            }

            let hasProgram = authStore.practitionerRoles.find((role) => {
                if (role.programId !== program.program || !program.locations.includes(role.locationId) || role?.restricted) {
                    return false;
                }

                let hasStatus = false;

                if (['active', 'triaged', 'in-progress', 'onleave', 'cancelled'].includes(program.status)) {
                    hasStatus = role.active;
                } else if (['planned', 'arrived'].includes(program.status)) {
                    hasStatus = role.inquiry;
                } else if (program.status === 'finished') {
                    hasStatus = role.past;
                }

                return hasStatus;
            });

            if (hasProgram) {
                hasAccess = true;
                break;
            }
        }

        return hasAccess;
    };

    const addProgramsToContacts = (contacts, encounters) => {
        contacts.forEach((c) => {
            if (c.resourceType !== 'Patient') return;

            c.temp.programs = encounters
                .filter((e) => e.subject?.id === c.id)
                .map((e) => ({
                    status: e.status,
                    program: e.healthcareService?.id,
                    locations: e.location.map((loc) => loc.location?.id),
                }));
        });
    };

    const hasAccessToProgram = (program, location) => {
        const authStore = useAuthStore();
        if (!program || !location) {
            return false;
        }
        let hasProgram = authStore.practitionerRoles.find((role) => {
            if (role?.programId !== program?.id || location?.id !== role?.locationId || role?.restricted) {
                return false;
            }

            if (!program.status) {
                return true;
            }

            let hasStatus = false;

            if (['active', 'triaged', 'in-progress', 'onleave', 'cancelled'].includes(program.status)) {
                hasStatus = role.active;
            } else if (['planned', 'arrived'].includes(program.status)) {
                hasStatus = role.inquiry;
            } else if (program.status === 'finished') {
                hasStatus = role.past;
            }

            return hasStatus;
        });

        return !!hasProgram;
    };

    return { hasAccessToContact, addProgramsToContacts, hasAccessToProgram };
}
